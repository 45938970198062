import { gql, useLazyQuery } from '@apollo/client'
import { ProgramCompetitor } from '../../models'

const GET_COMPETITOR = gql`
  query GetProgramCompetitor($id: String!) {
    programCompetitor(id: $id) {
      id
      name
      medication
      ownerId
      active
    }
  }
`

interface CompetitorData {
  programCompetitor: ProgramCompetitor
}

export const useProgramCompetitor = () => {
  const [get, { loading, error, data }] = useLazyQuery<CompetitorData>(GET_COMPETITOR, {
    fetchPolicy: 'network-only',
  })

  const getProgramCompetitor = (id: string) => {
    get({
      variables: {
        id: id,
      },
    })
  }

  const competitor = data?.programCompetitor || undefined
  return { getProgramCompetitor, loading, error, competitor }
}
