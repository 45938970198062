import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useCallback, useState } from 'react'
import { ProfileEnterprises } from '../models'

const GET_PROFILE_ENTERPRISES = gql`
  query ProfileEnterprises($profileId: String!) {
    profile(id: $profileId) {
      id
      familyName
      enterprises {
        id
        name
        active
        domain
        subdomain
        products
        addresses {
          countryId
        }
      }
    }
  }
`

export interface ProfileEnterprisesQuery {
  profile: ProfileEnterprises
}

export const useProfileEnterprises = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<ProfileEnterprisesQuery>(GET_PROFILE_ENTERPRISES, {
    fetchPolicy: 'network-only',
  })

  const getProfileEnterprises = useCallback(
    (profileId: string) => {
      getResults({
        variables: { profileId },
      })
    },
    [getResults],
  )

  return { getProfileEnterprises, loading, error, data }
}
