import { useContext } from 'react'

import { Avatar, Box, Card, IconButton, Tooltip, alpha, styled } from '@mui/material'
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone'
import { SidebarContext } from 'src/admin/contexts/SidebarContext'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import HeaderUserbox from './Userbox'

import PlaceBetIcon from 'src/admin/core/svgIcons/PlaceBet/PlaceBetIcon'
import { getAppBarState } from 'src/store'

const HeaderWrapper = styled(Card)(
  ({ theme }) => `
    height: ${theme.header.height};
    color: ${theme.header.textColor};
    padding: ${theme.spacing(0, 2)};
    right: 0;
    z-index: 6;
    background-color: ${alpha(theme.colors.primary.main, 0.95)};
    backdrop-filter: blur(3px);
    position: fixed;
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
`,
)

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    margin-left: ${theme.spacing(2)};

    &.active,
    &:active,
    &:hover {
      background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
      color: ${theme.colors.alpha.trueWhite[100]};
    }
`,
)

const BoxLogoWrapper = styled(Box)(
  ({ theme }) => `
  margin-right: ${theme.spacing(2)};

  @media (min-width: ${theme.breakpoints.values.lg}px) {
    width: calc(${theme.sidebar.width} - ${theme.spacing(4)});
  }
    
`,
)

const stringAvatar = (name: string) => {
  const names = name.split(' ')
  let initials = names[0].substring(0, 1).toUpperCase()

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }

  return {
    children: initials,
  }
}

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext)
  const {
    user: { profile },
  } = getAppBarState().appState

  return (
    <HeaderWrapper>
      <Box display="flex" alignItems="center">
        <BoxLogoWrapper>
          <PlaceBetIcon />
        </BoxLogoWrapper>
      </Box>
      <Box display="flex" alignItems="center">
        {profile && (
          <>
            <Avatar {...stringAvatar(profile.given_name)} />
            <HeaderUserbox profile={profile} />
          </>
        )}
        <Box
          component="span"
          sx={{
            display: { lg: 'none', xs: 'inline-block' },
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButtonPrimary color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
            </IconButtonPrimary>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  )
}

export default Header
