import { gql, useLazyQuery } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { Metadata, ResultsList, SearchGraphql } from 'src/admin/core/models'
import { Agency } from '../models'

const GET_AGENCIES = gql`
  query GetAgencies($where: AgencyFilterInput, $skip: Int, $take: Int) {
    agencies(where: $where, skip: $skip, take: $take) {
      items {
        id
        name
        active
        type
        products {
          code
          name
          providerCode
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface Data {
  agencies: {
    totalCount: number
    items: Agency[]
  }
}

export const useAgencies = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<Data>(GET_AGENCIES, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<Agency, Metadata>>({
    results: [],
    metadata: {},
  })

  const getAgencies = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({
        variables: {
          where: { ...where },
          skip: skip ? skip : 0,
          take: take ? take : 500,
        },
      })
    },
    [getResults],
  )

  useEffect(() => {
    if (data?.agencies) {
      setResults({
        results: data?.agencies.items || [],
        metadata: {
          Count: data?.agencies.totalCount,
        },
      })
    }
  }, [loading])

  return { getAgencies, loading, error, results }
}
