import { FormPage, generateFilterGraphql } from 'src/admin/core'
import { useAppTranslation, useNotification } from 'src/admin/core/hooks'
import { useCallback, useContext, useEffect } from 'react'
import { useRunnerFormHandler, useRunners, useUpsertProgramCompetitor } from '../../hooks'
import { Form } from './Form'
import { Stack } from '@mui/material'
import Runners from '../Runners'
import { EventContext, setRunners } from '../../state'
import { Race } from '../../models'
import { Loader } from 'src/admin/components/Loader'

const Runner = ({ race }: { race: Race }) => {
  const { label } = useAppTranslation()
  const { successNotification, errorNotification } = useNotification()
  const { formSchema, initialValues } = useRunnerFormHandler()
  const { results, getRunners, loading } = useRunners()
  const { upsertProgramCompetitor } = useUpsertProgramCompetitor()

  const runners = results.results

  useEffect(() => {
    if (race?.id) {
      fetchRunnersByRaceId(race.id)
    }
  }, [race])

  const afterSave = useCallback(
    (response: Promise<any>) => {
      response
        .then((res) => {
          const runner = res.data.upsertProgramRunner
          saveCompetitorMedications(runner.competitorId, runner.medication)
          successNotification(label(initialValues.id ? 'DATO_ACTUALIZADO' : 'DATO_REGISTRADO'))
          fetchRunnersByRaceId(race.id)
        })
        .catch((e) => {
          errorNotification(label(e.message))
        })
    },
    [race],
  )

  const saveCompetitorMedications = (id: string, medication: string) => {
    const response = upsertProgramCompetitor({
      variables: {
        input: {
          id,
          medication,
        },
      },
    })
  }
  const fetchRunnersByRaceId = (raceId: number) => {
    const customFilters = {
      and: [{ programRaceId: { ...generateFilterGraphql(raceId, 'eq') } }],
    }
    getRunners({
      where: customFilters,
      skip: 0,
      take: 100,
      order: {
        number: 'ASC',
      },
    })
  }

  return race?.id ? (
    <FormPage
      form={{
        formSchema: formSchema,
        formValues: initialValues,
      }}
    >
      <Stack spacing={2}>
        <Form afterSave={afterSave} runners={runners} />
        <Loader loading={loading}>
          <Runners runners={runners} />
        </Loader>
      </Stack>
    </FormPage>
  ) : null
}

export default Runner
