import React, { useCallback, useContext, useEffect, useState } from 'react'
import { TextField } from 'formik-material-ui'

import { Autocomplete, AutocompleteChangeReason, CircularProgress } from '@mui/material'
import { debounce } from 'lodash'
import { IFormField } from 'src/admin/core/models'
import { useProgramTypeOfRaces } from '../../hooks'
import { ProgramTypeOfRaces } from '../../models'
import { generateFilterGraphql } from 'src/admin/core'
import { useFormikContext } from 'formik'
import { EventContext, setTypeOfRaces } from '../../state'

export interface TypeOfRacesFormikFormikProps extends IFormField {
  keyValue: string
  keyLabel?: string
  isLoading?: boolean
  label?: string
  defaultValue?: any
  typeId?: string
  onChangeValue?: (value: any, action: string) => void
  onInputValueChange?: (value: string, fieldName: string, action: string) => void
  onSelectOpen?: () => void
  disabled?: boolean
  multiple?: boolean
  props?: any
}

export const TypeOfRacesFormik = ({
  keyValue,
  keyLabel,
  isLoading,
  label,
  onChangeValue,
  onInputValueChange,
  onSelectOpen,
  defaultValue,
  disabled,
  multiple,
  typeId,
  ...props
}: TypeOfRacesFormikFormikProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props
  const [open, setOpen] = useState(false)
  const { results, getProgramTypeOfRaces, loading } = useProgramTypeOfRaces()
  const [defaultType, setDefaultType] = useState<ProgramTypeOfRaces | undefined>(defaultValue)
  const data = results.results

  const { dispatch } = useContext(EventContext)

  useEffect(() => {
    if (typeId) {
      const object = data.filter((item) => item.code == typeId)
      if (object.length > 0) {
        setDefaultType(object[0])
        setFieldValue(name, object[0])
      }
    }
    dispatch(setTypeOfRaces(data))
  }, [typeId, data])

  useEffect(() => {
    getProgramTypeOfRaces({
      where: {
        and: [
          {
            name: { ...generateFilterGraphql('', 'contains') },
            active: { ...generateFilterGraphql(true, 'eq') },
          },
        ],
      },
      skip: 0,
      take: 500,
    })
  }, [])

  const onSelect = (event: React.ChangeEvent<any>, value: any, reason: AutocompleteChangeReason) => {
    try {
      if (reason === 'selectOption') {
        setFieldValue(name, value)
        if (onChangeValue instanceof Function) {
          onChangeValue(value, reason)
        }
      }
      if (reason === 'clear') {
        setFieldValue(name, value)
        if (onChangeValue instanceof Function) {
          onChangeValue(value, reason)
        }
      }
    } catch (e) {}
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    debouncedDispatchChangeQuery(value)
  }

  const debouncedDispatchChangeQuery = useCallback(
    debounce((value: string) => {
      if (onInputValueChange instanceof Function) {
        if (!checkIfValueExistsInLocalData(value)) {
          onInputValueChange(value, name, 'typing')
        }
      }
    }, 500),
    [onInputValueChange],
  )

  const checkIfValueExistsInLocalData = (value: string): boolean => {
    if (value.length > 0 && data.length > 0) {
      return data.some((item) => item[keyValue].includes(value))
    } else {
      console.log(value)
      return false
    }
  }

  const handleClose = () => {
    setOpen(false)
    if (onInputValueChange instanceof Function) {
      onInputValueChange('', name, 'close')
    }
  }

  const handleOpen = () => {
    setOpen(true)
    if (onSelectOpen instanceof Function) {
      onSelectOpen()
    }
  }

  return (
    <Autocomplete
      {...props}
      defaultValue={values[name] ? values[name] : defaultType}
      disabled={disabled}
      value={values[name]}
      onChange={onSelect}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      filterSelectedOptions={true}
      getOptionLabel={(option) => (option['name'] ? option['name'] : '')}
      options={data}
      loading={isLoading}
      multiple={multiple}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          onChange={onInputChange}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget
          if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
            console.log('More data')
          }
        },
      }}
    />
  )
}
