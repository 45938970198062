import React, { ReactElement, ReactNode } from 'react'

// Theme Components
import Status401 from 'src/content/pages/Status/Status401'

type PageProps = {
  children: React.ReactElement | React.ReactElement[]
  hasAccess?: boolean
}

export const SecurityPage = ({ hasAccess, children }: PageProps) => {
  return hasAccess !== undefined && !hasAccess ? <Status401 /> : <React.Fragment>{children}</React.Fragment>
}
