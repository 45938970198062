import React, { useEffect, useState } from 'react'
import { useWorkstations } from '../hooks'
import { CustomAutocomplete } from 'src/admin/core/components/Select/CustomAutoComplete'
import { generateFilterGraphql } from 'src/admin/core/services'
import { Workstation } from '../models'
import { useAppTranslation } from 'src/admin/core/hooks'

export type WorkstationProps = {
  locationId?: number | string
  onChangeValue?: (value: Workstation, action: string) => void
}

export const WorkstationSelect: React.FC<WorkstationProps> = ({ locationId, onChangeValue }) => {
  const { results, loading, getWorkstations } = useWorkstations()
  const [station, setStation] = useState<Workstation | null>(null)
  const { label } = useAppTranslation()

  useEffect(() => {
    setStation(null)
    fetchWorkstation(locationId)
  }, [locationId])

  const fetchWorkstation = (locationId: number | string) => {
    const customFilters = {
      and: [{ agencyId: { ...generateFilterGraphql(locationId, 'eq') } }],
    }

    getWorkstations({ skip: 0, take: 100, where: customFilters })
  }

  const handleChange = (value: Workstation, action: string) => {
    if (value) {
      setStation(value)
    } else {
      setStation(null)
    }
    onChangeValue(value, action)
  }

  return (
    <CustomAutocomplete
      onChangeValue={handleChange}
      data={results.results}
      placeholder={label('ESTACION')}
      isLoading={loading}
      keyLabel={'name'}
      defaultValue={station}
      label={label('ESTACION')}
    />
  )
}
