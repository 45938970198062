import { WorkstationType } from '../models'
import { gql, useLazyQuery } from '@apollo/client'
import { Metadata, ResultsList } from '../../../../core/models'
import { useState, useEffect, useCallback } from 'react'

const GET_WORKSTATION_TYPES = gql`
  query getWorkstationType($locationId: Int!) {
    workstationType(locationId: $locationId) {
      items {
        id
        name
      }
    }
  }
`

interface WorkstationTypeData {
  workstationType: {
    items: WorkstationType[]
  }
}

export const useWorkstationTypes = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<WorkstationTypeData>(GET_WORKSTATION_TYPES, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<WorkstationType, Metadata>>({
    results: [],
    metadata: {},
  })

  const getWorkstationTypes = useCallback(
    (locationId) => {
      getResults({
        variables: {
          locationId: locationId,
        },
      })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.workstationType.items || [],
      metadata: {},
    })
  }, [data?.workstationType.items, loading])
  return { loading, error, results, getWorkstationTypes }
}
