import React from 'react'
import { FormikProvider, Form, useFormik, FormikHelpers } from 'formik'
import { IForm } from '../../models'

type WrapperFormikProviderProps = {
  form: IForm
  children: JSX.Element | React.ReactElement[]
}

export const WrapperFormikProvider = ({ form, children }: WrapperFormikProviderProps) => {
  const formik = useFormik({
    validationSchema: form.formSchema,
    initialValues: form.formValues,
    onSubmit: form.handleSubmit ? form.handleSubmit : () => {},
    enableReinitialize: true,
  })

  return (
    <FormikProvider value={formik}>
      <Form>{children}</Form>
    </FormikProvider>
  )
}
