import { useWorkstation } from './useWorkstation'
import * as Yup from 'yup'
import { useAppTranslation } from 'src/admin/core/hooks'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { phoneRegExp } from 'src/admin/core'
import { Workstation } from '../models'

const initialValues: Workstation = {
  id: undefined,
  name: '',
  workstationTypeId: 'NONE',
  securityCode: '',
  active: true,
}

export const useFormHandler = () => {
  const { label } = useAppTranslation()
  const { id } = useParams()
  const { getWorkstation, data, loading } = useWorkstation()

  useEffect(() => {
    if (Number.parseInt(id) > 0) {
      getWorkstation(Number(id))
    }
  }, [id])

  const formSchema = Yup.object().shape({
    name: Yup.string().required(label('REQUERIDO')),
  })

  return { loading, formSchema, initialValues: data?.workstation || initialValues }
}
