import { ReactElement, ReactNode, useMemo, useReducer } from 'react'
import { ProgramSelectOption } from './ProgramSelectOption'
import { ProgramSelectOptionContext } from './ProgramSelectOptionContext'
import { reducer } from './reducer'

export const ProgramSelectOptionProvider = ({
  programSelectOptions,
  programSelectOption,
  children,
}: {
  programSelectOptions?: ProgramSelectOption[]
  programSelectOption?: ProgramSelectOption
  children: ReactElement | ReactNode
}) => {
  const [state, dispatch] = useReducer(reducer, {
    programSelectOption,
    programSelectOptions,
  })

  const contextValue = useMemo(() => {
    return { state, dispatch }
  }, [state, dispatch])

  return <ProgramSelectOptionContext.Provider value={contextValue}>{children}</ProgramSelectOptionContext.Provider>
}
