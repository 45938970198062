import { Geographic } from './../../../../../core/models/workstations/models'
import * as yup from 'yup'
import { useAppTranslation } from 'src/admin/core/hooks'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useAgency } from './useAgency'
import { Agency } from '../models'
import _ from 'lodash'
import { Address } from 'src/admin/core/models'
import { GeographicTypeEnum } from 'src/core/models'

export interface AgencyForm extends Agency {
  address: string
  cityOrSectorId: any
  countryId: string
  countyOrMunicipalityId: any
  stateOrProvinceId: any
}

const initialValues: Agency = {
  id: '',
  name: '',
  type: 'STANDARD',
  products: [],
  addresses: [],
  address: '',
  cityOrSectorId: '',
  countryId: '',
  countyOrMunicipalityId: '',
  stateOrProvinceId: '',
  active: true,
  phone: '',
  geographic: {
    type: GeographicTypeEnum.Point,
    coordinates: [],
    latitude: 0,
    longitude: 0,
  },
}

export const useFormHandler = () => {
  const { label } = useAppTranslation()
  const { id } = useParams()
  const { getAgency, agency, loading } = useAgency()
  const [agencyToUpdate, setAgencyToUpdate] = useState<Agency>(null)

  useEffect(() => {
    if (id) {
      getAgency(id)
    }
  }, [id])

  useEffect(() => {
    if (agency) {
      const clonedAgency = _.cloneDeep(agency)
      const address: Address =
        clonedAgency.addresses && clonedAgency.addresses?.length > 0 ? clonedAgency.addresses[0] : null
      if (address) {
        clonedAgency.address = address.address
        clonedAgency.cityOrSectorId = { id: address.cityOrSectorId }
        clonedAgency.countryId = { id: address.countryId }
        clonedAgency.countyOrMunicipalityId = { id: address.countyOrMunicipalityId }
        clonedAgency.stateOrProvinceId = { id: address.stateOrProvinceId }
      }
      if (clonedAgency.geographic) {
        clonedAgency.geographic.longitude =
          clonedAgency.geographic.coordinates?.length > 0 ? clonedAgency.geographic.coordinates[0] : 0
        clonedAgency.geographic.latitude =
          clonedAgency.geographic.coordinates?.length > 1 ? clonedAgency.geographic.coordinates[1] : 0
      } else {
        clonedAgency.geographic = {
          longitude: 0,
          latitude: 0,
          coordinates: [],
          type: GeographicTypeEnum.Point,
        }
      }

      setAgencyToUpdate(clonedAgency)
    }
  }, [agency])

  const formSchema = yup.object().shape({
    name: yup.string().required(label('REQUERIDO')),
    products: yup.array().min(1, label('REQUERIDO')),
    stateOrProvinceId: yup.object().required(label('REQUERIDO')).nullable(),
    geographic: yup.object({
      latitude: yup
        .number()
        .min(-90, label('MINIMO') + ' -90')
        .max(90, label('MAXIMO') + ' 90'),
      longitude: yup
        .number()
        .min(-180, label('MINIMO') + ' -180')
        .max(180, label('MAXIMO') + ' 180'),
    }),
  })

  return { loading, formSchema, initialValues: agencyToUpdate || initialValues }
}
