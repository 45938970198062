import React, { ReactElement } from 'react'
import { Accordion, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

type PermissionAccordionProps = {
  id: number
  header: ReactElement
  details: ReactElement
}

export const PermissionAccordion = ({ id, header, details }: PermissionAccordionProps) => {
  const [expanded, setExpanded] = React.useState<boolean>(false)

  const toggleAccordion = () => {
    setExpanded(!expanded)
  }

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon onClick={() => toggleAccordion()} />}
        aria-controls="panel1bh-content"
        id={`header-${id}`}
      >
        {header}
      </AccordionSummary>
      {details}
    </Accordion>
  )
}
