import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useProvinces } from '../../hooks/geography'
import { CustomAutocomplete, generateFilterGraphql } from '../..'
import { IFormField, Province } from '../../models'
import { Autocomplete, AutocompleteChangeReason, TextField } from '@mui/material'

export interface ProvinceSelectProps extends IFormField {
  label?: string
  countryId: string
  onChangeProvince: (province: Province) => void
  disabled?: boolean
  defaultValue?: string
}

export const ProvinceSelect = ({
  countryId,
  onChangeProvince,
  defaultValue,
  disabled,
  ...props
}: ProvinceSelectProps) => {
  const {
    form: { setFieldValue, values, errors },
    field: { name },
  } = props

  const { getProvinces, results, loading } = useProvinces()
  const { t: transLabel } = useTranslation()
  const [selected, setSelected] = useState<any>(null)
  const [inputValue, setInputValue] = useState('')
  const provinces = results.results

  useEffect(() => {
    if (countryId !== '') {
      filterProvinces(countryId)
    }
  }, [countryId])

  useEffect(() => {
    if (results.results.length > 0 && defaultValue && !selected) {
      const filter = results.results.filter((item: any) => item.id === defaultValue)
      if (filter.length > 0) {
        setSelected(filter[0])
        setFieldValue(name, filter[0])
      }
    }
  }, [defaultValue, provinces])

  const filterProvinces = (countryId: string, skip = 0, take = 100) => {
    const customFilters = {
      and: [{ countryId: { ...generateFilterGraphql(countryId, 'eq') } }],
    }

    getProvinces({ where: customFilters, skip, take })
  }

  const handleChange = useCallback(
    (event: React.ChangeEvent<any>, value: any, reason: AutocompleteChangeReason) => {
      setFieldValue(name, value)
      setSelected(value)
      if (onChangeProvince instanceof Function) {
        onChangeProvince(value)
      }
    },
    [setFieldValue, name, onChangeProvince],
  )

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setInputValue(value)
  }

  return (
    <Autocomplete
      sx={{
        m: 0,
        minWidth: 'inherit',
      }}
      onChange={handleChange}
      value={selected}
      getOptionLabel={(option) => (option['name'] ? option['name'] : '')}
      options={provinces}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={onInputChange}
          label={transLabel('PROVINCIA')}
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors[name]}
          helperText={errors[name] ?? ''}
          autoComplete={'off'}
        />
      )}
      disabled={disabled}
      {...props}
    />
  )
}
