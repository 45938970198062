import { ActionType, createAction } from 'typesafe-actions'
import { Event, FORM_MODE, ProgramTypeOfRaces, Race, Runner } from '../models'

// Event
export const setEvent = createAction('SET_EVENT')<Event>()
export const setEventFormMode = createAction('SET_EVENT_FORM_MODE')<FORM_MODE>()

// Race
export const setRace = createAction('SET_RACE')<Race>()
export const setRaces = createAction('SET_RACES')<Race[]>()
export const setRaceFormMode = createAction('SET_FORM_RACE_MODE')<FORM_MODE>()
export const setTypeOfRaces = createAction('SET_TYPE_OF_RACES')<ProgramTypeOfRaces[]>()

// Runner
export const setRunner = createAction('SET_RUNNER')<Runner>()
export const setRunners = createAction('SET_RUNNERS')<Runner[]>()
export const setRunnerFormMode = createAction('SET_FORM_RUNNER_MODE')<FORM_MODE>()

export type EventActions = ActionType<
  | typeof setEvent
  | typeof setRace
  | typeof setRaces
  | typeof setRunner
  | typeof setRunners
  | typeof setEventFormMode
  | typeof setRunnerFormMode
  | typeof setRaceFormMode
  | typeof setTypeOfRaces
>
