import { FormPage, PageTab, PageHeader, FormContent, BackButton } from 'src/admin/core'
import { useAppTranslation, useNotification, useUserClaimActions } from 'src/admin/core/hooks'
import { Form } from './Form'
import { useNavigate } from 'react-router-dom'
import { useCallback, useState } from 'react'
import { useUserFormHandler, usePasswordResetCode } from '../hooks'
import { Loader } from 'src/admin/components/Loader'
import _ from 'lodash'
import { CreateButton } from './CreateButton'
import { UpdateButton } from './UpdateButton'
import { Box, Button, Stack, styled } from '@mui/material'
import { green } from '@mui/material/colors'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { User as UserModel } from '../models'
import { UserCredentialsBanner } from '../components/UserCredentialsBanner'
import { UserCredentials } from '../models/UserCredentials'
import { ClaimActions } from 'src/admin/core/models'
import { SecurityPage } from 'src/admin/core/components/Page/SecurityPage'

const ColorButton = styled(Button)(({ theme }) => ({
  root: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))

const User: React.FC = () => {
  const { label, system } = useAppTranslation()
  const navigate = useNavigate()
  const { successNotification, errorNotification } = useNotification()
  const { formSchema, initialValues, loading, fetchUser } = useUserFormHandler()
  const { passwordResetCode } = usePasswordResetCode()
  const [userCredentials, setUserCredentials] = useState<UserCredentials | undefined>(undefined)
  const [isReloadingUser, setReloadingUser] = useState(false)
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('security.users', [
    ClaimActions.CanCreate,
    ClaimActions.CanView,
    ClaimActions.CanEdit,
  ])
  const disabledForm = !actions.canEdit
  const actionPassword = validateClaimActions('security.change-password', [
    ClaimActions.CanCreate,
    ClaimActions.CanEdit,
  ])

  const afterSavedRole = () => {
    fetchUser(initialValues.id)
    setReloadingUser(true)
  }

  const afterSave = useCallback((response: Promise<any>, action?: string) => {
    response
      .then((res) => {
        successNotification(label(initialValues.id ? 'DATO_ACTUALIZADO' : 'DATO_REGISTRADO'))
        if (action === 'create') {
          const createdUser = _.get(res, 'data.createUser', null) as UserModel
          navigate(`/admin/security/users/${createdUser.id}`)
          setUserCredentials({
            userName: createdUser.userName,
            passwordResetCode: undefined,
            code: createdUser.passwordResetCode,
          })
        } else {
          navigate(`/admin/security/users`)
        }
      })
      .catch((e) => {
        errorNotification(e.message)
      })
  }, [])

  const handleGenerateCode = (user: UserModel) => {
    if (user && user.id) {
      const response = passwordResetCode({
        variables: {
          input: {
            owner: user.id,
            lifetimeInSeconds: 2592000,
          },
        },
      })
      response.then((result: any) => {
        const code = _.get(result, 'data.passwordResetCode.passwordResetCode', undefined)
        if (code && user.userName) {
          setUserCredentials({
            userName: user.userName,
            passwordResetCode: undefined,
            code: code,
          })
        }
      })
    } else {
      errorNotification(system('USUARIO_INVALIDO'))
    }
  }

  return (
    <SecurityPage hasAccess={actions.canView}>
      {userCredentials && (
        <Box sx={{ p: 1.5, pb: 0 }}>
          <UserCredentialsBanner
            userName={userCredentials.userName}
            password={userCredentials.passwordResetCode}
            code={userCredentials.code}
          />
        </Box>
      )}
      <FormPage
        form={{
          formSchema: formSchema,
          formValues: initialValues,
        }}
      >
        <PageTab text={label('USUARIO_TITULO')} />
        <PageHeader
          title={label('USUARIO_SUB_TITULO')}
          actions={
            <Stack spacing={1} direction={'row'}>
              <BackButton />
              {initialValues && initialValues.id ? (
                <>
                  {(actionPassword.canEdit || actionPassword.canCreate) && (
                    <ColorButton
                      color="secondary"
                      variant="contained"
                      onClick={() => handleGenerateCode(initialValues)}
                      startIcon={<LockOpenIcon />}
                    >
                      {label('CAMBIAR_CLAVE')}
                    </ColorButton>
                  )}

                  {actions.canEdit && <UpdateButton afterSave={afterSave} />}
                </>
              ) : (
                actions.canCreate && <CreateButton afterSave={afterSave} />
              )}
            </Stack>
          }
        />
        <FormContent>
          <Form user={initialValues} afterSavedRole={afterSavedRole} disabledForm={disabledForm} />
        </FormContent>
      </FormPage>
    </SecurityPage>
  )
}

export default User
