import { useCallback, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, useTheme } from '@mui/material'
import { GridRowParams } from '@mui/x-data-grid'

// App Components
import { DataGridColumns } from 'src/admin/core/components/DataGrid/CustomDataGrid'
import { AdminDataGrid } from 'src/admin/core/components/DataGrid/AdminDataGrid'
import { Page } from 'src/admin/core/components'
import { CreateButton, generateFilterGraphql } from 'src/admin/core'
import { ContentDataGrid } from 'src/admin/core/components/DataGrid'

import { useAppTranslation, usePagination, useUserClaimActions } from 'src/admin/core/hooks'
import Filters, { FiltersProps } from './Filters'
import { useAgencies } from './hooks'
import { getAppBarState } from 'src/store'
import { ClaimActions } from 'src/admin/core/models'

const Agencies = () => {
  const theme = useTheme()
  const { label } = useAppTranslation()
  const navigate = useNavigate()
  const { results, loading, getAgencies } = useAgencies()
  const { page, pageSize, skip, handlePageChange, handlePageSizeChange } = usePagination()
  const [currentFilters, setCurrentFilters] = useState<FiltersProps>({ search: '' })
  const { userEnterprise } = getAppBarState().appState
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.agencies', [ClaimActions.CanView])

  useEffect(() => {
    const customFilters = getCustomFilters(currentFilters)

    getAgencies({ where: customFilters, skip: skip, take: pageSize })
  }, [currentFilters, skip, pageSize])

  const getCustomFilters = (filters: FiltersProps) => {
    const customFilters = {
      and: [
        { name: { ...generateFilterGraphql(filters.search, 'contains') } },
        { name: { ...generateFilterGraphql('ONLINE_AGENCY', 'neq') } },
        { enterpriseId: { ...generateFilterGraphql(userEnterprise.id, 'eq') } },
      ],
    }

    return customFilters
  }

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      NOMBRE: { field: 'name', contentType: 'string', align: 'left' },
      TIPO: { field: 'type', contentType: 'string', align: 'left' },
      ESTADO: { field: 'active', contentType: 'boolean', headerAlign: 'left', align: 'left' },
    }
  }, [])

  const goToCreate = () => {
    navigate(`/admin/entities/agencies/create`)
  }

  const goToEdit = (row: GridRowParams) => {
    if (actions.canEdit) {
      navigate(`/admin/entities/agencies/${row.id}`)
    }
  }

  return (
    <Page
      hasAccess={actions.canView}
      tabTitle={label('AGENCIA_TITULO')}
      pageTitle={label('AGENCIA_TITULO')}
      actions={actions.canCreate && <CreateButton onClick={goToCreate} />}
    >
      <Grid item xs={12}>
        <Filters onChange={(filters) => setCurrentFilters(filters)} />
      </Grid>
      <Grid item xs={12}>
        <ContentDataGrid pageSize={pageSize}>
          <AdminDataGrid
            columns={getColumnDefinitions()}
            data={results.results}
            onRowClick={goToEdit}
            count={results.metadata.Count}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            loading={loading}
            page={page}
            pageSize={pageSize}
          />
        </ContentDataGrid>
      </Grid>
    </Page>
  )
}

export default Agencies
