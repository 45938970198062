import { DownloadButton as Button } from 'src/admin/core'
import { useCallback, useContext, useEffect } from 'react'
import { useAppTranslation, useNotification, useRestClient } from 'src/admin/core/hooks'
import { EventContext } from '../../state'
import { useGetProgramFile } from '../../hooks'
import { saveAs } from 'file-saver'
import { dateIsBeforeThanTodayMoment } from 'src/admin/core/services/dateFunctions'

export const DownloadButton: React.FC = () => {
  const {
    state: { event },
  } = useContext(EventContext)
  const { downloadFile, response } = useRestClient()
  const { errorNotification, successNotification } = useNotification()
  const { getProgramFile, programFileDownloadUri } = useGetProgramFile()
  const { label, system } = useAppTranslation()

  useEffect(() => {
    getProgramFile(event.id)
  }, [event])

  useEffect(() => {
    if (response.errors.length > 0) {
      errorNotification(response.errors[0])
    }
  }, [response])

  const handleClick = useCallback(() => {
    if (event.id !== undefined) {
      if (!dateIsBeforeThanTodayMoment(event.date)) {
        saveAs(programFileDownloadUri.sasUri, programFileDownloadUri.fileName)

        successNotification(system('ARCHIVO_DESCARGADO'))
      } else {
        errorNotification(system('LA_FECHA_DEL_EVENTO_HA_PASADO'))
      }
    }
  }, [programFileDownloadUri])

  return <Button onClick={handleClick} />
}
