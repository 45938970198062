import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useAppTranslation } from 'src/admin/core/hooks'
import { useParams } from 'react-router-dom'
import { useEvent } from './useEvent'
import { Event } from '../../models'
import { formatToShortLocalDate } from 'src/admin/core/services/dateFunctions'
import _ from 'lodash'

const initialValues: Event = {
  id: '',
  name: '',
  date: new Date(),
  performanceCode: 'A',
  numberOfRaces: 6,
  active: true,
}

export const useEventFormHandler = () => {
  const { label } = useAppTranslation()
  const { id } = useParams()
  const { getEvent, event, loading: loadingEvent } = useEvent()
  const [customEvent, setCustomEvent] = useState<Event | null>(null)

  useEffect(() => {
    if (id && customEvent === null && !loadingEvent) {
      getEvent(id)
    }
  }, [id])

  useEffect(() => {
    if (event && customEvent === null) {
      const clonedEvent = _.cloneDeep(event)
      clonedEvent.date = formatToShortLocalDate(event.date, 'MM/DD/YYYY')
      setCustomEvent(clonedEvent)
    }
  }, [event])

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .max(30, '(30) ' + label('MAXIMO_CARACTERES'))
      .required(label('REQUERIDO')),
    // performanceCode: Yup.string().nullable().required(label('REQUERIDO')),
    date: Yup.date().nullable().required(label('REQUERIDO')).typeError(label('FECHA_INVALIDA')),
    numberOfRaces: Yup.number()
      .min(1, label('SOLO_VALORES_ENTRE') + ' 1-99')
      .max(99, label('SOLO_VALORES_ENTRE') + ' 1-99')
      .required(label('REQUERIDO')),
  })

  return {
    loading: loadingEvent,
    formSchema,
    initialValues: customEvent || initialValues,
    programSelectOptions: [],
  }
}
