import locales from './locales'

export default {
  [locales.ES]: {
    translation: {
      '+_RAPIDA': '+Rap',
      _SUPLIDOR: '%Suplidor',
      '1/2_INNING_VIS_GANA': '1/2 Inning Vis gana',
      '1_1/2_VIS_GANA': '1 1/2 vis gana',
      '1_INNING_COMPLETO': '1 Inning Completo',
      '1ER_INNING': '1er Inning',
      '1ER_PERIODO': '1er Periodo',
      '1RA_MITAD': '1era Mitad',
      '1RO': '1º',
      '2_1/2_IN_LOC_GANA': '2 1/2 In Loc Gana',
      '2_INNING_COMPLETOS': '2 INNING COMPLETOS',
      '2_OF_4': '2d4',
      '2_TEAM_4.5/6.5_PUNT': '2 Team 4.5/6.5 punt',
      '2_TEAM_5/7_PUNT': '2 Team 5/7 punt',
      '2DA_MITAD': '2da Mitad',
      '2DO': '2º',
      '2DO_INNING': '2do Inning',
      '2DO_PERIODO': '2do Periodo',
      '3_1/2_INNING': '3 1/2 Inning',
      '3_IN_COMPLETOS': '3 In completos',
      '3_OF_4': '3d4',
      '3_OF_5': '3d5',
      '3_OF_6': '3d6',
      '3_TEAM_4.5/6.5_PUNT': '3 Team 4.5/6.5 punt',
      '3_TEAM_5/7_PUNT': '3 Team 5/7 punt',
      '3_TEAM_6.5/9.5_PUNT': '3 Team 6.5/9.5 punt',
      '3_TEAM_6/9_PUNT': '3 Team 6/9 punt',
      '3_TEAM_7/10_PUNT': '3 Team 7/10 punt',
      '3_TEAMS_4/6_PUNTOS': '3 Teams 4/6 puntos',
      '3ER_INNING': '3er Inning',
      '3ER_PERIODO': '3er Periodo',
      '3RO': '3º',
      '4_1/2_IN_LOCAL_GANA': '4 1/2 In Local Gana',
      '4_INNINGS_COMPLETO': '4 Innings completo',
      '4_OF_4': '4d4',
      '4_OF_5': '4d5',
      '4_OF_6': '4d6',
      '4_TEAM_4.5/6.5_PUNT': '4 Team 4.5/6.5 punt',
      '4_TEAM_5/7PUNT': '4 Team 5/7punt',
      '4_TEAMS_4/6_PUNTOS': '4 Teams 4/6 puntos',
      '4TO': '4º',
      '4TO_INNING': '4to Inning',
      '4TO_PERIODO': '4to Periodo',
      '5_1/2_VIS_GANA': '5 1/2 vis gana',
      '5_INNINGS_COMPLETO': '5 Innings Completo',
      '5_OF_5': '5d5',
      '5_OF_6': '5d6',
      '5_TEAM_4.5/6.5_PUNT': '5 Team 4.5/6.5 punt',
      '5_TEAM_5/7_PUNT': '5 Team 5/7 punt',
      '5_TEAMS_4/6_PUNTOS': '5 Teams 4/6 puntos',
      '5TO': '5º',
      '5TO_INNING': '5to Inning',
      '6_1/2_INNINGS': '6 1/2 Innings',
      '6_INNINGS_COMPLETOS': '6 Innings completos',
      '6_OF_6': '6d6',
      '6_TEAM_4.5/6.5_PUNT': '6 Team 4.5/6.5 punt',
      '6_TEAM_5/7_PUNT': '6 Team 5/7 punt',
      '6_TEAMS_4/6_PUNTOS': '6 Teams 4/6 puntos',
      '6TO': '6º',
      '6TO_INNING': '6to Inning',
      '7_1/2_INNINGS': '7 1/2 Innings',
      '7_INNINGS_COMPLETOS': '7 Innings completos',
      '7_TEAM_4.5/6.5_PUNT': '7 Team 4.5/6.5 punt',
      '7_TEAM_5/7_PUNT': '7 Team 5/7 punt',
      '7_TEAMS_4/6_PUNTOS': '7 Teams 4/6 puntos',
      '7MO_INNING': '7mo Inning',
      '8_1/2_INNINGS': '8 1/2 Innings',
      '8_INNINGS_COMPLETO': '8 Innings completo',
      '8_TEAM_4.5/6.5_PUNT': '8 Team 4.5/6.5 punt',
      '8_TEAM_5/7_PUNT': '8 Team 5/7 punt',
      '8_TEAMS_4/6_PUNTOS': '8 Teams 4/6 puntos',
      '8VO_INNING': '8vo Inning',
      '9NO_INNING': '9no Inning',
      A_APOSTAR: 'Apostar',
      A_COBRAR: 'A cobrar',
      A_GANAR: 'Ganar',
      A_GANAR_IF: 'A Ganar IF',
      ACCESS_KEY: 'Access Key',
      ACCION: 'Acción',
      ACCOUNT_DEPOSIT: 'Depósito a cuenta',
      ACCOUNT_NOT_FOUND: 'Cuenta no encontrada',
      ACCOUNT_NUMBER: 'Número de cuenta',
      ACCOUNT_PIN: 'PIN',
      ACCOUNT_WITHDRAWAL: 'Retiro de cuenta',
      ACCOUNTTYPEPAYMENT: 'Efectivo',
      ACEPTAR: 'Aceptar',
      ACRONIMO: 'Acrónimo',
      ACTION_REVERSE: 'Juego Invertido',
      ACTIVAS: 'Activas',
      ACTIVE_HISTORY_DETAIL_TITLE: 'Histórico de transacciones',
      ACTIVO: 'Activo',
      ACTUAL: 'Actual',
      ACTUALIZACION: 'Actualización',
      ACTUALIZADO: 'Actualizado',
      ACTUALIZANDO_LA_COMPANIA: 'Actualizando company',
      ACUERDO_TERMINOS_Y_CONDICIONES: 'Acuerdo términos y condiciones',
      ACUERDO_TERMINOS_Y_CONDICIONES_LINK: 'http://placebet.com.do/terms',
      ADD_TO_TICKET: 'Agregar a ticket',
      ADMINISTRACION_DE_CAJAS: 'Administración de estaciones',
      ADMINISTRACION_DE_CAJAS_O_PUNTOS_DE_VENTA: 'Administración estaciones de trabajo',
      ADVERTENCIA: 'Advertencia',
      AGENCIA_SUB_TITULO: 'Lista y mantenimiento de agencias',
      AGENCIA_TITULO: 'Mantenimiento de agencias',
      AGENCIAS: 'Agencias',
      AGENCY_SITUATION: 'Situación de la agencia',
      AGENCIES_OPERATIONS: 'Operaciones de agencias',
      AGENCIES: 'Agencias',
      AJUSTE_DE_CUENTAS_DE_CLIENTE: 'Ajustes de cuentas',
      AJUSTE_DE_LA_CUENTA: 'Ajustes de cuenta',
      AMOUNT_BET: 'Apostado',
      ANTERIOR: 'Anterior',
      ANUAL: 'Anual',
      ANULAR: 'Anular',
      AO_ENTREGA_CENTRAL: 'AO entrega CENTRAL',
      AO_ENTREGA_DIG: 'AO entrega DIG',
      AO_RECIBE_DEL_DIG: 'AO recibe del Dig',
      APELLIDO: 'Apellido',
      APELLIDOS: 'Apellidos',
      API_SERVER_IDENTIDAD: 'Api Server identidad',
      APLICAR_JUGADA: 'Formulario de digitación',
      APODO: 'Apodo',
      APOSTADO: 'Apostado',
      APOSTADO_DEL_DIA: 'Apostado del dia',
      APOSTADO_IF: 'Apostado IF',
      APOSTADO_SOBREPASA_DISPONIBLE_DE: 'Apostado sobrepasa Disponible de',
      APOSTAR: 'Apostar',
      APUESTA: 'Apuesta',
      APUESTA_DEL_DIA: 'Apuesta del día',
      ARCHIVADOS: 'Guardados',
      ARCHIVO_GENERADO: 'Información enviada',
      AUTORACING: 'AutoRacing',
      AZUL: 'Azul',
      ADMIN_DASHBOARD: 'Dashboard',
      BALANCE: 'Balance',
      BALANCE_ACTUAL: 'Balance actual',
      BALANCE_EXCEDIDO: 'Excede el balance disponible',
      BALANCE_INICIAL: 'Balance inicial',
      BALANCE_PERDIDAS_SUBTITULO: 'Informaciones de Ganancias y perdidas',
      BALANCE_PERDIDAS_TITULO: 'Ganancias y perdidas',
      BALANCE_SEMANAL: 'Balance semanal',
      BALANCE_SEMANAL_SUBTITULO: 'Balance semanal',
      BALANCE_SEMANAL_TITULO: 'Balance semanal',
      BALANCE_SEMANAL_TITULO_BUSQUEDA: 'Búsqueda balance semanal',
      BALANCES_SEMANALES: 'Balances semanales',
      BALANCE_DIARIO_TITULO: 'Balance diario',
      BALANCES_DIARIOS: 'Balances diarios',
      BANCO: 'Banco',
      BAR: 'Barras',
      BAR_CHART: 'Gráfico de Barras',
      BASE_URL: 'Base url',
      BASEBALL: 'Baseball',
      BASKETBALL: 'Basketball',
      BASIC_REGISTRATION_PLAYS: 'Registrar Jugadas',
      BEGIN_ACCOUNT_WITHDRAWAL: 'Retiro',
      BEGINNING: 'Inicio',
      BEGINNING_OF_WEEK: 'Apertura',
      BENEFICIO_BRUTO: 'Beneficio bruto',
      BENEFICIO_NETO: 'Beneficio neto',
      BENEFICIOS: 'Beneficios',
      BLANCO: 'Blanco',
      BLOCK: 'Bloque',
      BLOQUE: 'Bloque',
      BLOQUEADO: 'Bloqueado',
      BORRAR_OPCION: 'Borrar opción',
      BORRAR_TICKET: 'Borrar ticket',
      BORRAR_TODOS_TICKETS: 'Borrar todos tickets',
      BOX: 'Box',
      BOXING: 'Boxing',
      BUBBLE_CHART: 'Gráfico de burbujas',
      BUSQUEDA: 'Búsqueda',
      BUSCAR_POR_EMAIL_O_TELEFONO: 'Buscar por email o telefono.',
      BUSQUEDAS_GUARDADAS: 'Búsquedas guardadas',
      BUSQUEDAS_RECIENTES: 'Búsquedas recientes',
      CAJA_A: 'Caja A',
      CAJA_ACTIVA: 'Activa',
      CAJA_B: 'Caja B',
      CAJA_C: 'Caja C',
      CAJA_CREADA: 'Caja creada',
      CAJA_D: 'Caja D',
      CALCULABLE: 'Calculable',
      CAMBIAR_CLAVE: 'Cambiar clave',
      CAMBIAR_CLAVE_TITULO: 'Cambiar clave',
      CAMBIAR_ESTADO: 'Cambiar estado',
      CAMBIO_CLAVE_EXITOSO: 'Cambio de clave exitoso',
      CAMBIO_DE_SIGNO_ACTUAL_ES_CONTRARIO_AL_ANTERIOR: 'Cambio de signo actual es contrario al anterior',
      CAMBIO_LENGUAJE: 'Cambio de idioma',
      CAMBIOS_AUTORIZADOS: 'Cambios autorizados',
      CAMBIOS_REALIZADOS: 'Cambios realizados',
      CANCELADO: 'Cancelado',
      CANCELAR: 'Cancelar',
      CANCELACIONES: 'Cancelaciones',
      CANCELLED: 'Cancelado',
      CANCREATE: 'Crear',
      CANDELETE: 'Borrar',
      CANEDIT: 'Modificar',
      CANPRINT: 'Imprimir',
      CANSEARCH: 'Buscar',
      CANVIEW: 'Ver',
      CARACTERES_ESPECIALES_NO_PERMITIDOS: 'Caracteres especiales no permitidos',
      CARGA: 'Carga',
      CARRERA: 'Carrera',
      CARRERA_CERRADA: 'Carrera en progreso',
      CARRERA_CREADO: 'Carrera creada',
      CARRERAS: 'Carreras',
      CART: 'Carrito',
      CARTELERA: 'Cartelera',
      CARTERA: 'Premio',
      CARTERA_REQUERIDA: 'Cartera réquerida',
      CENTRAL: 'Central',
      CIRCLED: 'CIRCLED',
      CLAIM: 'Reclamo',
      CLAVE: 'Clave',
      CLAVE_NO_CUMPLE:
        'Porfavor utilice al menos 6 caracteres, una letra mayúscula, números y caracteres especiales como !@#$%^&*.()',
      CLIENTE: 'Cliente',
      CEDULA: 'Cedula',
      CLIENTES_CONSULTAR: 'Clientes Consultar',
      CLIENTES_CREAR: 'Clientes Crear',
      CLIENT_ACCOUNTS: 'Cuentas',
      CLOSE: 'Cerrar',
      COCKFIGHTS: 'Peleas de gallos',
      COCK_FIGHTS: 'Peleas de gallos',
      CODIGO: 'Código',
      CODIGO_CLIENTE: 'Codigo',
      CODIGO_GENERADO: 'Código generado',
      CODIGO_NO_EXISTE: 'Codigo no existe',
      CODIGO_REGISTRACION: 'Código de registración',
      CODIGO_REGISTRACION_EXPIRADO: 'Código de registración expirado',
      CODIGO_YA_EXISTE: 'Codigo ya existe',
      COLAPSAR: 'Colapsar',
      COLOCAR_JUGADA: 'Colocar jugada',
      COMBINADA: 'Combinada',
      COMBUSTIBLE: 'Combustible',
      COMISION_EMPLEADO: '%Empleado',
      COMISSION: 'Comisión',
      COMMISSION: 'Comisión',
      COMPANIA: 'Compañia',
      COMPANIA_SUB_TITULO: 'Compañia',
      COMPANIA_TITULO: 'Nombre de la compañia',
      COMPANIAS: 'Compañias',
      COMPETIDOR: 'Corredor',
      COMPETIDOR_SUB_TITULO: 'Muestra información del corredor',
      COMPETIDOR_TITULO: 'Corredor',
      COMPETITORS: 'Corredores',
      COMPETIDORES: 'Corredores',
      COMPETIDORES_SUB_TITULO: 'Muestra la lista de corredores',
      COMPETIDORES_TITULO: 'Corredores',
      COMPETITOR_SUB_TITULO: 'Muestra información del corredor',
      COMPETITOR_TITULO: 'Corredor',
      COMPLETO: 'Juego',
      COMPROBANTE_SOLICITUD_RETIRO: 'Comprobante solicitud de retiro',
      COMPUTOS: 'Computos',
      CONCEPTO: 'Concepto',
      CONDICION: 'Condición',
      CONDICION_TICKET: 'Condición ticket',
      CONEXION: 'Conexión',
      CONEXIONES: 'Conexiones',
      CONNECTIONS: 'Conexiones',
      CONFIGURACION: 'Configuración',
      CONFIRMACION: 'Confirmación',
      CONFIRMACION_ANULADO: 'Confirmar anulación',
      CONFIRMACION_DE_CUENTA: 'Confirmación de cuenta',
      CONFIRMACION_ELIMINAR: 'Confirmar eliminar',
      CONFIRMAR_CLAVE: 'Confirmar clave',
      CONFIRMAR_MONTO: 'Confirmar monto',
      CONFIRMAR_NAVEGACION: 'Confirmar navegación',
      CONFIRMAR_TELEFONO: 'Confirmar teléfono',
      CONSORCIO: 'Consorcio',
      CONSORCIO_SUB_TITULO: 'Mantenimiento de consorcios',
      CONSORCIO_TITULO: 'Datos del Consorcio',
      CONSORCIO_REGISTRADO: 'Consorcio Registrado',
      CONSORCIOS: 'Consorcios',
      CONSULTAR: 'Consultar',
      CONSULTAR_TRANSACIONES: 'Consultar transacciones',
      CONSULTAS: 'Consultas',
      CONSULTS: 'Consultas',
      CONTACTAR_ADMINISTRADOR: 'Contactar administrador',
      CONTROL_DE_EVENTOS_TITULO: 'Control de eventos',
      CORREDORES: 'Corredores',
      CORREO_INVALIDO: 'Correo Invalido',
      CORREDORES_DUPLICADOS_EN_UNA_PATA: 'Corredores duplicados',
      CONFIGURATION: 'Configuración',
      COPIADO: 'Copiado',
      CONTINUAR: 'Continuar',
      CREANDO: 'CREANDO',
      CREANDO_ESTACION: 'Creando estación de trabajo',
      CREANDO_LA_COMPANIA: 'Creando Compañia',
      CREAR: 'Crear',
      CREAR_CUENTA: 'Crear cuenta',
      CREAR_CUENTA_CLIENTES: 'Crear cuenta',
      CREAR_ROLE: 'Crear role',
      CREAR_TICKET: 'Crear ticket',
      CREAR_TRANSACCIONES_CUENTAS: 'Crear transacción',
      CRECIMIENTO_DEL_PERIODO: 'Crecimiento',
      CREDENCIALES_DEL_USUARIO_UNICA_VEZ_MOSTRADO: 'Credenciales del usuario',
      CREDENCIALES_INVALIDAS: 'Credenciales invalidas',
      CREDITO: 'Crédito',
      CRITERIOS_DE_BUSQUEDA: 'Criterios de consulta',
      CUADRANTE: 'Cuadrante',
      CUADRANTE_00: 'Cuadrante 00',
      CUADRANTE_01: 'Cuadrante 01',
      CUADRANTE_02: 'Cuadrante 02',
      CUADRANTE_03: 'Cuadrante 03',
      CUADRANTE_04: 'Cuadrante 04',
      CUADRANTE_05: 'Cuadrante 05',
      CUADRANTE_06: 'Cuadrante 06',
      CUADRANTE_07: 'Cuadrante 07',
      CUADRANTE_08: 'Cuadrante 08',
      CUADRANTE_09: 'Cuadrante 09',
      CUADRANTE_0A: 'Cuadrante 0A',
      CUADRANTE_0B: 'Cuadrante 0B',
      CUADRANTE_10: 'Cuadrante 10',
      CUADRANTE_11: 'Cuadrante 11',
      CUADRANTE_12: 'Cuadrante 12',
      CUADRE_CAJA_CONSULTAR: 'Cuadre caja consultar',
      CUADRE_CAJA_CREAR: 'Cuadre caja crear',
      CUADRE_CAJA_IMPRIMIR: 'Cuadre caja imprimir',
      CUADRE_CAJA_MODIFICAR: 'Cuadre caja modificar',
      CUADRE_DE_CAJA: 'Cuadre de caja',
      CUARTETA: 'Cuarteta',
      CUENTA: 'Cuenta',
      CUENTA_PIN_INVALIDO: 'Cuenta ó PIN invalido',
      CUENTA_PRIMARIA: 'Cuenta primaria',
      CUENTA_RETIRAR_FONDOS: 'Retiro fondos',
      CUENTAS: 'Cuentas',
      CUENTAS_DE_CLIENTES: 'Cuentas de clientes',
      CUENTAS_DE_PAGOS: 'Cuentas de pagos',
      CUENTAS_Y_BALANCES_DE_LOS_CLIENTES: 'Cuentas y balances de los clientes',
      CURRENCYNAME: 'Moneda',
      CURSO: 'Curso',
      CHANGE_PASSWORD: 'Cambiar Clave',
      CIUDAD: 'Ciudad',
      DASHBOARD: 'Dashboard',
      DATO_ACTUALIZADO: 'Dato actualizado',
      DATO_COPIADO: 'Dato copiado',
      DATO_REGISTRADO: 'Dato registrado',
      DATOS_DE_LA_AGENCIA: 'Datos de la agencia',
      DATOS_DE_LA_COMPANIA: 'Datos de la compañia',
      DATOS_DE_LA_CONEXION: 'Información de la conexion',
      DATOS_DEL_COMPETIDOR: 'Información del corredor',
      DATOS_DEL_ENTRENADOR: 'Información del entrenador',
      DATOS_DEL_EVENTO: 'Datos del evento',
      DATOS_DEL_JINETE: 'Información del Jinete',
      DATOS_DEL_PROPIETARIO: 'Información del propietario',
      DATOS_DEL_PROVEEDOR: 'Datos del proveedor',
      DATOS_DEL_USUARIO: 'Datos del usuario',
      DATOS_DEL_USUARIO_EMPLEADO: 'Datos usuarios empleados',
      DATOS_MOSTRADOS_ESTAN_BASADOS_EN_ESTAS_ENTIDADADES: 'Muestra las pérdidas y ganancias de clientes y agencias',
      DATOS_MOSTRADOS_ESTAN_BASADOS_EN_ESTAS_ENTIDADES: 'Datos mostrados estan basados en estas entidades',
      DATOS_NO_ENCONTRADOS: 'Datos no encontrados',
      DATOS_DEL_TICKET: 'Datos del ticket',
      DAILY_BALANCES: 'Balances diarios',
      DD: 'Daily Double',
      DE: 'Double Exacta',
      DE_CARRERA_NO: 'No.',
      DE_FECHA: 'de fecha',
      DEBE_ESPECIFICAR_EL_SIGNO: 'Debe especificar el signo',
      DEBE_ESPECIFICAR_UNA_FECHA_MAYOR_O_IGUAL_A_LA_DE_HOY: 'Debe especificar una fecha mayor o igual a la de hoy',
      DEBITO: 'Débito',
      DECIMA: 'Decima',
      DELAY: 'DELAY',
      DELETE_TICKET: 'Delete ticket',
      DEPORTIVA: 'Deportiva',
      DEPOSIT: 'Depositar',
      DEPOSITADO: 'Depositado',
      DEPOSITO: 'Deposito',
      DEPOSITOS: 'Depositos',
      DEPOSITO_INTERNET: 'Deposito Internet',
      DESCARGA_FALLIDA: 'Descarga fallída',
      DESCRIPCION: 'Descripción',
      DESCRIPCION_RETIRO_CUENTA: 'Monto a retirar',
      DESDE: 'Desde',
      DESEA_CONTINUAR: 'Desea continuar?',
      DETALLE_DE_LA_CUENTA: 'Detalle de la cuenta',
      DETALLE_EFECTIVO: 'Detalle Efectivo',
      DETALLE_EFECTIVO_CONSULTAR: 'Detalle Efectivo consultar',
      DETALLE_EFECTIVO_CREAR: 'Detalle Efectivo crear',
      DETALLE_EFECTIVO_IMPRIMIR: 'Detalle Efectivo imprimir',
      DESCARTADO: 'Descartado',
      DIARIO: 'Diario',
      DIG_ENTREGA_AO: 'DIG entrega AO',
      DIG_RECIBE_DE_AO: 'DIG recibe de AO',
      DINERO_METRO: 'Dinero Metro',
      DIRECCION: 'Dirección',
      DIRECTA: 'Directa',
      DIRECTAS: 'Directas',
      DISCLAIMER_DEPOSIT: 'Comprobante de deposito a cuenta',
      DISCLAIMER_WITHDRAWAL: 'Comprobante de retiro a cuenta',
      DISCLAIMER_WITHDRAWAL_VOUCHER:
        'Este comprobante solo indica la intención de realizar un retiro, mas sin embargo, el monto no es reservado, por lo que su ejecución dependerá del balance al momento de hacerlo efectivo.',
      DISPONIBLE_PARA_APOSTAR: 'Disponible para apostar',
      DISPOSITIVO_HA_SIDO_AUTORIZADO: 'Dispositivo ha sido autorizado',
      DISPOSITIVO_NO_SE_PUDO_AUTORIZAR: 'Dispositivo no se pudo autorizar',
      DISTANCIA: 'Distancia',
      DISTANCIA_REQUERIDA: 'Distancia réquerida',
      DIVISA: 'Divisa',
      DOCEAVA: 'Doceava',
      DOLARES_EU: 'Dolares EU',
      DOM: 'Domingo',
      DOMINIO: 'Dominio',
      DOCUMENTO: 'Documento',
      DRAW: 'Draw',
      DRAW_DIFFERENCE: 'Máximo pago de Draw',
      DRAW_TITLE: 'Préstamos y devoluciones',
      DRAWS: 'N/A',
      DUENO: 'Establo',
      DUPLICATED_WAGER: 'Jugada repetida',
      DRIVER_LICENCE: 'Licencia de conducir',
      ED: 'Extra Double',
      EDAD: 'Edad',
      EDITANDO_ESTACION: 'Editando estación de trabajo',
      EDITAR_CONEXION: 'Modificar conexión',
      EFECTIVO: 'Efectivo',
      EGRESO_TC: 'Egresos TC',
      EGRESO_TRANSITO: 'Egresos tránsito',
      EGRESOS: 'Egresos',
      EGRESOS_CONFIRMADOS: 'Egresos confirmados',
      EGRESOS_MAQUINAS: 'Egresos Maquinas',
      EGRESOS_MONEDAS: 'Egresos Monedas',
      EGRESOS_RECARGA: 'Egresos Recarga',
      EL_MONTO_MINIMO_DE_APUESTA_ES: 'El Monto mínimo de apuesta es',
      ELECCIONES_RD: 'Elecciones RD',
      EMAIL: 'Email',
      EMPADRONAMIENTO_BASICO: 'Empadronamiento básico de apuestas',
      EMPADRONAMIENTO_DE_JUGADA: 'Muestra el resumen de las apuestas realizadas',
      EMPATE: 'Empate',
      EMPATE_COMPLETO: 'Empate completo',
      EN_PROGRESO: 'En Progreso',
      ENCARGADO_CUENTA: 'Encargado de cuenta',
      ENTIDADES: 'Entidades',
      ENTITIES: 'Entidades',
      ENTITY_NAME: 'Entidad',
      ENTRENADOR: 'Entrenador',
      ENTRENADOR_SUB_TITULO: 'Muestra información del entrenador',
      ENTRENADOR_TITULO: 'Entrenador',
      ENTRENADORES: 'Entrenadores',
      ENTRENADORES_SUB_TITULO: 'Muestra información del entrenador',
      ENTRENADORES_TITULO: 'Entrenadores',
      ENVIOS: 'Enviado',
      EQUIPOS: 'Equipos',
      ERRO: 'Error',
      ERROR_403:
        'Este puede ser un inconveniente temporal. Por favor, intente volver al inicio y seleccione nuevamente la opción deseada, si la situación persiste por favor contacte el administrador con este mensaje visible.',
      ERROR_404: 'Este puede ser un inconveniente temporal. Por favor, intente volver al inicio y seleccione nuevament',
      ERROR_500: 'Situación inesperada, favor intente nuevamente más tarde, si la situación persiste por favor contact',
      ERROR_ACTUALIZANDO_EL_DATO: 'Error actualizando información',
      ERROR_CARRERA_MAXIMA_PERMITIDA: 'Ha alcanzado la cantidad máxima de carreras',
      ERROR_COMPETIDOR_EN_USO: 'Corredor ya está en uso',
      ERROR_CORREDOR_MAXIMO_PERMITIDO: 'Ya ha creado la cantidad de corredores para esta carrera',
      ERROR_NUMERO_EN_USO: 'Número ya está en uso',
      ERROR_REGISTRANDO_EL_DATO: 'Error registrando el dato',
      ERROR_SUBMITING_TICKET: 'Ticket no pudo ser creado',
      ERROR_VERIFICAR_MONTO_DE_APUESTA: 'Error Verificar monto de apuesta',
      ESCRIBA_PARA_VER_RESULTADOS: 'Escriba para ver resultados',
      ESTA_SEGURO_DE_ANULAR_ESTE_TICKET: 'Esta seguro de anular este ticket',
      ESTA_SEGURO_DE_PAGAR_ESTE_TICKET: 'Esta seguro de pagar este ticket',
      ESTABLECE_LOS_PERMISOS_DE_LOS_USUARIOS: 'Establece los permisos de los usuarios',
      ESTACION: 'Estación',
      ESTACION_NO_PUEDE_SER_REGISTRADA: 'Estación no puede ser registrada',
      ESTACIONES: 'Estaciones',
      ESTACION_SUB_TITULO: 'Lista y mantenimiento de estaciones',
      ESTACION_TITULO: 'Mantenimiento de estaciones',
      ESTACIONES_DEL_USUARIO: 'Estaciones a que el Usuario tiene Permisos',
      DATOS_DE_LA_ESTACION: 'Datos de la estacion',
      LISTA_DE_ESTACIONES: 'Listado de las estaciones',
      ESTADISTICAS_DE_CUENTAS_VENTAS_Y_GANANCIAS: 'Tablero de ventas por períodos',
      ESTADO: 'Estado',
      ESTADO_SITUACION_AGENCIAS_SUBTITULO: 'Muestra la situación actual de las agencias',
      ESTADO_SITUACION_AGENCIAS_TITULO: 'Estado de situación',
      ESTAS_SEGURO_DE_HABILITAR_ENFRENTAMIENTOS_MARCADOS: 'Estas Seguro de habilitar Enfrentamientos marcados',
      ESTATUS: 'Estatus',
      ESTE_VOUCHER_FUE_REGISTRADO_EN_OTRA_LOCALIDAD: 'Este Voucher fue registrado en otra localidad',
      EVENT_RESULTS_NOT_FOUND: 'Resultados no encontrados',
      EVENTO: 'Evento',
      EVENTO_SUB_TITULO: 'Evento Hipódromo V Centenario',
      EVENTO_TITULO: 'Evento',
      EVENTS: 'Eventos',
      EVENTOS: 'Eventos',
      EVENTOS_DE_LA_CONEXION: 'Eventos de la conexión',
      EVENTOS_DEL_DIA: 'EVENTOS DE DÍA',
      EVENTOS_SUB_TITULO: 'Muestra información de los eventos',
      EVENTOS_TITULO: 'Eventos',
      EX: 'Exacta',
      EXA: 'Exacta',
      EXCEDE_CANTIDAD_DE_EQUIPOS_PERMITIDOS: 'Excede cantidad de equipos permitidos',
      EXCEDE_HEMBRAS_PERMITIDAS: 'Excede hembras permitidas de',
      EXCLUDED_BY_AN_EXISTING_WAGER: 'Jugada contraditoria',
      EXE: 'Exella',
      EXPANDIR: 'Expandir',
      EXPIRACION: 'Expiración',
      EXCLUIR: 'Excluir',
      ENTERPRISES: 'Consorcios',
      FECHA: 'Fecha',
      FECHA_INICIAL_DEBE_SER_MENOR_A_FECHA_FINAL: 'Fecha inicial debe ser menor a fecha final',
      FECHA_INSCRIPCION: 'Fecha inscripción',
      FECHA_INVALIDA: 'Fecha invalida',
      FECHA_NACIMIENTO: 'Fecha de nacimiento',
      FECHA_TRANS: 'Fecha',
      FEMALE: 'Femenino',
      FINAL: 'Final',
      'FIRST-HALF': 'FIRST-HALF',
      FOOTBALL: 'Football',
      FORMATO_APUESTA: 'Formato apuesta',
      FORMULARIO_DE_BUSQUE: 'Formulario de búsqueda',
      FROM: 'Desde',
      GALLOS: 'Gallos',
      GAN_NO_COBRADOS: 'GAN no cobrados',
      GANA: 'Gana',
      GANA_IF: 'Gana IF',
      GANADO: 'Ganado',
      GANADOR: 'Ganador',
      GANADOR_OFFLINE: 'Ganador OffLine',
      GANANCIA: 'Ganancia',
      GANANCIA_: 'Ganancia_',
      GANANCIAS_Y_PERDIDAS: 'Ganancias y pérdidas',
      GASTOS: 'Gastos',
      GAVELA_EXCEDE_LIMITES_ESTABLECIDOS: 'Gavela excede limites establecidos',
      GENERAR_ARCHIVO: 'Enviar al Totalizador',
      GENERAR_CODIGO: 'Generar código',
      GERENCIA: 'Gerencia',
      GESTION_DE_USUARIOS_Y_EMPLEADOS: 'Gestion de usuarios y empleados',
      GENERAR: 'Generar',
      GENERAR_CODIGO_PARA_REGISTRAR_ESTACION: 'Generar código para registrar estación',
      GOLF: 'Golf',
      GS: 'Grand Slam',
      GUARDAR: 'Guardar',
      GUARDAR_LA_BUSQUEDA: 'Guardar búsqueda',
      'H1-JC_VALIDO_+Y-JCNO': 'H1-JC VALIDO +Y-JCNO',
      H1_COMPLETO: 'H1 COMPLETO',
      HASTA: 'Hasta',
      HEMBRA: 'Hembra',
      HEX: 'Hexafecta',
      HIPISMO: 'Hipismo',
      HISTORIAL: 'Historial',
      HOCKEY: 'Hockey',
      HORA: 'Hora',
      HORA_DE_APERTURA: 'Hora de apertura',
      HORA_DE_CIERRE: 'Hora de cierre',
      HORSE_RACES: 'Carreras de caballos',
      IF: 'IF',
      IF_WIN_ONLY: 'Solo si Gana',
      IF_WIN_OR_TIE: 'Gana o Empata',
      IMPRESORA: 'Impresora',
      IMPRIMIR: 'Imprimir',
      IMPRIMIR_CUADRE: 'imprimir cuadre',
      IMPRIMIR_VOUCHER: 'Proceder',
      IMPUESTO_MUNICIPAL: 'Impuesto Municipal',
      IMPUESTOS: 'Impuestos',
      IMPUESTOS_: 'Impuestos_',
      'IN-GAME': 'In-Game',
      'IN-PROGRESS': 'IN-PROGRESS',
      IN_OUT: 'Otras',
      INACTIVAS: 'Inactivas',
      INACTIVO: 'Inactivo',
      INCREMENTO: 'Crecimiento',
      INFO: 'Información',
      INFORMACION: 'Información',
      INFORMACION_DE_GANACIAS_Y_PERDIDAS: 'Información ganancias y perdidas',
      INFORMACION_DE_GANANCIAS_Y_PERDIDAS: 'Información de ganancias y perdidas',
      INFORMACION_DEL_TICKET: 'Información del ticket',
      INFORMACION_GENERAL: 'Información general',
      INGRESO_TC: 'Ingresos TC',
      INGRESO_TRANSITO: 'Ingresos tránsito',
      INGRESOS: 'Ingresos',
      INGRESOS_CONFIRMADOS: 'Ingresos confirmados',
      INGRESOS_MAQUINAS: 'Ingresos Maquinas',
      INGRESOS_MONEDAS: 'Ingresos Monedas',
      INGRESOS_RECARGA: 'Ingresos Recarga',
      INGRESOS_TRANSITO: 'Ingresos tránsito',
      INICIAR_SESION: 'Iniciar sesión',
      INICIO: 'Inicio',
      INSERTE_LA_JUGADA: 'Favor digite su jugada',
      INSTITUCION: 'Institución',
      INTERNET: 'Internet',
      INTRODUSCA_EL_CODIGO_DE_REGISTRACION_PROVISTO: 'Introduzca el código de registración',
      INVALID_PASSWORD_RESET_CODE: 'El Usuario es incorrecto, el Código es inválido ó ha expirado.',
      INVALID_PLAY_CODE: 'Código de jugada invalida',
      INVALID_PLAY_NUMBER_OF_LEGS_DOES_NOT_MEET_POOL_REQUIREMENTS: 'Selección de patas invalidas',
      INVALID_PLAY_OR_NOT_SUPPORTED: 'Jugada invalida o no disponible',
      INVALID_RACE: 'Carrera invalida',
      INVALID_REFERENCE_ID: 'Referencia invalida',
      INVALID_TICKET_FORMAT: 'Formato de ticket invalido',
      ID_NUMBER_EXIST: 'Número de identificacion esta siendo usando',
      ISSUED: 'Vendido',
      IDENTITY: 'Identidad',
      IR_PAGINA_INICIO: 'Ir a la página de inicio',
      J_GANADORA_A_MANO: 'J ganadora a mano',
      J_PERDEDORA_A_MANO: 'J perdedora a mano',
      JINETE: 'Jinete',
      JINETE_SUB_TITULO: 'Muestra información del Jinete',
      JINETE_TITULO: 'Jinete',
      JINETES: 'Jinetes',
      JINETES_SUB_TITULO: 'Muestra información del Jinete',
      JINETES_TITULO: 'Jinetes',
      JOCKEY: 'Jockey',
      JUE: 'Jueves',
      JUEGOS_ASSOCIADOS: 'Juegos asociados',
      JUEGOS_DEL_DIA: 'Juegos del dia',
      JUEGOS_DEL_DIA_CONSULTAR: 'Juegos del dia consultar',
      JUEGOS_DEL_DIA_IMPRIMIR: 'Juegos del dia imprimir',
      JUGADA: 'Jugada',
      JUGADA_DUPLICADA: 'Jugada duplicada',
      JUGADA_IF: 'Jugada IF',
      JUGADA_INVALIDA: 'Jugada Invalida',
      JUGADA_INVALIDA_PATAS_INCOMPLETAS: 'Patas incompletas',
      JUGADA_MANUAL: 'Entrada de jugada',
      JUGADAS: 'Jugadas',
      JUGADORES: 'Jugadores',
      JUSTIFICACION: 'Justificación',
      KEY_WHEEL: 'Key Wheel',
      LA_FECHA_DEL_EVENTO_HA_PASADO: 'Fecha de evento ha pasado',
      LA_IMPRESORA_NO_ESTA_LISTA: 'La Impresora no esta Lista',
      LA_POSICION_EXISTE: 'Posición antes seleccionada',
      LADO_NO_SOPORTADO: 'Lado no soportado',
      LANGUAGE_SWITCHER: 'Cambiar idioma',
      LBS: 'Lbs',
      LEADING: 'Leading',
      LICENCIA: 'Licencia',
      LIGA: 'Liga',
      LINE: 'Líneas',
      LINE_CHART: 'Gráfico de líneas',
      LINK: 'Vincular',
      LINK_ACCOUNT: 'Relacionar cuenta',
      LISTA_DE_AGENCIAS: 'Lista de agencias',
      LISTA_DE_APUESTAS: 'Lista de apuestas',
      LISTA_DE_CARRERAS: 'Lista de carreras',
      LISTA_DE_CARRERAS_DE_FECHA: 'Lista de carreras del',
      LISTA_DE_COMPANIAS: 'Lista de compañias',
      LISTA_DE_COMPETIDORES: 'Lista de corredores',
      LISTA_DE_CONSORCIOS: 'Lista de consorcios',
      LISTA_DE_CORREDORES: 'Lista de corredores',
      LISTA_DE_CORREDORES_DE_LA_CARRERA_NO: 'Corredores de la carrera no.',
      LISTA_DE_ENTRENADORES: 'Lista de entrenadores',
      LISTA_DE_EVENTOS: 'Lista de eventos',
      LISTA_DE_JINETES: 'Lista de Jinetes',
      LISTA_DE_PROPIETARIO: 'Lista de propietarios',
      LISTA_DE_PROVEEDORES: 'Lista de proveedores',
      LOCALE: 'Lugar',
      LOGOUT: 'Cerrar sesión',
      LOTERIA: 'Loteria',
      LOTERY: 'Loteria',
      LOTERIA_SPORT: 'Loteria Sport',
      LOTO_DEPORTE: 'Loto Deporte',
      LOTODEPORTE: 'Loto Deporte',
      LUN: 'Lunes',
      LONGITUD: 'Longitud',
      LATITUD: 'Latitud',
      MACHO: 'Macho',
      MAIL: 'Mail',
      MALE: 'Masculino',
      MANAGE_ACCOUNTS: 'Administrar cuentas',
      MANT_AIRE_ACOND: 'Mant Aire Acond',
      MANT_BATERIAS: 'Mant Baterias',
      MANT_COMPUTADOR: 'Mant Computador',
      MANT_PLANTA: 'Mant Planta',
      MANT_SUCURSAL: 'Mant Sucursal',
      MAQUINA_GRAFICA: 'Maquina gráfica',
      MAQUINAS_DETALLADAS: 'Maquina detalle',
      MAQUINAS_DETALLADAS_POR_LOCALIDAD: 'Maquina detalle',
      MAQUINAS_RESUMIDA: 'Maquina resumen',
      MAQUINAS_RESUMIDO_POR_LOCALIDAD: 'Maquina resumen',
      MACHINE_DETAILS: 'Detalles de la máquina',
      MACHINE_SUMMARIZED: 'Máquina resumida',
      MAR: 'Martes',
      MARGEN: 'Margen',
      MAX_MEDICAMENTOS: 'Selecciones hasta tres medicamentos',
      MAXIMO_CARACTERES: 'caracteres máximo',
      MAXIMO: 'máximo',
      MEDICACION: 'Medicación',
      MEDICAMENTO: 'Med.',
      MENSUAL: 'Mensual',
      MI_CUENTA: 'Mi cuenta',
      MIE: 'Miércoles',
      MINIMO_NUMERO_1: 'Valor mínimo 1',
      MINIMO_11: 'Mínimo 11 caracteres',
      MINIMO: 'Mínimo',
      MINUTE: 'Minutos',
      MINUTES: 'Minutos',
      ML: 'Gana',
      MODIFICAR: 'Modificar',
      MONEDAS: 'Monedas',
      MONTO: 'Monto',
      MONTO_INVALIDO: 'Monto invalido',
      MONTO_MINIMO: 'Monto mínimo',
      'MONTO_MINIMO_DE_APUESTA_ES_:_': 'Monto mínimo de apuesta es',
      MONTO_POR_DEFECTO_APUESTAS: 'Monto por defecto de apuesta',
      MOSTRAR_LOS_QUE_COINCIDAD_CON: 'Mostrar los que coinciden con',
      MUNICIPIO: 'Municipio',
      MY_ACCOUNTS: 'Mís cuentas',
      METROS: 'Metros',
      NAVIGATION_WARNING_MESSAGE: 'Perdera la apuesta en proceso',
      NEEDED_RACES_NOT_PROVIDED: 'Faltan carreras por especificar',
      NEEDED_RUNNERS_NOT_PROVIDED: 'Faltan corredores por especificar',
      NO: 'No',
      NO_ASIGNADO: 'No asignado',
      NO_COBRADO: 'No cobrado',
      NO_ES_POSSIBLE_ANADIR_MAS_PATAS: 'No es posible añadir más patas',
      NO_ES_POSSIBLE_ANADIR_PATAS_VACIAS: 'No es posible añadir patas vacias',
      NO_EVENT_FOUND_WITH_ID: 'Código de evento no encontrado',
      NO_EVENTS_SCHEDULED: 'No Hay Eventos en el Calendario',
      NO_EXISTEN_TICKETS: 'No existen tickets',
      NO_EXISTEN_TRANSACCIONES_PARA_ESTA_CUENTA: 'No existen transacciones para esta cuenta',
      NO_HAY_CAMBIOS: 'No hay Cambios, Imprimir de todos modos',
      NO_HAY_EVENTOS_PROGRAMADOS: 'No hay eventos programados',
      NO_PUEDE_AGREGAR_JUGADAS: 'No puede agregar jugadas',
      NO_PUEDE_AGREGAR_MAS_JUGADAS_PARA_ESTA_LIGA: 'No puede agregar mas jugadas para esta liga',
      NO_PUEDE_AGREGAR_MAS_JUGADAS_PARA_ESTE_TICKET: 'No puede agregar mas jugadas para este ticket',
      NO_PUEDE_SER_EJECUTADO: 'No puede ser ejecutado',
      NO_RACES_FOUND: 'Carreras no encontrada',
      NO_RUNNERS_FOUND: 'Corredores no encontrados',
      NO_SE_ENCONTRARON_APUESTAS: 'No se encontraron apuestas',
      NO_SE_ENCONTRO_CONEXION_PARA_ESTE_PROVEEDOR: 'No se encontro conexión para este proveedor',
      NO_TIENE_PRIVILEGIO_NECESARIO: 'No tiene privilegio necesario',
      NO_WAGERS_FOUND: 'Tipo de apuesta no especificada',
      NO_TIENES_ACCESO_PARA_VER_ESTA_PAGINA: 'No tiene acceso a esta página !',
      NO_TIENES_ACCESO_PARA_VER_ESTA_PAGINA_DESCRIPCION: 'Por favor contacte el administrador.',
      NOMBRE: 'Nombre',
      NOMBRE_ROLE: 'Nombre role',
      NOMBRES: 'Nombres',
      NONE: 'n/a',
      NOT_PAYED: 'No pagado',
      NOVENA: 'Novena',
      NUEVA_CLAVE: 'Nueva clave',
      NUEVA_CONEXION: 'Crear conexión',
      NUEVA_ESTACION: 'Nueva estación de trabajo',
      NUEVAS_CUENTAS: 'Nuevas cuentas',
      NULO: 'Nulo',
      NUM: 'The Number',
      NUMERO: 'Número',
      NUMERO_TICKET: 'Ticket',
      NAME_OR_SUBDOMAIN_EXIST: 'Ya existe un dominio o subdominio con la misma descripcion.',
      OCTAVA: 'Octava',
      OCULTAR: 'Ocultar',
      ODDS: 'Odds',
      OFICIAL: 'Oficial',
      OK: 'Ok',
      OMN: 'Omni',
      OPCIONES_DE_CONSULTAS: 'Opciones de consultas',
      OPCIONES_DE_TRANSACCIONES: 'Opciones de transacciones',
      OPERACIONES: 'Operaciones',
      OPERACIONES_AGENCIAS: 'Operaciones agencias',
      OPERACIONES_POR_CONSORCIOS: 'Operaciones consorcios',
      OPERACIONES_POR_LOCALIDADES: 'Operaciones localidades',
      OTHER: 'Other',
      OTRAS_OPCIONES: 'Otras opciones',
      OWNERS: 'Propietarios',
      OVER: 'Mas',
      PAGADO: 'Pagado',
      PAGAR: 'Pagar',
      PAGO: 'Pago',
      PAGOS: 'Pagos',
      PAGO_DEPORTIVA: 'Pago Deportiva',
      PAGO_GALLOS: 'Pago Gallos',
      PAGO_LOTEKA_OP: 'PAGO LOTEKA OP.',
      PAGO_LOTERIA: 'Pago Loteria',
      PAID: 'Pagado',
      PAID_DRAW: 'Pago Draw',
      PAIS: 'País',
      PAIS_CONSORCIO_INVALIDO: 'El país del consorcio seleccionado es invalido.',
      PALE: 'Pale',
      PARA_APOSTAR_UTILICE: 'Para apostar utilice',
      PARLAY: 'Parlay',
      PASSWORDS_MUST_BE_AT_LEAST_6_CHARACTERS: 'Debe usar una clave de al menos seis (6) caracteres',
      'PASSWORDS_MUST_HAVE_AT_LEAST_ONE_DIGIT_(0-9)': 'La clave debe contener al menos un número',
      'PASSWORDS_MUST_HAVE_AT_LEAST_ONE_LOWERCASE_(A-Z)': 'La clave debe contener al menos un letra minuscula',
      PASSWORDS_MUST_HAVE_AT_LEAST_ONE_NON_ALPHANUMERIC_CHARACTER:
        'La clave debe contener al menos un símbolo especial.',
      'PASSWORDS_MUST_HAVE_AT_LEAST_ONE_UPPERCASE_(A-Z)': 'La clave debe contener al menos un letra mayúcula',
      PAY: 'Pagar',
      PAYDRAW: 'Pago Prestamo',
      PAYOFF: 'Paga',
      PAYTICKET: 'Pagados',
      PASSPORT: 'Pasaporte',
      PELEAS_DE_GALLOS: 'Peleas de Gallos',
      PEN: 'Pentafecta',
      PENDIENTE: 'Pendiente',
      PENDING: 'Pending',
      PENDING_TRANSACTIONS: 'Transacciones pendientes',
      PERDEDOR: 'Perdedor',
      PERDIDA_LOTEKA: 'PERDIDA LOTEKA',
      PERDIDA_RULETA_MULTI: 'Perdida Ruleta Multi',
      PERFILES: 'Perfiles',
      PERIODO: 'Período',
      PERIODOS: 'Periodos',
      PERMISO_DE_USO_EXPIRADO: 'Permiso de Uso Expirado',
      PESO: 'Peso',
      PESOS_DOMINICANOS: 'Pesos Dominicanos',
      PERSONA: 'Persona',
      PERSONAS: 'Personas',
      PERSONAS_EMPLEADOS: 'Personas empleados',
      PERSONA_SUB_TITULO: 'Persona del sistema',
      GESTION_DE_PERSONAS_Y_EMPLEADOS: 'Gestion de personas y empleados',
      'PIN ': 'Pin ',
      PIN: 'Pin',
      PK10: 'Pick10',
      PK11: 'Pick11',
      PK12: 'Pick12',
      PK3: 'Pick3',
      PK4: 'Pick4',
      PK5: 'Pick5',
      PK6: 'Pick6',
      PK7: 'Pick7',
      PK8: 'Pick8',
      PK9: 'Pick9',
      PLACEBET: 'Placebet',
      PLC: 'Place',
      POINT_OF_SALE: 'Punto de ventas',
      POOL_LIST: 'Pool list',
      POR_COBRAR: 'Por Cobrar',
      POSIBLE_PAGO: 'Posible pago',
      POSICION: 'Posición',
      POSICION_NO_EXISTENTE_O_DESCARTADA: 'Posición no disponible',
      POSICION_YA_ASSIDO_ASIGNADA: 'Corredor repetido',
      POSITION_NOT_AVAILABLE: 'No es posible añadir mas patas',
      POSPUESTO: 'Pospuesto',
      'POST-SEASON': 'POST-SEASON',
      POSTPONE: 'POSTPONE',
      POWER_BOX: 'Power Box',
      'PRE-GAME': 'Pre-Game',
      'PRE-SEASON': 'PRE-SEASON',
      PRECIO: 'Precio',
      PRECIO_EXCEDE_LIMITES_ESTABLECIDOS: 'Precio excede limites establecidos',
      PREMIO: 'Premio',
      PREFERENCIAS: 'Preferencias',
      PREFERENCIAS_USUARIOS: 'Preferencias usuarios',
      PREG: 'Preguntas',
      PREPAGADA: 'Prepagada',
      PRETICKET_NO_PUDO_SER_CREADO: 'PreTicket no pudo ser creado',
      PROCESO_EXITOSO: 'Proceso exitoso',
      PROCESO_FALLIDO: 'Proceso fallido',
      PROCESO_FINALIZADO: 'Proceso Finalizado',
      PRODUCT_DESCRIPTION: 'Descripción de producto',
      PRODUCT_DISCLAIMER_TBD: 'Tickets expira en 90 días',
      PRODUCT_NAME: 'Producto',
      PRODUCTO: 'Producto',
      PRODUCTO_AUTENTICADO_DESCRIPCION:
        'Servicio de gestión de usuarios, registro, login, claves y seguridad de acceso en sus plataformas digitales.',
      PRODUCTO_AUTENTICADO_LOGO: 'https://pbpublicimages.blob.core.windows.net/logos/autenticadovertical.svg',
      PRODUCTO_AUTENTICADO_NOMBRE: 'Autentica.DO',
      PRODUCTO_EN_PLACEBET: 'Nuestros Productos',
      PRODUCTO_GALLO_DESCRIPCION: 'Peleas de gallos en vivo, proporcionados por Gallero Soy.',
      PRODUCTO_GALLO_LOGO: 'https://pbpublicimages.blob.core.windows.net/logos/gallerosoy-logo.svg',
      PRODUCTO_GALLO_NOMBRE: 'Gallos',
      PRODUCTO_HIPICA_DESCRIPCION: 'Carrera de caballos en vivo, proporcionados por el Hipódromo V Centenario.',
      PRODUCTO_HIPICA_LOGO: 'https://pbpublicimages.blob.core.windows.net/logos/hipismo.svg',
      PRODUCTO_HIPICA_NOMBRE: 'Hipismo',
      PRODUCTO_PLACEBANK_DESCRIPCION:
        'Tenga control de todas sus cuentas de pagos y apuestas; pague, retire, vea su historial.',
      PRODUCTO_PLACEBANK_LOGO: 'https://pbankpublicimages.blob.core.windows.net/logos/placeBankLogoColorB.svg',
      PRODUCTO_PLACEBANK_NOMBRE: 'Place Bank',
      PRODUCTO_PLACEPIN_DESCRIPCION: 'Gestione la generación de PIN para destinar a pago de servicios o apuestas.',
      PRODUCTO_PLACEPIN_LOGO: 'https://pbankpublicimages.blob.core.windows.net/logos/placebankwallet.svg',
      PRODUCTO_PLACEPIN_NOMBRE: 'Place PIN',
      PRODUCTOS_DISPONIBLES_CONSORCIO: 'Productos disponibles',
      PRODUCTOS_DISPONIBLE_AGENCIA: 'Productos disponibles',
      PRODUCTS: 'Productos',
      PROFIT_PRODUCT: 'Beneficios por productos',
      PROFIT_PRODUCT_NEXT_PERIOD: 'Beneficios siguiente nivel',
      PROGRAMA_HIPICO: 'Programa hípico',
      PROPIETARIO: 'Establo',
      PROPIETARIO_SUB_TITULO: 'Muestra información del Establo',
      PROPIETARIO_TITULO: 'Establo',
      PROPIETARIOS: 'Establo',
      PROVEEDOR: 'Proveedor',
      PROVEEDORES: 'Proveedores',
      PROVINCIA: 'Provincia',
      PROXIMOS: 'Próximos',
      PROXIMOS_EVENTOS: 'PRÓXIMOS EVENTOS',
      PRUEBA_JUGADA_HIPICA_SUBTITULO: 'Prueba formato de digitación',
      PRUEBA_JUGADA_HIPICA_TITULO: 'Prueba digitación',
      PROVINCIA_REQUERIDA: 'Provincia Requerida',
      PS: 'Place/Show',
      PUEDE_REGISTRARSE: 'Puede registrarse',
      PUNTO_DE_VENTA: 'Punto de Venta',
      PUNTOS: 'Puntos',
      PROFILE_CLAIMS: 'Permisos',
      PROFILES: 'Perfiles',
      PROFIT_AND_LOSS: 'Ganancias y pérdidas',
      PROVIDERS: 'Proveedores',
      PRODUCTO_REQUERIDO: 'Producto Requerido',
      PRODUCTO_O_CODIGO_REQUERIDO: 'Producto o Codigo Requerido',
      PRODUCTO_DUPLICADO: 'Producto duplicado',
      QU: 'Quinella',
      QUICK_WAGER: 'Automático',
      QUINCENAL: 'Quincenal',
      QUINTETA: 'Quinteta',
      RACE_HAS_ALREADY_STARTED: 'Carrera iniciada',
      'RAIN-DELAY': 'RAIN-DELAY',
      RAZA: 'Raza',
      RECENT_TRANSACTIONS: 'Transacciones recientes',
      RECIBOS: 'Recibidos',
      REEMBOLSO_TICKET: 'Reembolso',
      REGISTRACION_CONFIRM_EMAIL_MESSAGE: 'Revise sú correo electrónico para confirmarlo y comience a usar sú cuenta',
      REGISTRACION_CREAR_CUENTA_SUBTITULO: 'Cree su cuenta de placebet',
      REGISTRACION_CREAR_CUENTA_TITULO: 'Crear cuenta',
      REGISTRACION_CUENTA_VERIFICADA: 'El correo ha sido verificado.',
      REGISTRACION_DE_ESTACION: 'Registración de estación',
      REGISTRACION_YA_TIENE_UNA_CUENTA: 'Ya tiene una cuenta asociada',
      REGISTRACION_YO_ACEPTO: 'Yo acepto',
      REGISTRATION_ERROR: 'Error de registración',
      REGISTRO_JUGADAS: 'Registro de jugadas',
      REGULAR: 'REGULAR',
      REMOVE_THIS_SEARCH_FROM_FAVOURITES: 'Eliminar esta búsqueda de favoritos',
      REMOVER_BUSQUEDA_DE_LA_HISTORIA: 'Remover búsqueda',
      REMOVER_DE_FAVORITOS: 'Remover de favoritos',
      RENTABILIDAD: '%',
      REPORTE_RESUMEN_VENTAS: 'Resumen de ventas',
      REQUERIDO: 'Requerido',
      REQUIERE_CODIGO_DE_VALIDACION: 'Requiere codigo de validacion',
      RESTRICCION_DE_EDAD: 'Restricción de edad',
      RESTRICCION_DE_GENERO: 'Restricción de género',
      RESTRINGIDO_POR_LOCALIDAD: 'Restringir por localidad',
      RESULTADO: 'Resultado',
      RESULTADOS: 'Resultados',
      RESUMEN_DE_CUENTA: 'Resumen de cuenta',
      RESUMEN_MAQUINAS: 'Resumen maquinas',
      RESUMEN_OPERACIONES_SUBTITULO: 'Resumen de las operaciones por fecha',
      RESUMEN_OPERACIONES_TITULO: 'Resumen operaciones',
      RESUMEN_VENTAS_SUB_TITULO: 'Resumen venta de las agencias',
      RESUMEN_VENTAS_TITULO: 'Resumen ventas',
      RETIRADO: 'Retirado',
      RETIRO: 'Retiro',
      RETIROS: 'Retiros',
      RETIRO_CUENTA: 'Retiro cuenta',
      RETIRO_INTERNET: 'Retiro Internet',
      RETURNS: 'Reembolso',
      REVERSAR_DEPOSITO: 'Reversar Deposito',
      REVERSAR_GANADOR: 'Reversar Ganador',
      REVERSE: 'Reverse',
      RIESGO: 'Riesgo',
      RISK: 'Riesgo',
      ROL: 'Roles',
      ROL_CLAIMS: 'Rol claims',
      ROLE: 'Rol',
      ROLE_ASIGNANDO_EXITOSAMENTE_AL_USUARIO: 'Asignación de role exitosa',
      ROLE_CLAIM_AGREGADO: 'Permiso Otorgado',
      ROLE_CLAIM_ELIMINADO: 'Permiso eliminado',
      ROLES_DE_USUARIOS: 'Roles de usuarios',
      ROLES_PERMISOS: 'Roles permisos',
      ROUND_ROBIN: 'Round Robin',
      RUN_LINE: 'Gavela',
      RUNNER: 'Corredor',
      RACETRACK: 'Pista',
      SAB: 'Sábado',
      SALES: 'Ventas',
      SALES_RESUME_INCORRECT_BALANCE: 'Error consultando balance',
      SALES_RESUME_NOT_BALANCE_AVAILABLE: 'Balance no encontrado',
      SALES_TOTAL: 'Total venta actual',
      SalesSummary: 'Resumen de ventas',
      SALIR: 'Salir',
      SAVED: 'Salvado',
      SALES_PROFIT: 'Ventas y Ganancias',
      SCATTER_CHART: 'Gráfico de dispersión',
      SCRATCHED: 'Retirados',
      SE_HAN_DETECTADO_CAMBIOS_EN_EL_FORMULARIO: 'Se han detectado cambios en el formulario',
      'SECOND-HALF': 'SECOND-HALF',
      SECTOR: 'Sector',
      SEGUNDA_MITAD_H2_NUL: 'segunda mitad H2 nul',
      SEGURIDAD: 'Seguridad',
      SELECCIONAR_AGENCIA: 'Seleccionar agencia',
      SELECCIONAR_ESTACION: 'Seleccionar estación',
      SELECCIONAR_LIGA: 'Seleccionar liga',
      SELECCIONAR_PRODUCTO: 'Seleccionar producto',
      SELECCIONAR_PRODUCTOS: 'Seleccionar productos',
      SEMANAL: 'Semanal',
      SEMESTRAL: 'Semestral',
      SEPTIMA: 'Septima',
      SERIE: 'Serie',
      SERVICE_CHARGE: 'Sobrecargo',
      SERVICIO_AGUA: 'Servicio Agua',
      SERVICIO_BASURA: 'Servicio Basura',
      SERVICIO_TELECABLE: 'Servicio Telecable',
      SEXO: 'Sexo',
      SEXTETA: 'Sexteta',
      SHW: 'Show',
      SI: 'Sí',
      SI_CONTINUA_PERDERA_LOS_CAMBIOS_EN_EL_FORMULARIO_DESEA_CONTINUAR:
        'Si continua perderá los cambios en el formulario desea continuar',
      SI_NO_POSEE_CUENTA:
        'Si ya tiene una cuenta, asóciela en la opción “Administrar cuentas”, de lo contrario, vaya a “Crear cuenta”',
      SIGUIENTE: 'Siguiente',
      SING_OUT: 'Desconectar',
      SITUACION_AGENCIA: 'Situación agencia',
      SMS: 'SMS',
      SOCCER: 'Soccer',
      SOLO_DIGITOS_PERMITIDOS_EN_MONTO: 'Solo se permiten números',
      SOLO_VALORES_ENTRE: 'Sólo valores entre',
      SOPORTE_TECNICO: 'Soporte técnico',
      SORTEOS_LOTERIA: 'Sorteos Loteria',
      SPORTS: 'Deportes',
      SOBRECARGOS: 'Sobrecargos',
      SOBRECARGO: 'Sobrecargo',
      SPORT: 'Deporte',
      SPR: 'Superfecta',
      STRAIGHT: 'Straight',
      SUBDOMINIO: 'subdominio',
      SUBMITTICKET: 'Vendidos',
      SUPERVISOR: 'Supervisor',
      SUPLIDOR: 'Suplidor',
      SUSPEND: 'SUSPEND',
      SYNC_DATE: 'Fecha sincronización',
      SECURITY: 'Seguridad',
      TABLE: 'Tabla',
      TABLERO: 'Tablero',
      TABLERO_VENTAS_GANANCIAS: 'Ventas y Ganancias',
      TARJETA_CREDITO: 'Tarjeta Credito',
      TEAM_TOTAL: 'Solo',
      TEASER: 'Teaser',
      'TEASER_2_TEAM_45/65': 'Teaser 2 Team 45/65',
      'TEASER_2_TEAMS_4/6': 'Teaser 2 Teams 4/6',
      TELEFONO: 'Teléfono',
      Telegram: 'Telegram',
      TENNIS: 'Tennis',
      TERMINADO: 'KO',
      TERMINAL_LOG_SUB_TITULO: 'Actividades de terminal de venta',
      TERMINAL_LOG_TITULO: 'Historial de terminal',
      TERMINOS_DE_BUSQUEDA: 'Términos de búsqueda',
      TERRITORIO: 'Territorio',
      TERRITORIO_AZUL: 'Territorio azul',
      TERRITORIO_BLANCO: 'Territorio blanco',
      THE_EVENT_HAS_NOT_RACES: 'Apuestas cerradas para esta carrera',
      THE_EVENT_IS_NOT_OPEN: 'Evento está cerrado',
      THE_PASSWORD_AND_CONFIRMATION_DID_NOT_MATCH: 'La Clave y la confirmación no son iguales.',
      THE_RUNNER_DOES_NOT_EXISTS_IN_THE_RACES: 'Corredor invalido',
      THE_RUNNER_HAS_BEEN_SCRATCHED: 'El corredor a sido descartado',
      THE_STATION_IS_NOT_REGISTERED_TO_THE_USER: 'Localidad no registrada',
      THE_WAGER_IS_NOT_SUPPORTED_ON_THIS_RACE: 'Jugada no disponible para esta carrera',
      THEME: 'Tema',
      TICKET: 'Ticket',
      TICKET_ANULAR: 'Ticket anular',
      TICKET_BLOQUEADO: 'Ticket bloqueado',
      TICKET_CANNOT_BE_PAID: 'Ticket no puede ser pagado',
      TICKET_CANNOT_BE_VOIDED: 'Ticket no puede ser cancelado',
      TICKET_CON_JUEGO_INICIADO: 'Contiene juego iniciado',
      TICKET_CONSULTAR_GANADORES: 'Ticket Consultar Ganadores',
      TICKET_CONSULTAR_VENDIDOS: 'Ticket Consultar vendidos',
      TICKET_DE_FECHA_ANTERIOR_NO_PUEDE_SER_ANULADO: 'Ticket de fecha anterior no puede ser anulado',
      TICKET_EXPIRADO: 'Ticket expirado',
      TICKET_GANADOR: 'Ticket Ganador',
      TICKET_IMPRIMIR_GANADORES: 'Ticket imprimir Ganadores',
      TICKET_IMPRIMIR_MAIL: 'Ticket imprimir Mail',
      TICKET_IMPRIMIR_PAPEL: 'Ticket imprimir papel',
      TICKET_IMPRIMIR_SMS: 'Ticket imprimir SMS',
      TICKET_IMPRIMIR_WHATSAPP: 'Ticket imprimir Whatsapp',
      TICKET_NO: 'Ticket#',
      TICKET_NO_PUDO_SER_CREADO: 'Ticket no pudo ser creado',
      TICKET_NOT_FOUND: 'Ticket no encontrado',
      TICKET_NULO: 'Ticket Nulo',
      TICKET_PAGAR: 'Ticket Pagar',
      TICKET_VENDIDO: 'Ticket Vendido',
      TICKET_VENTA: 'Ticket venta',
      TICKETS: 'Tickets',
      TICKET_EN_RECLAMACION: 'El ticket se encuentra en proceso de reclamacion.',
      TICKET_ES_GANADOR_A_PAGAR: 'El ticket es ganador, el monto a pagar es:',
      TICKETS_VERIFICATION: 'Verificación de tickets',
      EL_TICKET_FUE: 'El ticket fue',
      TIEMPO_EXPIRACION: 'Tiempo expiración',
      TIEMPO_EXTRA: 'Tiempo Extra',
      TIENE_JUEGOS_INCIADOS: 'No puede ser anulado, tiene Juegos Inciados',
      TIPO: 'Tipo',
      TIPO_CARRERA: 'Tipo carrera',
      TIPO_CUENTA: 'Tipo cuenta',
      TIPO_DE_CAJA: 'Tipo estación de trabajo',
      TIPO_DE_LOCALIDAD: 'Tipo de agencia',
      TIPO_DE_PERMISO: 'Tipo de permiso',
      TIPO_ENTIDAD: 'Tipo de entidad',
      TIPO_PISTA: 'Pista',
      TIPO_TERRENO: 'Terreno',
      TIPO_TICKET: 'Tipo Ticket',
      TIPO_USUARIO: 'Tipo de usuario',
      TIZA: 'Tiza',
      TIZA_PICK_2: 'TIZA PICK 2',
      TIZA_PICK_3: 'TIZA PICK 3',
      TIZA_PICK_4: 'TIZA PICK 4',
      TIZA_PICK_5: 'TIZA PICK 5',
      TIZA_PICK_6: 'TIZA PICK 6',
      TO: 'hasta',
      TODOS_LOS_HIPODROMOS: 'Venta general',
      TOTAL: 'Total',
      TOTAL_CUENTAS: 'Total de cuentas',
      TOTAL_OFICINAS: 'Total oficinas',
      TOTALES: 'Totales',
      TOTAL_SEMANA: 'Total Semana',
      TOTELINK_ERROR_1225: 'Carrera no esta abierta',
      TOTELINK_ERROR_151: 'Carreras no iniciadas',
      TOTELINK_ERROR_153: 'No es ganador',
      TOTELINK_ERROR_156: 'Ya fue pagado',
      TOTELINK_ERROR_2221: 'Máximo de opciones 6, máximo de pool 5',
      TOTELINK_ERROR_2234: 'Multiples carreras no se combinan con otras opciones',
      TOTELINK_ERROR_2235: 'Opciones de más de 3 patas no se combinan con otros',
      TOTELINK_ERROR_INVALID_WAGER: 'Jugada invalida',
      TOTELINK_ERROR_PAYOFF_EXCEEEDS_CURRENT_BALANCE: 'Fondos insuficientes',
      TOTELINK_FAILED_TICKET: 'Verificar monto y combinación',
      TODOS: 'Todos',
      TODAS: 'Todas',
      TRACK: 'Distancia',
      TRAGAMONEDA: 'Tragamoneda',
      TRANSACCION_AGENCIA: 'Agencia',
      TRANSACCION_CANCELADA: 'Operación no exitosa',
      TRANSACCION_ESTACION: 'Estación',
      TRANSACCIONES: 'Transacciones',
      TRANSACCIONES_CREAR: 'Transacciones Crear',
      TRANSACCIONES_CUENTA_CLIENTE: 'Transacciones cuenta',
      TRANSACCIONES_CUENTA_CLIENTE_DETALLE: 'Detalle de las Transacciones de los clientes',
      TRANSACCIONES_CUENTAS: 'Transacciones cuenta',
      TRANSACCIONES_IMPRIMIR: 'Transacciones Imprimir',
      TRANSACTIONS: 'Transacciones',
      TRANSFERENCIAS: 'Transferencias',
      TRANSFERENCIAS_CONSULTAR: 'Transferencias Consultar',
      TRANSFERENCIAS_IMPRIMIR: 'Transferencias imprimir',
      TRAINER: 'Entrenador',
      TRAINERS: 'Entrenadores',
      TRI: 'Trifecta',
      TRIMESTRAL: 'Trimestral',
      TRIPLETA: 'Tripleta',
      TS: 'Tri-Super',
      TT: 'Twin-Trifecta',
      TYPE_SCORE: 'Tipo apuesta',
      UBET: 'UBET',
      ULTIMA_ACTIVIDAD: 'Última actividad',
      ULTIMO_TICKET: 'último Ticket',
      UN_CIRCLED: 'UN_CIRCLED',
      UNABLE_TO_CREATE_WAGER_WITH_PLAYCODE: 'Jugada no encontrada',
      UNDECIMA: 'Undecima',
      UNDER: 'Menos',
      UNKNOW: 'Unknow',
      UPCOMING: 'Próximos eventos',
      URL_INVALIDO: 'URL invalido',
      URL_CAMBIO_CLAVE: 'URL',
      USER: 'Usuario',
      USERS: 'Usuarios',
      USER_NOT_FOUND: 'Usuario no encontrado',
      USER_WORKSTATION_NOT_DEFINED: 'Estación no definida',
      USERNAME: 'Nombre del usuario',
      USUARIO: 'Usuario',
      USUARIO_INVALIDO: 'Usuario invalido',
      USUARIO_SUB_TITULO: 'Usuario del sistema',
      USUARIO_TITULO: 'Gestion de usuarios',
      USUARIOS: 'Usuarios',
      USUARIOS_EMPLEADOS: 'Usuarios empleados',
      USUARIOS_CON_PERMISO_A_LA_ESTACION: 'Usuarios con permiso a la estacion',
      UTILES_DE_OFICINA: 'Utiles de Oficina',
      UTILES_LIMPIEZA: 'Utiles Limpieza',
      USER_PREFERENCES: 'Preferencias del usuario',
      UBET_ERROR_1: 'Evento iniciado',
      UBET_ERROR_10: 'Ticket cancelado',
      UBET_ERROR_11: 'No cancelado, excedio tiempo',
      UBET_ERROR_12: 'No cancelado, evento iniciado',
      UBET_ERROR_20: 'Ticket pagado',
      UBET_ERROR_21: 'Aún no esta autorizado a pago',
      UBET_ERROR_22: 'Ticket reportado',
      UBET_ERROR_23: 'Ticket caducado',
      UBET_ERROR_24: 'Ticket no pagado, pedir autorización',
      VALOR: 'Valor',
      VALOR_APOSTADO_IF_NO_PUEDE_SER_MAYOR_AL_APOSTADO_ARRIBA:
        'Valor Apostado IF no puede ser mayor al Apostado arriba',
      VALOR_NO_VALIDO: 'Dato inválido',
      VENCIDO: 'Vencido',
      VENDEDOR: 'vendedor',
      VENTA: 'Venta',
      VENTAS: 'Ventas',
      VENTA_DEL_DIA: 'Venta del dia',
      VENTA_DEPORTIVA: 'Venta Deportiva',
      VENTA_GALLOS: 'Venta Gallos',
      VENTA_LOTERIA: 'Venta Loteria',
      VENTA_OFFLINE: 'Venta OffLine',
      VENTA_Y_ESTATUS: 'Venta y estatus',
      VER: 'Ver',
      VER_MAS: 'Ver más',
      VER_TICKETS_GANADORES: 'Ver Tickets ganadores',
      VER_TICKETS_NULOS: 'Ver Tickets nulos',
      VER_TICKETS_PAGADOS: 'Ver Tickets pagados',
      VER_TICKETS_VENDIDOS: 'Ver Tickets vendidos',
      VERIFICACION_DE_TICKET_SUB_TITULO: 'Consulta para fines de pago o anulación',
      VERIFICACION_DE_TICKET_TITULO: 'Verificación de ticket',
      VERIFICACION_DE_TICKETS: 'Verificación de ticket',
      VERSION: 'Version',
      VIATICOS: 'Viaticos',
      VIE: 'Viernes',
      VOID: 'Cancelar',
      VOIDED: 'Anulado',
      VOIDTICKET: 'Anulado',
      VOLVER_AL_INICIO: 'Inicio',
      VUELVA_A_INTENTAR: 'Vuelva a intentar',
      WALLET_PRODUCTS: 'Productos de la Billetera',
      WET: 'Win/Exacta/Trifecta',
      WhatsApp: 'WhatsApp',
      WHEEL: 'Wheel',
      WIN: 'Win',
      'WIN/PLC/SHW': 'Win/Place/Show',
      WIN_REVERSE: 'Ganar Invertido',
      WITHDRAW: 'Retirar',
      WP: 'Win/Place',
      WS: 'Win/Show',
      WEEKLY_BALANCES: 'Balances semanales',
      WORKSTATIONS: 'Estaciones',
      ZONA_HORARIA: 'Zona Horaria',
      ZONE_INFO: 'Información de zona',
    },
  },
  [locales.EN]: {
    translation: {
      '+_RAPIDA': 'Faster',
      _SUPLIDOR: '%Supplier',
      '1/2_INNING_VIS_GANA': '1/2 Inning Visitor win',
      '1_1/2_VIS_GANA': '1.5 visitor win',
      '1_INNING_COMPLETO': '1 Full Innings',
      '1ER_INNING': '1st Inning',
      '1ER_PERIODO': '1st Periodo',
      '1RA_MITAD': '1st Half',
      '1RO': '1º',
      '2_1/2_IN_LOC_GANA': '2.5 Innings Local win',
      '2_INNING_COMPLETOS': '2 Full Innings',
      '2_OF_4': '2d4',
      '2_TEAM_4.5/6.5_PUNT': '2 Team 4.5/6.5 points',
      '2_TEAM_5/7_PUNT': '2 Team 5/7 points',
      '2DA_MITAD': '2nd Half',
      '2DO': '2º',
      '2DO_INNING': '2nd Inning',
      '2DO_PERIODO': '2nd Periodo',
      '3_1/2_INNING': '3.5 Inning',
      '3_IN_COMPLETOS': '3 Full Innings',
      '3_OF_4': '3d4',
      '3_OF_5': '3d5',
      '3_OF_6': '3d6',
      '3_TEAM_4.5/6.5_PUNT': '3 Team 4.5/6.5 points',
      '3_TEAM_5/7_PUNT': '3 Team 5/7 points',
      '3_TEAM_6.5/9.5_PUNT': '3 Team 6.5/9.5 points',
      '3_TEAM_6/9_PUNT': '3 Team 6/9 points',
      '3_TEAM_7/10_PUNT': '3 Team 7/10 points',
      '3_TEAMS_4/6_PUNTOS': '3 Teams 4/6 points',
      '3ER_INNING': '3rd Inning',
      '3ER_PERIODO': '3rd Periodo',
      '3RO': '3º',
      '4_1/2_IN_LOCAL_GANA': '4.5 Innings Local win',
      '4_INNINGS_COMPLETO': '4 Full Innings',
      '4_OF_4': '4d4',
      '4_OF_5': '4d5',
      '4_OF_6': '4d6',
      '4_TEAM_4.5/6.5_PUNT': '4 Team 4.5/6.5 points',
      '4_TEAM_5/7PUNT': '4 Team 5/7points',
      '4_TEAMS_4/6_PUNTOS': '4 Teams 4/6 points',
      '4TO': '4º',
      '4TO_INNING': '4th Inning',
      '4TO_PERIODO': '4th Periodo',
      '5_1/2_VIS_GANA': '5.5 visitor win',
      '5_INNINGS_COMPLETO': '5 Full Innings',
      '5_OF_5': '5d5',
      '5_OF_6': '5d6',
      '5_TEAM_4.5/6.5_PUNT': '5 Team 4.5/6.5 points',
      '5_TEAM_5/7_PUNT': '5 Team 5/7 points',
      '5_TEAMS_4/6_PUNTOS': '5 Teams 4/6 points',
      '5TO': '5º',
      '5TO_INNING': '5th Inning',
      '6_1/2_INNINGS': '6.5 Innings',
      '6_INNINGS_COMPLETOS': '6 Full Innings',
      '6_OF_6': '6d6',
      '6_TEAM_4.5/6.5_PUNT': '6 Team 4.5/6.5 points',
      '6_TEAM_5/7_PUNT': '6 Team 5/7 points',
      '6_TEAMS_4/6_PUNTOS': '6 Teams 4/6 points',
      '6TO': '6º',
      '6TO_INNING': '6th Inning',
      '7_1/2_INNINGS': '7.5 Innings',
      '7_INNINGS_COMPLETOS': '7 Full Innings',
      '7_TEAM_4.5/6.5_PUNT': '7 Team 4.5/6.5 points',
      '7_TEAM_5/7_PUNT': '7 Team 5/7 points',
      '7_TEAMS_4/6_PUNTOS': '7 Teams 4/6 points',
      '7MO_INNING': '7th Inning',
      '8_1/2_INNINGS': '8.5 Innings',
      '8_INNINGS_COMPLETO': '8 Full Innings',
      '8_TEAM_4.5/6.5_PUNT': '8 Team 4.5/6.5 points',
      '8_TEAM_5/7_PUNT': '8 Team 5/7 points',
      '8_TEAMS_4/6_PUNTOS': '8 Teams 4/6 points',
      '8VO_INNING': '8th Inning',
      '9NO_INNING': '9th Inning',
      A_APOSTAR: 'Risk',
      A_COBRAR: 'Payment',
      A_GANAR: 'To win',
      A_GANAR_IF: 'To win IF',
      ACCESS_KEY: 'Access Key',
      ACCION: 'Action',
      ACCOUNT_DEPOSIT: 'Account deposit',
      ACCOUNT_NOT_FOUND: 'Account not found',
      ACCOUNT_NUMBER: 'Account number',
      ACCOUNT_PIN: 'PIN',
      ACCOUNT_WITHDRAWAL: 'Account withdrawal',
      ACCOUNTTYPEPAYMENT: 'Debit',
      ACEPTAR: 'Accept',
      ACRONIMO: 'Acronym',
      ACTION_REVERSE: 'Action Reverse',
      ACTIVAS: 'Active',
      ACTIVE_HISTORY_DETAIL_TITLE: 'Transaction history',
      ACTIVO: 'Active',
      ACTUAL: 'Actual',
      ACTUALIZACION: 'Last Updated',
      ACTUALIZADO: 'Updated',
      ACTUALIZANDO_LA_COMPANIA: 'Company updating',
      ACUERDO_TERMINOS_Y_CONDICIONES: 'Agreement terms and conditions',
      ACUERDO_TERMINOS_Y_CONDICIONES_LINK: 'http://placebet.com.do/terms',
      ADD_TO_TICKET: 'Add to ticket',
      ADMINISTRACION_DE_CAJAS: 'Station management',
      ADMINISTRACION_DE_CAJAS_O_PUNTOS_DE_VENTA: 'Workstation administration',
      ADVERTENCIA: 'Warning',
      AGENCIA_SUB_TITULO: 'List and maintenance of agencies',
      AGENCIA_TITULO: 'Agency maintenance',
      AGENCIAS: 'Agencies',
      AGENCIES: 'Agencias',
      AJUSTE_DE_CUENTAS_DE_CLIENTE: 'account adjustment',
      AJUSTE_DE_LA_CUENTA: 'Account to adjust',
      AMOUNT_BET: 'Amount Bet',
      ANTERIOR: 'Previous',
      ANUAL: 'Annual',
      ANULAR: 'Cancel',
      AO_ENTREGA_CENTRAL: 'AO entrega CENTRAL',
      AO_ENTREGA_DIG: 'AO entrega DIG',
      AO_RECIBE_DEL_DIG: 'AO recibe del Dig',
      APELLIDO: 'Last name',
      APELLIDOS: 'Family name',
      API_SERVER_IDENTIDAD: 'Identity server Api',
      APLICAR_JUGADA: 'Formulario de digitación',
      APODO: 'Nickname',
      APOSTADO: 'Bet',
      APOSTADO_DEL_DIA: 'Bet of the day',
      APOSTADO_IF: 'Bet IF',
      APOSTADO_SOBREPASA_DISPONIBLE_DE: 'Bet exceeds Available from',
      APOSTAR: 'Place bet',
      APUESTA: 'Risk',
      APUESTA_DEL_DIA: 'Wagers of',
      ARCHIVADOS: 'Saved',
      ARCHIVO_GENERADO: 'Information sent',
      AUTORACING: 'AutoRacing',
      AZUL: 'Blue',
      BALANCE: 'Balance',
      BALANCE_ACTUAL: 'Current balance',
      BALANCE_EXCEDIDO: 'Exceeds available balance',
      BALANCE_INICIAL: 'Initial balance',
      BALANCE_PERDIDAS_SUBTITULO: 'Loss and gain data',
      BALANCE_PERDIDAS_TITULO: 'Loss and gain',
      BALANCE_SEMANAL: 'Weekly balance',
      BALANCE_SEMANAL_SUBTITULO: 'Weekly balance subtitle',
      BALANCE_SEMANAL_TITULO: 'Weekly balance title',
      BALANCE_SEMANAL_TITULO_BUSQUEDA: 'Weekly balance search',
      BALANCES_SEMANALES: 'Weekly balance',
      BALANCES_DIARIOS: 'Balances diarios',
      BANCO: 'Bank',
      BAR: 'Bar',
      BAR_CHART: 'Bar chart',
      BASE_URL: 'Base url',
      BASEBALL: 'Baseball',
      BASKETBALL: 'Basketball',
      BEGIN_ACCOUNT_WITHDRAWAL: 'Withdrawal',
      BEGINNING: 'Beginning',
      BEGINNING_OF_WEEK: 'Openning',
      BENEFICIO_BRUTO: 'Gross profit',
      BENEFICIO_NETO: 'Net profit',
      BENEFICIOS: 'Profit',
      BLANCO: 'White',
      BLOCK: 'Block',
      BLOQUE: 'Block',
      BLOQUEADO: 'Locked',
      BORRAR_OPCION: 'Delete option',
      BORRAR_TICKET: 'Delete Ticket',
      BORRAR_TODOS_TICKETS: 'Delete all tickets',
      BOX: 'Box',
      BOXING: 'Boxing',
      BUBBLE_CHART: 'Bubble chart',
      BUSQUEDA: 'Search',
      BUSQUEDAS_GUARDADAS: 'Saved searches',
      BUSQUEDAS_RECIENTES: 'Recent searches',
      CAJA_A: 'Station A',
      CAJA_ACTIVA: 'Active',
      CAJA_B: 'Station B',
      CAJA_C: 'Station C',
      CAJA_CREADA: 'Station created',
      CAJA_D: 'Station D',
      CALCULABLE: 'Calculable',
      CAMBIAR_CLAVE: 'Change Password',
      CAMBIAR_CLAVE_TITULO: 'Change password',
      CAMBIAR_ESTADO: 'Change Status',
      CAMBIO_CLAVE_EXITOSO: 'Successful password change',
      CAMBIO_DE_SIGNO_ACTUAL_ES_CONTRARIO_AL_ANTERIOR: 'Change of current sign is contrary to the previous one',
      CAMBIO_LENGUAJE: 'Change of language',
      CAMBIOS_AUTORIZADOS: 'Authorized changes',
      CAMBIOS_REALIZADOS: 'Changes made',
      CANCELADO: 'Cancelled',
      CANCELAR: 'Cancel',
      CANCELLED: 'Cancelado',
      CANCREATE: 'Create',
      CANDELETE: 'Delete',
      CANEDIT: 'Edit',
      CANPRINT: 'Print',
      CANSEARCH: 'Search',
      CANVIEW: 'View',
      CARACTERES_ESPECIALES_NO_PERMITIDOS: 'Special characters not allowed',
      CARGA: 'Weight',
      CARRERA: 'Race',
      CARRERA_CERRADA: 'Race in progress',
      CARRERA_CREADO: 'Run created',
      CARRERAS: 'Races',
      CARTELERA: 'Billboard',
      CARTERA: 'Purse',
      CARTERA_REQUERIDA: 'Purse required',
      CENTRAL: 'Central',
      CIRCLED: 'CIRCLED',
      CLAIM: 'Claim',
      CLAVE: 'Password',
      CLAVE_NO_CUMPLE:
        'Please use at least 6 characters, an uppercase letter, numbers and special characters like !@#$%^&*.()',
      CLIENTE: 'Client',
      CLIENTES_CONSULTAR: 'Client Consult',
      CLIENTES_CREAR: 'Client Create',
      COCKFIGHTS: 'Cockfights',
      CODIGO: 'Code',
      CODIGO_CLIENTE: 'Code',
      CODIGO_GENERADO: 'Code generated',
      CODIGO_NO_EXISTE: 'Code does not exist',
      CODIGO_REGISTRACION: 'Registration code',
      CODIGO_REGISTRACION_EXPIRADO: 'Registration code expired',
      CODIGO_YA_EXISTE: 'Code already exists',
      COLAPSAR: 'Collapse',
      COLOCAR_JUGADA: 'Place Bet',
      COMBINADA: 'Combined',
      COMBUSTIBLE: 'Fuel',
      COMISION_EMPLEADO: '%Employee',
      COMISSION: 'Comission',
      COMPANIA: 'Company',
      COMPANIA_SUB_TITULO: 'Company',
      COMPANIA_TITULO: 'Company title',
      COMPANIAS: 'Companies',
      COMPETIDOR: 'Runner',
      COMPETIDOR_SUB_TITULO: 'Show Runner information',
      COMPETIDOR_TITULO: 'Runner',
      COMPETIDORES: 'Runners',
      COMPETIDORES_SUB_TITULO: 'Show the runners list',
      COMPETIDORES_TITULO: 'Runners',
      COMPETITOR_SUB_TITULO: 'Show Runner information',
      COMPETITOR_TITULO: 'Runner',
      COMPLETO: 'Full Game',
      COMPROBANTE_SOLICITUD_RETIRO: 'Proof of withdrawal request',
      COMPUTOS: 'Computes',
      CONCEPTO: 'Concept',
      CONDICION: 'Condition',
      CONDICION_TICKET: 'Ticket condition',
      CONECCION: 'Conection',
      CONEXIONES: 'Connections',
      CONFIGURACION: 'Settings',
      CONFIRMACION: 'Confirmation',
      CONFIRMACION_ANULADO: 'Confirm cancellation',
      CONFIRMACION_DE_CUENTA: 'Account confirmation',
      CONFIRMAR_CLAVE: 'Confirm password',
      CONFIRMAR_MONTO: 'Confirm amount',
      CONFIRMAR_NAVEGACION: 'Confirm navigation',
      CONFIRMAR_TELEFONO: 'Confirm phone',
      CONSORCIO: 'Enterprise',
      CONSORCIO_SUB_TITULO: 'Enterprise maintenance',
      CONSORCIO_TITULO: 'Enterprise Data',
      CONSORCIOS: 'Enterprises',
      CONSULTAR: 'Consult',
      CONSULTAR_TRANSACIONES: 'Check transactions',
      CONSULTAS: 'Queries',
      CONSULTS: 'Queries',
      CONTACTAR_ADMINISTRADOR: 'Contact administrator',
      CORREDORES: 'Runners',
      CORREDORES_DUPLICADOS_EN_UNA_PATA: 'Duplicate runner',
      CREANDO: 'Creating',
      CREANDO_ESTACION: 'Creating workstation',
      CREANDO_LA_COMPANIA: 'Creating Company',
      CREAR: 'Create',
      CREAR_CUENTA: 'Create account',
      CREAR_CUENTA_CLIENTES: 'Create account',
      CREAR_ROLE: 'Create role',
      CREAR_TICKET: 'Create ticket',
      CREAR_TRANSACCIONES_CUENTAS: 'Create transaction',
      CRECIMIENTO_DEL_PERIODO: 'Increase',
      CREDENCIALES_DEL_USUARIO_UNICA_VEZ_MOSTRADO: 'User credential',
      CREDENCIALES_INVALIDAS: 'Invalid credentials',
      CREDITO: 'Credit',
      CRITERIOS_DE_BUSQUEDA: '  Query criteria',
      CUADRANTE: 'Quadrant',
      CUADRANTE_00: 'Quadrant 00',
      CUADRANTE_01: 'Quadrant 01',
      CUADRANTE_02: 'Quadrant 02',
      CUADRANTE_03: 'Quadrant 03',
      CUADRANTE_04: 'Quadrant 04',
      CUADRANTE_05: 'Quadrant 05',
      CUADRANTE_06: 'Quadrant 06',
      CUADRANTE_07: 'Quadrant 07',
      CUADRANTE_08: 'Quadrant 08',
      CUADRANTE_09: 'Quadrant 09',
      CUADRANTE_0A: 'Quadrant 0A',
      CUADRANTE_0B: 'Quadrant 0B',
      CUADRANTE_10: 'Quadrant 10',
      CUADRANTE_11: 'Quadrant 11',
      CUADRANTE_12: 'Quadrant 12',
      CUADRE_CAJA_CONSULTAR: 'Square box consult',
      CUADRE_CAJA_CREAR: 'Square box create',
      CUADRE_CAJA_IMPRIMIR: 'Square box print',
      CUADRE_CAJA_MODIFICAR: 'Square box Modify',
      CUADRE_DE_CAJA: 'Cash box',
      CUARTETA: 'Quatrain',
      CUENTA: 'Account',
      CUENTA_PIN_INVALIDO: 'Invalid account or PIN',
      CUENTA_PRIMARIA: 'Account default',
      CUENTA_RETIRAR_FONDOS: 'Withdraw',
      CUENTAS: 'Accounts',
      CUENTAS_DE_CLIENTES: 'Client accounts',
      CUENTAS_DE_PAGOS: 'Payments account',
      CUENTAS_Y_BALANCES_DE_LOS_CLIENTES: 'Customer Accounts and balances',
      CURRENCYNAME: 'Currency',
      CURSO: 'Course',
      DASHBOARD: 'Dashboard',
      DATO_ACTUALIZADO: 'Updated data',
      DATO_COPIADO: 'Copied data',
      DATO_REGISTRADO: 'Registered data',
      DATOS_DE_LA_AGENCIA: 'Agency details',
      DATOS_DE_LA_COMPANIA: 'Company information',
      DATOS_DE_LA_CONEXION: 'Connection information',
      DATOS_DEL_COMPETIDOR: 'Competitor Information',
      DATOS_DEL_ENTRENADOR: 'Trainer information',
      DATOS_DEL_EVENTO: 'Event information',
      DATOS_DEL_JINETE: 'Jockey information',
      DATOS_DEL_PROPIETARIO: 'owner information',
      DATOS_DEL_PROVEEDOR: 'Provider data',
      DATOS_DEL_USUARIO: 'User data',
      DATOS_DEL_USUARIO_EMPLEADO: 'Employee user data',
      DATOS_MOSTRADOS_ESTAN_BASADOS_EN_ESTAS_ENTIDADADES: 'Shows the profit and loss of clients and agencies',
      DATOS_MOSTRADOS_ESTAN_BASADOS_EN_ESTAS_ENTIDADES: 'Data displayed is based on these entities',
      DD: 'Daily Double',
      DE: 'Double Exacta',
      DE_CARRERA_NO: 'No.',
      DE_FECHA: 'of date',
      DEBE_ESPECIFICAR_EL_SIGNO: 'You must specify the sign',
      DEBE_ESPECIFICAR_UNA_FECHA_MAYOR_O_IGUAL_A_LA_DE_HOY: 'You must specify a date greater than or equal to todays',
      DEBITO: 'Debit',
      DECIMA: 'Tenth',
      DELAY: 'DELAY',
      DELETE_TICKET: 'Delete Ticket',
      DEPORTIVA: 'Sport',
      DEPOSIT: 'Deposit',
      DEPOSITADO: 'Deposited',
      DEPOSITO: 'Deposit',
      DEPOSITO_INTERNET: 'Internet Deposit',
      DESCARGA_FALLIDA: 'Download failed',
      DESCRIPCION: 'Description',
      DESCRIPCION_RETIRO_CUENTA: 'Smount to withdraw',
      DESDE: 'From',
      DESEA_CONTINUAR: 'Do you wish to continue?',
      DETALLE_DE_LA_CUENTA: 'Account detail',
      DETALLE_EFECTIVO: 'Cash Detail',
      DETALLE_EFECTIVO_CONSULTAR: 'Detail Cash consult',
      DETALLE_EFECTIVO_CREAR: 'Detail Cash create',
      DETALLE_EFECTIVO_IMPRIMIR: 'Detail Cash print',
      DIARIO: 'Diario',
      DIG_ENTREGA_AO: 'DIG entrega AO',
      DIG_RECIBE_DE_AO: 'DIG recibe de AO',
      DINERO_METRO: 'Money Metro',
      DIRECCION: 'Address',
      DIRECTA: 'Direct',
      DIRECTAS: 'Straight',
      DISCLAIMER_DEPOSIT: 'Proof of account deposit',
      DISCLAIMER_WITHDRAWAL: 'Proof of withdrawal on account',
      DISCLAIMER_WITHDRAWAL_VOUCHER:
        'This voucher only indicates the intention to make a withdrawal, however, the amount is not reserved, so its execution will depend on the balance at the time of making it effective.',
      DISPONIBLE_PARA_APOSTAR: 'Available to bet',
      DISPOSITIVO_HA_SIDO_AUTORIZADO: 'Device has been authorized',
      DISPOSITIVO_NO_SE_PUDO_AUTORIZAR: 'Device could not be authorized',
      DISTANCIA: 'Distance',
      DISTANCIA_REQUERIDA: 'Required distance',
      DIVISA: 'Currency',
      DOCEAVA: 'Twelve',
      DOLARES_EU: 'US Dollars',
      DOM: 'Sunday',
      DOMINIO: 'Domain',
      DRAW: 'Draw',
      DRAW_DIFFERENCE: 'Maximum to withdraw:',
      DRAW_TITLE: 'Loans and returns',
      DRAWS: 'N/A',
      DUENO: 'Owner',
      DUPLICATED_WAGER: 'Duplicated wager',
      ED: 'Extra Double',
      EDAD: 'Age',
      EDITANDO_ESTACION: 'Editing workstation',
      EDITAR_CONEXION: 'Modify connection',
      EFECTIVO: 'Cash',
      EGRESO_TC: 'Outcome CC',
      EGRESO_TRANSITO: 'Outcome transit',
      EGRESOS: 'Outcome',
      EGRESOS_CONFIRMADOS: 'Confirmed outcome',
      EGRESOS_MAQUINAS: 'Machine Outcome',
      EGRESOS_MONEDAS: 'Currency Outcome',
      EGRESOS_RECARGA: 'Egresos Recarga',
      EL_MONTO_MINIMO_DE_APUESTA_ES: 'The minimum bet amount is',
      ELECCIONES_RD: 'Elecciones RD',
      EMAIL: 'Email',
      EMPADRONAMIENTO_BASICO: 'Ticket census',
      EMPADRONAMIENTO_DE_JUGADA: 'Shows the summary of bets placed',
      EMPATE: 'Draw',
      EMPATE_COMPLETO: 'Full Draw',
      EN_PROGRESO: 'In progress',
      ENCARGADO_CUENTA: 'Account manager',
      ENTIDADES: 'Entities',
      ENTITIES: 'Entities',
      ENTITY_NAME: 'Entity',
      ENTRENADOR: 'Trainer',
      ENTRENADOR_SUB_TITULO: 'Show trainer information',
      ENTRENADOR_TITULO: 'Trainer',
      ENTRENADORES: 'Trainers',
      ENTRENADORES_SUB_TITULO: 'Show trainer information',
      ENTRENADORES_TITULO: 'Trainer',
      ENVIOS: 'Sent',
      EQUIPOS: 'Teams',
      ERRO: 'Error',
      ERROR_403:
        'This can be a temporary inconvenience. Please, try to go back to the start and select the desired option again, if the situation persists please contact the administrator with this visible message.',
      ERROR_404: 'This can be a temporary inconvenience. Please, try to go back to the start and select the desired op',
      ERROR_500: 'Unexpected situation, please try again later, if the situation persists please contact the administr',
      ERROR_ACTUALIZANDO_EL_DATO: 'Error updating the data',
      ERROR_CARRERA_MAXIMA_PERMITIDA: 'You have reached the maximum number of runs',
      ERROR_COMPETIDOR_EN_USO: 'Runner is already in use',
      ERROR_CORREDOR_MAXIMO_PERMITIDO: 'You have already created the number of runners for this race',
      ERROR_NUMERO_EN_USO: 'Number is already in use',
      ERROR_REGISTRANDO_EL_DATO: 'Error registering the data',
      ERROR_SUBMITING_TICKET: 'Ticket could not be created',
      ERROR_VERIFICAR_MONTO_DE_APUESTA: 'Error check amount bet',
      ESCRIBA_PARA_VER_RESULTADOS: 'Enter to see results',
      ESTA_SEGURO_DE_ANULAR_ESTE_TICKET: 'Are you sure to cancel this ticket',
      ESTA_SEGURO_DE_PAGAR_ESTE_TICKET: 'Are you sure to pay this ticket',
      ESTABLECE_LOS_PERMISOS_DE_LOS_USUARIOS: 'Set user permissions',
      ESTACION: 'Station',
      ESTACION_NO_PUEDE_SER_REGISTRADA: 'Station cannot be registered',
      ESTACIONES: 'Stations',
      ESTADISTICAS_DE_CUENTAS_VENTAS_Y_GANANCIAS: 'Statistics of sales and earnings accounts',
      ESTADO: 'State',
      ESTADO_SITUACION_AGENCIAS_SUBTITULO: 'Shows the current situation of the agencies',
      ESTADO_SITUACION_AGENCIAS_TITULO: 'Accounting statement',
      ESTAS_SEGURO_DE_HABILITAR_ENFRENTAMIENTOS_MARCADOS: 'Are you sure to enable marked matches',
      ESTATUS: 'Status',
      ESTE_VOUCHER_FUE_REGISTRADO_EN_OTRA_LOCALIDAD: 'This Voucher was registered in another location',
      EVENT_RESULTS_NOT_FOUND: 'Results not found',
      EVENTO: 'Event',
      EVENTO_SUB_TITULO: 'Hipódromo V Centenario Event',
      EVENTO_TITULO: 'Event',
      EVENTOS: 'Events',
      EVENTOS_DE_LA_CONEXION: 'Connection events',
      EVENTOS_DEL_DIA: 'TODAY EVENTS',
      EVENTOS_SUB_TITULO: 'Shows event information',
      EVENTOS_TITULO: 'Events',
      EX: 'Exacta',
      EXA: 'Exacta',
      EXCEDE_CANTIDAD_DE_EQUIPOS_PERMITIDOS: 'You exceed the amount of equipment allowed',
      EXCEDE_HEMBRAS_PERMITIDAS: 'Exceed max underdog of',
      EXCLUDED_BY_AN_EXISTING_WAGER: 'Contradictory play',
      EXE: 'Exella',
      EXPANDIR: 'extend',
      EXPIRACION: 'Expiration',
      EXCLUIR: 'Excluir',
      ENTERPRISES: 'Consorios',
      FECHA: 'Date',
      FECHA_INICIAL_DEBE_SER_MENOR_A_FECHA_FINAL: 'Start date must be less than end date',
      FECHA_INSCRIPCION: 'Registration date',
      FECHA_INVALIDA: 'Invalid Date',
      FECHA_NACIMIENTO: 'Date of birth',
      FECHA_TRANS: 'Date',
      FEMALE: 'Female',
      FINAL: 'Final',
      'FIRST-HALF': 'FIRST-HALF',
      FOOTBALL: 'Football',
      FORMATO_APUESTA: 'Bet format',
      FORMULARIO_DE_BUSQUE: 'Search form',
      FROM: 'From',
      GALLOS: 'Roosters',
      GAN_NO_COBRADOS: 'GAN no cobrados',
      GANA: 'Win',
      GANA_IF: 'Win IF',
      GANADO: 'Win',
      GANADOR: 'Winner',
      GANADOR_OFFLINE: 'OffLine winner',
      GANANCIA: 'Profit',
      GANANCIA_: 'Profit_',
      GANANCIAS_Y_PERDIDAS: 'Profit',
      GASTOS: 'Expenses',
      GAVELA_EXCEDE_LIMITES_ESTABLECIDOS: 'Gavela exceeds established limits',
      GENERAR_ARCHIVO: 'Send to Totalizer',
      GENERAR_CODIGO: 'Generate code',
      GERENCIA: 'Management',
      GESTION_DE_USUARIOS_Y_EMPLEADOS: 'User and employee management',
      GOLF: 'Golf',
      GS: 'Grand Slam',
      GUARDAR: 'Save',
      GUARDAR_LA_BUSQUEDA: 'Save search',
      'H1-JC_VALIDO_+Y-JCNO': 'H1-JC VALIDO +Y-JCNO',
      H1_COMPLETO: 'H1 Full',
      HASTA: 'To',
      HEMBRA: 'Under Dog',
      HEX: 'Hexafecta',
      HIPISMO: 'Hipism',
      HISTORIAL: 'Record',
      HOCKEY: 'Hockey',
      HORA: 'Time',
      HORA_DE_APERTURA: 'Opening time',
      HORA_DE_CIERRE: 'closing time',
      HORSE_RACES: 'Horse races',
      IF: 'IF',
      IF_WIN_ONLY: 'If Win Only',
      IF_WIN_OR_TIE: 'If Win or Tie',
      IMPRESORA: 'Impresora',
      IMPRIMIR: 'To print',
      IMPRIMIR_CUADRE: 'print square',
      IMPRIMIR_VOUCHER: 'Proceed',
      IMPUESTO_MUNICIPAL: 'Council Tax',
      IMPUESTOS: 'Taxes',
      IMPUESTOS_: 'Taxes_',
      'IN-GAME': 'In-Game',
      'IN-PROGRESS': 'IN-PROGRESS',
      IN_OUT: 'Others',
      INACTIVAS: 'Inactive',
      INACTIVO: 'Inactive',
      INCREMENTO: 'Increase',
      INFO: 'Information',
      INFORMACION: 'Information',
      INFORMACION_DE_GANACIAS_Y_PERDIDAS: 'Profit and loss information',
      INFORMACION_DE_GANANCIAS_Y_PERDIDAS: 'Profit and loss information',
      INFORMACION_DEL_TICKET: 'Ticket information',
      INFORMACION_GENERAL: 'General information',
      INGRESO_TC: 'Income CC',
      INGRESO_TRANSITO: 'Income transit',
      INGRESOS: 'Income',
      INGRESOS_CONFIRMADOS: 'Confirmed income',
      INGRESOS_MAQUINAS: 'Machine Income',
      INGRESOS_MONEDAS: 'Currency Income',
      INGRESOS_RECARGA: 'Ingresos Recarga',
      INGRESOS_TRANSITO: 'Income transit',
      INICIAR_SESION: 'Log in',
      INICIO: 'Openning',
      INSERTE_LA_JUGADA: 'Favor digite su jugada',
      INSTITUCION: 'Institution',
      INTERNET: 'Internet',
      INTRODUSCA_EL_CODIGO_DE_REGISTRACION_PROVISTO: 'Enter the registration code',
      INVALID_PASSWORD_RESET_CODE: 'The User is incorrect, the Code is invalid or has expired.',
      INVALID_PLAY_CODE: 'Invalid play code',
      INVALID_PLAY_NUMBER_OF_LEGS_DOES_NOT_MEET_POOL_REQUIREMENTS: 'Selection of invalid legs',
      INVALID_PLAY_OR_NOT_SUPPORTED: 'Invalid or unavailable play',
      INVALID_RACE: 'Invalid race',
      INVALID_REFERENCE_ID: 'Invalid reference ID',
      INVALID_TICKET_FORMAT: 'Invalid ticket format',
      ISSUED: 'Issued',
      INCLUIR: 'Incluir',
      J_GANADORA_A_MANO: 'J ganadora a mano',
      J_PERDEDORA_A_MANO: 'J perdedora a mano',
      JINETE: 'Horse rider',
      JINETE_SUB_TITULO: 'Show Jockey information',
      JINETE_TITULO: 'Jockey',
      JINETES: 'Jockey',
      JINETES_SUB_TITULO: 'Show Jockey information',
      JINETES_TITULO: 'Jockey',
      JOCKEY: 'Jockey',
      JUE: 'Thursday',
      JUEGOS_ASSOCIADOS: 'Related Games',
      JUEGOS_DEL_DIA: 'Games of the day',
      JUEGOS_DEL_DIA_CONSULTAR: 'Games of the day consult',
      JUEGOS_DEL_DIA_IMPRIMIR: 'Games of the day print',
      JUGADA: 'Bet',
      JUGADA_DUPLICADA: 'Duplicated play',
      JUGADA_IF: 'Bet IF',
      JUGADA_INVALIDA: 'Play is not valid',
      JUGADA_INVALIDA_PATAS_INCOMPLETAS: 'Incomplete legs',
      JUGADA_MANUAL: 'Play input',
      JUGADAS: 'Plays',
      JUGADORES: 'Players',
      JUSTIFICACION: 'Justification',
      KEY_WHEEL: 'Key Wheel',
      LA_FECHA_DEL_EVENTO_HA_PASADO: 'Event date has passed',
      LA_IMPRESORA_NO_ESTA_LISTA: 'Printer is not ready',
      LA_POSICION_EXISTE: 'Previously selected position',
      LADO_NO_SOPORTADO: 'Unsoported Side',
      LANGUAGE_SWITCHER: 'Language change',
      LBS: 'Weight',
      LEADING: 'Leading',
      LICENCIA: 'License',
      LIGA: 'League',
      LINE: 'Line',
      LINE_CHART: 'Line chart',
      LINK: 'Link',
      LINK_ACCOUNT: 'Link account',
      LISTA_DE_AGENCIAS: 'List of agencies',
      LISTA_DE_APUESTAS: 'Bet list',
      LISTA_DE_CARRERAS: 'Race list',
      LISTA_DE_CARRERAS_DE_FECHA: 'Date race list',
      LISTA_DE_COMPANIAS: 'Companies list',
      LISTA_DE_COMPETIDORES: 'Runners list',
      LISTA_DE_CONSORCIOS: 'Enterprises list',
      LISTA_DE_CORREDORES: 'List of runners',
      LISTA_DE_CORREDORES_DE_LA_CARRERA_NO: 'Runners of race number',
      LISTA_DE_ENTRENADORES: 'Trainer list',
      LISTA_DE_EVENTOS: 'Event list',
      LISTA_DE_JINETES: 'Jockey list',
      LISTA_DE_PROPIETARIO: 'List of owners',
      LISTA_DE_PROVEEDORES: 'List of providers',
      LOCALE: 'Locale',
      LOGOUT: 'Logout',
      LOTERIA: 'Lottery',
      LOTERIA_SPORT: 'Loteria Sport',
      LOTO_DEPORTE: 'Loto Deporte',
      LOTODEPORTE: 'Lottery Sport',
      LUN: 'Monday',
      MACHO: 'Favorite',
      MAIL: 'Mail',
      MALE: 'Male',
      MANAGE_ACCOUNTS: 'Manage accounts',
      MANT_AIRE_ACOND: 'Maintenance Air Conditioning',
      MANT_BATERIAS: 'Battery Maintenance',
      MANT_COMPUTADOR: 'Computer Maintenance',
      MANT_PLANTA: 'Electrical Plant Maintenance',
      MANT_SUCURSAL: 'Local Maintenance',
      MAQUINA_GRAFICA: 'Machine chart',
      MAQUINAS_DETALLADAS: 'Machine detail',
      MAQUINAS_DETALLADAS_POR_LOCALIDAD: 'Machine detail',
      MAQUINAS_RESUMIDA: 'Machine resume',
      MAQUINAS_RESUMIDO_POR_LOCALIDAD: 'Machine resume',
      MAR: 'Tuesday',
      MARGEN: 'Margin',
      MAX_MEDICAMENTOS: 'Select up to three medications',
      MAXIMO_CARACTERES: 'maximum characters ',
      MEDICACION: 'Medication',
      MEDICAMENTO: 'Med.',
      MENSUAL: 'Monthly',
      MI_CUENTA: 'My account',
      MIE: 'Wednesday',
      MINIMO_NUMERO_1: 'Minimum value 1',
      MINUTE: 'Minutes',
      MINUTES: 'Minutes',
      ML: 'Win',
      MODIFICAR: 'Modify',
      MONEDAS: 'Currency',
      MONTO: 'Amount',
      MONTO_INVALIDO: 'Invalid amount',
      MONTO_MINIMO: 'Minimum amount',
      'MONTO_MINIMO_DE_APUESTA_ES_:_': 'The minimum bet amount is',
      MONTO_POR_DEFECTO_APUESTAS: 'Default bet amount',
      MOSTRAR_LOS_QUE_COINCIDAD_CON: 'Show those that match',
      MUNICIPIO: 'Municipality',
      MY_ACCOUNTS: 'My accounts',
      NAVIGATION_WARNING_MESSAGE: 'You will lose the bet in process',
      NEEDED_RACES_NOT_PROVIDED: 'Needed races not provided',
      NEEDED_RUNNERS_NOT_PROVIDED: 'Needed runners not provided',
      NO: 'No',
      NO_ASIGNADO: 'Not assigned',
      NO_COBRADO: 'No payed',
      NO_ES_POSSIBLE_ANADIR_MAS_PATAS: 'It is not possible to add more legs',
      NO_ES_POSSIBLE_ANADIR_PATAS_VACIAS: 'It is not possible to add empty legs',
      NO_EVENT_FOUND_WITH_ID: 'Event code not found',
      NO_EVENTS_SCHEDULED: 'No Events scheduled',
      NO_EXISTEN_TICKETS: 'There are no tickets',
      NO_EXISTEN_TRANSACCIONES_PARA_ESTA_CUENTA: 'No transaction found for this account',
      NO_HAY_CAMBIOS: 'No Changes, Print anyway',
      NO_HAY_EVENTOS_PROGRAMADOS: 'No events schedule',
      NO_PUEDE_AGREGAR_JUGADAS: 'Unable to add plays',
      NO_PUEDE_AGREGAR_MAS_JUGADAS_PARA_ESTA_LIGA: 'You cannot add more options to this league',
      NO_PUEDE_AGREGAR_MAS_JUGADAS_PARA_ESTE_TICKET: 'Ticket cannot add more plays for this',
      NO_PUEDE_SER_EJECUTADO: 'Cannot be executed',
      NO_RACES_FOUND: 'No races found',
      NO_RUNNERS_FOUND: 'No runners found',
      NO_SE_ENCONTRARON_APUESTAS: 'No wager found',
      NO_SE_ENCONTRO_CONEXION_PARA_ESTE_PROVEEDOR: 'No connection found for this provider',
      NO_TIENE_PRIVILEGIO_NECESARIO: 'No privilege necessary',
      NO_WAGERS_FOUND: 'No wager found',
      NOMBRE: 'Name',
      NOMBRE_ROLE: 'Name role',
      NOMBRES: 'Given name',
      NONE: 'None',
      NOT_PAYED: 'Not Payed',
      NOVENA: 'Ninth',
      NUEVA_CLAVE: 'New password',
      NUEVA_CONEXION: 'Create connection',
      NUEVA_ESTACION: 'New workstation',
      NUEVAS_CUENTAS: 'New accounts',
      NULO: 'Cancelled',
      NUM: 'The Number',
      NUMERO: 'Number',
      NUMERO_TICKET: 'Ticket',
      OCTAVA: 'Eighth',
      OCULTAR: 'Hide',
      ODDS: 'Odds',
      OFICIAL: 'Oficial',
      OK: 'Ok',
      OMN: 'Omni',
      OPCIONES_DE_CONSULTAS: 'Query options',
      OPCIONES_DE_TRANSACCIONES: 'Transaction options',
      OPERACIONES: 'Operations',
      OPERACIONES_AGENCIAS: 'Agencies operations',
      OPERACIONES_POR_CONSORCIOS: 'Enterprise operations',
      OPERACIONES_POR_LOCALIDADES: 'Location operations',
      OTHER: 'Other',
      OTRAS_OPCIONES: 'Others Options',
      OVER: 'Over',
      PAGADO: 'Paid out',
      PAGAR: 'Pay',
      PAGO: 'Payment',
      PAGO_DEPORTIVA: 'Sports Payment',
      PAGO_GALLOS: 'Payment Gallos',
      PAGO_LOTEKA_OP: 'Payment Loteka',
      PAGO_LOTERIA: 'Lottery Payment',
      PAID: 'Paid',
      PAID_DRAW: 'Paid draw',
      PAIS: 'Country',
      PALE: 'Pale',
      PARA_APOSTAR_UTILICE: 'To bet use',
      PARLAY: 'Parlay',
      PASSWORDS_MUST_BE_AT_LEAST_6_CHARACTERS: 'You must use a password of at least six (6) characters',
      'PASSWORDS_MUST_HAVE_AT_LEAST_ONE_DIGIT_(0-9)': 'The password must contain at least one number',
      'PASSWORDS_MUST_HAVE_AT_LEAST_ONE_LOWERCASE_(A-Z)': 'The password must contain at least one lowercase letter',
      PASSWORDS_MUST_HAVE_AT_LEAST_ONE_NON_ALPHANUMERIC_CHARACTER:
        'The password must contain at least one special symbol',
      'PASSWORDS_MUST_HAVE_AT_LEAST_ONE_UPPERCASE_(A-Z)': 'The password must contain at least capital letter',
      PAY: 'Pay',
      PAYDRAW: 'Pay draw',
      PAYOFF: 'Payoff',
      PAYTICKET: 'Paid',
      PELEAS_DE_GALLOS: 'Cockfights',
      PEN: 'Pentafecta',
      PENDIENTE: 'Pending',
      PENDING: 'Pending',
      PENDING_TRANSACTIONS: 'Pending transactions',
      PERDEDOR: 'Loser',
      PERDIDA_LOTEKA: 'Loteka Lose',
      PERDIDA_RULETA_MULTI: 'Loss Roulette Multiposition',
      PERFILES: 'Profiles',
      PERIODO: 'Period',
      PERIODOS: 'Periods',
      PERMISO_DE_USO_EXPIRADO: 'Expired Use Permit',
      PESO: 'Weight',
      PESOS_DOMINICANOS: 'Dominican Pesos',
      'PIN ': 'Pin',
      PIN: 'Pin',
      PK10: 'Pick10',
      PK11: 'Pick11',
      PK12: 'Pick12',
      PK3: 'Pick3',
      PK4: 'Pick4',
      PK5: 'Pick5',
      PK6: 'Pick6',
      PK7: 'Pick7',
      PK8: 'Pick8',
      PK9: 'Pick9',
      PLACEBET: 'Placebet',
      PLC: 'Place',
      POINT_OF_SALE: 'Point of sales',
      POOL_LIST: 'Pool list',
      POR_COBRAR: 'To Collect',
      POSIBLE_PAGO: 'Possible payment',
      POSICION: 'Position',
      POSICION_NO_EXISTENTE_O_DESCARTADA: 'Position not available',
      POSICION_YA_ASSIDO_ASIGNADA: 'Repeated runner',
      POSITION_NOT_AVAILABLE: 'It is not possible to add more legs',
      POSPUESTO: 'Postponed',
      'POST-SEASON': 'POST-SEASON',
      POSTPONE: 'POSTPONE',
      POWER_BOX: 'Power Box',
      'PRE-GAME': 'Pre-Game',
      'PRE-SEASON': 'PRE-SEASON',
      PRECIO: 'Price',
      PRECIO_EXCEDE_LIMITES_ESTABLECIDOS: 'Price exceeds established limits',
      PREFERENCIAS: 'Preferences',
      PREFERENCIAS_USUARIOS: 'User preferences',
      PREG: 'Questions',
      PREPAGADA: 'Prepaid',
      PRETICKET_NO_PUDO_SER_CREADO: 'PreTicket could not be created',
      PROCESO_EXITOSO: 'Successful process',
      PROCESO_FALLIDO: 'Failed process',
      PROCESO_FINALIZADO: 'Ended process',
      PRODUCT_DESCRIPTION: 'Account descriptión',
      PRODUCT_DISCLAIMER_TBD: 'Tickets expire in 90 days',
      PRODUCT_NAME: 'Product',
      PRODUCTO: 'Product',
      PRODUCTO_AUTENTICADO_DESCRIPCION:
        'User management service, registration, login, passwords and access security on their digital platforms',
      PRODUCTO_AUTENTICADO_LOGO: 'https://pbpublicimages.blob.core.windows.net/logos/autenticadovertical.svg',
      PRODUCTO_AUTENTICADO_NOMBRE: 'Autentica.DO',
      PRODUCTO_EN_PLACEBET: 'Our products',
      PRODUCTO_GALLO_DESCRIPCION: 'Live cockfights, provided by Gallero Soy',
      PRODUCTO_GALLO_LOGO: 'https://pbpublicimages.blob.core.windows.net/logos/gallerosoy-logo.svg',
      PRODUCTO_GALLO_NOMBRE: 'Roosters',
      PRODUCTO_HIPICA_DESCRIPCION: 'Live horse racing, provided by Hipodromo V Centenario',
      PRODUCTO_HIPICA_LOGO: 'https://pbpublicimages.blob.core.windows.net/logos/hipismo.svg',
      PRODUCTO_HIPICA_NOMBRE: 'Horse racing',
      PRODUCTO_PLACEBANK_DESCRIPCION:
        'Have control of all your payment and betting accounts; pay, withdraw, view your history',
      PRODUCTO_PLACEBANK_LOGO: 'https://pbankpublicimages.blob.core.windows.net/logos/placeBankLogoColorB.svg',
      PRODUCTO_PLACEBANK_NOMBRE: 'Place Bank',
      PRODUCTO_PLACEPIN_DESCRIPCION: 'Manage the generation of PIN to payment of services or bets',
      PRODUCTO_PLACEPIN_LOGO: 'https://pbankpublicimages.blob.core.windows.net/logos/placebankwallet.svg',
      PRODUCTO_PLACEPIN_NOMBRE: 'Place PIN',
      PRODUCTS: 'Products',
      PROFIT_PRODUCT: 'Profit product',
      PROFIT_PRODUCT_NEXT_PERIOD: 'Profit product next period',
      PROGRAMA_HIPICO: 'Race program',
      PROPIETARIO: 'Owner',
      PROPIETARIO_SUB_TITULO: 'Show owner information',
      PROPIETARIO_TITULO: 'Owner',
      PROPIETARIOS: 'Owners',
      PROVEEDOR: 'Provider',
      PROVEEDOR_SUB_TITULO: 'Show provider information',
      PROVEEDOR_TITULO: 'Provider',
      PROVEEDORES: 'Providers',
      PROVINCIA: 'Province',
      PROXIMOS: 'Upcoming',
      PROXIMOS_EVENTOS: 'UPCOMING EVENTS',
      PRUEBA_JUGADA_HIPICA_SUBTITULO: 'Prueba formato de digitación',
      PRUEBA_JUGADA_HIPICA_TITULO: 'Prueba digitación',
      PS: 'Place/Show',
      PUEDE_REGISTRARSE: 'You can register',
      PUNTO_DE_VENTA: 'POS',
      PUNTOS: 'Points',
      PRODUCTOS_DISPONIBLE_AGENCIA: 'Productos Disponibles de la agencia',
      PRODUCTO_REQUERIDO: 'Producto Requerido',
      PRODUCTO_O_CODIGO_REQUERIDO: 'Producto o Codigo Requerido',
      QU: 'Quinella',
      QUICK_WAGER: 'Quick',
      QUINCENAL: 'Quincenal',
      QUINTETA: 'Quintet',
      RACE_HAS_ALREADY_STARTED: 'Race started',
      'RAIN-DELAY': 'RAIN-DELAY',
      RAZA: 'Breed',
      RECENT_TRANSACTIONS: 'Recent transactions',
      RECIBOS: 'Received',
      REEMBOLSO_TICKET: 'Refund',
      REGISTRACION_CONFIRM_EMAIL_MESSAGE: 'Check your email to confirm it, and start using your account',
      REGISTRACION_CREAR_CUENTA_SUBTITULO: 'Create your placebet account',
      REGISTRACION_CREAR_CUENTA_TITULO: 'Create account',
      REGISTRACION_CUENTA_VERIFICADA: 'The email has been verified.',
      REGISTRACION_DE_ESTACION: 'Station registration',
      REGISTRACION_YA_TIENE_UNA_CUENTA: 'I already have an account',
      REGISTRACION_YO_ACEPTO: 'I accept',
      REGISTRATION_ERROR: 'Registration error',
      REGISTRO_JUGADAS: 'Registration plays',
      REGULAR: 'REGULAR',
      REMOVE_THIS_SEARCH_FROM_FAVOURITES: 'Remove this search from favourites',
      REMOVER_BUSQUEDA_DE_LA_HISTORIA: 'Remove search',
      REMOVER_DE_FAVORITOS: 'Remove from favorites',
      RENTABILIDAD: '%',
      REPORTE_RESUMEN_VENTAS: 'Sales summary',
      REQUERIDO: 'Required',
      REQUIERE_CODIGO_DE_VALIDACION: 'Requires validation code',
      RESTRICCION_DE_EDAD: 'Age restriction',
      RESTRICCION_DE_GENERO: 'Gender restriction',
      RESTRINGIDO_POR_LOCALIDAD: 'Restrict by location',
      RESULTADO: 'Result',
      RESULTADOS: 'Results',
      RESUMEN_DE_CUENTA: 'Account summary',
      RESUMEN_MAQUINAS: 'Machine summarized',
      RESUMEN_OPERACIONES_SUBTITULO: 'Summary of operations by date',
      RESUMEN_OPERACIONES_TITULO: 'Operations summary',
      RESUMEN_VENTAS_SUB_TITULO: 'Agency sales summary',
      RESUMEN_VENTAS_TITULO: 'Sales Summary',
      RETIRADO: 'Withdrawn',
      RETIRO: 'Withdraw',
      RETIRO_CUENTA: 'Withdrawal account',
      RETIRO_INTERNET: 'Internet Outcome',
      RETURNS: 'Returns',
      REVERSAR_DEPOSITO: 'Reverse Deposit',
      REVERSAR_GANADOR: 'Reverse Winner',
      REVERSE: 'Reverse',
      RIESGO: 'Risk',
      RISK: 'Risk',
      ROL: 'Roles',
      ROL_CLAIMS: 'Role claims',
      ROLE: 'Role',
      ROLE_ASIGNANDO_EXITOSAMENTE_AL_USUARIO: 'Successful role assignment',
      ROLE_CLAIM_AGREGADO: 'Permission Otorgado',
      ROLE_CLAIM_ELIMINADO: 'Permission removed',
      ROLES_DE_USUARIOS: 'User roles',
      ROLES_PERMISOS: 'Roles permissions',
      ROUND_ROBIN: 'Round Robin',
      RUN_LINE: 'Handicap',
      RUNNER: 'Runner',
      SAB: 'Saturday',
      SALES: 'Sales',
      SALES_RESUME_INCORRECT_BALANCE: 'Error consulting balance',
      SALES_RESUME_NOT_BALANCE_AVAILABLE: 'Balance not found',
      SALES_TOTAL: 'Total current sale',
      SalesSummary: 'Sales summary',
      SALIR: 'Exit',
      SAVED: 'Saved',
      SCATTER_CHART: 'Scatter chart',
      SCRATCHED: 'Scratched',
      SE_HAN_DETECTADO_CAMBIOS_EN_EL_FORMULARIO: 'Changes have been detected in the form',
      'SECOND-HALF': 'SECOND-HALF',
      SECTOR: 'Sector',
      SEGUNDA_MITAD_H2_NUL: 'segunda mitad H2 nul',
      SEGURIDAD: 'Security',
      SELECCIONAR_AGENCIA: 'Select agency',
      SELECCIONAR_ESTACION: 'Select station',
      SELECCIONAR_LIGA: 'Select league',
      SELECCIONAR_PRODUCTO: 'Select product',
      SELECCIONAR_PRODUCTOS: 'Select product',
      SEMANAL: 'Weekly',
      SEMESTRAL: 'Biannual',
      SEPTIMA: 'Seventh',
      SERIE: 'Serie',
      SERVICE_CHARGE: 'Service charge',
      SERVICIO_AGUA: 'Water Service',
      SERVICIO_BASURA: 'Garbage Service',
      SERVICIO_TELECABLE: 'TV Service',
      SEXO: 'Sex',
      SEXTETA: 'Sextet',
      SHW: 'Show',
      SI: 'Yes',
      SI_CONTINUA_PERDERA_LOS_CAMBIOS_EN_EL_FORMULARIO_DESEA_CONTINUAR:
        'If you continue you will lose the changes in the form you want to continue',
      SI_NO_POSEE_CUENTA:
        'If you already have an account, associate it in the “Manage accounts” option, otherwise go to “Create account”',
      SIGUIENTE: 'Next',
      SING_OUT: 'Sing out',
      SITUACION_AGENCIA: 'Agency situation',
      SMS: 'SMS',
      SOCCER: 'Soccer',
      SOLO_DIGITOS_PERMITIDOS_EN_MONTO: 'Only numbers are allowed',
      SOLO_VALORES_ENTRE: 'Only values between',
      SOPORTE_TECNICO: 'Technical support',
      SORTEOS_LOTERIA: 'Lottery draws',
      SPORTS: 'Sports',
      SPR: 'Superfecta',
      STRAIGHT: 'Straight',
      SUBDOMINIO: 'subdomain',
      SUBMITTICKET: 'Sold',
      SUPERVISOR: 'Supervisor',
      SUPLIDOR: 'Supplier',
      SUSPEND: 'SUSPEND',
      SYNC_DATE: 'Synchronization date',
      TABLE: 'Table',
      TABLERO: 'Dashboard',
      TABLERO_VENTAS_GANANCIAS: 'Sales and Profit',
      TARJETA_CREDITO: 'Credit card',
      TEAM_TOTAL: 'Team',
      TEASER: 'Teaser',
      'TEASER_2_TEAM_45/65': 'Teaser 2 Team 45/65',
      'TEASER_2_TEAMS_4/6': 'Teaser 2 Teams 4/6',
      TELEFONO: 'Phone',
      Telegram: 'Telegram',
      TENNIS: 'Tennis',
      TERMINADO: 'KO',
      TERMINAL_LOG_SUB_TITULO: 'Sales terminal activities',
      TERMINAL_LOG_TITULO: 'Terminal log',
      TERMINOS_DE_BUSQUEDA: 'Search terms',
      TERRITORIO: 'Field',
      TERRITORIO_AZUL: 'Blue Field',
      TERRITORIO_BLANCO: 'White Field',
      THE_EVENT_HAS_NOT_RACES: 'Bets closed for this race',
      THE_EVENT_IS_NOT_OPEN: 'Event is closed',
      THE_PASSWORD_AND_CONFIRMATION_DID_NOT_MATCH: 'The Password and the confirmation are not the same.',
      THE_RUNNER_DOES_NOT_EXISTS_IN_THE_RACES: 'Invalid runner',
      THE_RUNNER_HAS_BEEN_SCRATCHED: 'The runner has been scratched',
      THE_STATION_IS_NOT_REGISTERED_TO_THE_USER: 'Location is not registered',
      THE_WAGER_IS_NOT_SUPPORTED_ON_THIS_RACE: 'Bet not available for this race',
      THEME: 'Theme',
      TICKET: 'Ticket',
      TICKET_ANULAR: 'Ticket Cancel',
      TICKET_BLOQUEADO: 'Ticket blocked',
      TICKET_CANNOT_BE_PAID: 'Ticket cannot be paid',
      TICKET_CANNOT_BE_VOIDED: 'Ticket cannot be voided',
      TICKET_CON_JUEGO_INICIADO: 'Contains started game',
      TICKET_CONSULTAR_GANADORES: 'Check ticket winner',
      TICKET_CONSULTAR_VENDIDOS: 'Check ticket sold',
      TICKET_DE_FECHA_ANTERIOR_NO_PUEDE_SER_ANULADO: 'Ticket dated before can not be canceled',
      TICKET_EXPIRADO: 'Ticket expired',
      TICKET_GANADOR: 'Ticket Winner',
      TICKET_IMPRIMIR_GANADORES: 'Print winner',
      TICKET_IMPRIMIR_MAIL: 'Send Mail',
      TICKET_IMPRIMIR_PAPEL: 'Print paper',
      TICKET_IMPRIMIR_SMS: 'Send SMS',
      TICKET_IMPRIMIR_WHATSAPP: 'Send Whatsapp',
      TICKET_NO: 'Ticket#',
      TICKET_NO_PUDO_SER_CREADO: 'Ticket could not be created',
      TICKET_NOT_FOUND: 'Ticket not found',
      TICKET_NULO: 'Ticket Canceled',
      TICKET_PAGAR: 'Ticket Pay',
      TICKET_VENDIDO: 'Ticket Sold',
      TICKET_VENTA: 'Ticket sale',
      TICKETS: 'Tickets',
      TIEMPO_EXPIRACION: 'Expiration time',
      TIEMPO_EXTRA: 'Over Time',
      TIENE_JUEGOS_INCIADOS: 'It cannot be canceled, it has Started Games',
      TIPO: 'Type',
      TIPO_CARRERA: 'Race type',
      TIPO_CUENTA: 'Account type',
      TIPO_DE_CAJA: 'Workstation type',
      TIPO_DE_LOCALIDAD: 'Agency type',
      TIPO_DE_PERMISO: 'Type of permit',
      TIPO_ENTIDAD: 'Entity type',
      TIPO_PISTA: 'Track',
      TIPO_TERRENO: 'Ground',
      TIPO_TICKET: 'Ticket Type',
      TIPO_USUARIO: 'User type',
      TIZA: 'Tiza',
      TIZA_PICK_2: 'TIZA PICK 2',
      TIZA_PICK_3: 'TIZA PICK 3',
      TIZA_PICK_4: 'TIZA PICK 4',
      TIZA_PICK_5: 'TIZA PICK 5',
      TIZA_PICK_6: 'TIZA PICK 6',
      TO: 'to',
      TODOS_LOS_HIPODROMOS: 'General sale',
      TOTAL: 'Total',
      TOTAL_CUENTAS: 'Total accounts',
      TOTAL_OFICINAS: 'Total offices',
      TOTALES: 'Totals',
      TOTELINK_ERROR_1225: 'Race is not open',
      TOTELINK_ERROR_151: 'Races not started',
      TOTELINK_ERROR_153: 'Not a winner',
      TOTELINK_ERROR_156: 'Already paid',
      TOTELINK_ERROR_2221: 'Too many wagers',
      TOTELINK_ERROR_2234: 'Multirace pools may not be combined with other pools',
      TOTELINK_ERROR_2235: 'Pools with more than 3 legs may not be combined with other pools',
      TOTELINK_ERROR_INVALID_WAGER: 'Invalid wager',
      TOTELINK_ERROR_PAYOFF_EXCEEEDS_CURRENT_BALANCE: 'Insufficient funds',
      TOTELINK_FAILED_TICKET: 'Check amount and combination',
      TRACK: 'Distance',
      TRAGAMONEDA: 'Swallow Coin',
      TRANSACCION_AGENCIA: 'Agency',
      TRANSACCION_CANCELADA: 'Unsuccessful operation',
      TRANSACCION_ESTACION: 'Station',
      TRANSACCIONES: 'Transactions',
      TRANSACCIONES_CREAR: 'Transfers Create',
      TRANSACCIONES_CUENTA_CLIENTE: 'Accounts transctions',
      TRANSACCIONES_CUENTA_CLIENTE_DETALLE: 'Detail of customer transactions',
      TRANSACCIONES_CUENTAS: 'Account transactions',
      TRANSACCIONES_IMPRIMIR: 'Transfers print',
      TRANSACTIONS: 'Transactions',
      TRANSFERENCIAS: 'Transfers',
      TRANSFERENCIAS_CONSULTAR: 'Transfers Consult',
      TRANSFERENCIAS_IMPRIMIR: 'Transfers print',
      TRI: 'Trifecta',
      TRIMESTRAL: 'Trimestral',
      TRIPLETA: 'Triplet',
      TS: 'Tri-Super',
      TT: 'Twin-Trifecta',
      TYPE_SCORE: 'Type score',
      UBET: 'UBET',
      ULTIMA_ACTIVIDAD: 'Last activity',
      ULTIMO_TICKET: 'Last Ticket',
      UN_CIRCLED: 'UN_CIRCLED',
      UNABLE_TO_CREATE_WAGER_WITH_PLAYCODE: 'play not found',
      UNDECIMA: 'One tenth',
      UNDER: 'Under',
      UNKNOW: 'Unknow',
      UPCOMING: 'Upcoming events',
      URL_INVALIDO: 'Invalid URL',
      USER: 'User',
      USER_NOT_FOUND: 'User not found',
      USER_WORKSTATION_NOT_DEFINED: 'Workstation not defined',
      USERNAME: 'Username',
      USUARIO: 'User',
      USUARIO_INVALIDO: 'Invalid user',
      USUARIO_SUB_TITULO: 'System user',
      USUARIO_TITULO: 'User Management',
      USUARIOS: 'Users',
      USUARIOS_EMPLEADOS: 'User employee',
      UTILES_DE_OFICINA: 'Office Supplies',
      UTILES_LIMPIEZA: 'Cleaning Tools',
      VALOR: 'Value',
      VALOR_APOSTADO_IF_NO_PUEDE_SER_MAYOR_AL_APOSTADO_ARRIBA: 'Bet Value IF cannot be higher than Bet Above',
      VALOR_NO_VALIDO: 'Invalid data',
      VENCIDO: 'Expired',
      VENDEDOR: 'Seller',
      VENTA: 'Sales',
      VENTA_DEL_DIA: 'Sale of the day',
      VENTA_DEPORTIVA: 'Sales Sport',
      VENTA_GALLOS: 'Sale Roosters',
      VENTA_LOTERIA: 'Sales Lottery',
      VENTA_OFFLINE: 'Offline sale',
      VENTA_Y_ESTATUS: 'Sale and status',
      VER: 'View',
      VER_MAS: 'See more',
      VER_TICKETS_GANADORES: 'Tickets winners',
      VER_TICKETS_NULOS: 'Tickets canceled',
      VER_TICKETS_PAGADOS: 'Tickets paid',
      VER_TICKETS_VENDIDOS: 'Tickets sold',
      VERIFICACION_DE_TICKET_SUB_TITULO: 'Query for payment or cancellation purposes',
      VERIFICACION_DE_TICKET_TITULO: 'Ticket verification',
      VERSION: 'Version',
      VIATICOS: 'Viatic',
      VIE: 'Friday',
      VOID: 'Void',
      VOIDED: 'Voided',
      VOIDTICKET: 'Void',
      VOLVER_AL_INICIO: 'Home',
      VUELVA_A_INTENTAR: 'Try again',
      WALLET_PRODUCTS: 'Wallet products',
      WET: 'Win/Exacta/Trifecta',
      WhatsApp: 'WhatsApp',
      WHEEL: 'Wheel',
      WIN: 'Win',
      'WIN/PLC/SHW': 'Win/Place/Show',
      WIN_REVERSE: 'Win Reverse',
      WITHDRAW: 'Withdraw',
      WP: 'Win/Place',
      WS: 'Win/Show',
      ZONA_HORARIA: 'Time Zone',
      ZONE_INFO: 'Zone information',
    },
  },
}
