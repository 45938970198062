import { gql, useLazyQuery } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { Country, Metadata, Municipality, Province, ResultsList, SearchGraphql, Sector } from '../models'

const GET_COUNTRIES = gql`
  query GetCountries($where: CountryFilterInput, $skip: Int, $take: Int) {
    countries(where: $where, take: $take, skip: $skip, order: { name: DESC }) {
      items {
        code
        id
        name
      }
      totalCount
    }
  }
`

interface CountriesData {
  countries: {
    totalCount: number
    items: Country[]
  }
}

export const useCountries = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<CountriesData>(GET_COUNTRIES, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<Country, Metadata>>({
    results: [],
    metadata: {},
  })

  const getCountries = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({
        variables: {
          skip: skip ? skip : 0,
          take: take ? take : 100,
        },
      })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.countries.items || [],
      metadata: {
        Count: data?.countries.totalCount,
      },
    })
  }, [data?.countries, loading])

  return { loading, error, results, getCountries }
}

const GET_PROVINCES = gql`
  query GetProvinces($where: StateOrProvinceFilterInput, $skip: Int, $take: Int) {
    stateOrProvinces(where: $where, skip: $skip, take: $take, order: { name: ASC }) {
      items {
        id
        name
        countryId
        code
      }
      totalCount
    }
  }
`

interface ProvincesData {
  stateOrProvinces: {
    items: Province[]
    totalCount: number
  }
}

export const useProvinces = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<ProvincesData>(GET_PROVINCES, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<Province, Metadata>>({
    results: [],
    metadata: {},
  })

  const getProvinces = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({
        variables: {
          where: where,
          skip: skip ? skip : 0,
          take: take ? take : 100,
        },
      })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.stateOrProvinces.items || [],
      metadata: {
        Count: data?.stateOrProvinces.totalCount,
      },
    })
  }, [data?.stateOrProvinces, loading])

  return { loading, error, results, getProvinces }
}

const GET_MUNICIPALITIES = gql`
  query municipaly($where: CountyOrMunicipalityFilterInput, $skip: Int, $take: Int) {
    countyOrMunicipalities(where: $where, skip: $skip, take: $take, order: { name: ASC }) {
      items {
        id
        name
        stateOrProvinceId
      }
    }
  }
`

interface MunicipalitiesData {
  countyOrMunicipalities: {
    items: Municipality[]
    totalCount
  }
}

export const useMunicipalities = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<MunicipalitiesData>(GET_MUNICIPALITIES, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<Municipality, Metadata>>({
    results: [],
    metadata: {},
  })

  const getMunicipalities = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({
        variables: {
          where: where,
          skip: skip ? skip : 0,
          take: take ? take : 100,
        },
      })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.countyOrMunicipalities.items || [],
      metadata: {
        Count: data?.countyOrMunicipalities.totalCount,
      },
    })
  }, [data?.countyOrMunicipalities, loading])

  return { loading, error, results, getMunicipalities }
}

const GET_SECTORS = gql`
  query GetSectors($where: CityOrSectorFilterInput, $skip: Int, $take: Int) {
    cityOrSectors(where: $where, skip: $skip, take: $take, order: { name: ASC }) {
      items {
        id
        name
        countyOrMunicipalityId
      }
      totalCount
    }
  }
`

interface SectorsData {
  cityOrSectors: {
    items: Sector[]
    totalCount: number
  }
}

export const useSectors = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<SectorsData>(GET_SECTORS, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<Sector, Metadata>>({
    results: [],
    metadata: {},
  })

  const getSectors = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({
        variables: {
          where: where,
          skip: skip ? skip : 0,
          take: take ? take : 100,
        },
      })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.cityOrSectors.items || [],
      metadata: {
        Count: data?.cityOrSectors.totalCount,
      },
    })
  }, [data?.cityOrSectors, loading])

  return { loading, error, results, getSectors }
}
