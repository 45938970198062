import { Container, Box, Avatar, styled } from '@mui/material'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import { useAppTranslation } from 'src/admin/core/hooks'
import Text from 'src/components/Text'

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.main};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      box-shadow: ${theme.colors.shadows.success};
      margin-left: auto;
      margin-right: auto;

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`,
)

function ChangedPasswordSuccessAlert() {
  const { label } = useAppTranslation()

  return (
    <Box px={4} py={8}>
      <Container maxWidth="sm">
        <AvatarSuccess>
          <DoneAllIcon />
        </AvatarSuccess>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Text color="success">{label('CAMBIO_CLAVE_EXITOSO')}!</Text>
        </Box>
      </Container>
    </Box>
  )
}

export default ChangedPasswordSuccessAlert
