import { useState, useEffect } from 'react'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../../../core/models'
import { DefaultProgramRaceWagers } from '../../models'

const GET_DEFAULT_PROGRAM_RACE_WAGERS = gql`
  query GetProgramRaceDefaultWagers($where: ProgramRaceDefaultWagerFilterInput, $skip: Int, $take: Int) {
    programRaceDefaultWagers(where: $where, skip: $skip, take: $take) {
      items {
        id
        totalRaces
        raceNumber
        wagerIds
        possibleWagerIds
        wagers {
          id
          name
        }
        possibleWagers {
          id
          name
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface DefaultProgramRaceWagersData {
  programRaceDefaultWagers: {
    totalCount: number
    items: DefaultProgramRaceWagers[]
  }
}

export const useProgramDefaultWagers = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<DefaultProgramRaceWagersData>(
    GET_DEFAULT_PROGRAM_RACE_WAGERS,
    {
      fetchPolicy: 'network-only',
    },
  )

  const [results, setResults] = useState<ResultsList<DefaultProgramRaceWagers, Metadata>>({
    results: [],
    metadata: {},
  })

  useEffect(() => {
    if (data?.programRaceDefaultWagers) {
      setResults({
        results: data?.programRaceDefaultWagers.items || [],
        metadata: {
          Count: data?.programRaceDefaultWagers.totalCount,
        },
      })
    }
  }, [loading])

  const getProgramDefaultWagers = ({ where, take, skip }: any) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 5,
      },
    })
  }

  return { getProgramDefaultWagers, loading, error, results }
}
