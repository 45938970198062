import React from 'react'
import { DataGrid, GridCellParams, GridColDef, GridCallbackDetails, GridRowParams, GridRow } from '@mui/x-data-grid'
import { Button, Tooltip, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { formatAsCurrency } from 'src/admin/core/services/formatting'
import { ColumnAction, getActionsColumn } from './ActionsColumn'
import * as formatters from 'src/admin/core/services/formatting'
import clsx from 'clsx'
import {
  formatToShortLocalDateTime,
  formatToShortDateUS,
  formatToLocalTime,
  formatToShortLocalDate,
} from 'src/admin/core/services/dateFunctions'
import { PercentBar, PercentArrow } from 'src/admin/core/components/Percentage'
import { StatusChips } from 'src/admin/core/components/Chips/StatusChips'
import { isMobile } from 'src/admin/core/services'
import { useAppTranslation } from '../../hooks'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    columns: {
      '& .MuiDataGrid-columnsContainer': {
        lineHeight: '18px !important',
      },
      '& MuiDataGrid-colCellTitle': {
        whiteSpace: 'normal',
        wordBreak: 'breakWord',
      },
      '& .MuiDataGrid-colCellTitleContainer': {
        overflow: 'unset',
        whiteSpace: 'unset',
      },
      '& .MuiDataGrid-colCellTitle': {
        whiteSpace: 'unset',
      },
    },
    uppercase: {
      textTransform: 'uppercase',
    },
    columnCentered: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    columnRight: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    columnLeft: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    cellContentTruncated: {
      whiteSpace: 'nowrap',
      // overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
)

type DataGridComponent = {
  Pagination: () => React.ReactElement
}
export interface DataGridProps {
  page?: number
  pageSize?: number
  columns: DataGridColumns
  data: Array<any>
  count?: number
  onPageChange?: (page: number, details: GridCallbackDetails) => void
  onPageSizeChange?: (page: number, details: GridCallbackDetails) => void
  onRowClick?: (param: GridRowParams) => void
  disableColumnMenu?: boolean
  hideFooterPagination?: boolean
  components?: DataGridComponent
  loading?: boolean
  scroll?: boolean
  getRowId?: (row: any) => any
}

export interface DataGridColumns {
  [key: string]: GridColDef | DefaultColumDef
}

export type ColumnContentType =
  | 'id'
  | 'string'
  | 'smallString'
  | 'largeString'
  | 'localized'
  | 'currency'
  | 'currency_with_decimals'
  | 'currency_with_color'
  | 'number'
  | 'numberWithDecimal'
  | 'boolean'
  | 'date'
  | 'dateTime'
  | 'time'
  | 'percentageBar'
  | 'percentage'
  | 'percentageArrow'
  | 'actions'
  | 'object'
  | 'undefined'
  | 'custom'
  | 'button'
  | (() => React.ReactNode)

export interface DefaultColumDef {
  field: string
  tooltip?: string
  onAction?: (row: any, action: ColumnAction) => void
  contentType: ColumnContentType
  flex?: number
  width?: number
  renderCell?: (params: GridCellParams) => React.ReactElement
  headerAlign?: 'left' | 'right' | 'center'
  align?: 'left' | 'right' | 'center'
  noTranslateHeader?: boolean
}

export const CustomDataGrid = ({
  data,
  count,
  columns,
  pageSize,
  page,
  components,
  loading,
  scroll,
  getRowId,
  ...props
}: DataGridProps) => {
  const classes = useStyles()
  const { label } = useAppTranslation()

  const getCellContent = (content: string, tooltip?: string) => {
    return tooltip ? (
      <Tooltip title={tooltip} arrow>
        <span className={classes.cellContentTruncated}>{content}</span>
      </Tooltip>
    ) : (
      <React.Fragment>{content}</React.Fragment>
    )
  }

  const getFieldColumnDefinition = (
    field: string,
    headerName: string,
    contentType: ColumnContentType,
    tooltip?: string,
    renderCell?: (params: GridCellParams) => React.ReactElement,
    flex?: number,
    width?: number,
    onAction?: (row: any, action: ColumnAction) => void,
    headerAlign?: 'left' | 'right' | 'center',
    align?: 'left' | 'right' | 'center',
  ): GridColDef => {
    switch (contentType) {
      case 'id':
        return {
          field,
          headerName,
          headerAlign: headerAlign ? headerAlign : 'center',
          align: align ? align : 'right',
          flex: 0.5,
        }

      case 'string':
        return {
          width: width || 200,
          flex: flex || 1.25,
          field,
          headerName,
          headerAlign: 'left',
          cellClassName: clsx(classes.columnLeft),
          renderCell: (params: GridCellParams) => {
            const value = params.getValue(params.id, params.field)?.toString()
            return getCellContent(label(value || 'NONE'), tooltip)
          },
        }
      case 'smallString':
        return {
          width: width || 50,
          flex: flex || 0.5,
          field,
          headerName,
          headerAlign: 'left',
          cellClassName: clsx(classes.columnLeft),
          renderCell: (params: GridCellParams) => {
            const value = params.getValue(params.id, params.field)?.toString()
            return getCellContent(label(value || 'NONE'), tooltip)
          },
        }
      case 'largeString':
        return {
          // width: 400,
          flex: 1,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnLeft),
        }

      case 'localized':
        return {
          // width: 200,
          flex: 1,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnLeft),
          renderCell: (params: GridCellParams) => {
            const value = params.getValue(params.id, params.field)?.toString()
            return getCellContent(label(value || 'NONE'), tooltip)
          },
          align: 'left',
        }

      case 'date':
        return {
          // width: 200,
          flex: 0.5,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnCentered),
          renderCell: (params: GridCellParams) => {
            const value = params.getValue(params.id, params.field) as any
            return getCellContent(formatToShortLocalDate(value), tooltip)
          },
        }

      case 'dateTime':
        return {
          // width: 200,
          flex: 1,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnCentered),
          renderCell: (params: GridCellParams) => {
            const value = params.getValue(params.id, params.field) as any
            return getCellContent(formatToShortLocalDateTime(value), tooltip)
          },
        }

      case 'time':
        return {
          // width: 200,
          flex: flex || 1,
          field,
          headerName,
          headerAlign: 'center',
          align: align ? align : 'left',
          cellClassName: clsx(classes.columnCentered),
          renderCell: (params: GridCellParams) => {
            const value = params.getValue(params.id, params.field) as any
            return getCellContent(formatToLocalTime(value), tooltip)
          },
        }

      case 'number':
        return {
          // width: 125,
          flex: 0.75,
          field,
          headerName,
          headerAlign: 'center',
          align: align ? align : 'right',
          cellClassName: clsx(classes.columnRight),
          renderCell: (params: GridCellParams) => {
            const stringValue = params.getValue(params.id, params.field)?.toString()
            const value = Number.parseFloat(stringValue || '0')
            return getCellContent(formatters.formatAsNumber(value, 0), tooltip)
          },
        }

      case 'percentage':
        return {
          // width: 125,
          flex: 0.5,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnRight),
          renderCell: (params: GridCellParams) => {
            const stringValue = params.getValue(params.id, params.field)?.toString()
            const value = Number.parseFloat(stringValue || '0')
            return getCellContent(formatters.formatAsPercentage(value, 0), tooltip)
          },
        }
      case 'percentageArrow':
        return {
          // width: 100,
          flex: 1,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnRight),
          renderCell: (params: GridCellParams) => {
            const stringValue = params.getValue(params.id, params.field)?.toString()
            const number = Number.parseFloat(stringValue || '0')
            return <PercentArrow value={number} />
          },
        }
      case 'percentageBar':
        return {
          // width: 125,
          flex: 1,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnCentered),
          renderCell: (params: GridCellParams) => {
            const stringValue = params.getValue(params.id, params.field)?.toString()
            const number = Number.parseFloat(stringValue || '0')
            return <PercentBar value={number} />
          },
        }
      case 'currency':
        return {
          flex: flex || 1,
          width: width || 100,
          field,
          headerName,
          headerAlign: 'right',
          cellClassName: clsx(classes.columnRight),
          renderCell: (params: GridCellParams) => {
            const stringValue = params.getValue(params.id, params.field)?.toString()
            const value = Number.parseFloat(stringValue || '0')
            return getCellContent(formatAsCurrency(value, 0), tooltip)
          },
          align: 'right',
        }
      case 'currency_with_decimals':
        return {
          flex: flex || 1,
          width: width || 100,
          field,
          headerName,
          headerAlign: 'right',
          cellClassName: clsx(classes.columnRight),
          renderCell: (params: GridCellParams) => {
            const stringValue = params.getValue(params.id, params.field)?.toString()
            const value = Number.parseFloat(stringValue || '0')
            return getCellContent(formatAsCurrency(value, 2), tooltip)
          },
          align: 'right',
        }
      case 'currency_with_color':
        return {
          flex: flex || 1,
          width: width || 100,
          field,
          headerName,
          headerAlign: 'right',
          cellClassName: clsx(classes.columnRight),
          renderCell: (params: GridCellParams) => {
            const stringValue = params.getValue(params.id, params.field)?.toString()
            const value = Number.parseFloat(stringValue || '0')
            return (
              <Typography color={value < 0 ? 'red' : ''}>
                {getCellContent(formatAsCurrency(value, 0), tooltip)}
              </Typography>
            )
          },
          align: 'right',
        }
      case 'boolean':
        return {
          // width: 125,
          flex: 1,
          field,
          headerName,
          headerAlign: headerAlign ? headerAlign : 'center',
          align: align ? align : 'center',
          cellClassName: clsx(classes.columnCentered),
          renderCell: (params: GridCellParams) => {
            const value = params.getValue(params.id, params.field) as boolean
            return <StatusChips status={value} />
          },
        }

      case 'custom':
        return {
          // width: 125,
          flex: 1,
          field,
          headerName,
          headerAlign: headerAlign || 'center',
          align: align || 'center',
          cellClassName: clsx(classes.columnCentered),
          // defaultMuiPrevented: true,  Stop event propagation
          renderCell:
            renderCell !== undefined
              ? renderCell
              : (params: GridCellParams) => {
                  const value = params.getValue(params.id, params.field) as boolean
                  return <StatusChips status={value} />
                },
        }

      case 'button':
        return {
          // width: 125,
          flex: 1,
          field,
          headerName,
          headerAlign: 'center',
          cellClassName: clsx(classes.columnCentered),
          // disableClickEventBubbling: true,  Stop event propagation
          renderCell:
            renderCell !== undefined
              ? renderCell
              : (params: GridCellParams) => {
                  const value = params.getValue(params.id, params.field) as boolean
                  return onAction ? (
                    <Button size="small" variant="contained" color="primary" onClick={() => onAction(params, 'Edit')}>
                      VER
                    </Button>
                  ) : (
                    <StatusChips status={value} />
                  )
                },
        }

      case 'actions':
        return getActionsColumn(headerName)

      default:
        return {
          width: 200,
          field,
          headerName,
        }
    }
  }

  const getCustomGridColumns = (columns: DataGridColumns): GridColDef[] => {
    return Object.keys(columns).map((key: string) => {
      const column = columns[key]
      const isDefaultGridColDef = (column as DefaultColumDef).contentType !== undefined
      if (isDefaultGridColDef) {
        const {
          field,
          contentType,
          tooltip,
          renderCell,
          flex,
          width,
          onAction,
          headerAlign,
          align,
          noTranslateHeader,
        } = column as DefaultColumDef
        return getFieldColumnDefinition(
          field,
          noTranslateHeader ? key : label(key.toUpperCase()),
          contentType,
          tooltip,
          renderCell,
          flex,
          width,
          onAction,
          headerAlign,
          align,
        )
      } else {
        return column as GridColDef
      }
    })
  }

  return (
    <DataGrid
      className={clsx({
        [classes.columns]: !isMobile(),
      })}
      autoHeight={data.length <= 100}
      rows={data}
      rowHeight={38}
      rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
      columns={getCustomGridColumns(columns)}
      pageSize={pageSize}
      paginationMode="server"
      page={page}
      rowCount={count}
      components={components}
      loading={loading}
      {...props}
    />
  )
}
