import { gql, useMutation } from '@apollo/client'

const PASSWORD_RESET = gql`
  mutation ResetPassword($input: PasswordResetInput!) {
    passwordReset(input: $input) {
      success
      errors
    }
  }
`

export const usePasswordReset = () => {
  const [passwordReset, { loading, error }] = useMutation(PASSWORD_RESET)
  return { passwordReset, loading, error }
}
