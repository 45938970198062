import * as React from 'react'
import { styled } from '@mui/material/styles'
import { CustomDataGrid, DataGridProps } from './CustomDataGrid'

const StyledDataGrid = styled(CustomDataGrid)(({ theme }) => ({
  border: 0,
  color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  },
  '& .MuiDataGrid-cell': {
    color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.primary.contrastText,
    boxShadow: '1px 2px 15px 5px #9497942e',
  },
  '& .MuiDataGrid-main': {
    overflow: 'auto',
  },
  '& .MuiDataGrid-columnHeader--moving': {
    backgroundColor: 'unset',
  },
}))

interface AdminDataGridProps extends DataGridProps {}

export const AdminDataGrid = ({ data, columns, pageSize, page, components, ...props }: AdminDataGridProps) => {
  return (
    <StyledDataGrid data={data} columns={columns} pageSize={pageSize} page={page} components={components} {...props} />
  )
}
