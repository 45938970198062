import { useEffect, useRef, useState } from 'react'
import useAuth from 'src/hooks/useAuth'
import { NavLink } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'

import { Avatar, Box, Button, Divider, List, ListItem, ListItemText, Popover, Typography, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone'
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone'
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone'
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone'
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone'
import { AuthConsumer } from 'pb-shared'
import { useAppTranslation } from 'src/admin/core/hooks'
import { AuthService } from 'src/admin/core/auth/authService'
import { Profile } from 'oidc-client'

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(0)};
        color: ${theme.colors.alpha.trueWhite[70]};

        &:hover {
          color: ${theme.colors.alpha.trueWhite[100]};
        }
`,
)

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`,
)

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`,
)

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`,
)

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.secondary.light}
`,
)

function HeaderUserbox({ profile }: { profile: Profile }) {
  const { label } = useAppTranslation()

  const location = useLocation()
  const navigate = useNavigate()
  const { logout } = useAuth()

  const ref = useRef<any>(null)
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose()
      await logout()
      navigate('/')
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Box
      component="span"
      sx={{
        display: { xs: 'none', sm: 'inline-block' },
      }}
    >
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        {/* <Avatar alt={user.name} src={user.avatar} /> */}
        <ExpandMoreTwoToneIcon
          fontSize="small"
          sx={{
            ml: 0.5,
          }}
        />
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* <MenuUserBox
          sx={{
            minWidth: 210,
          }}
          display="flex"
        >
          <Avatar variant="rounded" alt={user.name} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">{user.jobtitle}</UserBoxDescription>
          </UserBoxText>
        </MenuUserBox> */}
        <Divider
          sx={{
            mb: 0,
          }}
        />
        <List
          sx={{
            p: 1,
          }}
          component="nav"
        >
          {profile && <ListItem>{profile.given_name}</ListItem>}

          <ListItem
            button
            onClick={() => {
              handleClose()
            }}
            to={`/${location.pathname.split('/')[1]}/management/users/single/1`}
            component={NavLink}
          >
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary={label('Profile')} />
          </ListItem>
          {/* <ListItem
            button
            onClick={() => {
              handleClose()
            }}
            to={`/${location.pathname.split('/')[1]}/applications/mailbox/inbox`}
            component={NavLink}
          >
            <InboxTwoToneIcon fontSize="small" />
            <ListItemText primary={label('Inbox')} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              handleClose()
            }}
            to={`/${location.pathname.split('/')[1]}/applications/projects-board`}
            component={NavLink}
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary={label('Projects')} />
          </ListItem> */}
        </List>
        <Divider />

        <AuthConsumer>
          {({ logout }) => (
            <Box m={1}>
              <Button color="primary" fullWidth onClick={logout}>
                <LockOpenTwoToneIcon
                  sx={{
                    mr: 1,
                  }}
                />
                {label('SALIR')}
              </Button>
            </Box>
          )}
        </AuthConsumer>
      </Popover>
    </Box>
  )
}

export default HeaderUserbox
