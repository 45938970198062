import { useCallback, useContext } from 'react'
import { GridCellParams, GridRowParams } from '@mui/x-data-grid'

// App Components
import { DataGridColumns } from 'src/admin/core/components/DataGrid/CustomDataGrid'
import { AdminDataGrid } from 'src/admin/core/components/DataGrid/AdminDataGrid'
import { ContentDataGrid } from 'src/admin/core/components/DataGrid'
import { Runner } from '../models'
import { EventContext, setRunner, setRunnerFormMode } from '../state'
import { StatusChips } from 'src/admin/core/components/Chips'

const Runners = ({ runners }: { runners: Runner[] }) => {
  const { dispatch } = useContext(EventContext)

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      DE_CARRERA_NO: { field: 'number', contentType: 'number', align: 'left' },
      // DESCRIPCION_PENDIENTE: { field: 'description', contentType: 'string' },
      // DESCARTADO: { field: 'active', contentType: 'boolean' },
      DESCRIPCION: {
        field: 'description',
        contentType: 'custom',
        align: 'left',
        headerAlign: 'left',
        renderCell: (params: GridCellParams) => {
          const runner = params.row as Runner
          return (
            <span>{`${runner.odds} ${runner.competitor ? runner.competitor.name : ''} / ${
              runner.jockeyOrKennel ? runner.jockeyOrKennel.name : ''
            }`}</span>
          )
        },
      },
      DESCARTADO: {
        field: '',
        contentType: 'custom',
        renderCell: (params: any) => {
          const { row: runner } = params
          return <StatusChips status={!runner.active} label={runner.active ? 'SI' : 'NO'} />
        },
      },
    }
  }, [])

  const selectToEdit = (params: GridRowParams) => {
    dispatch(setRunnerFormMode('EDIT'))
    dispatch(setRunner(params.row as Runner))
  }

  return (
    <>
      <ContentDataGrid height={300}>
        <AdminDataGrid
          data={runners}
          columns={getColumnDefinitions()}
          onRowClick={selectToEdit}
          hideFooterPagination={true}
        />
      </ContentDataGrid>
    </>
  )
}

export default Runners
