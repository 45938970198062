import { useCallback, useState } from 'react'
import { SearchRequest } from '../models'

export const usePagination = () => {
  const [paging, setPaging] = useState<SearchRequest>({
    page: 0,
    pageSize: 10,
  })

  const handlePageChange = useCallback(
    (page: number) => {
      console.log(page)
      setPaging({
        ...paging,
        page,
      })
    },
    [paging],
  )

  const handlePageSizeChange = useCallback(
    (pageSize: number) => {
      setPaging({
        ...paging,
        pageSize,
      })
    },
    [paging],
  )

  return {
    ...paging,
    skip: paging.page * paging.pageSize,
    handlePageChange,
    handlePageSizeChange,
  }
}
