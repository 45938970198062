import { ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Card } from '@mui/material'

type ContentDataGridProps = {
  children: ReactNode
  height?: number
  width?: string
  styles?: object
  pageSize?: number
}

const Content = styled(
  'div',
  {},
)<{ height?: number; width?: string }>(({ theme, height, width }) => ({
  display: 'flex',
  height: height ? height : 500,
  width: width ? width : '100%',
}))

export const ContentDataGrid = ({ height, width, pageSize, children }: ContentDataGridProps) => {
  let customHeight = height
  if (pageSize) {
    switch (pageSize) {
      case 15:
        customHeight = 700
        break
      case 25:
        customHeight = 800
        break
      case 50:
        customHeight = 1600
        break
      case 100:
        customHeight = 3200
        break
      default:
        customHeight = 500
    }
  }

  return (
    <Card>
      <Box flex={1} p={2} display={{ xs: 'block', md: 'flex' }} alignItems="center" justifyContent="space-between">
        <Content height={customHeight} width={width}>
          {children}
        </Content>
      </Box>
    </Card>
  )
}
