import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useAppTranslation } from 'src/admin/core/hooks'
import { IconButton, Tooltip, Typography } from '@mui/material'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { IssuedTickets } from '../models'
import ContactsIcon from '@mui/icons-material/Contacts'
import PaymentsIcon from '@mui/icons-material/Payments'
import Text from 'src/components/Text'
import { formatAsCurrency } from 'src/admin/core'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'

type CanceledPaidAlertProps = {
  open: boolean
  handleClose: () => void
  code: string
  ticket: IssuedTickets
}

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export const CanceledPaidAlert = ({ open, code, ticket, handleClose }: CanceledPaidAlertProps) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { label } = useAppTranslation()
  const [copyCode, setCopyCode] = React.useState(false)
  let claimed = null
  let net = null

  if (ticket.product === 'COCKFIGHTS') {
    claimed =
      ticket.cockfightsWagerCollections && ticket.cockfightsWagerCollections.length > 0
        ? ticket.cockfightsWagerCollections[0].wagerPayoff.claimed
        : null

    net =
      ticket.cockfightsWagerCollections && ticket.cockfightsWagerCollections.length > 0
        ? ticket.cockfightsWagerCollections[0].wagerPayoff.payoff?.net
        : 0
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-canceled-paid"
      aria-describedby="alert-dialog-description-canceled-paid"
    >
      <BootstrapDialogTitle id="alert-dialog-title-canceled-paid" onClose={handleClose}>
        {label('DATOS_DEL_TICKET')}
      </BootstrapDialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {claimed === true && (
            <Typography sx={{ padding: 0.5 }}>
              <Text color="warning">
                <ContactsIcon fontSize="small" sx={{ marginBottom: -0.2 }} /> {label('TICKET_EN_RECLAMACION')}
              </Text>
            </Typography>
          )}
          {ticket.status === 'ISSUED' && net > 0 && (
            <Typography sx={{ padding: 0.5 }}>
              <Text color="success">
                <PaymentsIcon fontSize="small" sx={{ marginBottom: -0.2 }} /> {label('TICKET_ES_GANADOR_A_PAGAR')}{' '}
                <strong>{formatAsCurrency(net)}</strong>
              </Text>
            </Typography>
          )}
          <Typography sx={{ padding: 0.5 }}>
            <Text color="secondary">
              <PaymentsIcon fontSize="small" sx={{ marginBottom: -0.2 }} /> {label('EL_TICKET_FUE')}{' '}
              <strong>{label(ticket.status)}</strong>
            </Text>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button>{code}</Button>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => setCopyCode(false)}
          open={copyCode}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={label('COPIADO') as string}
        >
          <Button color="primary" variant="outlined" size="small">
            <CopyToClipboard
              text={code}
              onCopy={() => {
                setCopyCode(true)
                setTimeout(() => {
                  handleClose()
                }, 500)
              }}
            >
              {copyCode ? <DoneAllIcon color="success" /> : <FileCopyIcon color={'primary'} />}
            </CopyToClipboard>
          </Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  )
}
