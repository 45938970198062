import { Field } from 'formik'
import { useEffect } from 'react'
import { CustomAutocomplete, generateFilterGraphql } from 'src/admin/core'
import { useAppTranslation } from 'src/admin/core/hooks'
import { ProgramOwner } from '../../models'
import { useProgramTrainers } from '../../hooks'

type Props = {
  defaultValue?: ProgramOwner
  disabled?: boolean
  ownerId?: number
  handleChange?: (value: ProgramOwner) => void
}

export const TrainerSelect = ({ defaultValue, disabled, ownerId, handleChange }: Props) => {
  const { results, getProgramTrainers, loading } = useProgramTrainers()

  const { label } = useAppTranslation()

  useEffect(() => {
    fetchProgramTrainers()
  }, [])

  const fetchProgramTrainers = () => {
    const customFilters = {
      and: [
        {
          name: { ...generateFilterGraphql('', 'contains') },
        },
      ],
    }

    getProgramTrainers({ where: customFilters, skip: 0, take: 500 })
  }

  const handleChangeValue = (value: ProgramOwner) => {
    if (handleChange) {
      handleChange(value)
    }
  }

  return (
    <CustomAutocomplete
      keyValue={'id'}
      keyLabel={'name'}
      data={results.results}
      isLoading={loading}
      label={label('ENTRENADOR')}
      onChangeValue={handleChangeValue}
      disabled={disabled}
    />
  )
}
