import { gql, useMutation } from '@apollo/client'

const UPDATE_PROGRAM_TRAINER = gql`
  mutation UpdateProgramTrainer($input: UpsertProgramTrainerInput!) {
    upsertProgramTrainer(input: $input) {
      id
      name
      active
    }
  }
`

export interface UpdateProgramTrainerInput {
  id: number
  name: string
  active: boolean
}

export const useUpdateProgramTrainer = () => {
  const [updateProgramTrainer, { loading, error }] = useMutation<UpdateProgramTrainerInput>(UPDATE_PROGRAM_TRAINER)
  return { updateProgramTrainer, loading, error }
}
