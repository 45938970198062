import { FiltersCard } from 'src/admin/components/Cards/FiltersCard'
import { Grid, TextField } from '@mui/material'
import { useAppTranslation } from 'src/admin/core/hooks'
import { useEffect, useState } from 'react'
import { SearchField } from 'src/admin/core'
import DatePicker from '@mui/lab/DatePicker'

export type FiltersProps = {
  search?: string
  date: Date
}

export const initialFilters: FiltersProps = {
  search: '',
  date: new Date(),
}

const Filters: React.FC<{ onChange: (filters: FiltersProps) => void }> = ({ onChange }) => {
  const { label } = useAppTranslation()
  const [filters, setFilters] = useState<FiltersProps>(initialFilters)

  useEffect(() => {
    onChange(filters)
  }, [filters])

  return (
    <FiltersCard>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <SearchField
            onChange={(query: string) => setFilters({ ...filters, search: query })}
            placeholder={label('MOSTRAR_LOS_QUE_COINCIDAD_CON')}
            width={'100%'}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            value={filters.date}
            onChange={(newValue) =>
              setFilters({
                ...filters,
                date: newValue,
              })
            }
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>
      </Grid>
    </FiltersCard>
  )
}

export default Filters
