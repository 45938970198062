import { SaveButton } from 'src/admin/core'
import { useFormikContext } from 'formik'
import { Workstation, WorkstationTypeEnum } from '../models'
import { useUpdateWorkstation } from '../hooks'

export const UpdateStation: React.FC<{
  locationId: number
  afterSave?: (promise: Promise<any>, action: string) => void
}> = ({ locationId, afterSave }) => {
  const { updateWorkstation, loading, error } = useUpdateWorkstation()
  const form = useFormikContext()
  const { values, dirty, isValid, setSubmitting } = form
  const station = { ...(values as Workstation) }

  const handleUpdate = () => {
    setSubmitting(true)

    const response = updateWorkstation({
      variables: {
        id: station.id,
        input: {
          name: station.name,
          workstationType: station.workstationType,
          locationId: locationId,
          active: station.active,
        },
      },
    })
    if (afterSave) {
      afterSave(response, 'update')
    }
    setSubmitting(false)
  }

  return <SaveButton onClick={handleUpdate} isLoading={loading} disabled={!(dirty && isValid)} />
}
