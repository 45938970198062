import { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Grid, styled } from '@mui/material'
import { useReactToPrint } from 'react-to-print'

// App Components
import { DataGridColumns } from 'src/admin/core/components/DataGrid/CustomDataGrid'
import { AdminDataGrid } from 'src/admin/core/components/DataGrid/AdminDataGrid'
import { Page } from 'src/admin/core/components'
import { generateFilterGraphql } from 'src/admin/core'
import { ContentDataGrid } from 'src/admin/core/components/DataGrid'
import { PrintButton } from 'src/admin/core/components'

// Hooks

import { useAppTranslation, usePagination, useUserClaimActions, useUserHasRole } from 'src/admin/core/hooks'
import { Filters, FilterModel, initialFilters } from './components/Filters'
import { ClaimActions } from 'src/admin/core/models'
import { useAgencyTicketTransactionsSummary } from '../weeklyBalance/hooks'
import { Products } from 'src/core/models'
import { AgencyWeeklySales } from '../weeklyBalance/models/AgencyWeeklySales'
import _ from 'lodash'
import { PrintReport } from './components/PrintReport'

const pageStyle = `
  @page { size: legal landscape; }

`

const PrintWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
}))

export type ConsolidatedData = {
  id: number
  sales: number
  cancelled: number
  payments: number
  surcharge: number
  deposits: number
  withdrawals: number
  draw: number
  payDraw: number
  agencyName: string
  agencyWithCode: string
  amount: number
  commission: number
}

const DailyBalance = () => {
  const { label } = useAppTranslation()
  const [currentFilters, setCurrentFilters] = useState<FilterModel>(initialFilters)
  const { page, pageSize } = usePagination()
  const { validateClaimActions } = useUserClaimActions()
  const { hasRole } = useUserHasRole()
  const actions = validateClaimActions('consults.daily-balances', [ClaimActions.CanView, ClaimActions.CanPrint])
  const { results, drawAndPayDraw, loading, getAgencyTicketTransactionsSummary } = useAgencyTicketTransactionsSummary()
  const [consolidatedData, setConsolidatedData] = useState<ConsolidatedData[]>([])
  const [startPrinting, setStartPrinting] = useState(false)
  const componentRef = useRef<any>(null)
  const [selectedDate, setSelectedDate] = useState('')

  useEffect(() => {
    const customFilters = getCustomFilters(currentFilters)

    if (customFilters) {
      getAgencyTicketTransactionsSummary(customFilters)
    }
  }, [currentFilters, page, pageSize])

  useEffect(() => {
    const data = results.results

    if (data.length > 0) {
      const agencyGroup = groupByAgencyName(data)

      const consolidated = []

      Object.keys(agencyGroup).forEach((key, index) => {
        const agency = agencyGroup[key]

        try {
          const calculatedData = calculateAmounts(agency)
          const calculatedDraws = calculateDraws(drawAndPayDraw)
          const agencyObj = agency[0]

          const products = agencyObj.key.agency.products as Products[]
          consolidated.push({
            id: index,
            agencyName: key,
            agencyWithCode: `${products.length > 0 ? products[0].code : ''} ${key}`,
            sales: calculatedData.sales,
            cancelled: calculatedData.cancelled,
            payments: calculatedData.payments,
            surcharge: calculatedData.totalSurcharge,
            amount: calculatedData.totalAmount,
            ...calculatedDraws,
          })
        } catch (e) {
          setConsolidatedData([])
        }
      })

      setConsolidatedData(consolidated)
    } else {
      setConsolidatedData([])
    }
  }, [results.results, drawAndPayDraw])

  const getCustomFilters = (filters: FilterModel) => {
    setSelectedDate(filters.startDate)
    if (filters.product !== '') {
      if (filters.locationId) {
        return {
          product: filters.product,
          currency: filters.currencyId,
          from: `${filters.startDate}T00:00:00Z`,
          to: `${filters.endDate}T23:59:59Z`,
          where: {
            key: {
              id: { ...generateFilterGraphql(filters.locationId, 'eq') },
            },
          },
        }
      } else {
        const agencyFilters = []
        filters.agencies.forEach((agency) => {
          agencyFilters.push({
            key: {
              id: { ...generateFilterGraphql(agency.id, 'eq') },
            },
          })
        })

        return {
          product: filters.product,
          currency: filters.currencyId,
          from: `${filters.startDate}T00:00:00Z`,
          to: `${filters.endDate}T23:59:59Z`,
          where: [],
        }
      }
    }
    return null
  }

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      AGENCIAS: { field: 'agencyWithCode', contentType: 'string' },
      VENTAS: { field: 'sales', contentType: 'currency', headerAlign: 'right', align: 'right' },
      CANCELACIONES: { field: 'cancelled', contentType: 'currency', headerAlign: 'right', align: 'right' },
      PAGOS: { field: 'payments', contentType: 'currency', headerAlign: 'right', align: 'right' },
      SOBRECARGOS: { field: 'surcharge', contentType: 'currency', headerAlign: 'right', align: 'right' },
      DEPOSITOS: { field: 'deposits', contentType: 'currency', headerAlign: 'right', align: 'right' },
      RETIROS: { field: 'withdrawals', contentType: 'currency', headerAlign: 'right', align: 'right' },
      BALANCE: { field: 'amount', contentType: 'currency', headerAlign: 'right', align: 'right' },
      DRAW: { field: 'draw', contentType: 'currency', headerAlign: 'right', align: 'right' },
      PAYDRAW: { field: 'payDraw', contentType: 'currency', headerAlign: 'right', align: 'right' },
    }
  }, [])

  const handlePrint = useCallback(() => {
    setStartPrinting(true)
    setTimeout(() => {
      printReport()
    }, 1000)
  }, [])

  const printReport = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
    onAfterPrint: () => {
      window.focus()
    },
    onBeforePrint: () => {
      setStartPrinting(false)
    },
  })

  return (
    <Page
      hasAccess={actions.canView}
      tabTitle={label('BALANCE_DIARIO_TITULO')}
      pageTitle={label('BALANCE_DIARIO_TITULO')}
      actions={actions.canPrint ? <PrintButton onClick={handlePrint} disabled={consolidatedData.length === 0} /> : null}
    >
      <Grid item xs={12}>
        <Filters
          onChange={(filters: FilterModel) => setCurrentFilters(filters)}
          disabledDownloadFile={hasRole('Ventas')}
        />
      </Grid>
      <Grid item xs={12}>
        <ContentDataGrid pageSize={pageSize}>
          <AdminDataGrid
            columns={getColumnDefinitions()}
            data={consolidatedData}
            loading={loading}
            hideFooterPagination={true}
          />
        </ContentDataGrid>
        {startPrinting && (
          <PrintWrapper ref={componentRef}>
            <PrintReport data={consolidatedData} date={selectedDate} />
          </PrintWrapper>
        )}
      </Grid>
    </Page>
  )
}

const groupByAgencyName = (data: any) => {
  const groupedByAgencyName = data.reduce((result, item) => {
    const agencyName = item.key.agency.name
    if (!result[agencyName]) {
      result[agencyName] = []
    }
    result[agencyName].push(item)
    return result
  }, {})

  return groupedByAgencyName
}

const calculateAmounts = (data: AgencyWeeklySales[]) => {
  let sales = 0
  let cancelled = 0
  let payments = 0
  let totalSurcharge = 0

  let totalAmount = 0
  data.forEach((item) => {
    const {
      amount,
      surcharge,
      key: { transactionType },
    } = item
    if (transactionType === 'SALE') {
      totalSurcharge = totalSurcharge + surcharge
    } else if (transactionType === 'CANCELLATION') {
      totalSurcharge = totalSurcharge - surcharge
    }

    sales = sales += transactionType === 'SALE' ? amount : 0
    cancelled = cancelled += transactionType === 'CANCELLATION' ? amount : 0
    payments = payments += transactionType === 'PAYMENT' ? amount : 0
  })

  totalAmount = sales - cancelled - payments + totalSurcharge
  return {
    sales,
    cancelled,
    payments,
    totalAmount,
    totalSurcharge,
  }
}

const calculateDraws = (data: AgencyWeeklySales[]) => {
  let draw = 0
  let payDraw = 0

  data.forEach((item) => {
    const {
      amount,
      key: { transactionType },
    } = item
    if (transactionType === 'PAY_DRAW') {
      payDraw = payDraw + amount
    } else if (transactionType === 'DRAW') {
      draw = draw + amount
    }
  })

  return {
    draw,
    payDraw,
  }
}

export default DailyBalance
