import { gql, useLazyQuery } from '@apollo/client'
import { SearchGraphql, PageInfo, ResultsList, Metadata } from '../../../../../core/models'
import { useEffect, useState } from 'react'
import { IssuedTickets } from '../models'

const GET_TICKETS = gql`
  query IssuedTickets($where: TicketFilterInput, $skip: Int, $take: Int) {
    issuedTickets(where: $where, take: $take, skip: $skip) {
      items {
        id
        date
        number
        providerTicketNumbers
        workstation {
          id
          name
          agency {
            id
            products {
              code
            }
            name
          }
        }
        horseRacesWagerCollections {
          eventId
        }
        cockfightsWagerCollections {
          eventId
          wagerPayoff {
            claimed
            payoff {
              net
            }
          }
        }
        totalAmount
        status
        product
      }
      totalCount
    }
  }
`

interface UsersData {
  issuedTickets: {
    totalCount: number
    items: IssuedTickets[]
    pageInfo: PageInfo
  }
}

export const useIssuedTickets = () => {
  const [getResults, { loading, error, data }] = useLazyQuery(GET_TICKETS, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<IssuedTickets, Metadata>>({
    results: [],
    metadata: {},
  })

  useEffect(() => {
    if (data?.issuedTickets) {
      setResults({
        results: data?.issuedTickets.items || [],
        metadata: {
          Count: data?.issuedTickets.totalCount,
        },
      })
    }
  }, [loading])

  const getIssuedTickets = ({ where, take, skip }: SearchGraphql, order?: any) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 100,
      },
    })
  }

  const issuedTickets = results.results.map((ticket) => {
    const clonedTicket = { ...ticket }
    
    clonedTicket.agencyName = `${
      ticket.workstation.agency.products && ticket.workstation.agency.products.length > 0
        ? ticket.workstation.agency.products[0].code
        : ''
    } ${ticket.workstation.agency.name}`
    clonedTicket.raceTrack =
      ticket.horseRacesWagerCollections && ticket.horseRacesWagerCollections.length > 0
        ? ticket.horseRacesWagerCollections[0].eventId
        : ''
        clonedTicket.workstationName = ticket.workstation.name
    return clonedTicket
  })

  return { loading, error, count: results.metadata.Count, issuedTickets, getIssuedTickets }
}
