import { createReducer } from 'typesafe-actions'

import {
  Actions,
  globalSearchAction,
  clearAction,
  setUserEnterprise,
  setEnterprises,
  setUserClaims,
  setIsAdmin,
  setUser,
  setAllowedModules,
} from './actions'
import { Enterprise } from 'src/core/models'
import { Dictionary } from '../../models'
import { User } from 'oidc-client'

export interface AppBarState {
  searchQuery?: string
  userEnterprise?: Enterprise
  enterprises: Array<Enterprise>
  userClaims: Dictionary
  user: User
  allowedModules: string[]
  isAdmin: boolean
  claims: Dictionary
}

export interface AppState {
  appBar: AppBarState
}

export const appBarReducer = createReducer<AppBarState, Actions>({
  searchQuery: '',
  userEnterprise: undefined,
  enterprises: [],
  userClaims: {},
  user: undefined,
  allowedModules: [],
  isAdmin: false,
  claims: {},
})
  .handleAction(globalSearchAction, (state, action) => {
    return {
      ...state,
      searchQuery: action.payload,
    }
  })
  .handleAction(clearAction, (state) => {
    return {
      ...state,
      searchQuery: '',
    }
  })
  .handleAction(setUserEnterprise, (state, action) => {
    return {
      ...state,
      userEnterprise: action.payload,
    }
  })
  .handleAction(setEnterprises, (state, action) => {
    return {
      ...state,
      enterprises: action.payload,
    }
  })
  .handleAction(setUserClaims, (state, action) => {
    return {
      ...state,
      userClaims: action.payload,
    }
  })
  .handleAction(setIsAdmin, (state, action) => {
    return {
      ...state,
      isAdmin: action.payload,
    }
  })
  .handleAction(setUser, (state, action) => {
    return {
      ...state,
      user: action.payload,
    }
  })
  .handleAction(setAllowedModules, (state, action) => {
    return {
      ...state,
      allowedModules: action.payload,
    }
  })
