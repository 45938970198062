import { FiltersCard } from 'src/admin/components/Cards/FiltersCard'
import { Grid, TextField } from '@mui/material'
import { useAppTranslation } from 'src/admin/core/hooks'
import { useEffect, useState } from 'react'
import { SearchField } from 'src/admin/core'
import { TrainerSelect } from '../../../trainers/components/TrainerSelect'
import { ProgramTrainer } from '../../../models'

export type FiltersProps = {
  name?: string
  trainerId: string | undefined
}

export const initialFilters: FiltersProps = {
  name: '',
  trainerId: undefined,
}

const Filters: React.FC<{ onChange: (filters: FiltersProps) => void }> = ({ onChange }) => {
  const { label } = useAppTranslation()
  const [filters, setFilters] = useState<FiltersProps>(initialFilters)

  useEffect(() => {
    onChange(filters)
  }, [filters])

  return (
    <FiltersCard>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <SearchField
            onChange={(query: string) => setFilters({ ...filters, name: query })}
            placeholder={label('MOSTRAR_LOS_QUE_COINCIDAD_CON')}
            width={'100%'}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <TrainerSelect
            handleChange={(value: ProgramTrainer) =>
              setFilters({ ...filters, trainerId: value ? value.id : undefined })
            }
          />
        </Grid>
      </Grid>
    </FiltersCard>
  )
}

export default Filters
