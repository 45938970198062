import { useState, useEffect } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from 'src/admin/core/models'
import { ProgramSelectOption } from './ProgramSelectOption'

const GET_PROGRAM_SELECT_OPTIONS = gql`
  query ProgramSelectOptions($where: ProgramSelectOptionFilterInput!, $skip: Int, $take: Int) {
    programSelectOptions(where: $where, skip: $skip, take: $take, order: { name: ASC }) {
      items {
        name
        code
        entity
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface ProgramSelectOptionsData {
  programSelectOptions: {
    totalCount: number
    items: ProgramSelectOption[]
  }
}

export const useProgramSelectOptions = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<ProgramSelectOptionsData>(GET_PROGRAM_SELECT_OPTIONS, {
    fetchPolicy: 'network-only',
  })
  const [results, setResults] = useState<ResultsList<ProgramSelectOption, Metadata>>({
    results: [],
    metadata: {},
  })

  const getProgramSelectOptions = ({ where, take, skip }: SearchGraphql) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 100,
      },
    })
  }

  // if (data && data?.programSelectOptions.items.length > 0) {
  //   data?.programSelectOptions.items.map((item, index) => {
  //     const newItem = {
  //       id: index,
  //       name: item.name,
  //       code: item.code,
  //       entity: item.entity,
  //     }
  //     return newItem
  //   })
  // }

  useEffect(() => {
    if (data?.programSelectOptions) {
      data?.programSelectOptions.items.map((item, index) => {
        const newItem = {
          id: index,
          name: item.name,
          code: item.code,
          entity: item.entity,
        }
        return newItem
      })

      setResults({
        results: data?.programSelectOptions.items || [],
        metadata: {
          Count: data?.programSelectOptions.totalCount,
        },
      })
    }
  }, [loading])

  // const results = {
  //   results: data?.programSelectOptions.items,
  //   metadata: {
  //     Count: data?.programSelectOptions.totalCount,
  //   },
  // }

  return { getProgramSelectOptions, loading, error, results }
}
