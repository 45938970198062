import { gql, useMutation } from '@apollo/client'

const UPDATE_PROGRAM_RACE = gql`
  mutation UpdateProgramRace($input: UpsertProgramRaceInput!) {
    upsertProgramRace(input: $input) {
      id
      programEventId
      raceNumber
      typeOfRace
      purse
      distanceId
      runners
      genderRestriction
      ageRestriction
      weight
      wagerIds
      breed
      course
      postTime
      createdDate
      createdBy
      active
    }
  }
`

export interface UpdateProgramRaceInput {
  id: number
  programEventId: number
  raceNumber: number
  typeOfRace: string
  purse: string
  distanceId: number
  wagerIds: string
  runners: number
  genderRestriction: string
  ageRestriction: string
  weight: number
  breed: string
  course: string
  postTime: string
  active: boolean
}

export const useUpdateProgramRace = () => {
  const [updateProgramRace, { loading, error }] = useMutation<UpdateProgramRaceInput>(UPDATE_PROGRAM_RACE)
  return { updateProgramRace, loading, error }
}
