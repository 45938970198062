import { FormPage, generateFilterGraphql } from 'src/admin/core'
import { useAppTranslation, useNotification } from 'src/admin/core/hooks'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useRaceFormHandler, useRaces, useRemoveRace } from '../../hooks'
import { Form } from './Form'
import { Stack, Box } from '@mui/material'
import Races from '../Races'
import { EventContext, setRace, setRaces } from '../../state'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import { Race as RaceModel } from '../../models'
import Runner from '../../runners/runner'

const Race: React.FC = () => {
  const { label } = useAppTranslation()
  const { successNotification, errorNotification } = useNotification()
  const { formSchema, initialValues } = useRaceFormHandler()
  const { results: racesInfo, getRaces } = useRaces()
  const { removeRace, error } = useRemoveRace()
  const [raceToRemove, setRaceToRemove] = useState<RaceModel | undefined>(undefined)

  const {
    dispatch,
    state: { event, race },
  } = useContext(EventContext)

  useEffect(() => {
    if (racesInfo) {
      // set  races in the store
      dispatch(setRaces(racesInfo.results))
    }
  }, [racesInfo.results])

  useEffect(() => {
    if (event.id) {
      fetchRacesByEventId(event.id)
    }
  }, [event])

  const afterSave = useCallback(
    (response: Promise<any>) => {
      response
        .then(() => {
          successNotification(label(initialValues.id ? 'DATO_ACTUALIZADO' : 'DATO_REGISTRADO'))
          dispatch(setRace(null))
          fetchRacesByEventId(event.id)
        })
        .catch((e) => {
          errorNotification(label(e.message))
        })
    },
    [event],
  )

  const fetchRacesByEventId = (eventId: string) => {
    const customFilters = {
      and: [{ programEventId: { ...generateFilterGraphql(eventId, 'eq') } }],
    }
    getRaces({
      where: customFilters,
      skip: 0,
      take: 100,
      order: {
        raceNumber: 'ASC',
      },
    })
  }

  return (
    <>
      <Box
        sx={{
          padding: '21.5px',
          paddingRight: '38px',
          paddingBottom: 0,
        }}
      >
        <FormPage
          form={{
            formSchema: formSchema,
            formValues: initialValues,
          }}
        >
          <Stack spacing={2}>
            <Form afterSave={afterSave} />
            <Races afterRemove={() => fetchRacesByEventId(event.id)} />
          </Stack>
        </FormPage>
      </Box>
      {race && (
        <Box
          sx={{
            padding: '21.5px',
            paddingRight: '38px',
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          <Runner race={race} />
        </Box>
      )}
    </>
  )
}

export default Race
