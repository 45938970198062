import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../../../core/models'
import { ProgramMedication } from '../../models'
import { useEffect, useState } from 'react'

const GET_PROGRAM_MEDICATIONS = gql`
  query ProgramMedications {
    programMedications {
      items {
        code
        id
        name
        active
      }
      totalCount
    }
  }
`

interface Data {
  programMedications: {
    items: ProgramMedication[]
    totalCount: number
  }
}

export const useProgramMedications = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<Data>(GET_PROGRAM_MEDICATIONS, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<ProgramMedication, Metadata>>({
    results: [],
    metadata: {},
  })

  useEffect(() => {
    if (data?.programMedications) {
      setResults({
        results: data?.programMedications.items || [],
        metadata: {
          Count: data?.programMedications.totalCount,
        },
      })
    }
  }, [loading])

  const getProgramMedications = ({ where, take, skip }: SearchGraphql) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 100,
      },
    })
  }

  return { getProgramMedications, loading, error, results }
}
