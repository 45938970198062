import { Field } from 'formik'
import { useEffect, useState } from 'react'
import { generateFilterGraphql } from 'src/admin/core'
import { CustomAutoCompleteSelectFormik } from 'src/admin/core/components/Select/CustomAutoCompleteSelectFormik'
import { useAppTranslation } from 'src/admin/core/hooks'
import { useProgramTrainers } from '../../hooks'
import { ProgramTrainer } from '../../models'

type Props = {
  defaultValue?: ProgramTrainer
  disabled?: boolean
  handleChange?: (value: ProgramTrainer) => void
  handleSelectOpen?: () => void
  trainerId?: string
}

export const TrainerSelectFormik = ({ trainerId, defaultValue, disabled, handleChange, handleSelectOpen }: Props) => {
  const { results: trainers, getProgramTrainers, loading } = useProgramTrainers()
  const { label } = useAppTranslation()
  const [defaultTrainer, setDefaultTrainer] = useState<ProgramTrainer | undefined>(defaultValue)

  useEffect(() => {
    if (trainerId) {
      const object = trainers.results.filter((item) => item.id == trainerId)
      if (object.length > 0) {
        setDefaultTrainer(object[0])
      }
    }
  }, [trainerId, trainers])

  useEffect(() => {
    getProgramTrainers({
      where: {
        and: [
          {
            name: { ...generateFilterGraphql('', 'contains') },
            active: { ...generateFilterGraphql(true, 'eq') },
          },
        ],
      },
      skip: 0,
      take: 500,
    })
  }, [])

  return (
    <Field
      defaultValue={defaultTrainer}
      component={CustomAutoCompleteSelectFormik}
      keyValue={'id'}
      keyLabel={'name'}
      data={trainers.results}
      isLoading={loading}
      label={label('PROPIETARIO')}
      name="trainerId"
      onChangeValue={handleChange}
      disabled={disabled}
      onSelectOpen={handleSelectOpen}
    />
  )
}
