import { SaveButton } from 'src/admin/core'
import { useFormikContext } from 'formik'
import { FormEnterprise, useUpsertEnterprise } from '../hooks'
import { Enterprise } from 'src/core/models'
import { useAppTranslation, useNotification } from 'src/admin/core/hooks'
import { useEffect } from 'react'

export const UpdateEnterprise: React.FC<{ afterSave?: (promise: Promise<any>, action?: string) => void }> = ({
  afterSave,
}) => {
  const { upsertEnterprise, loading, error } = useUpsertEnterprise()
  const { errorNotification } = useNotification()
  const { label } = useAppTranslation()
  const form = useFormikContext()
  const { values, dirty, isValid, setSubmitting } = form

  useEffect(() => {
    if (error) {
      errorNotification(label(error.message))
    }
  }, [error])

  const handleSave = () => {
    setSubmitting(true)
    const data = values as FormEnterprise
    const response = upsertEnterprise({
      variables: {
        input: {
          id: data.id,
          name: data.name,
          domain: data.domain,
          subdomain: data.subdomain,
          active: data.active,
          products: data.products,
          addresses: [
            {
              countryId: data.country?.id,
              cityOrSectorId: data.country?.id,
              address: '',
              stateOrProvinceId: data.country?.id,
              main: true,
              zip: 0,
            },
          ],
        },
      },
    })
    if (afterSave) {
      afterSave(response)
    }
    setSubmitting(false)
  }

  return <SaveButton onClick={handleSave} isLoading={loading} disabled={!(dirty && isValid)} />
}
