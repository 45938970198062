import { FormPage, PageTab, PageHeader, FormContent, BackButton } from 'src/admin/core'
import { useAppTranslation, useNotification, useUserClaimActions } from 'src/admin/core/hooks'
import { Form } from './Form'
import { useNavigate } from 'react-router-dom'
import { CreateButton } from './CreateButton'
import { useCallback } from 'react'
import { useFormHandler } from '../../hooks'
import { Loader } from 'src/admin/components/Loader'
import { UpdateButton } from './UpdateButton'
import { Stack } from '@mui/material'
import { SecurityPage } from 'src/admin/core/components/Page/SecurityPage'
import { ClaimActions } from 'src/admin/core/models'

const Competitor: React.FC = () => {
  const { label } = useAppTranslation()
  const navigate = useNavigate()
  const { successNotification, errorNotification } = useNotification()
  const { formSchema, initialValues, loading } = useFormHandler()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.competitors', [ClaimActions.CanView])

  const afterSave = useCallback((response: Promise<any>, action) => {
    response
      .then((res) => {
        setTimeout(() => {
          navigate(`/admin/entities/events-competitors`)
        }, 1000)
      })
      .catch((e) => {
        errorNotification(label(e.message))
      })
  }, [])

  return (
    <Loader loading={loading}>
      <SecurityPage hasAccess={actions.canView}>
        <FormPage
          form={{
            formSchema: formSchema,
            formValues: initialValues,
          }}
        >
          <PageTab text={label('COMPETIDOR_TITULO')} />
          <PageHeader
            title={label('COMPETIDOR_SUB_TITULO')}
            subTitle={label('COMPETIDOR_SUB_TITULO')}
            actions={
              <Stack spacing={1} direction={'row'}>
                <BackButton />
                {initialValues && initialValues.id ? (
                  <UpdateButton afterSave={afterSave} competitor={initialValues} />
                ) : (
                  <CreateButton afterSave={afterSave} />
                )}
              </Stack>
            }
          />
          <FormContent>
            <Form competitor={initialValues} />
          </FormContent>
        </FormPage>
      </SecurityPage>
    </Loader>
  )
}

export default Competitor
