import React, { useCallback, useEffect, useMemo } from 'react'
import { TextField } from 'formik-material-ui'
import { Autocomplete, AutocompleteChangeReason, CircularProgress } from '@mui/material'
import _ from 'lodash'
import { IFormField } from 'src/admin/core/models'
import { Wager } from '../../models'

export interface AutoCompleteSelectFormikProps extends IFormField {
  keyValue: string
  data: Wager[]
  isLoading?: boolean
  label?: string
  defaultValue?: any
  onChangeValue?: (value: any, action: string, item: any) => void
  onInputValueChange?: (value: string) => void
  disabled?: boolean
  multiple?: boolean
  props?: any
}

export const WagerAutoCompleteSelectFormik = ({
  keyValue,
  data,
  isLoading,
  label,
  onChangeValue,
  onInputValueChange,
  defaultValue,
  disabled,
  multiple,
  ...props
}: AutoCompleteSelectFormikProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props
  const [open, setOpen] = React.useState(false)

  const onSelect = useCallback(
    (event: React.ChangeEvent<any>, value: string[], reason: AutocompleteChangeReason) => {
      setFieldValue(name, value.join('/'))

      if (onChangeValue instanceof Function) {
        onChangeValue(value, reason, value[value.length - 1])
      }
    },
    [setFieldValue, name],
  )

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // console.log(event)
      // const value = event.target.value
      // setInputValue(value)
      // setFieldValue(name, value)
      // if (onInputValueChange instanceof Function) {
      //   onInputValueChange(value)
      // }
    },
    [setFieldValue, name],
  )

  const getArrayOfValues = (value: string | string[]): string[] | [] => {
    if (Array.isArray(value)) {
      return value
    } else if (value && value.length > 0) {
      const cleanData = value.replace(/\s/g, '').split('/')
      // Remove duplicated
      const newData = cleanData.filter((item: string, index: number) => {
        return cleanData.indexOf(item) == index
      })
      return newData
    } else {
      return []
    }
  }

  return (
    <Autocomplete
      {...props}
      disabled={disabled}
      defaultValue={defaultValue}
      value={values[name] !== '' ? getArrayOfValues(values[name]) : []}
      onChange={onSelect}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={data}
      loading={isLoading}
      multiple={multiple}
      disableCloseOnSelect
      filterSelectedOptions={true}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          label={label}
          onChange={onInputChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget
          if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
            console.log('More data')
          }
        },
      }}
    />
  )
}
