import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSectors } from '../../hooks/geography'
import { CustomAutocomplete, generateFilterGraphql } from '../..'
import { IFormField, Sector } from '../../models'
import { Autocomplete, AutocompleteChangeReason, TextField } from '@mui/material'

export interface SectorSelectProps extends IFormField {
  label?: string
  municipalityId: string
  disabled?: boolean
  defaultValue?: string
}

export const SectorSelect = ({ municipalityId, disabled, defaultValue, ...props }: SectorSelectProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props

  const [selected, setSelected] = useState<any>(null)
  const { getSectors, results, loading } = useSectors()
  const { t: transLabel } = useTranslation()
  const sectors = results.results

  useEffect(() => {
    if (sectors.length > 0 && defaultValue && !selected) {
      const filter = sectors.filter((item: any) => item.id === defaultValue)
      if (filter.length > 0) {
        setFieldValue(name, filter[0])
        setSelected(filter[0])
      }
    }
  }, [defaultValue, sectors])

  useEffect(() => {
    if (municipalityId !== '') {
      filterSectors(municipalityId)
    }
  }, [municipalityId])

  const filterSectors = (value: string, skip = 0, take = 100) => {
    const customFilters = {
      and: [{ countyOrMunicipalityId: { ...generateFilterGraphql(value, 'eq') } }],
    }

    getSectors({ where: customFilters, skip, take })
  }

  const handleChange = useCallback(
    (event: React.ChangeEvent<any>, value: any, reason: AutocompleteChangeReason) => {
      setFieldValue(name, value)
      setSelected(value)
    },
    [setFieldValue, name],
  )

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    // setInputValue(value)
  }

  return (
    <Autocomplete
      sx={{
        m: 0,
        minWidth: 'inherit',
      }}
      onChange={handleChange}
      value={selected}
      getOptionLabel={(option) => (option['name'] ? option['name'] : '')}
      options={sectors}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={onInputChange}
          label={transLabel('SECTOR')}
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          autoComplete={'off'}
        />
      )}
      disabled={disabled}
      {...props}
    />
  )
}
