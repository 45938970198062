import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { useAppTranslation } from '../../hooks'
import { IFormField } from '../../models'

export interface SelectProps extends IFormField {
  labelTitle?: string
  data: string[]
  defaultValue?: string
}
export const StringFormikSelect = ({ labelTitle, data, defaultValue, ...props }: SelectProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props
  const [value, setValue] = React.useState(defaultValue)
  const { label } = useAppTranslation()

  useEffect(() => {
    setValue(defaultValue)
    setFieldValue(name, defaultValue)
  }, [defaultValue])

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const value = event.target.value as string
      setFieldValue(name, value)
      setValue(value)
    },
    [setFieldValue, name],
  )

  return (
    <Select
      fullWidth
      labelId={labelTitle}
      id="StringFormikSelect"
      value={value}
      label={labelTitle}
      onChange={handleChange}
    >
      {data.map((d) => (
        <MenuItem key={d} value={d}>
          {label(d)}
        </MenuItem>
      ))}
    </Select>
  )
}
