import * as React from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useAppTranslation } from '../../hooks'

export const TypeSelect = ({ onChange, disabled }: { disabled?: boolean; onChange?: (value: string) => void }) => {
  const [type, setType] = React.useState('STANDARD')
  const { label } = useAppTranslation()

  const handleChange = (event: SelectChangeEvent) => {
    const type = event.target.value as string
    setType(type)
    if (onChange) {
      onChange(type)
    }
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="type-simple-select-label">{label('TIPO')}</InputLabel>
      <Select
        disabled={disabled}
        fullWidth
        labelId="type-simple-select-label"
        id="type-simple-select"
        value={type}
        label="type"
        onChange={handleChange}
      >
        <MenuItem value={'STANDARD'}>{label('STANDARD')}</MenuItem>
      </Select>
    </FormControl>
  )
}
