import { useCallback, useEffect, useState } from 'react'
import { FormPage, PageTab, PageHeader, FormContent, BackButton } from 'src/admin/core'
import { useAppTranslation, useNotification, useUserClaimActions } from 'src/admin/core/hooks'
import { StationForm } from './StationForm'
import { useNavigate, useParams } from 'react-router-dom'
import { Loader } from 'src/admin/components/Loader'
import { Grid, Stack, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import _ from 'lodash'
import { useFormHandler } from '../hooks/useFormHandler'
import { CreateStation } from './CreateStation'
import { UpdateStation } from './UpdateStation'
import { StationCodeGenerator } from '../components/StationCodeGenerator'
import RefreshIcon from '@mui/icons-material/Refresh'
import { green } from '@mui/material/colors'
import { ClaimActions } from 'src/admin/core/models'
import { SecurityPage } from 'src/admin/core/components/Page/SecurityPage'

const GreenButton = styled(Button)({
  backgroundColor: green[500],
  '&:hover': {
    backgroundColor: green[700],
  },
})

const Station: React.FC = () => {
  const { label, system } = useAppTranslation()
  const navigate = useNavigate()
  const { successNotification, errorNotification } = useNotification()
  const { formSchema, initialValues, loading } = useFormHandler()
  const { agencyId } = useParams()
  const [isEditing, setIsEditing] = useState(false)
  const [openGenerator, setOpenGenerator] = useState(false)
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.workstations', [ClaimActions.CanView])

  useEffect(() => {
    setIsEditing(initialValues.id !== undefined)
  }, [initialValues])

  const afterSave = useCallback(
    (response: Promise<any>, action: string) => {
      response
        .then((res) => {
          if (action === 'create') {
            const id = _.get(res, 'data.addWorkstation.workstation.id', 0)
            if (id > 0) {
              successNotification(system('DATO_REGISTRADO'))
              navigate(`/admin/entities/agencies/${agencyId}`)
            }
          } else {
            successNotification(system('DATO_ACTUALIZADO'))
          }
        })
        .catch((e) => {
          errorNotification(system('ERROR_REGISTRANDO_EL_DATO'))
        })
    },
    [agencyId],
  )

  return (
    <Loader loading={loading}>
      <SecurityPage hasAccess={actions.canView}>
        <FormPage
          form={{
            formSchema: formSchema,
            formValues: initialValues,
          }}
        >
          <PageTab text={label('ADMINISTRACION_DE_CAJAS')} />
          <PageHeader
            title={label('ADMINISTRACION_DE_CAJAS')}
            subTitle={label('ADMINISTRACION_DE_CAJAS_O_PUNTOS_DE_VENTA')}
            actions={
              <Stack direction="row" spacing={2}>
                {isEditing && (
                  <GreenButton
                    color="secondary"
                    variant="contained"
                    onClick={() => setOpenGenerator(true)}
                    startIcon={<RefreshIcon />}
                  >
                    {label('GENERAR_CODIGO')}
                  </GreenButton>
                )}
                {isEditing ? (
                  <UpdateStation afterSave={afterSave} locationId={Number.parseInt(agencyId)} />
                ) : (
                  <CreateStation afterSave={afterSave} locationId={Number.parseInt(agencyId)} />
                )}
                <BackButton />
              </Stack>
            }
          />
          <FormContent>
            <StationForm
              disableFields={!isEditing && !!initialValues}
              station={initialValues}
              locationId={Number.parseInt(agencyId)}
            />

            {isEditing && (
              <StationCodeGenerator
                isOpen={openGenerator}
                onClose={() => setOpenGenerator(false)}
                workstationId={initialValues.id}
              />
            )}
          </FormContent>
        </FormPage>
      </SecurityPage>
    </Loader>
  )
}

export default Station
