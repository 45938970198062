import { ReactElement, ReactNode, useEffect, useMemo, useReducer } from 'react'
import { EventContext, reducer, setEvent } from '.'
import { Event } from '../models'
import { t } from 'i18next'

export const EventProvider = ({ event, children }: { event: Event; children: ReactElement | ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, {
    event,
    races: [],
    runners: [],
    typeOfRaces: [],
    eventFormMode: 'VIEW',
    runnerFormMode: 'CANCEL',
    raceFormMode: 'CANCEL',
  })

  useEffect(() => {
    dispatch(setEvent(event))
  }, [event])

  const contextValue = useMemo(() => {
    return { state, dispatch }
  }, [state, dispatch])

  return <EventContext.Provider value={contextValue}>{children}</EventContext.Provider>
}
