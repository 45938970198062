import { gql, useMutation } from '@apollo/client'
import { Profile } from 'oidc-client'
import { Enterprise } from 'src/admin/scenes/entities/enterprises/models'

const CREATE_USER = gql`
  mutation CreateUser($request: CreateEnterpriseUserRequestInput!) {
    createUser(request: $request) {
      id
      userName
      passwordResetCode
      passwordResetCodeExpiration
    }
  }
`
export interface CreateUserInput {
  profile: Profile
  enterprises: Enterprise[]
  roles: String[]
}

export const useCreateUser = () => {
  const [createUser, { loading, error }] = useMutation<CreateUserInput>(CREATE_USER)
  return { createUser, loading, error }
}
