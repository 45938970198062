import { ModifyButton as Button } from 'src/admin/core'
import { useContext } from 'react'
import { EventContext, setEventFormMode } from '../../state'

export const ModifyButton: React.FC = () => {
  const { dispatch } = useContext(EventContext)

  const handleModify = () => {
    dispatch(setEventFormMode('EDIT'))
  }

  return <Button onClick={handleModify} />
}
