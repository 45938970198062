import React from 'react'
import { LoadingAnimation } from 'src/admin/components/Loader'
import { AuthConsumer } from '../AuthProvider'

const Callback = () => (
  <AuthConsumer>
    {({ signinRedirectCallback }) => {
      signinRedirectCallback()
      return <LoadingAnimation />
    }}
  </AuthConsumer>
)

export default Callback
