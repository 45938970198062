import { getAppBarState } from 'src/store'

export const useUserHasRole = () => {
  const hasRole = (role: string): boolean => {
    const { userClaims } = getAppBarState().appState

    return userClaims && userClaims.role && (userClaims.role === role || userClaims.role.includes(role))
  }

  return { hasRole }
}
