import { gql, useMutation } from '@apollo/client'

const ADD_PROGRAM_EVENT = gql`
  mutation CreateProgramEvent($input: UpsertProgramEventInput!) {
    upsertProgramEvent(input: $input) {
      id
    }
  }
`
export interface AddProgramEventInput {
  name: string
  date: Date
  performanceCodes: string
  isActive: boolean
}

export const useAddProgramEvent = () => {
  const [addProgramEvent, { loading, error }] = useMutation<AddProgramEventInput>(ADD_PROGRAM_EVENT)
  return { addProgramEvent, loading, error }
}
