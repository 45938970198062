import { useEffect, useState } from 'react'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../../../core/models'
import { Runner } from '../../models'

const GET_RUNNERS = gql`
  query GetProgramRunners($where: ProgramRunnerFilterInput, $skip: Int, $take: Int, $order: [ProgramRunnerSortInput!]) {
    programRunners(where: $where, skip: $skip, take: $take, order: $order) {
      items {
        id
        programRaceId
        competitorId
        competitor {
          name
        }
        number
        odds
        position
        weight
        jockeyOrKennelId
        jockeyOrKennel {
          name
        }
        medication
        active
        ownerId
        createdDate
        trainerId
        trainer {
          name
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface RunnersData {
  programRunners: {
    totalCount: number
    items: Runner[]
  }
}

export const useRunners = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<RunnersData>(GET_RUNNERS, {
    fetchPolicy: 'network-only',
  })
  const [fetched, setFetched] = useState(false)

  const [results, setResults] = useState<ResultsList<Runner, Metadata>>({
    results: [],
    metadata: {},
  })

  useEffect(() => {
    if (data?.programRunners) {
      setResults({
        results: data?.programRunners.items || [],
        metadata: {
          Count: data?.programRunners.totalCount,
        },
      })
      setFetched(true)
    }
  }, [loading])

  const getRunners = ({ where, take, skip, order }: any) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 5,
        order: order
          ? order
          : {
              raceNumber: 'ASC',
            },
      },
    })
  }

  return { getRunners, loading, error, results, fetched }
}
