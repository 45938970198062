import { Card, CardContent, Divider, Grid, Stack } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { DatePicker } from 'formik-mui-lab'
import { useAppTranslation } from 'src/admin/core/hooks'
import { FormAsterisk, PhoneTextFieldFormik } from 'src/admin/core'
import { HandleUserRoles } from '../components/HandleUserRoles'
import { User } from '../models'
import { StringRadioButtonFormik } from 'src/admin/core/components/RadioButtons'
import { useEffect } from 'react'

export const Form = ({
  user,
  afterSavedRole,
  disabledForm,
}: {
  disabledForm: boolean
  user: User
  afterSavedRole: () => void
}) => {
  const { label } = useAppTranslation()
  const isCreating = !user.id

  return (
    <Card>
      <Divider />
      <CardContent>
        <Stack spacing={2}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormAsterisk validate={true}>
                <Field
                  fullWidth
                  component={TextField}
                  type="text"
                  label={label('NOMBRE')}
                  name="givenName"
                  disabled={disabledForm}
                />
              </FormAsterisk>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormAsterisk validate={true}>
                <Field
                  fullWidth
                  component={TextField}
                  type="text"
                  label={label('APELLIDO')}
                  name="familyName"
                  disabled={disabledForm}
                />
              </FormAsterisk>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormAsterisk>
                <Field
                  fullWidth
                  component={TextField}
                  type="text"
                  label={label('APODO')}
                  name="nickname"
                  disabled={disabledForm}
                />
              </FormAsterisk>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormAsterisk validate={true}>
                <Field
                  fullWidth
                  component={TextField}
                  type="text"
                  label={label('DOCUMENTO')}
                  name="documentId"
                  disabled={!isCreating || disabledForm}
                />
              </FormAsterisk>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormAsterisk validate={true}>
                <Field
                  fullWidth
                  component={TextField}
                  type="email"
                  label={label('EMAIL')}
                  name="email"
                  disabled={disabledForm}
                />
              </FormAsterisk>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormAsterisk validate={true}>
                <Field
                  fullWidth
                  component={PhoneTextFieldFormik}
                  label={label('TELEFONO')}
                  name="phoneNumber"
                  disabled={disabledForm}
                />
              </FormAsterisk>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Field
                fullWidth
                component={DatePicker}
                type="text"
                label={label('FECHA_NACIMIENTO')}
                name="birthdate"
                inputFormat={'dd/MM/yyyy'}
                disabled={disabledForm}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormAsterisk>
                <Field
                  fullWidth
                  component={TextField}
                  label={label('ZONE_INFO')}
                  name="zoneinfo"
                  disabled={disabledForm}
                />
              </FormAsterisk>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormAsterisk>
                <Field fullWidth component={TextField} label={label('LOCALE')} name="locale" disabled={disabledForm} />
              </FormAsterisk>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormAsterisk>
                <Field
                  component={StringRadioButtonFormik}
                  title={'SEXO'}
                  name="gender"
                  label1={'MALE'}
                  label2={'FEMALE'}
                  value1={'MALE'}
                  value2={'FEMALE'}
                  disabled={disabledForm}
                />
              </FormAsterisk>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormAsterisk validate={true}>
                <HandleUserRoles user={user} afterSaved={afterSavedRole} disabled={disabledForm} />
              </FormAsterisk>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Field
                fullWidth
                component={TextField}
                label={label('DIRECCION')}
                name="address"
                placeholder={label('DIRECCION')}
                multiline
                minRows={3}
                maxRows={8}
                disabled={disabledForm}
              />
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  )
}
