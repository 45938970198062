import { gql, useMutation } from '@apollo/client'

const PROGRAM_COMPETITOR = gql`
  mutation UpsertProgramCompetitor($input: UpsertProgramCompetitorInput!) {
    upsertProgramCompetitor(input: $input) {
      name
    }
  }
`
export interface ProgramCompetitorInput {
  name: string
  ownerId: number
  active: boolean
}

export const useUpsertProgramCompetitor = () => {
  const [upsertProgramCompetitor, { loading, error }] = useMutation<ProgramCompetitorInput>(PROGRAM_COMPETITOR)
  return { upsertProgramCompetitor, loading, error }
}
