import React, { useEffect, useState } from 'react'
import { Box, Card, Grid } from '@mui/material'
import { CurrencySelect } from '../../currencies/components/CurrencySelect'
import { Currency } from '../../currencies/models/Currency'
import { DateWeekControl, WeekDaysType, monday, sunday } from 'src/admin/core/components/date/DateWeekControl'
import { UserAgenciesSelect, WorkstationProductSelect } from 'src/admin/core'
import { Agency, WorkstationProductsEnum } from 'src/core/models'

export interface FilterModel {
  locationId: string | undefined
  currencyId: string
  startDate: string
  endDate: string
  product: string
  agencies: Agency[]
}

export const initialFilters = {
  currencyId: 'DOP',
  locationId: undefined,
  startDate: monday,
  endDate: sunday,
  product: '',
  agencies: [],
}

export const Filters: React.FC<{ onChange: (filters: FilterModel) => void }> = ({ onChange }) => {
  const [filters, setFilters] = useState<FilterModel>(initialFilters)

  useEffect(() => {
    onChange(filters)
  }, [filters])

  const handleWeekChanges = (weekDays: WeekDaysType) => {
    setFilters({
      ...filters,
      startDate: weekDays.monday,
      endDate: weekDays.sunday,
    })
  }

  return (
    <Card
      sx={{
        p: 1,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box p={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3} lg={3} textAlign="center" alignItems={'center'}>
                <DateWeekControl onChange={handleWeekChanges} showDateLabel={false} />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <WorkstationProductSelect
                  value={filters.product as WorkstationProductsEnum}
                  onChange={(val) => {
                    setFilters({
                      ...filters,
                      product: val,
                    })
                  }}
                  getDefaultValue={(val) => {
                    setFilters({
                      ...filters,
                      product: val,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <UserAgenciesSelect
                  setDefault={true}
                  disableClearable={false}
                  onChangeValue={(value: Agency) =>
                    setFilters({
                      ...filters,
                      locationId: value?.id,
                    })
                  }
                  getAllAgencies={(values) =>
                    setFilters({
                      ...filters,
                      agencies: values,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <CurrencySelect
                  initialValue={''}
                  onChangeValue={(value: Currency) =>
                    setFilters({
                      ...filters,
                      currencyId: value?.grupal,
                    })
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Card>
  )
}
