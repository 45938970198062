import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import SuspenseLoader from 'src/components/SuspenseLoader'
import HorseSalesReport from '../scenes/consults/horseSalesReport/HorseSalesReport'
import WeeklyBalance from '../scenes/consults/weeklyBalance'
import TicketVerification from '../scenes/consults/tickets/verification'
import EventControl from '../scenes/consults/events/control'
import Terminal from '../scenes/consults/logs/terminal'
import DailyBalance from '../scenes/consults/dailyBalance'
import EnterpriseSales from '../scenes/consults/enterpriseSales'

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  )

const StatusComingSoon = Loader(lazy(() => import('src/admin/content/pages/Status/ComingSoon')))

const outes = [
  // {
  //   path: '/',
  //   element: <Navigate to="horse-sales-report" replace />,
  // },
  // {
  //   path: 'horse-sales-report',
  //   element: <HorseSalesReport />,
  // },
  {
    path: 'tickets',
    element: <StatusComingSoon pageTitle={'Tickets'} />,
  },
  {
    path: 'tickets-verification',
    element: <TicketVerification />,
  },
  // {
  //   path: 'events-control',
  //   element: <EventControl />,
  // },
  {
    path: 'weekly-balances',
    element: <WeeklyBalance />,
  },
  {
    path: 'daily-balances',
    element: <DailyBalance />,
  },
  // {
  //   path: 'enterprise-sales',
  //   element: <EnterpriseSales />,
  // },
  // {
  //   path: 'profit-and-loss',
  //   element: <StatusComingSoon pageTitle={'Ganancia y Perdidas'} />,
  // },
  // {
  //   path: 'basic-registration-plays',
  //   element: <StatusComingSoon pageTitle={'EMPADRONAMIENTO_BASICO'} />,
  // },
  // {
  //   path: 'agency-situation',
  //   element: <StatusComingSoon pageTitle={'ESTADO_SITUACION_AGENCIAS_TITULO'} />,
  // },
  // {
  //   path: 'agencies-operations',
  //   element: <StatusComingSoon pageTitle={'RESUMEN_OPERACIONES_TITULO'} />,
  // },
  // {
  //   path: 'partnership-operations',
  //   element: <StatusComingSoon pageTitle={'OPERACIONES_POR_CONSORCIOS'} />,
  // },
  // {
  //   path: 'locations-operations',
  //   element: <StatusComingSoon pageTitle={'OPERACIONES_POR_LOCALIDADES'} />,
  // },
  // {
  //   path: 'machine-details',
  //   element: <StatusComingSoon pageTitle={'MAQUINAS_DETALLADAS'} />,
  // },
  // {
  //   path: 'machine-summarized',
  //   element: <StatusComingSoon pageTitle={'MAQUINAS_RESUMIDA'} />,
  // },
  // {
  //   path: 'account-summary',
  //   element: <StatusComingSoon pageTitle={'RESUMEN_DE_CUENTA'} />,
  // },
  // {
  //   path: 'machine-chart',
  //   element: <StatusComingSoon pageTitle={'MAQUINA_GRAFICA'} />,
  // },
  // {
  //   path: 'terminal-logs',
  //   element: <Terminal />,
  // },
]

export default outes
