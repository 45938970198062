import React, { useEffect } from 'react'
import { useAgencies, useLocations } from '../hooks'
import { CustomAutocomplete } from 'src/admin/core/components/Select/CustomAutoComplete'
import { generateFilterGraphql } from 'src/admin/core/services'
import { Location } from 'src/admin/scenes/entities/agencies/models'
import { useAppTranslation } from 'src/admin/core/hooks'

export type AgencyProps = {
  onChangeValue?: (value: Location, action: string) => void
}

export const AgencySelect: React.FC<AgencyProps> = ({ onChangeValue }) => {
  // const { results, loading, getLocations } = useLocations()
  const { results, loading, getAgencies } = useAgencies()
  const { label } = useAppTranslation()

  useEffect(() => {
    fetchLocation()
  }, [])

  const fetchLocation = (agencyName = '') => {
    const customFilters = {
      and: [
        { name: { ...generateFilterGraphql(agencyName, 'contains') } },
        { name: { ...generateFilterGraphql('ONLINE_AGENCY', 'neq') } },
      ],
    }
    getAgencies({ where: customFilters, skip: 0, take: 500 })
  }

  return (
    <CustomAutocomplete
      onChangeValue={onChangeValue}
      data={results.results}
      placeholder={label('AGENCIAS')}
      keyLabel={'name'}
      isLoading={loading}
      label={label('AGENCIAS')}
    />
  )
}
