import { FileCopyOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import _ from 'lodash'
import { useContext, useEffect } from 'react'
import { useAppTranslation, useNotification, useRestClient } from 'src/admin/core/hooks'
import { dateIsBeforeThanTodayMoment } from 'src/admin/core/services/dateFunctions'
import { useUploadProgramFile } from '../../hooks'
import { EventContext } from '../../state'

export const GenerateFileButton: React.FC = () => {
  const {
    state: { event },
  } = useContext(EventContext)
  const { downloadFile, response } = useRestClient()
  const { errorNotification, successNotification } = useNotification()
  const { label, system } = useAppTranslation()
  const { uploadProgramFile, loading: uploadFileLoading, error } = useUploadProgramFile()

  const handleClick = () => {
    if (event.id !== undefined) {
      if (!dateIsBeforeThanTodayMoment(event.date)) {
        const response = uploadProgramFile({
          variables: {
            eventId: event.id,
          },
        })
        response
          .then((result: any) => {
            const error = _.get(result, 'errors', undefined)
            if (error && error.length > 0) {
              errorNotification(system(error[0].message))
            } else {
              successNotification(system('ARCHIVO_GENERADO'))
            }
          })
          .catch((e: any) => {
            // console.log(e)
          })
      } else {
        errorNotification(system('LA_FECHA_DEL_EVENTO_HA_PASADO'))
      }
    }
  }

  return (
    <Button color="secondary" variant="contained" onClick={handleClick} startIcon={<FileCopyOutlined />}>
      {label('GENERAR_ARCHIVO')}
    </Button>
  )
}
