import { useContext, useEffect, useState } from 'react'
import { Box, Card, CardContent, Divider, Grid, Stack, Typography } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { BooleanRadioButton } from 'src/admin/core/components/RadioButtons/BooleanRadioButton'
import { useAppTranslation, useNotification } from 'src/admin/core/hooks'
import { MedicationSelectFormik } from '../../components/Select'
import { useProgramMedications, useRunners } from '../../hooks'
import { EventContext, setRunner, setRunnerFormMode } from '../../state'
import { useFormikContext } from 'formik'
import { ProgramCompetitor, ProgramJockeyOrKennel, ProgramOwner } from '../../models'
import { UpdateButton } from './UpdateButton'
import { CreateButton } from './CreateButton'
import { AddButton, ClearButton, generateFilterGraphql } from 'src/admin/core'
import { OwnerSelectFormik } from '../../owners/components'
import { CompetitorSelectFormik } from '../../competitors/components'
import { JockeyOrKennelsSelectFormik } from '../../jockeyOrKennels/components'
import { Runner } from '../../models/Runner'
import _ from 'lodash'
import { formatToShortLocalDate, getTomorrow } from 'src/admin/core/services/dateFunctions'

export const Form: React.FC<{ runners: Runner[]; afterSave?: (promise: Promise<any>) => void }> = ({
  afterSave,
  runners,
}) => {
  const { label, system } = useAppTranslation()
  const [ownerId, setOwnerId] = useState<string | undefined>(undefined)
  const { results, getProgramMedications, loading } = useProgramMedications()
  const medications = results.results
  const { setFieldValue, values, resetForm } = useFormikContext()
  const { errorNotification } = useNotification()
  const { results: duplicatedRunners, getRunners: getRunnersByDate } = useRunners()
  const [hasDuplicatedRunner, setHasDuplicatedRunner] = useState(false)
  const [defaultMedication, setDefaultMedication] = useState<string | string[]>('')

  const {
    dispatch,
    state: { runnerFormMode, race, runner, event },
  } = useContext(EventContext)

  useEffect(() => {
    if (runnerFormMode === 'CANCEL') {
      handleCancel()
    }
  }, [runnerFormMode])

  useEffect(() => {
    getProgramMedications({
      where: {
        and: [
          {
            name: { ...generateFilterGraphql('', 'contains') },
            active: { ...generateFilterGraphql(true, 'eq') },
          },
        ],
      },
      skip: 0,
      take: 500,
    })
  }, [])

  useEffect(() => {
    if (runner) {
      const runnerClone = _.cloneDeep(runner)
      setOwnerId(runner.ownerId)
      setFieldValue('number', runnerClone.number)
      setFieldValue('trainerName', runnerClone.trainer?.name)
      setFieldValue('trainerId', runnerClone.trainerId)
      setFieldValue('weight', runnerClone.weight)
      setFieldValue('competitorId', runnerClone.competitorId)
      setFieldValue('jockeyOrKennelId', runner.jockeyOrKennelId)
      setFieldValue('odds', runnerClone.odds)
      setFieldValue('medication', getMedicationNames(runnerClone.medication))
      setFieldValue('ownerId', runner.ownerId)
      setFieldValue('active', runner.active)
    }
  }, [runner])

  const getMedicationNames = (medicationString: string | string[]): string | string[] => {
    if (!Array.isArray(medicationString)) {
      const list = medicationString.split('')
      const medicationNames = []
      medications.forEach((medication) => {
        if (list.includes(medication.code)) {
          medicationNames.push(medication.name)
        }
      })
      return medicationNames
    }

    return medicationString
  }

  useEffect(() => {
    if (ownerId !== undefined && ownerId !== '') {
      fetchProgramCompetitors(ownerId)
    }
  }, [ownerId])

  useEffect(() => {
    if (duplicatedRunners.results.length > 0) {
      setHasDuplicatedRunner(true)
      errorNotification(system('CORREDOR_SELECCIONADO_EN_OTRA_CARRERA'))
    } else {
      setHasDuplicatedRunner(false)
    }
  }, [duplicatedRunners.results])

  const fetchProgramCompetitors = (ownerId: string | undefined) => {
    if (ownerId) {
      const customFilters = {
        and: [
          {
            ownerId: { ...generateFilterGraphql(ownerId, 'eq') },
          },
        ],
      }
    }
  }

  const fetchRunnersByCompetitorAndEventDate = (competitorId: string) => {
    const customFilters = {
      and: [
        {
          createdDate: generateFilterGraphql(formatToShortLocalDate(event.date, 'YYYY-MM-DD'), 'gte'),
          competitorId: generateFilterGraphql(competitorId, 'eq'),
        },
        {
          createdDate: generateFilterGraphql(getTomorrow('YYYY-MM-DD'), 'lte'),
        },
      ],
    }
    getRunnersByDate({ where: customFilters, skip: 0, take: 100 })
  }

  const handleChangeCompetitor = (competitor: ProgramCompetitor) => {
    if (competitor !== null) {
      fetchRunnersByCompetitorAndEventDate(competitor.id)
      setFieldValue('name', competitor.name)
      setFieldValue('ownerName', competitor.owner)
      setFieldValue('ownerId', competitor.ownerId)
      setOwnerId(competitor.ownerId)
      setFieldValue('jockeyOrKennelId', undefined)
      setFieldValue('weight', '')

      if (competitor.medication) {
        setFieldValue('medication', getMedicationNames(competitor.medication))
      } else {
        setFieldValue('medication', '')
      }
    } else {
      setFieldValue('ownerName', '')
      setFieldValue('medication', '')
      setDefaultMedication(undefined)
    }
  }

  const handleChangeJockey = (jockey: ProgramJockeyOrKennel) => {
    setFieldValue('jockeyOrKennelName', jockey.name)
    setFieldValue('weight', '')
  }

  const handleChangeOwner = (value: ProgramOwner) => {
    setOwnerId(value.id)
    setFieldValue('trainerName', value.programTrainer?.name)
    setFieldValue('trainerId', value.trainerId)
    setFieldValue('weight', '')
    setFieldValue('competitorId', undefined)
    setFieldValue('jockeyOrKennelId', undefined)
    setFieldValue('medication', '')
    setDefaultMedication(undefined)
  }

  const handleNewRunner = () => {
    if (!race) {
      errorNotification(system('DEBE_SELECCIONAR_UNA_CARRERA'))
    } else if (Number(race.runners) <= runners.length) {
      errorNotification(`${system('ERROR_CORREDOR_MAXIMO_PERMITIDO')} ${race.runners}`)
    } else {
      dispatch(setRunnerFormMode('CREATE'))
      handleCancel()
    }
  }

  const handleCancel = () => {
    resetForm()
    dispatch(setRunner(null))

    setOwnerId(undefined)
    setFieldValue('number', runners.length + 1)
    setFieldValue('trainerName', '')
    setFieldValue('trainerId', '')
    setFieldValue('weight', '')
    setFieldValue('competitorId', null)
    setFieldValue('jockeyOrKennelId', null)
    setFieldValue('odds', '')
    setFieldValue('medication', '')
    setFieldValue('ownerId', null)
  }

  const isDisabled = runnerFormMode === 'CANCEL'

  return (
    <Card>
      <Box p={2.5} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography gutterBottom variant="h4">
            {`${label('LISTA_DE_CORREDORES_DE_LA_CARRERA_NO')} ${race.raceNumber}`}
          </Typography>
        </Box>
        <Stack direction="row" spacing={1}>
          {runnerFormMode === 'CANCEL' ? (
            <AddButton onClick={handleNewRunner} />
          ) : (
            <>
              {runner && runner.id ? (
                <Box>
                  <UpdateButton
                    afterSave={afterSave}
                    runners={runners}
                    disabled={hasDuplicatedRunner}
                    medications={medications}
                  />
                </Box>
              ) : (
                <Box>
                  <CreateButton
                    afterSave={afterSave}
                    runners={runners}
                    disabled={hasDuplicatedRunner}
                    medications={medications}
                  />
                </Box>
              )}
              <ClearButton onClick={() => dispatch(setRunnerFormMode('CANCEL'))} />
            </>
          )}
        </Stack>
      </Box>
      <Divider />
      <CardContent>
        <Grid container spacing={3} pb={3}>
          <Grid item lg={3} md={3} xs={12} sm={12}>
            <Field
              InputProps={{ inputProps: { min: 1, max: 999 } }}
              component={TextField}
              type="text"
              label={label('NUMERO')}
              name="number"
              fullWidth
              disabled={true}
            />
          </Grid>

          <Grid item lg={2} md={2} xs={12} sm={12}>
            <Field
              component={TextField}
              type="text"
              label={label('PUNTOS')}
              name="odds"
              fullWidth
              disabled={isDisabled}
            />
          </Grid>

          <Grid item lg={2} md={2} xs={12} sm={12}>
            <OwnerSelectFormik
              ownerId={ownerId}
              handleChange={handleChangeOwner}
              disabled={isDisabled}
              handleClear={() => {
                setOwnerId(undefined)
                setFieldValue('trainerName', '')
                setFieldValue('trainerId', '')
                setFieldValue('weight', '')
                setFieldValue('competitorId', undefined)
                setFieldValue('jockeyOrKennelId', undefined)
                setFieldValue('medication', '')
                setDefaultMedication(undefined)
              }}
            />
          </Grid>

          <Grid item lg={2} md={2} xs={12} sm={12}>
            <Field
              component={TextField}
              type="text"
              label={label('ENTRENADOR')}
              name="trainerName"
              fullWidth
              disabled={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} pb={3}>
          <Grid item lg={3} md={3} xs={12} sm={12}>
            <CompetitorSelectFormik
              competitorId={runner ? runner.competitorId : undefined}
              ownerId={ownerId}
              handleChange={handleChangeCompetitor}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item lg={2} md={2} xs={12} sm={12}>
            <JockeyOrKennelsSelectFormik
              handleChange={handleChangeJockey}
              handleClear={() => {
                setFieldValue('weight', '')
              }}
              disabled={isDisabled}
              jockeyId={runner ? runner.jockeyOrKennelId : undefined}
            />
          </Grid>

          <Grid item lg={2} md={2} xs={12} sm={12}>
            <Field
              InputProps={{ inputProps: { min: 1, max: 9999 } }}
              component={TextField}
              type="number"
              label={label('PESO')}
              name="weight"
              fullWidth
              disabled={isDisabled}
            />
          </Grid>

          <Grid item lg={2} md={2} xs={12} sm={12}>
            <Field
              fullWidth
              component={MedicationSelectFormik}
              label={label('MEDICACION')}
              name="medication"
              disabled={isDisabled}
              defaultValue={defaultMedication}
              data={medications}
            />
          </Grid>

          <Grid item lg={2} md={2} sm={12} xs={12}>
            <Field
              component={BooleanRadioButton}
              title={'DESCARTADO'}
              name="active"
              label1={'SI'}
              label2={'NO'}
              value1={'active'}
              value2={'inactive'}
              disabled={isDisabled}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
