import { Field } from 'formik'
import { useEffect, useState } from 'react'
import { generateFilterGraphql } from 'src/admin/core'
import { CustomAutoCompleteSelectFormik } from 'src/admin/core/components/Select/CustomAutoCompleteSelectFormik'
import { useAppTranslation } from 'src/admin/core/hooks'
import { useJockeyOrKennels } from '../../hooks'
import { ProgramJockeyOrKennel } from '../../models'

type Props = {
  defaultValue?: ProgramJockeyOrKennel
  disabled?: boolean
  jockeyId?: string
  handleChange?: (value: ProgramJockeyOrKennel | null) => void
  handleSelectOpen?: () => void
  handleClear?: () => void
}

export const JockeyOrKennelsSelectFormik = ({
  jockeyId,
  defaultValue,
  disabled,
  handleChange,
  handleClear,
  handleSelectOpen,
}: Props) => {
  const { results, getJockeyOrKennels, loading } = useJockeyOrKennels()
  const { label } = useAppTranslation()
  const [defaultJockey, setDefaultJockey] = useState<ProgramJockeyOrKennel | undefined>(defaultValue)

  useEffect(() => {
    if (jockeyId) {
      const object = results.results.filter((item) => item.id == jockeyId)
      if (object.length > 0) {
        setDefaultJockey(object[0])
      }
    }
  }, [jockeyId, results.results])

  useEffect(() => {
    getJockeyOrKennels({
      where: {
        and: [
          {
            active: { ...generateFilterGraphql(true, 'eq') },
          },
        ],
      },
      skip: 0,
      take: 500,
    })
  }, [])

  const handleCustomChange = (value: any, reason: string) => {
    if (reason === 'selectOption') {
      handleChange(value)
    }
    if (reason == 'clear') {
      handleClear()
    }
  }

  return (
    <Field
      defaultValue={defaultJockey}
      component={CustomAutoCompleteSelectFormik}
      keyValue={'id'}
      keyLabel={'name'}
      data={results.results}
      isLoading={loading}
      label={label('JINETE')}
      name="jockeyOrKennelId"
      onChangeValue={handleCustomChange}
      disabled={disabled}
    />
  )
}
