import { useContext, useEffect, useMemo } from 'react'
import { useAppTranslation } from 'src/admin/core/hooks'
import _ from 'lodash'
import { Loader } from 'src/admin/components/Loader'
import { EventContext } from '../../../state'
import { Race } from '../../../models'
import { useRaceWagers } from '../../../hooks'

type Props = {
  race: Race
}

export const RaceWagers: React.FC<Props> = ({ race }) => {
  const { fetchProgramDefaultWagers, loadingWagers, raceWagers } = useRaceWagers()
  const { label } = useAppTranslation()

  const {
    state: { event },
  } = useContext(EventContext)

  useEffect(() => {
    if (event) {
      fetchProgramDefaultWagers(event, race.raceNumber)
    }
  }, [event, race])

  const wagerNames = useMemo(() => {
    return raceWagers.map((wager) => {
      const values = []
      wager.possibleWagers.forEach((wager) => {
        if (race.wagerIds.includes(wager.id)) {
          values.push(label(wager.name))
        }
      })
      return values.join(', ')
    })
  }, [raceWagers, race])

  return (
    <Loader loading={loadingWagers}>
      <>{wagerNames}</>
    </Loader>
  )
}
