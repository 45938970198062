import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, useTheme } from '@mui/material'
import { GridRowParams } from '@mui/x-data-grid'

// App Components
import { DataGridColumns } from 'src/admin/core/components/DataGrid/CustomDataGrid'
import { AdminDataGrid } from 'src/admin/core/components/DataGrid/AdminDataGrid'
import { Page } from 'src/admin/core/components'
import { CreateButton, generateFilterGraphql } from 'src/admin/core'
import { ContentDataGrid } from 'src/admin/core/components/DataGrid'

// Hooks
import { useProgramCompetitors } from '../hooks'

import { useAppTranslation, usePagination, useUserClaimActions } from 'src/admin/core/hooks'
import Filters, { FiltersProps, initialFilters } from './components/Filters'
import { ClaimActions } from 'src/admin/core/models'

const Competitors = () => {
  const theme = useTheme()
  const { label } = useAppTranslation()
  const navigate = useNavigate()
  const { results, loading, getProgramCompetitors } = useProgramCompetitors()
  const { page, pageSize, skip, handlePageChange, handlePageSizeChange } = usePagination()
  const [filters, setFilters] = useState<FiltersProps>(initialFilters)
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.competitors', [ClaimActions.CanView])

  useEffect(() => {
    let customFilters: any = []

    if (filters.ownerId) {
      customFilters.push({
        ownerId: { ...generateFilterGraphql(filters.ownerId, 'eq') },
      })
    }
    customFilters.push({
      name: { ...generateFilterGraphql(filters.name, 'contains') },
    })

    getProgramCompetitors({
      where: {
        and: customFilters,
      },
      skip: skip,
      take: pageSize,
    })
  }, [filters, skip, pageSize])

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      NOMBRE: { field: 'name', contentType: 'string' },
      PROPIETARIO: { field: 'ownerName', contentType: 'string' },
      ESTADO: { field: 'active', contentType: 'boolean' },
    }
  }, [])

  const goToCreate = () => {
    navigate(`/admin/entities/events-competitors/create`)
  }

  const goToEdit = (row: GridRowParams) => {
    navigate(`/admin/entities/events-competitors/${row.id}`)
  }

  return (
    <Page
      hasAccess={actions.canView}
      tabTitle={label('COMPETIDORES_TITULO')}
      pageTitle={label('COMPETIDORES_TITULO')}
      pageSubTitle={label('COMPETIDORES_SUB_TITULO')}
      actions={<CreateButton onClick={goToCreate} />}
    >
      <Grid item xs={12}>
        <Filters onChange={(filters) => setFilters({ ...filters })} />
      </Grid>
      <Grid item xs={12}>
        <ContentDataGrid>
          <AdminDataGrid
            columns={getColumnDefinitions()}
            data={results.results}
            count={results.metadata.Count}
            onRowClick={goToEdit}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            loading={loading}
            page={page}
            pageSize={pageSize}
          />
        </ContentDataGrid>
      </Grid>
    </Page>
  )
}

export default Competitors
