import { Field } from 'formik'
import { useEffect, useState } from 'react'
import { generateFilterGraphql } from 'src/admin/core'
import { CustomAutoCompleteSelectFormik } from 'src/admin/core/components/Select/CustomAutoCompleteSelectFormik'
import { useAppTranslation } from 'src/admin/core/hooks'
import { useProgramOwners } from '../../hooks'
import { ProgramOwner } from '../../models'

type Props = {
  defaultValue?: ProgramOwner
  disabled?: boolean
  handleChange?: (value: ProgramOwner) => void
  handleSelectOpen?: () => void
  handleClear?: () => void
  ownerId?: string
}

export const OwnerSelectFormik = ({
  ownerId,
  defaultValue,
  disabled,
  handleChange,
  handleSelectOpen,
  handleClear,
}: Props) => {
  const { results: owners, getProgramOwners, loading } = useProgramOwners()
  const { label } = useAppTranslation()
  const [defaultOwner, setDefaultOwner] = useState<ProgramOwner | undefined>(defaultValue)

  useEffect(() => {
    if (ownerId) {
      const object = owners.results.filter((item) => item.id == ownerId)
      if (object.length > 0) {
        setDefaultOwner(object[0])
      }
    } else {
      setDefaultOwner(undefined)
    }
  }, [ownerId, owners])

  useEffect(() => {
    getProgramOwners({
      where: {
        or: [
          {
            name: { ...generateFilterGraphql('', 'contains') },
            active: { ...generateFilterGraphql(true, 'eq') },
          },
        ],
      },
      skip: 0,
      take: 500,
    })
  }, [])

  const handleCustomChange = (value: any, reason: string) => {
    if (reason === 'selectOption') {
      handleChange(value)
    }
    if (reason == 'clear') {
      handleClear()
    }
  }

  return (
    <Field
      defaultValue={defaultOwner}
      component={CustomAutoCompleteSelectFormik}
      keyValue={'id'}
      keyLabel={'name'}
      data={owners.results}
      isLoading={loading}
      label={label('PROPIETARIO')}
      name="ownerId"
      onChangeValue={handleCustomChange}
      disabled={disabled}
      onSelectOpen={handleSelectOpen}
    />
  )
}
