import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useAppTranslation } from 'src/admin/core/hooks'
import { useParams } from 'react-router-dom'
import { People } from '../models'
import { phoneRegExp } from 'src/admin/core'
import _ from 'lodash'
import { excludedRolesForNotUserAdmin } from 'src/admin/core/services/userClaims'
import { usePeople } from './usePeople'

const initialValues: People = {
  identificationType: 'CEDULA',
  identificationNumber: '',
  firstName: '',
  lastName: '',
  nickName: '',
  birthdate: '',
  cellPhone: '',
  email: '',
  gender: 'MALE',
  active: true,
}

export const usePeopleFormHandler = () => {
  const { label } = useAppTranslation()
  const { id } = useParams()
  const { getPeople, data, loading } = usePeople()
  const [people, setPeople] = useState<People | null>(null)

  useEffect(() => {
    if (id && !loading) {
      fetchPeople(id)
    }
  }, [id])

  const fetchPeople = (id: string) => {
    getPeople(id)
  }

  useEffect(() => {
    if (data?.people) {
      const clonedPeople = _.cloneDeep(data?.people)
      clonedPeople.identificationType =
        clonedPeople.identificationType == 'ID' ? 'CEDULA' : clonedPeople.identificationType
      setPeople(clonedPeople)
    }
  }, [data])

  const formSchema = Yup.object().shape({
    firstName: Yup.string().required(label('REQUERIDO')),
    lastName: Yup.string().required(label('REQUERIDO')),
    cellPhone: Yup.string().required(label('REQUERIDO')).matches(phoneRegExp, label('VALOR_NO_VALIDO')).nullable(),
    birthdate: Yup.string().required(label('REQUERIDO')).nullable(),
    identificationNumber: Yup.string()
      .max(15, `${label('MAXIMO')} 15`)
      .required(label('REQUERIDO')),
  })

  return {
    loading,
    formSchema: formSchema,
    initialValues: people || initialValues,
    fetchPeople: fetchPeople,
  }
}
