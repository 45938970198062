import React from 'react'
import CloudOffIcon from '@mui/icons-material/CloudOff'

export const EmptyData: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <div
      style={{
        display: 'grid',
        margin: '0 auto',
      }}
    >
      <CloudOffIcon
        sx={{
          fontSize: '10rem',
          color: 'gainsboro',
        }}
      />
      <span>{`${text ? text : 'Datos no encontrados'}`}</span>
    </div>
  )
}
