import { Checkbox, CheckboxProps, CircularProgress, styled } from '@mui/material'
import { green } from '@mui/material/colors'
import React, { ReactElement, useState } from 'react'

const CustomCircularProgress = styled(CircularProgress)(
  ({ theme }) => `
  margin-left: 10;
`,
)

export type RoleClaim = {
  claimValue: string
  claimType: string
  roleName: string
}

interface CustomCheckboxColorProps extends CheckboxProps {
  checked?: boolean
  icon: ReactElement
  checkedIcon: ReactElement
  name: string
  customColor: string
  isClaimed: boolean
  size?: 'medium' | 'small'
  roleClaim: RoleClaim
  addClaim: (roleClaim: RoleClaim) => void
  removeClaim: (roleClaim: RoleClaim) => void
}

export const ClaimCheckbox = ({ ...props }: CustomCheckboxColorProps) => {
  const { isClaimed, addClaim, removeClaim, size, roleClaim, disabled } = props

  const [isProgressing, setIsProgressing] = useState<boolean>(false)

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked
    setIsProgressing(true)
    if (value) {
      await addClaim(roleClaim)
    } else {
      await removeClaim(roleClaim)
    }
    setIsProgressing(false)
  }

  return isProgressing ? (
    <CustomCircularProgress size={20} />
  ) : (
    <Checkbox {...props} color="default" checked={isClaimed} onChange={handleChange} size={size} disabled={disabled} />
  )
}
