import { useCallback } from 'react'
import { FormPage, PageTab, PageHeader, FormContent, BackButton } from 'src/admin/core'
import { useAppTranslation, useNotification, useUserClaimActions } from 'src/admin/core/hooks'
import { AgencyForm } from './AgencyForm'
import { useNavigate } from 'react-router-dom'
import { CreateAgency } from './CreateAgency'
import { UpdateAgency } from './UpdateAgency'
import { Loader } from 'src/admin/components/Loader'
import { Stack } from '@mui/material'
import _ from 'lodash'
import { useFormHandler } from '../hooks'
import { ClaimActions } from 'src/admin/core/models'
import { SecurityPage } from 'src/admin/core/components/Page/SecurityPage'

const Agency2: React.FC = () => {
  const { label, system } = useAppTranslation()
  const navigate = useNavigate()
  const { successNotification, errorNotification } = useNotification()
  const { formSchema, initialValues, loading } = useFormHandler()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.agencies', [ClaimActions.CanView])

  const afterSave = useCallback((response: Promise<any>, action: string) => {
    response
      .then((res) => {
        if (action === 'create') {
          const id = _.get(res, 'data.upsertAgency.id', '')
          if (id !== '') {
            successNotification(system('DATO_REGISTRADO'))
            navigate(`/admin/entities/agencies`)
          }
        } else {
          successNotification(system('DATO_ACTUALIZADO'))
        }
      })
      .catch((e) => {
        errorNotification(system('ERROR_REGISTRANDO_EL_DATO'))
      })
  }, [])

  return (
    <Loader loading={loading}>
      <SecurityPage hasAccess={actions.canView}>
        <FormPage
          form={{
            formSchema: formSchema,
            formValues: initialValues,
          }}
        >
          <PageTab text={label('AGENCIA_TITULO')} />
          <PageHeader
            title={label('AGENCIA_TITULO')}
            actions={
              <Stack direction="row" spacing={2}>
                <BackButton />
                {initialValues && initialValues.id
                  ? actions.canEdit && <UpdateAgency afterSave={afterSave} />
                  : actions.canCreate && <CreateAgency afterSave={afterSave} />}
              </Stack>
            }
          />
          <FormContent>
            <Stack spacing={2}>
              <AgencyForm />
            </Stack>
          </FormContent>
        </FormPage>
      </SecurityPage>
    </Loader>
  )
}

export default Agency2
