import { formatSpanishDate } from 'src/admin/core/services/dateFunctions'

export const FooterReport = () => {
  const now = new Date()
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '0',
        width: '100%',
        padding: '10px',
        backgroundColor: '#f1f1f1',
        fontStyle: 'italic',
      }}
    >
      Fecha Impresion: {formatSpanishDate(now.toLocaleDateString())}
    </div>
  )
}
