import { Button } from '@mui/material'
import { useAppTranslation } from '../../hooks'
import EditIcon from '@mui/icons-material/Edit'

type buttonProps = {
  onClick: () => void
  className?: string
  disabled?: boolean
}
export const ModifyButton = ({ onClick, ...props }: buttonProps) => {
  const { label } = useAppTranslation()

  return (
    <Button {...props} variant="contained" color="primary" onClick={onClick} startIcon={<EditIcon />}>
      {label('MODIFICAR')}
    </Button>
  )
}
