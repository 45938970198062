import { Box, Card, CardContent, Divider, Grid, Stack, Typography } from '@mui/material'
import { Field, useFormikContext } from 'formik'
import { TextField, CheckboxWithLabel } from 'formik-material-ui'
import { BooleanRadioButton } from 'src/admin/core/components/RadioButtons/BooleanRadioButton'
import { useAppTranslation } from 'src/admin/core/hooks'
import { Enterprise } from '../models'
import { CountrySelect } from 'src/admin/core/components/Geography'
import { FormEnterprise } from '../hooks'

const products = [
  {
    label: 'HORSE_RACES',
    value: 'HORSE_RACES',
  },
  {
    label: 'COCKFIGHTS',
    value: 'COCKFIGHTS',
  },
  {
    label: 'SPORTS',
    value: 'SPORTS',
  },
]

export const Form = () => {
  const { label } = useAppTranslation()
  const form = useFormikContext()
  const enterprise = form.values as FormEnterprise

  const isEditing = !!enterprise?.id

  return (
    <Card>
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item lg={6} xs={12} sm={12}>
            <Field fullWidth component={TextField} type="text" label={label('CONSORCIO')} name="name" />
          </Grid>
          <Grid item lg={6} xs={12} sm={12}>
            <Field
              component={CountrySelect}
              name={'country'}
              defaultCountry={!isEditing ? 'RD' : enterprise.addresses ? enterprise.addresses[0]?.countryId : ''}
            />
          </Grid>
          <Grid item lg={6} xs={12} sm={12}>
            <Field
              disabled={isEditing}
              fullWidth
              component={TextField}
              type="text"
              label={label('SUBDOMINIO')}
              name="subdomain"
            />
          </Grid>
          <Grid item lg={6} xs={12} sm={12}>
            <Field
              disabled={isEditing}
              fullWidth
              component={TextField}
              type="text"
              label={label('DOMINIO')}
              name="domain"
            />
          </Grid>
          <Grid item lg={6} xs={12} sm={12}>
            <Field
              component={BooleanRadioButton}
              title={'ESTADO'}
              name="active"
              label1={'ACTIVO'}
              label2={'INACTIVO'}
              value1={'active'}
              value2={'inactive'}
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          <Typography variant={'h3'}>{label('PRODUCTOS_DISPONIBLES_CONSORCIO')}</Typography>
          <Divider />
        </Box>
        <Grid container>
          <Stack direction={'row'} spacing={3}>
            {products.map((opt) => (
              <Field
                type="checkbox"
                component={CheckboxWithLabel}
                name="products"
                key={opt.value}
                value={opt.value}
                Label={{ label: label(opt.label) }}
              />
            ))}
          </Stack>
        </Grid>
      </CardContent>
    </Card>
  )
}
