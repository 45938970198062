import { PartialRouteObject } from 'react-router'

import BaseLayout from 'src/layouts/BaseLayout'

import adminRoutes from 'src/admin/router/adminRoutes'

const router: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: adminRoutes,
  },
]

export default router
