import { useNotification } from 'src/admin/core/hooks/useNotification'
import { User } from '../models'
import { RoleSelectFormik } from './RoleSelectFormik'
import { useAssignUserRole, useRemoveUserRole } from '../hooks'
import { useAppTranslation } from 'src/admin/core/hooks'
import _ from 'lodash'

export const HandleUserRoles = ({
  user,
  afterSaved,
  disabled,
}: {
  disabled: boolean
  user: User
  afterSaved: () => void
}) => {
  const { successNotification, errorNotification, warningNotification } = useNotification()
  const { assignUserRole } = useAssignUserRole()
  const { removeUserRole } = useRemoveUserRole()
  const { system } = useAppTranslation()

  const handleChangeRole = (value: any, reason: string, item: string) => {
    if (item && user.id) {
      if (reason === 'selectOption') {
        const response = assignUserRole({
          variables: {
            input: {
              role: item,
              userName: user.userName,
            },
          },
        })
        response
          .then((e: any) => {
            const succeeded = _.get(e, 'data.assignUserToRole.succeeded', false)
            if (succeeded) {
              successNotification(system('ROLE_CLAIM_AGREGADO'))
              afterSaved()
            } else {
              errorNotification(system('ERROR_REGISTRANDO_EL_DATO'))
            }
          })
          .catch((e: any) => {
            errorNotification(system('ERROR_REGISTRANDO_EL_DATO'))
          })
      } else if (reason === 'removeOption') {
        const rolesDiff = _.xor(user.roles, value)
        if (user && user.id && rolesDiff.length > 0) {
          const response = removeUserRole({
            variables: {
              input: {
                role: rolesDiff[0],
                userName: user.userName,
              },
            },
          })
          response
            .then((e: any) => {
              const succeeded = _.get(e, 'data.removeUserFromRole.succeeded', false)
              if (succeeded) {
                warningNotification(system('ROLE_CLAIM_ELIMINADO'))
              } else {
                errorNotification(system('ERROR_ACTUALIZANDO_EL_DATO'))
              }
            })
            .catch((e: any) => {
              errorNotification(system('ERROR_ACTUALIZANDO_EL_DATO'))
            })
        }
      } else {
        errorNotification(system('ERROR_ACTUALIZANDO_EL_DATO'))
      }
    }
  }

  return <RoleSelectFormik onChangeValue={handleChangeRole} disabled={disabled} />
}
