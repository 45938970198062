// import { getAccessToken } from 'pb-shared'
import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { Enterprise } from 'src/admin/scenes/entities/enterprises/models'
import { getAccessToken } from '../auth/authService'
import { getEnvSettings } from './configurationServices'
// import { Enterprise } from '../../scenes/entities/enterprises/models'

export const getApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const envSettings = getEnvSettings()

  const httpLink = createHttpLink({
    uri: envSettings.REACT_APP_PB_GRAPH_BASE_URL,
  })

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getAccessToken()
    // return the headers to the context so httpLink can read them

    const strEnterprise = window.sessionStorage.getItem('selectedEnterprise')
    let enterprise = null
    if (strEnterprise && strEnterprise !== 'none') {
      enterprise = JSON.parse(strEnterprise) as Enterprise
    }
    return {
      headers: {
        ...headers,
        'X-PLACEBET-ENTERPRISE': enterprise ? enterprise.id : 'none',
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  })

  return client
}
