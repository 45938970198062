import React from 'react'
import { useTranslation } from 'react-i18next'
import { Autocomplete, AutocompleteChangeReason, TextField } from '@mui/material'

export interface CustomAutoCompleteProps {
  data: any
  defaultValue?: any
  onChangeValue?: (value: any, action: string) => void
  onInputValueChange?: (value: string) => void
  placeholder?: string
  keyValue?: string
  keyLabel?: string
  isLoading?: boolean
  label?: string
  multiple?: boolean
  disableClearable?: boolean
  disabled?: boolean
  error?: string
}

export const CustomAutocomplete: React.FC<CustomAutoCompleteProps> = ({
  data,
  defaultValue,
  onChangeValue,
  onInputValueChange,
  placeholder,
  keyValue,
  keyLabel,
  label,
  isLoading,
  multiple,
  disableClearable,
  disabled,
  error,
  ...props
}) => {
  const { t: transLabel } = useTranslation('Label')
  const [inputValue, setInputValue] = React.useState('')
  const [selectedOption, setSelectedOption] = React.useState(multiple ? [] : defaultValue)

  const onSelect = (event: React.ChangeEvent<any>, value: any, reason: AutocompleteChangeReason) => {
    setSelectedOption(value)
    if (onChangeValue instanceof Function) {
      onChangeValue(value, reason)
    }
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setInputValue(value)
    if (onInputValueChange instanceof Function) {
      onInputValueChange(value)
    }
  }

  return (
    <Autocomplete
      sx={{
        m: 0,
        minWidth: 'inherit',
      }}
      onChange={onSelect}
      value={defaultValue}
      getOptionLabel={(option) => (keyLabel && option[keyLabel] ? option[keyLabel] : '')}
      options={data}
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={onInputChange}
          value={inputValue}
          label={transLabel(label)}
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={transLabel(placeholder)}
          autoComplete={'off'}
          error={!!error}
          helperText={error ? error : ''}
        />
      )}
      disableClearable={disableClearable}
      disabled={disabled}
      {...props}
    />
  )
}
