import { Field } from 'formik'
import { useEffect, useState } from 'react'
import { generateFilterGraphql } from 'src/admin/core'
import { CustomAutoCompleteSelectFormik } from 'src/admin/core/components/Select/CustomAutoCompleteSelectFormik'
import { useAppTranslation } from 'src/admin/core/hooks'
import { useProgramCompetitors } from '../../hooks'
import { ProgramCompetitor } from '../../models'

type Props = {
  defaultValue?: ProgramCompetitor
  disabled?: boolean
  ownerId?: string
  competitorId?: string
  handleChange?: (value: ProgramCompetitor) => void
  handleSelectOpen?: () => void
}

export const CompetitorSelectFormik = ({
  disabled,
  ownerId,
  competitorId,
  defaultValue,
  handleChange,
  handleSelectOpen,
}: Props) => {
  const { results, loading, getProgramCompetitors } = useProgramCompetitors()

  const { label } = useAppTranslation()
  const [defaultCompetitor, setDefaultCompetitor] = useState<ProgramCompetitor | undefined>(defaultValue)

  useEffect(() => {
    if (competitorId) {
      const object = results.results.filter((item) => item.id == competitorId)
      if (object.length > 0) {
        setDefaultCompetitor(object[0])
      }
    }
  }, [competitorId, results.results])

  useEffect(() => {
    fetchProgramCompetitors(ownerId)
  }, [ownerId])

  const fetchProgramCompetitors = (ownerId: string | undefined) => {
    if (ownerId) {
      const customFilters = {
        and: [
          {
            ownerId: { ...generateFilterGraphql(ownerId, 'eq') },
          },
        ],
      }

      getProgramCompetitors({ where: customFilters, skip: 0, take: 500 })
    }
  }

  return (
    <Field
      defaultValue={defaultCompetitor}
      component={CustomAutoCompleteSelectFormik}
      keyValue={'id'}
      keyLabel={'name'}
      data={results.results}
      isLoading={loading}
      label={label('COMPETIDOR')}
      name="competitorId"
      onChangeValue={handleChange}
      disabled={disabled}
      fullWidth
    />
  )
}
