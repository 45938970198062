import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Dictionary } from '../../../../core/models'
import { Workstation } from '../models'

const GET_WORKsTATION = gql`
  query GetWorkstation($id: Int!) {
    workstation(id: $id) {
      id
      name
      enterpriseId
      locationId
      workstationType
      active
      agencyId
      agency {
        id
        name
        active
      }
    }
  }
`

interface Data {
  workstation: Workstation
}

export const useWorkstation = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<Data>(GET_WORKsTATION, {
    fetchPolicy: 'network-only',
  })

  const getWorkstation = (id: number) => {
    getResults({
      variables: { id },
    })
  }

  return { getWorkstation, loading, error, data }
}
