import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import SuspenseLoader from 'src/components/SuspenseLoader'

// Company
import Companies from '../scenes/entities/companies'
import Company from '../scenes/entities/companies/Company'

// Enterprise
import Enterprises from '../scenes/entities/enterprises'
import Enterprise from '../scenes/entities/enterprises/Enterprise'

// Agencies
import Station from '../scenes/entities/stations/station'

import Agencies from '../scenes/entities/agencies'
import Agency from '../scenes/entities/agencies/Agency'

// Events
import Events from '../scenes/entities/hipicas/events'
import Event from '../scenes/entities/hipicas/events/Event'
import Competitors from '../scenes/entities/hipicas/competitors'
import Competitor from '../scenes/entities/hipicas/competitors/competitor'
import JockeyOrKennels from '../scenes/entities/hipicas/jockeyOrKennels'
import JockeyOrKennel from '../scenes/entities/hipicas/jockeyOrKennels/jockeyOrKennel'
import Trainers from '../scenes/entities/hipicas/trainers'
import Trainer from '../scenes/entities/hipicas/trainers/trainer'
import Owners from '../scenes/entities/hipicas/owners'
import Owner from '../scenes/entities/hipicas/owners/owner'
import Peoples from '../scenes/entities/peoples'
import People from '../scenes/entities/peoples/people'

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  )

const Workstations = Loader(lazy(() => import('src/admin/scenes/entities/workstations/list')))
const Workstation = Loader(lazy(() => import('src/admin/scenes/entities/workstations/single/Form')))

const StatusComingSoon = Loader(lazy(() => import('src/admin/content/pages/Status/ComingSoon')))

const configurationRoutes = [
  {
    path: '/',
    element: <Navigate to="enterprises" replace />,
  },
  {
    path: 'enterprises',
    element: <Enterprises />,
  },
  {
    path: 'enterprises/create',
    element: <Enterprise />,
  },
  {
    path: 'enterprises/:id',
    element: <Enterprise />,
  },
  {
    path: 'workstations',
    element: <Workstations />,
  },
  {
    path: 'workstations/:id',
    element: <Workstation />,
  },
  {
    path: 'agencies',
    element: <Agencies />,
  },
  {
    path: 'agencies/create',
    element: <Agency />,
  },
  {
    path: 'agencies/:id',
    element: <Agency />,
  },
  {
    path: 'agencies/:agencyId/station',
    element: <Station />,
  },
  {
    path: 'agencies/:agencyId/station/:id',
    element: <Station />,
  },
  {
    path: 'events',
    element: <Events />,
  },
  {
    path: 'events/create',
    element: <Event />,
  },
  {
    path: 'events/:id',
    element: <Event />,
  },
  {
    path: 'events-competitors',
    element: <Competitors />,
  },
  {
    path: 'events-competitors/create',
    element: <Competitor />,
  },
  {
    path: 'events-competitors/:id',
    element: <Competitor />,
  },
  {
    path: 'events-jockeyOrKennels',
    element: <JockeyOrKennels />,
  },
  {
    path: 'events-jockeyOrKennels/create',
    element: <JockeyOrKennel />,
  },
  {
    path: 'events-jockeyOrKennels/:id',
    element: <JockeyOrKennel />,
  },
  {
    path: 'events-trainers',
    element: <Trainers />,
  },
  {
    path: 'events-trainers/create',
    element: <Trainer />,
  },
  {
    path: 'events-trainers/:id',
    element: <Trainer />,
  },
  {
    path: 'events-owners',
    element: <Owners />,
  },
  {
    path: 'events-owners/create',
    element: <Owner />,
  },
  {
    path: 'events-owners/:id',
    element: <Owner />,
  },
  {
    path: 'events-owners',
    element: <StatusComingSoon pageTitle={'PROPIETARIOS'} />,
  },
  {
    path: 'peoples',
    element: <Peoples />,
  },
  {
    path: 'peoples/create',
    element: <People />,
  },
  {
    path: 'peoples/:id',
    element: <People />,
  },
]

export default configurationRoutes
