import { useCallback, useEffect, useState } from 'react'
import { Stack, Grid, Divider, Tooltip, IconButton, Card, Box } from '@mui/material'
import { Page } from 'src/admin/core/components'
import { useAppTranslation, useNotification, usePagination, useUserClaimActions } from 'src/admin/core/hooks'
import { Filter as FilterModel, IssuedTickets } from './models'
import Filters, { initialFilters } from './components/Filters'

import { AdminDataGrid, ContentDataGrid } from 'src/admin/core/components/DataGrid'
import { DataGridColumns } from 'src/admin/core/components/DataGrid/CustomDataGrid'
import { generateFilterGraphql, getEnvSettings } from 'src/admin/core'
import { GridCellParams } from '@mui/x-data-grid'
import { formatToShortAPItDate } from 'src/admin/core/services/dateFunctions'
import { getAppBarState } from 'src/store'
import { ClaimActions } from 'src/admin/core/models'
import { useIssuedTickets } from './hooks/useIssuedTickets'
import NotInterestedIcon from '@mui/icons-material/NotInterested'
import VisibilityIcon from '@mui/icons-material/Visibility'
import PaymentsIcon from '@mui/icons-material/Payments'
import { CanceledPaidAlert } from './components/CanceledPaidAlert'

const TicketVerification = () => {
  const { label } = useAppTranslation()
  const { page, pageSize, skip, handlePageChange, handlePageSizeChange } = usePagination()
  const { issuedTickets, count, loading, getIssuedTickets, error } = useIssuedTickets()
  const [currentFilters, setCurrentFilters] = useState<FilterModel>(initialFilters)
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('consults.tickets-verification', [ClaimActions.CanView])
  const [openAlert, setOpenAlert] = useState(false)
  const [code, setCode] = useState('')
  const [selectedTicket, setSelectedTicket] = useState<IssuedTickets>(null)
  const { REACT_APP_PB_POS_URL } = getEnvSettings()
  const { errorGraphNotification } = useNotification()

  const { userEnterprise } = getAppBarState().appState

  useEffect(() => {
    if (error) {
      errorGraphNotification(error)
    }
  }, [error])

  useEffect(() => {
    const customFilters = getCustomFilters(currentFilters)

    if (customFilters) {
      getIssuedTickets({
        where: customFilters,
        skip: skip,
        take: pageSize,
      })
    }
  }, [currentFilters, page, pageSize])

  const getCustomFilters = (filters: FilterModel) => {
    let customFilters = {}
    if (filters.salesTime && filters.locationId && filters.stationId && filters.productId !== '') {
      let conditionalFilter = {}
      if (filters.ticketNumber && filters.ticketNumber !== '') {
        conditionalFilter = { number: { ...generateFilterGraphql(filters.ticketNumber, 'eq') } }
      }
      if (filters.providerTicket && filters.providerTicket !== '') {
        conditionalFilter = {
          providerTicketNumbers: {
            ...generateFilterGraphql(
              {
                contains: filters.providerTicket,
              },
              'some',
            ),
          },
        }
      }
      if (filters.wagerAmountTotal) {
        conditionalFilter = { totalAmount: { ...generateFilterGraphql(filters.wagerAmountTotal, 'eq') } }
      }
      customFilters = {
        and: [
          {
            date: {
              ...generateFilterGraphql(formatToShortAPItDate(filters.salesTime), 'gt'),
            },
          },
          {
            enterpriseId: { ...generateFilterGraphql(userEnterprise.id, 'eq') },
          },
          {
            agencyId: { ...generateFilterGraphql(filters.locationId, 'eq') },
          },
          {
            workstationId: { ...generateFilterGraphql(filters.stationId, 'eq') },
          },
          {
            product: { ...generateFilterGraphql(filters.productId, 'eq') },
          },
          { ...conditionalFilter },
        ],
      }
      return customFilters
    }

    return null
  }

  const handleOpenAlert = (ticket: IssuedTickets, code: string) => {
    setOpenAlert(true)
    setCode(code)
    setSelectedTicket(ticket)
  }

  const handleCloseAlert = () => {
    setOpenAlert(false)
    setCode('')
    setSelectedTicket(null)
  }

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      HORA: { field: 'date', contentType: 'time', align: 'right', flex: 0.5 },
      AGENCIAS: { field: 'agencyName', contentType: 'string' },
      ESTACION: { field: 'workstationName', contentType: 'string' },
      RACETRACK: { field: 'raceTrack', contentType: 'smallString', flex: 0.2 },
      MONTO: { field: 'totalAmount', contentType: 'currency', flex: 0.3 },
      ESTADO: { field: 'status', contentType: 'smallString' },
      ACCION: {
        field: '',
        flex: 0.5,
        contentType: 'custom',
        renderCell: (params: GridCellParams) => {
          const ticket = params.row as IssuedTickets

          const { number, providerTicketNumbers } = ticket

          return (
            <Stack direction="row" spacing="8">
              <Tooltip title={label('CANCELAR')}>
                <IconButton onClick={() => handleOpenAlert(ticket, providerTicketNumbers[0])}>
                  <NotInterestedIcon fontSize="small" color="error" />
                </IconButton>
              </Tooltip>
              <Tooltip title={label('PAGAR')}>
                <IconButton onClick={() => handleOpenAlert(ticket, number)}>
                  <PaymentsIcon fontSize="small" color="primary" />
                </IconButton>
              </Tooltip>
            </Stack>
          )
        },
      },
    }
  }, [])

  return (
    <Page
      hasAccess={actions.canView}
      tabTitle={label('VERIFICACION_DE_TICKET_TITULO')}
      pageTitle={label(`VERIFICACION_DE_TICKET_TITULO`)}
      pageSubTitle={label('VERIFICACION_DE_TICKET_SUB_TITULO')}
    >
      <Grid item xs={12}>
        <Filters onChange={(filters: FilterModel) => setCurrentFilters(filters)} />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <Box flex={1} p={2} display={{ xs: 'block', md: 'flex' }} alignItems="center" justifyContent="space-between">
            <AdminDataGrid
              columns={getColumnDefinitions()}
              data={issuedTickets}
              count={count}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              loading={loading}
              page={page}
              pageSize={pageSize}
            />
          </Box>
        </Card>
      </Grid>
      {selectedTicket && openAlert && (
        <CanceledPaidAlert ticket={selectedTicket} handleClose={handleCloseAlert} open={openAlert} code={code} />
      )}
    </Page>
  )
}

export default TicketVerification
