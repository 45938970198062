import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const useAppTranslation = () => {
  const { t: transLabel } = useTranslation()
  const { t: transAction } = useTranslation()
  const { t: transSystem } = useTranslation()
  const { t: transMenu } = useTranslation()

  const label = (text: string) => {
    return transLabel(text)
  }

  const action = useCallback(
    (text: string) => {
      return transAction(text)
    },
    [transAction],
  )

  const system = (text: string) => {
    return transSystem(text)
  }

  const menu = (text: string) => {
    return transMenu(text)
  }

  return {
    action,
    label,
    system,
    menu,
  }
}
