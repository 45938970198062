import * as React from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'

type FiltersCardProps = {
  title?: React.ReactElement
  actions?: React.ReactElement
  contentClass?: string
  children: React.ReactElement
}

export const FiltersCard = ({ title, actions, contentClass, children }: FiltersCardProps) => {
  return (
    <Card>
      {/* card header and action */}
      {title && <CardHeader title={title} action={actions} />}

      {/* card content */}
      <CardContent className={contentClass}>{children}</CardContent>
    </Card>
  )
}
