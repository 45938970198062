import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'

import SuspenseLoader from 'src/components/SuspenseLoader'
import Callback from '../core/auth/scenes/Callback'
import Logout from '../core/auth/scenes/Logout'
import LogoutCallback from '../core/auth/scenes/LogoutCallback'
import SilentRenew from '../core/auth/scenes/SilentRenew'
import AccentHeaderLayout from '../layouts/AccentHeaderLayout'
import configurationRoutes from './configurationRoutes'
import consultsRoutes from './consultsRoutes'
import securityRoutes from './securityRoutes'
import transactionsRoutes from './transactionsRoutes'
import sandboxRoutes from './sandboxRoutes'
import Protected from '../core/Protected'
import { getApolloClient } from '../core/services'
import PasswordReset from '../scenes/security/passwordReset'
import Status404 from '../content/pages/Status/Status404'
import Status500 from '../content/pages/Status/Status500'

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  )

// Dashboard
const Dashboard = Loader(lazy(() => import('src/admin/content/dashboards/Statistics')))

const apolloClient = getApolloClient()
apolloClient.resetStore()

const adminRoutes = [
  {
    path: '/',
    element: <Navigate to="admin" replace />,
    children: [],
  },
  {
    path: 'overview',
    element: <Navigate to="/" replace />,
  },
  {
    path: 'admin',
    element: (
      <ApolloProvider client={apolloClient}>
        <Protected>
          <AccentHeaderLayout />
        </Protected>
      </ApolloProvider>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="statistics/sales-profit-dashboard" replace />,
      },
      {
        path: 'statistics/sales-profit-dashboard',
        element: <Dashboard />,
      },
      // {
      //   path: 'transactions',
      //   children: transactionsRoutes,
      // },
      {
        path: 'consults',
        children: consultsRoutes,
      },
      {
        path: 'security',
        children: securityRoutes,
      },
      {
        path: 'entities',
        children: configurationRoutes,
      },
      {
        path: 'sandbox',
        children: sandboxRoutes,
      },
    ],
  },
  {
    path: 'signin-oidc',
    element: <Callback />,
  },
  {
    path: 'logout',
    element: <Logout />,
  },
  {
    path: 'logout/callback',
    element: <LogoutCallback />,
  },
  {
    path: 'silentrenew',
    element: <SilentRenew />,
  },
  {
    path: 'passwordreset',
    element: (
      <ApolloProvider client={apolloClient}>
        <PasswordReset />
      </ApolloProvider>
    ),
  },

  {
    path: 'status',
    children: [
      // {
      //   path: '/',
      //   element: <Navigate to="404" replace />
      // },
      // {
      //   path: '',
      //   element: <Status404 />
      // },
      {
        path: '500',
        element: <Status500 />,
      },
      // {
      //   path: 'maintenance',
      //   element: <StatusMaintenance />
      // },
      // {
      //   path: 'coming-soon',
      //   element: <StatusComingSoon />
      // }
    ],
  },
  {
    path: '*',
    element: <Status404 />,
  },
]

export default adminRoutes
