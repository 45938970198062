import { gql, useMutation } from '@apollo/client'

const ADD_WORKSTATION = gql`
  mutation AddWorkstation($input: AddWorkstationInput!) {
    addWorkstation(input: $input) {
      workstation {
        id
        name
        locationId
      }
    }
  }
`

export const useAddWorkstation = () => {
  const [addWorkstation, { loading, error }] = useMutation(ADD_WORKSTATION)
  return { addWorkstation, loading, error }
}
