import { SaveButton } from 'src/admin/core'
import { useFormikContext } from 'formik'
import { Event } from '../../models'
import { useAddProgramEvent } from '../../hooks'
import { formatToShortLocalDate } from 'src/admin/core/services/dateFunctions'

export const CreateButton: React.FC<{ afterSave?: (promise: Promise<any>, action: string) => void }> = ({
  afterSave,
}) => {
  const { addProgramEvent, loading, error } = useAddProgramEvent()
  const form = useFormikContext()
  const { values, dirty, isValid, setSubmitting } = form

  const handleCreate = () => {
    setSubmitting(true)
    const formValues = values as Event

    const data: Event = {
      name: formValues.name,
      date: formatToShortLocalDate(formValues.date, 'MM/DD/YYYY'),
      performanceCode: formValues.performanceCode,
      numberOfRaces: formValues.numberOfRaces,
      active: formValues.active,
    }

    const response = addProgramEvent({
      variables: {
        input: { ...data },
      },
    })
    if (afterSave) {
      afterSave(response, 'create')
    }
    setSubmitting(false)
  }

  return <SaveButton onClick={handleCreate} isLoading={loading} disabled={!(dirty && isValid)} />
}
