import { gql, useMutation } from '@apollo/client'

const ADD_PROGRAM_RUNNER = gql`
  mutation CreateProgramRunner($input: UpsertProgramRunnerInput!) {
    upsertProgramRunner(input: $input) {
      id
      programRaceId
      number
      odds
      position
      weight
      active
      jockeyOrKennelId
      competitorId
      medication
      ownerId
      createdDate
      trainerId
      trainer {
        name
      }
    }
  }
`
export interface AddProgramRunnerInput {
  programRaceId: number
  number: string
  odds: string
  position: number
  competitorId: number
  jockeyOrKennelId: number
  trainerId: number
  ownerId: number
  weight: number
  active: boolean
}

export const useAddProgramRunner = () => {
  const [addProgramRunner, { loading, error }] = useMutation(ADD_PROGRAM_RUNNER)
  return { addProgramRunner, loading, error }
}
