import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Typography, useTheme } from '@mui/material'
import { GridCellParams, GridRowParams } from '@mui/x-data-grid'

// App Components
import { DataGridColumns } from 'src/admin/core/components/DataGrid/CustomDataGrid'
import { AdminDataGrid } from 'src/admin/core/components/DataGrid/AdminDataGrid'
import { Page } from 'src/admin/core/components'
import { CreateButton, generateFilterGraphql } from 'src/admin/core'
import { ContentDataGrid } from 'src/admin/core/components/DataGrid'

// Hooks
import { useEvents } from '../hooks'

import { useAppTranslation, usePagination, useUserClaimActions } from 'src/admin/core/hooks'
import Filters, { FiltersProps, initialFilters } from '../Filters'
import { customFormatDate } from 'src/admin/core/services/dateFunctions'
import { ClaimActions } from 'src/admin/core/models'

const Events = () => {
  const theme = useTheme()
  const { label } = useAppTranslation()
  const navigate = useNavigate()
  const { results, loading, getEvents } = useEvents()
  const { page, pageSize, skip, handlePageChange, handlePageSizeChange } = usePagination()
  const [filters, setFilters] = useState<FiltersProps>(initialFilters)
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.events', [ClaimActions.CanView])

  useEffect(() => {
    const customFilters = {
      where: {
        or: [
          {
            name: generateFilterGraphql(filters.search, 'contains'),
          },
          {
            date: generateFilterGraphql(customFormatDate(filters.date, 'YYYY-MM-DD'), 'eq'),
          },
        ],
      },
      take: pageSize,
      skip: skip,
    }
    getEvents(customFilters)
  }, [filters, skip, pageSize])

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      FECHA: { field: 'date', contentType: 'date' },
      NOMBRE: { field: 'name', contentType: 'string' },
      CARRERAS: { field: 'numberOfRaces', contentType: 'number' },
      CODIGO: {
        field: '',
        contentType: 'custom',
        renderCell: (params: GridCellParams) => {
          return (
            <Typography>
              {params.row.performanceCode} - {params.row.name}
            </Typography>
          )
        },
      },
      ESTADO: { field: 'active', contentType: 'boolean' },
    }
  }, [])

  const goToCreate = () => {
    navigate(`/admin/entities/events/create`)
  }

  const goToEdit = (row: GridRowParams) => {
    navigate(`/admin/entities/events/${row.id}`)
  }

  return (
    <Page
      hasAccess={actions.canView}
      tabTitle={label('EVENTOS_TITULO')}
      pageTitle={label('EVENTOS_TITULO')}
      pageSubTitle={label('EVENTOS_SUB_TITULO')}
      actions={<CreateButton onClick={goToCreate} />}
    >
      <Grid item xs={12}>
        <Filters onChange={(filters) => setFilters({ ...filters })} />
      </Grid>
      <Grid item xs={12}>
        <ContentDataGrid>
          <AdminDataGrid
            columns={getColumnDefinitions()}
            data={results.results}
            count={results.metadata.Count}
            onRowClick={goToEdit}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            loading={loading}
            page={page}
            pageSize={pageSize}
          />
        </ContentDataGrid>
      </Grid>
    </Page>
  )
}

export default Events
