import { GraphQLErrors } from '@apollo/client/errors'
import { useAppTranslation } from '../../hooks'

interface Error {
  code: string
}

export const GraphErrorList = ({ errors }: { errors: GraphQLErrors }) => {
  const { label } = useAppTranslation()

  return (
    <ul>
      {errors.map((e, i) => (
        <li key={i}>
          {label(e.extensions?.code as string)} {label(e?.message)}
        </li>
      ))}
    </ul>
  )
}
