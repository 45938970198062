import { SaveButton, differenceBetweenTwoObjects } from 'src/admin/core'
import { useFormikContext } from 'formik'
import { useUpsertProgramCompetitor } from '../../hooks'
import { useAppTranslation, useNotification } from 'src/admin/core/hooks'
import { ProgramCompetitor } from '../../models'

export const UpdateButton: React.FC<{
  competitor: ProgramCompetitor
  afterSave?: (promise: Promise<any>, action: string) => void
}> = ({ competitor, afterSave }) => {
  const { upsertProgramCompetitor, loading, error } = useUpsertProgramCompetitor()
  const form = useFormikContext()
  const { values, dirty, isValid, setSubmitting } = form
  const { label } = useAppTranslation()
  const { successNotification, errorNotification } = useNotification()

  const handleSave = () => {
    const formValues = values as ProgramCompetitor
    const changes = differenceBetweenTwoObjects(formValues, competitor)

    const response = upsertProgramCompetitor({
      variables: {
        input: {
          id: formValues.id,
          ...changes,
        },
      },
    })
    response
      .then(() => {
        successNotification(label('DATO_ACTUALIZADO'))
      })
      .catch(() => {
        errorNotification(label('ERROR_REGISTRANDO_EL_DATO'))
      })
    if (afterSave) {
      afterSave(response, 'update')
    }
    setSubmitting(false)
  }

  return <SaveButton onClick={handleSave} isLoading={loading} disabled={!dirty} />
}
