import { gql, useMutation } from '@apollo/client'

const PROGRAM_OWNER = gql`
  mutation UpsertProgramOwner($input: UpsertProgramOwnerInput!) {
    upsertProgramOwner(input: $input) {
      id
      name
      trainerId
      active
    }
  }
`
export interface ProgramOwnerInput {
  name: string
  trainerId: number
  active: boolean
}

export const useUpsertProgramOwner = () => {
  const [upsertProgramOwner, { loading, error }] = useMutation<ProgramOwnerInput>(PROGRAM_OWNER)
  return { upsertProgramOwner, loading, error }
}
