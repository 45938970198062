import React from 'react'

import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import { useTranslation } from 'react-i18next'
import { Role } from './models'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { TextField } from 'formik-material-ui'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import DoneIcon from '@mui/icons-material/Done'

type AddRoleProps = {
  open: boolean
  submit: (values: Role) => void
  cancel: () => void
  initialRole: Role
}

export const AddRole = ({ open, submit, cancel, initialRole }: AddRoleProps) => {
  // App Label Translation
  const { t: transLabel } = useTranslation()
  const { t: transAction } = useTranslation()

  const formSchema = Yup.object().shape({
    name: Yup.string().required(transLabel('REQUERIDO')),
  })

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      sx={{
        minWidth: 400,
      }}
    >
      <DialogTitle id="form-dialog-title">{transLabel('CREAR_ROLE')}</DialogTitle>

      <Formik validationSchema={formSchema} initialValues={{ ...initialRole }} onSubmit={submit}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <DialogContent>
              <Field component={TextField} name="name" type="text" label={transLabel('NOMBRE_ROLE')} />
            </DialogContent>
            <DialogActions>
              <Button onClick={cancel} color="inherit" startIcon={<HighlightOffIcon />} size={'small'}>
                {transAction('CANCELAR')}
              </Button>
              <Button onClick={submitForm} color="primary" startIcon={<DoneIcon />} size={'small'}>
                {transAction('GUARDAR')}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}
