import { Button } from '@mui/material'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppTranslation } from '../../hooks'

type buttonProps = {
  className?: string
  disabled?: boolean
  size?: 'small' | 'medium' | 'large' | undefined
}
export const BackButton = ({ size, ...props }: buttonProps) => {
  const { action } = useAppTranslation()
  const navigate = useNavigate()

  return (
    <Button
      {...props}
      onClick={() => navigate(-1)}
      variant="outlined"
      color="primary"
      size={size}
      startIcon={<FirstPageIcon />}
    >
      {action('REGRESAR')}
    </Button>
  )
}
