import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { generateFilterGraphql } from 'src/admin/core/services'
import { useAgencies } from 'src/admin/scenes/entities/agencies/hooks'
import { Agency } from 'src/core/models'
import { useAppTranslation } from '../../hooks'
import { getAppBarState } from 'src/store'
import { useWorkstations } from 'src/admin/scenes/entities/stations/hooks'

export type UserAgencyProps = {
  onChangeValue?: (value: Agency, action: string) => void
  getDefaultAgency?: (value: Agency) => void
  getAllAgencies?: (values: Agency[]) => void
  disableClearable?: boolean
  setDefault?: boolean
}

export const UserAgenciesSelect: React.FC<UserAgencyProps> = ({
  onChangeValue,
  getDefaultAgency,
  getAllAgencies,
  disableClearable = true,
  setDefault = false,
}) => {
  const { results, loading, getWorkstations } = useWorkstations()
  const [agencies, setAgencies] = useState<Agency[]>([])
  const [selectedAgency, setSelectedAgency] = useState<any>(null)
  const { label } = useAppTranslation()
  const { userEnterprise, user } = getAppBarState().appState
  const [localDisabled, setLocalDisabled] = useState(false)

  useEffect(() => {
    fetchAgencies()
  }, [])

  useEffect(() => {
    const workstationAgencies = []
    if (user && results.results.length > 0) {
      const addedAgencies: string[] = []
      results.results.forEach((item) => {
        if (item.accessList?.length > 0 && item.accessList.includes(user.profile.sub)) {
          if (!addedAgencies.includes(item.agency.id)) {
            workstationAgencies.push(item.agency)
            addedAgencies.push(item.agency.id)
          }
        }
      })
      setAgencies(workstationAgencies)
    }
  }, [results.results, user])

  useEffect(() => {
    if (agencies.length > 0) {
      if (getDefaultAgency) {
        const agency = {
          id: agencies[0].id,
          name: agencies[0].name,
          active: agencies[0].active,
        }
        setSelectedAgency(agency)
        getDefaultAgency(agency)
      }

      if (setDefault && agencies.length === 1) {
        const agency = {
          id: agencies[0].id,
          name: agencies[0].name,
          active: agencies[0].active,
        }
        setSelectedAgency(agency)
        setLocalDisabled(true)
      }

      if (getAllAgencies instanceof Function) {
        getAllAgencies(agencies)
      }
    }
  }, [agencies])

  const fetchAgencies = (agencyName = '') => {
    const customFilters = {
      or: [{ name: { ...generateFilterGraphql(agencyName, 'contains') } }],
      enterpriseId: { ...generateFilterGraphql(userEnterprise.id, 'eq') },
    }

    getWorkstations({ where: customFilters, skip: 0, take: 100 })
  }

  const handleChange = (event: any, values: Agency, reason: string) => {
    setSelectedAgency(values)
    if (onChangeValue) {
      onChangeValue(values, reason)
    }
  }

  return (
    <Autocomplete
      sx={{
        m: 0,
        minWidth: 'inherit',
      }}
      onChange={handleChange}
      value={selectedAgency}
      getOptionLabel={(option) => option['name']}
      options={agencies}
      loading={loading}
      disableClearable={disableClearable}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label('AGENCIAS')}
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={label('SELECCIONAR_AGENCIA')}
        />
      )}
      disabled={localDisabled}
    />
  )
}
