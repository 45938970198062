import { Box, Card, CardContent, Divider, Grid, Stack, Typography } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { DatePicker } from 'formik-mui-lab'
import { useContext, useEffect } from 'react'
import { BooleanRadioButton } from 'src/admin/core/components/RadioButtons/BooleanRadioButton'
import { CustomAutoCompleteSelectFormik } from 'src/admin/core/components/Select/CustomAutoCompleteSelectFormik'
import { useAppTranslation } from 'src/admin/core/hooks'
import { EventContext, setEventFormMode } from '../../state'
import { CreateButton } from './CreateButton'
import { DownloadButton } from './DownloadButton'
import { GenerateFileButton } from './GenerateFileButton'
import { ModifyButton } from './ModifyButton'
import { UpdateButton } from './UpdateButton'
import { useGetProgramOptionsByEntity } from 'src/admin/core/stores/programSelectOptions'
import { Event } from '../../models'

export const Form: React.FC<{ initialValues: Event; afterSave?: (promise: Promise<any>) => void }> = ({
  afterSave,
  initialValues,
}) => {
  const { label } = useAppTranslation()
  const { getPerformaceCodeOptions } = useGetProgramOptionsByEntity()
  const {
    dispatch,
    state: { eventFormMode, event, races },
  } = useContext(EventContext)

  useEffect(() => {
    if (!event.id) {
      dispatch(setEventFormMode('CREATE'))
    }
  }, [event])

  const isDisabled = eventFormMode == 'VIEW'

  return (
    <Card>
      <Box p={2.5} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography gutterBottom variant="h4">
            {label('EVENTO_TITULO')}
          </Typography>
          <Typography variant="subtitle2">{label('EVENTO_SUB_TITULO')}</Typography>
        </Box>
        <Stack direction="row" spacing={1}>
          {initialValues.id && initialValues.numberOfRaces == races.length ? (
            <>
              <DownloadButton />
              <GenerateFileButton />
            </>
          ) : null}
          {eventFormMode === 'VIEW' ? (
            <ModifyButton />
          ) : (
            <>
              {initialValues.id ? (
                <Box>
                  <UpdateButton afterSave={afterSave} />
                </Box>
              ) : (
                <Box>
                  <CreateButton afterSave={afterSave} />
                </Box>
              )}
            </>
          )}
        </Stack>
      </Box>
      <Divider />
      <CardContent>
        <Grid container spacing={3} pb={3}>
          <Grid item lg={3} md={3} xs={12} sm={12}>
            <Field
              fullWidth
              component={TextField}
              type="text"
              label={label('NOMBRE')}
              name="name"
              disabled={isDisabled}
            />
          </Grid>

          {/* <Grid item lg={3} md={3} xs={12} sm={12}>
            <Field
              fullWidth
              component={CustomAutoCompleteSelectFormik}
              type="text"
              label={label('CODIGO')}
              name="performanceCode"
              data={getPerformaceCodeOptions()}
              keyLabel="name"
              keyValue="code"
              disabled={isDisabled}
            />
          </Grid> */}

          <Grid item lg={2} md={2} xs={12} sm={12}>
            <Field
              fullWidth
              component={DatePicker}
              type="text"
              label={label('FECHA')}
              name="date"
              inputFormat={'dd/MM/yyyy'}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item lg={2} md={2} xs={12} sm={12}>
            <Field
              fullWidth
              component={TextField}
              type="number"
              label={label('CARRERAS')}
              name="numberOfRaces"
              InputProps={{ inputProps: { min: 1, max: 99 } }}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item lg={2} md={2} sm={12} xs={12}>
            <Field
              component={BooleanRadioButton}
              title={'ESTADO'}
              name="active"
              label1={'ACTIVO'}
              label2={'INACTIVO'}
              value1={'active'}
              value2={'inactive'}
              disabled={isDisabled}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
