import React, { createContext, Dispatch } from 'react'
import { EventState, initialState } from './reducer'
import { EventActions } from './actions'

export const EventContext = createContext<{
  state: EventState
  dispatch: Dispatch<EventActions>
}>({
  state: initialState,
  dispatch: () => {},
})
