import { SaveButton } from 'src/admin/core'
import { useFormikContext } from 'formik'
import { useAddProgramTrainer } from '../../hooks'
import { useAppTranslation, useNotification } from 'src/admin/core/hooks'
import { ProgramTrainer } from '../../models'

export const CreateButton: React.FC<{
  afterSave?: (promise: Promise<any>, action: string) => void
}> = ({ afterSave }) => {
  const { addProgramTrainer, loading } = useAddProgramTrainer()
  const { successNotification, errorNotification } = useNotification()
  const { label } = useAppTranslation()

  const form = useFormikContext()
  const { values, dirty, isValid, setSubmitting } = form

  const handleCreate = () => {
    const formValues = values as ProgramTrainer
    delete formValues.id

    const response = addProgramTrainer({
      variables: {
        input: { ...formValues },
      },
    })
    response
      .then(() => {
        successNotification(label('DATO_REGISTRADO'))
      })
      .catch(() => {
        errorNotification(label('ERROR_REGISTRANDO_EL_DATO'))
      })
    setSubmitting(false)
    if (afterSave) {
      afterSave(response, 'create')
    }
    setSubmitting(false)
  }

  return <SaveButton onClick={handleCreate} isLoading={loading} disabled={!(dirty && isValid)} />
}
