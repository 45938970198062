import { SaveButton, generateFilterGraphql } from 'src/admin/core'
import { useFormikContext } from 'formik'
import { useAppTranslation, useNotification } from 'src/admin/core/hooks'
import { ProgramMedication, Runner } from '../../models'
import { EventContext, setRunnerFormMode } from '../../state'
import { useContext, useEffect } from 'react'
import { useRunners, useUpdateProgramRunner } from '../../hooks'

export const UpdateButton: React.FC<{
  disabled: boolean
  runners: Runner[]
  medications: ProgramMedication[]
  afterSave?: (promise: Promise<any>) => void
}> = ({ runners, afterSave, disabled, medications }) => {
  const form = useFormikContext()
  const { values, dirty, isValid, setSubmitting } = form
  const { errorNotification } = useNotification()
  const { system } = useAppTranslation()
  const { getRunners, results, fetched } = useRunners()

  const {
    dispatch,
    state: { runner, race, event },
  } = useContext(EventContext)
  const { updateProgramRunner, loading } = useUpdateProgramRunner()

  useEffect(() => {
    if (fetched) {
      if (results.results.length > 0) {
        errorNotification(system('ERROR_COMPETIDOR_EN_USO'))
      } else {
        handleUpdate()
      }
    }
  }, [fetched, results])

  const triggerValidator = () => {
    const runnerToSave = values as Runner
    const customFilters = {
      and: [
        {
          programEventId: { ...generateFilterGraphql(event.id, 'eq') },
          competitorId: { ...generateFilterGraphql(runnerToSave.competitorId, 'eq') },
          id: { ...generateFilterGraphql(runner.id, 'neq') },
        },
      ],
    }
    getRunners({ where: customFilters, skip: 0, take: 100 })
  }

  const handleUpdate = () => {
    setSubmitting(true)
    const runnerToSave = values as any
    runnerToSave.id = runner.id

    if (race && race.id && isRunnerValid(runnerToSave)) {
      runnerToSave.programRaceId = race.id
      if (runnerToSave.number > race.runners) {
        errorNotification(system('ERROR_NUMERO_CORREDOR_MAYOR_A_NUMERO_CARRERAS'))
      } else {
        const medicationCode = getMedicationCode(runnerToSave.medication)

        const response = updateProgramRunner({
          variables: {
            input: {
              id: runner.id,
              number: String(runnerToSave.number),
              odds: runnerToSave.odds,
              position: Number.parseInt(runnerToSave.number),
              competitorId:
                typeof runnerToSave.competitorId === 'object'
                  ? runnerToSave.competitorId?.id
                  : runnerToSave.competitorId,
              jockeyOrKennelId:
                typeof runnerToSave.jockeyOrKennelId === 'object'
                  ? runnerToSave.jockeyOrKennelId?.id
                  : runnerToSave.jockeyOrKennelId,
              trainerId: runnerToSave.trainerId,
              ownerId: typeof runnerToSave.ownerId === 'object' ? runnerToSave.ownerId?.id : runnerToSave.ownerId,
              weight: runnerToSave.weight,
              medication: medicationCode,
              active: runnerToSave.active,
            },
          },
        })

        dispatch(setRunnerFormMode('CANCEL'))
        afterSave(response)
      }
    }
  }

  const getMedicationCode = (value: string): string => {
    const values: string[] = Array.isArray(value) ? value : value.split(',')
    const ids: string[] = []
    if (medications.length > 0 && values.length > 0) {
      values.forEach((val: string) => {
        const medication = medications.find((item: ProgramMedication) => {
          return item.name == val
        })
        if (medication !== undefined) {
          ids.push(medication.code)
        }
      })
    }
    if (ids.length > 0) {
      return ids.join('')
    }
    return ''
  }

  const isRunnerValid = (runnerToValidate: Runner): boolean => {
    let isValid = true
    runners.forEach((item: Runner) => {
      if (runnerToValidate.id !== item.id) {
        if (runnerToValidate.position === item.position) {
          errorNotification(system('ERROR_POSICION_EN_USO_POR_OTRA_CARRERA'))
          isValid = false
        }
        if (runnerToValidate.jockeyOrKennelId === item.jockeyOrKennelId) {
          errorNotification(system('ERROR_JINETE_EN_USO'))
          isValid = false
        }
      }
    })
    return isValid
  }

  return <SaveButton onClick={triggerValidator} isLoading={loading} disabled={!(dirty && isValid) || disabled} />
}
