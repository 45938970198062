import React, { useCallback, useEffect, useState } from 'react'
import { Box, Card, Grid } from '@mui/material'
import { CurrencySelect } from '../../currencies/components/CurrencySelect'
import { Currency } from '../../currencies/models/Currency'
import { DateDayControl } from 'src/admin/core/components/date/DateDayControl'
import { Agency, WorkstationProductsEnum } from 'src/core/models'
import { AgencySelect, UserAgenciesSelect, WorkstationProductSelect } from 'src/admin/core'
import { useLotsalcomFileDownloadUri } from '../hooks'
import { useAppTranslation } from 'src/admin/core/hooks'
import { saveAs } from 'file-saver'
import { DownloadButton } from 'src/admin/core'
import {
  dateIsBeforeThanTodayMoment,
  formatJSDateToLocal,
  isFutureDate,
  isSameDate,
} from 'src/admin/core/services/dateFunctions'

export interface FilterModel {
  product: string
  locationId: string | undefined
  currencyId: string
  startDate: string
  endDate: string
  agencies: Agency[]
}

export const today = new Date()

export const initialFilters = {
  product: '',
  currencyId: 'DOP',
  locationId: undefined,
  startDate: formatJSDateToLocal(today),
  endDate: formatJSDateToLocal(today),
  agencies: [],
}

export const Filters: React.FC<{ disabledDownloadFile: boolean; onChange: (filters: FilterModel) => void }> = ({
  onChange,
  disabledDownloadFile,
}) => {
  const [filters, setFilters] = useState<FilterModel>(initialFilters)
  const { getLotsalcomFileDownloadUri, file, error } = useLotsalcomFileDownloadUri()
  const [downloading, setDownloading] = useState(true)
  const [readyToDownload, setReadyToDownload] = useState(true)

  const { label } = useAppTranslation()
  const startDate = filters.startDate

  useEffect(() => {
    if (filters.agencies.length > 0 || filters.locationId) {
      onChange(filters)
    }
  }, [filters])

  useEffect(() => {
    if (file && startDate && readyToDownload) {
      saveAs(file.sasUri, file.fileName)
    }
  }, [file, startDate, readyToDownload])

  const handleDayChanges = (date: Date) => {
    setReadyToDownload(false)
    setFilters({
      ...filters,
      startDate: formatJSDateToLocal(date),
      endDate: formatJSDateToLocal(date),
    })
  }

  const handleDownlaod = useCallback((date: string) => {
    getLotsalcomFileDownloadUri(date)
    setReadyToDownload(true)
  }, [])

  return (
    <Card
      sx={{
        p: 1,
      }}
    >
      <Box p={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={2} textAlign="left">
            <DateDayControl onChange={handleDayChanges} showButtons={false} />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <UserAgenciesSelect
              setDefault={true}
              disableClearable={false}
              onChangeValue={(value: Agency) =>
                setFilters({
                  ...filters,
                  locationId: value?.id,
                })
              }
              getAllAgencies={(values) =>
                setFilters({
                  ...filters,
                  agencies: values,
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <CurrencySelect
              initialValue={''}
              onChangeValue={(value: Currency) =>
                setFilters({
                  ...filters,
                  currencyId: value?.grupal,
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <WorkstationProductSelect
              value={filters.product ? (filters.product as WorkstationProductsEnum) : undefined}
              onChange={(val) => {
                setFilters({
                  ...filters,
                  product: val,
                })
              }}
              getDefaultValue={(val) => {
                setFilters({
                  ...filters,
                  product: val,
                })
              }}
            />
          </Grid>
          <Grid item md={4} lg={4} textAlign={'end'}>
            <DownloadButton
              onClick={() => handleDownlaod(filters.startDate)}
              title={label('LOTSALCOM')}
              disabled={isFutureDate(filters.startDate) || isSameDate(filters.startDate) || disabledDownloadFile}
            />
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}
