import {
  Box,
  Card,
  Typography,
  Container,
  Divider,
  Button,
  FormControl,
  OutlinedInput,
  InputAdornment,
  styled,
} from '@mui/material'
import { Helmet } from 'react-helmet-async'
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone'

import { useTranslation } from 'react-i18next'

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`,
)

const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }) => `
    background-color: ${theme.colors.alpha.white[100]};
`,
)

const ButtonSearch = styled(Button)(
  ({ theme }) => `
    margin-right: -${theme.spacing(1)};
`,
)

function Status401() {
  const { t }: { t: any } = useTranslation()

  return (
    <>
      <Helmet>
        <title>Status - 401</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="md">
          <Box textAlign="center">
            <img alt="404" height={180} src="/static/images/status/401.svg" />
            <Typography
              variant="h2"
              sx={{
                my: 2,
              }}
            >
              {t('NO_TIENES_ACCESO_PARA_VER_ESTA_PAGINA')}
            </Typography>
            <Typography
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
              sx={{
                mb: 4,
              }}
            >
              {t('NO_TIENES_ACCESO_PARA_VER_ESTA_PAGINA_DESCRIPCION')}
            </Typography>
          </Box>
          <Container maxWidth="sm">
            <Card
              sx={{
                textAlign: 'center',
                mt: 3,
                p: 4,
              }}
            >
              <Button href="/" variant="outlined">
                {t('IR_PAGINA_INICIO')}
              </Button>
            </Card>
          </Container>
        </Container>
      </MainContent>
    </>
  )
}

export default Status401
