import * as Yup from 'yup'
import { useAppTranslation } from 'src/admin/core/hooks'
import { getRegretToNotAllowSpecialsCharacters } from 'src/admin/core'
import { useContext, useEffect, useState } from 'react'
import { EventContext } from '../../state'
import { Runner } from '../../models'
import _ from 'lodash'
import { useEventFormHandler } from '../event'

const initialValues = {
  id: 0,
  programRaceId: 0,
  number: '',
  odds: '',
  name: '',
  weight: 90,
  competitorId: 0,
  jockeyOrKennelId: 0,
  trainerId: 0,
  ownerId: 0,
  medication: '',
  jockeyOrKennel: '',
  trainerName: '',
  ownerName: '',
  active: false,
}

export const useRunnerFormHandler = () => {
  const { label } = useAppTranslation()
  const [customRunner, setCustomRunner] = useState<Runner>()
  // const { getMedicationOptions } = useEventFormHandler()

  // const {
  //   state: { runner },
  // } = useContext(EventContext)

  // useEffect(() => {
  //   if (runner) {
  //     const runnerClone = _.cloneDeep(runner)

  //     // Medication
  //     const medication = medicationConvertFromCodeToName(runnerClone.medication)
  //     runnerClone.medication = medication.join(',')

  //     runnerClone.competitorId = {
  //       id: runner.competitorId,
  //       name: runner.competitorName,
  //     }

  //     // Jockey
  //     runnerClone.jockeyOrKennelId = {
  //       id: runner.jockeyOrKennelId,
  //       name: runner.jockeyOrKennelName,
  //     }

  //     // Owner
  //     runnerClone.ownerId = {
  //       id: runner.ownerId,
  //       name: runner.ownerName,
  //     }

  //     setCustomRunner(runnerClone)
  //   }
  // }, [runner])

  // const medicationConvertFromCodeToName = (codes: String): string[] => {
  //   const medicationOptions = getMedicationOptions()
  //   const medicationValues: string[] = []
  //   const medicationsCodes = codes.split('')
  //   medicationOptions.forEach((item) => {
  //     if (medicationsCodes.includes(item.code)) {
  //       console.log(item)
  //       medicationValues.push(`${item.name},`)
  //     }
  //   })
  //   return medicationValues
  // }

  const formSchema = Yup.object().shape({
    number: Yup.number()
      .min(1, label('SOLO_VALORES_ENTRE') + ' 1-999')
      .max(999, label('SOLO_VALORES_ENTRE') + ' 1-999')
      .required(label('REQUERIDO')),
    odds: Yup.string().max(8, '(8) ' + label('MAXIMO_CARACTERES')),
    // position: Yup.number()
    //   .min(1, label('SOLO_VALORES_ENTRE') + ' 1-99')
    //   .max(99, label('SOLO_VALORES_ENTRE') + ' 1-99')
    //   .required(label('REQUERIDO')),
    // competitorId: Yup.object()
    //   .nullable()
    //   .required(label('REQUERIDO'))
    //   .test('test_competitorId', '', function (competitor) {
    //     console.log(competitor)
    //     return competitor ? true : this.createError({ message: label('REQUERIDO') })
    //   }),
    weight: Yup.number()
      .min(90, label('SOLO_VALORES_ENTRE') + ' 90-140')
      .max(140, label('SOLO_VALORES_ENTRE') + ' 90-140')
      .required(label('REQUERIDO')),
    // medication: Yup.string()
    //   .required(label('REQUERIDO'))
    //   .test('test_medication_length', '', function (medications) {
    //     return medications && medications.split(',').length > 3
    //       ? this.createError({ message: label('MAX_MEDICAMENTOS') })
    //       : true
    //   }),
  })

  return {
    formSchema,
    initialValues: customRunner || initialValues,
  }
}
