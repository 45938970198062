import { Role, RoleClaims } from './models'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../core/models'
import { useState, useEffect, useCallback } from 'react'

const GET_ROLES_CLAIMS = gql`
  query GetRolesClaims($role: String!) {
    roleClaimsMatrix(role: $role) {
      allowedClaimValues {
        key
        value
      }
      roleScopeClaims {
        displayName
        scope
        claims {
          claimType
          displayName
          values
          allowed {
            key
          }
        }
      }
    }
  }
`

export interface RoleClaimsData {
  roleClaimsMatrix: RoleClaims
}

export const useRoleClaims = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<RoleClaimsData>(GET_ROLES_CLAIMS, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<RoleClaims>({ allowedClaimValues: undefined, roleScopeClaims: undefined })

  const getRoleClaims = useCallback(
    (role: string) => {
      getResults({ variables: { role } })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      allowedClaimValues: data?.roleClaimsMatrix.allowedClaimValues || undefined,
      roleScopeClaims: data?.roleClaimsMatrix.roleScopeClaims || undefined,
    })
  }, [data?.roleClaimsMatrix, loading])

  return { loading, error, results, getRoleClaims }
}

const GET_ROLES = gql`
  query GetRoles($where: RoleFilterInput, $skip: Int, $take: Int) {
    roles(where: $where, skip: $skip, take: $take) {
      items {
        id
        name
        normalized
      }
    }
  }
`

export interface RoleData {
  roles: {
    items: Role[]
  }
}

export const useRole = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<RoleData>(GET_ROLES, { fetchPolicy: 'network-only' })

  const [results, setResults] = useState<ResultsList<Role, Metadata>>({
    results: [],
    metadata: {},
  })

  const getRoles = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({ variables: { where, take, skip } })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.roles.items || [],
      metadata: {},
    })
  }, [data?.roles.items, loading])

  return { loading, error, results, getRoles }
}

export interface CreateRoleInput {
  name: string
}

const CREATE_ROLE = gql`
  mutation CreateRole($name: String!) {
    createRole(name: $name) {
      succeeded
    }
  }
`

export const useCreateRole = () => {
  const [createRole, { loading, error }] = useMutation(CREATE_ROLE)
  return { createRole, loading, error }
}

export interface RenameRoleInput {
  from: string
  to: string
}

const RENAME_ROLE = gql`
  mutation RenameRole($renameRole: RenameRoleInput!) {
    renameRole(input: $renameRole) {
      succeeded
    }
  }
`

export const useRenameRole = () => {
  const [renameRole, { loading, error }] = useMutation(RENAME_ROLE)
  return { renameRole, loading, error }
}

export interface AddRoleClaimInput {
  roleName: string
  claimValue: string
  claimType: string
}

const ADD_ROLE_CLAIM = gql`
  mutation AddRoleClaim($addRoleClaim: AddRoleClaimInput!) {
    addRoleClaim(input: $addRoleClaim) {
      succeeded
    }
  }
`

export const useAddRoleClaim = () => {
  const [addRoleClaim, { loading, error }] = useMutation(ADD_ROLE_CLAIM)
  return { addRoleClaim, loading, error }
}

export interface RemoveRoleClaimInput {
  roleName: string
  claimValue: string
  claimType: string
}

const REMOVE_ROLE_CLAIM = gql`
  mutation RemoveRoleClaim($removeRoleClaim: RemoveRoleClaimInput!) {
    removeRoleClaim(input: $removeRoleClaim) {
      succeeded
    }
  }
`

export const useRemoveRoleClaim = () => {
  const [removeRoleClaim, { loading, error }] = useMutation(REMOVE_ROLE_CLAIM)
  return { removeRoleClaim, loading, error }
}
