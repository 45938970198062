import { useCallback, useEffect, useState, useRef } from 'react'
import { Box, Grid, styled } from '@mui/material'

import { useReactToPrint } from 'react-to-print'

// App Components
import { DataGridColumns } from 'src/admin/core/components/DataGrid/CustomDataGrid'
import { AdminDataGrid } from 'src/admin/core/components/DataGrid/AdminDataGrid'
import { Page } from 'src/admin/core/components'
import { generateFilterGraphql } from 'src/admin/core'
import { ContentDataGrid } from 'src/admin/core/components/DataGrid'
import { PrintButton } from 'src/admin/core/components'

// Hooks
import { useAgencyTicketTransactionsSummary } from './hooks'

import { useAppTranslation, usePagination, useUserClaimActions } from 'src/admin/core/hooks'
import { Filters, FilterModel, initialFilters } from './components/Filters'
import { ClaimActions } from 'src/admin/core/models'
import { monday, sunday } from 'src/admin/core/components/date/DateWeekControl'
import { AgencyWeeklySales as AgencyWeeklySalesModel } from './models/AgencyWeeklySales'
import _ from 'lodash'
import { PrintReport } from './components/PrintReport'
import { getDayNumber } from 'src/admin/core/services/dateFunctions'

const pageStyle = `
  @page { size: legal landscape; }

`

const PrintWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
}))

export type ConsolidatedData = {
  id: number
  monday: number
  tuesday: number
  wednesday: number
  thursday: number
  friday: number
  saturday: number
  sunday: number
  agencyName: string
  amount: number
  commission: number
}

export type WeekRange = {
  monday: string
  sunday: string
}

export type columnNames = {
  LUN: string
  MAR: string
  MIE: string
  JUE: string
  VIE: string
  SAB: string
  DOM: string
}

const AgencyWeeklySales = () => {
  const { label } = useAppTranslation()
  const { results, loading, getAgencyTicketTransactionsSummary } = useAgencyTicketTransactionsSummary()
  const [currentFilters, setCurrentFilters] = useState<FilterModel>(initialFilters)
  const { page, pageSize, skip, handlePageChange, handlePageSizeChange } = usePagination()
  const [week, setWeek] = useState<WeekRange>({ monday: monday, sunday: sunday })
  const [consolidatedData, setConsolidatedData] = useState<ConsolidatedData[]>([])
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('consults.weekly-balances', [ClaimActions.CanView, ClaimActions.CanPrint])
  const [startPrinting, setStartPrinting] = useState(false)
  const componentRef = useRef<any>(null)
  const [colNames, setColNames] = useState<columnNames>({
    LUN: 'Lunes',
    MAR: 'Martes',
    MIE: 'Miercoles',
    JUE: 'Jueves',
    VIE: 'Viernes',
    SAB: 'Sabado',
    DOM: 'Domingo',
  })
  useEffect(() => {
    setColNames(completeWeek(week))
  }, [week])

  useEffect(() => {
    const customFilters = getCustomFilters(currentFilters)

    getAgencyTicketTransactionsSummary(customFilters)
  }, [currentFilters, page, pageSize])

  useEffect(() => {
    const data = results.results
    if (data.length > 0) {
      const agencyGroup = groupByAgencyName(data)
      const consolidated = []

      Object.keys(agencyGroup).forEach((key, index) => {
        const agency = agencyGroup[key]

        try {
          const groupedData = groupAndOrder(agency)
          const mondayAmount = groupedData['Monday'] ? calculateAmount(groupedData['Monday']) : 0
          const tuesdayAmount = groupedData['Tuesday'] ? calculateAmount(groupedData['Tuesday']) : 0
          const wednesdayAmount = groupedData['Wednesday'] ? calculateAmount(groupedData['Wednesday']) : 0
          const thursdayAmount = groupedData['Thursday'] ? calculateAmount(groupedData['Thursday']) : 0
          const fridayAmount = groupedData['Friday'] ? calculateAmount(groupedData['Friday']) : 0
          const saturdayAmount = groupedData['Saturday'] ? calculateAmount(groupedData['Saturday']) : 0
          const sundayAmount = groupedData['Sunday'] ? calculateAmount(groupedData['Sunday']) : 0

          const amount =
            mondayAmount +
            tuesdayAmount +
            wednesdayAmount +
            thursdayAmount +
            fridayAmount +
            saturdayAmount +
            sundayAmount

          const mondayCommission = groupedData['Monday'] ? calculateCommission(groupedData['Monday']) : 0
          const tuesdayCommission = groupedData['Tuesday'] ? calculateCommission(groupedData['Tuesday']) : 0
          const wednesdayCommission = groupedData['Wednesday'] ? calculateCommission(groupedData['Wednesday']) : 0
          const thursdayCommission = groupedData['Thursday'] ? calculateCommission(groupedData['Thursday']) : 0
          const fridayCommission = groupedData['Friday'] ? calculateCommission(groupedData['Friday']) : 0
          const saturdayCommission = groupedData['Saturday'] ? calculateCommission(groupedData['Saturday']) : 0
          const sundayCommission = groupedData['Sunday'] ? calculateCommission(groupedData['Sunday']) : 0

          const commission =
            mondayCommission +
            tuesdayCommission +
            wednesdayCommission +
            thursdayCommission +
            fridayCommission +
            saturdayCommission +
            sundayCommission

          consolidated.push({
            id: index,
            agencyName: key,
            monday: mondayAmount,
            tuesday: tuesdayAmount,
            wednesday: wednesdayAmount,
            thursday: thursdayAmount,
            friday: fridayAmount,
            saturday: saturdayAmount,
            sunday: sundayAmount,
            amount: amount,
            commission: commission,
          })
        } catch (e) {
          setConsolidatedData([])
        }
      })

      setConsolidatedData(consolidated)
    } else {
      setConsolidatedData([])
    }
  }, [results.results])

  const getCustomFilters = (filters: FilterModel) => {
    let customFilters = []

    if (filters.locationId) {
      return {
        product: filters.product,
        currency: filters.currencyId,
        from: filters.startDate,
        to: filters.endDate,
        where: {
          key: {
            id: { ...generateFilterGraphql(filters.locationId, 'eq') },
          },
        },
      }
    } else {
      const agencyFilters = []
      filters.agencies.forEach((agency) => {
        agencyFilters.push({
          key: {
            id: { ...generateFilterGraphql(agency.id, 'eq') },
          },
        })
      })

      return {
        product: filters.product,
        currency: filters.currencyId,
        from: filters.startDate,
        to: filters.endDate,
        where: [],
      }
    }
  }

  const getColumnDefinitions = useCallback((dayName: columnNames): DataGridColumns => {
    return {
      AGENCIAS: { field: 'agencyName', contentType: 'string' },
      [dayName.LUN]: {
        field: 'monday',
        contentType: 'currency_with_color',
        headerAlign: 'right',
        align: 'right',
        noTranslateHeader: true,
      },
      [dayName.MAR]: { field: 'tuesday', contentType: 'currency_with_color', noTranslateHeader: true },
      [dayName.MIE]: { field: 'wednesday', contentType: 'currency_with_color', noTranslateHeader: true },
      [dayName.JUE]: { field: 'thursday', contentType: 'currency_with_color', noTranslateHeader: true },
      [dayName.VIE]: { field: 'friday', contentType: 'currency_with_color', noTranslateHeader: true },
      [dayName.SAB]: { field: 'saturday', contentType: 'currency_with_color', noTranslateHeader: true },
      [dayName.DOM]: { field: 'sunday', contentType: 'currency_with_color', noTranslateHeader: true },
      TOTAL_SEMANA: { field: 'amount', contentType: 'currency_with_color' },
      COMMISSION: { field: 'commission', contentType: 'currency_with_color' },
    }
  }, [])

  const handlePrint = useCallback(() => {
    setStartPrinting(true)
    setTimeout(() => {
      printReport()
    }, 1000)
  }, [])

  const printReport = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
    onAfterPrint: () => {
      window.focus()
    },
    onBeforePrint: () => {
      setStartPrinting(false)
    },
  })

  const completeWeek = (originalObject: WeekRange): columnNames => {
    const daysOfWeek = ['LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB', 'DOM']
    const startDate = new Date(originalObject.monday)
    const result = {}

    for (let i = 0; i < daysOfWeek.length; i++) {
      const dayOfWeek = daysOfWeek[i]
      const currentDate = new Date(startDate.getTime() + (i + 1) * 24 * 60 * 60 * 1000)

      const abbreviatedMonth = currentDate.toLocaleString('es-ES', { month: 'short' })
      const capitalizedMonth = abbreviatedMonth.charAt(0).toUpperCase() + abbreviatedMonth.slice(1)
      const formattedDate = `${capitalizedMonth} ${currentDate.getDate()} ${label(dayOfWeek)}`
      result[dayOfWeek] = formattedDate
    }

    return result as columnNames
  }

  return (
    <Page
      hasAccess={actions.canView}
      tabTitle={label('BALANCE_SEMANAL_TITULO')}
      pageTitle={label('BALANCE_SEMANAL_TITULO')}
      pageSubTitle={<DateLabel start={week.monday} end={week.sunday} />}
      actions={actions.canPrint ? <PrintButton onClick={handlePrint} disabled={consolidatedData.length === 0} /> : null}
    >
      <Grid item xs={12}>
        <Filters
          onChange={(filters: FilterModel) => {
            setCurrentFilters(filters)
            setWeek({
              monday: filters.startDate,
              sunday: filters.endDate,
            })
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <ContentDataGrid pageSize={pageSize}>
          <AdminDataGrid
            columns={getColumnDefinitions(colNames)}
            data={consolidatedData}
            loading={loading}
            hideFooterPagination={true}
          />
        </ContentDataGrid>
        {startPrinting && (
          <PrintWrapper ref={componentRef}>
            <PrintReport data={consolidatedData} dateRange={week} />
          </PrintWrapper>
        )}
      </Grid>
    </Page>
  )
}

const groupAndOrder = (data: any) => {
  const groupedData = data.reduce((acc, curr) => {
    const dayOfWeek = new Date(curr.key.date).toLocaleDateString('en-US', { weekday: 'long' })
    if (!acc[dayOfWeek]) {
      acc[dayOfWeek] = []
    }
    acc[dayOfWeek].push(curr)
    return acc
  }, {})

  const sortedKeys = Object.keys(groupedData).sort()

  const result = sortedKeys.reduce((acc, curr) => {
    acc[curr] = groupedData[curr]
    return acc
  }, {})

  return result
}

const groupByAgencyName = (data: any) => {
  const groupedByAgencyName = data.reduce((result, item) => {
    const agencyName = item.key.agency.name
    if (!result[agencyName]) {
      result[agencyName] = []
    }
    result[agencyName].push(item)
    return result
  }, {})

  return groupedByAgencyName
}

const DateLabel = ({ start, end }: { start: string; end: string }) => {
  return (
    <span>
      Semana del <strong>{start}</strong> al <strong>{end}</strong>
    </span>
  )
}

const calculateAmount = (data: AgencyWeeklySalesModel[]) => {
  let totalAmount = 0
  let totalSurchargeAdd = 0
  let totalSurchargeSub = 0

  data.forEach((item) => {
    const { key, amount, surcharge } = item
    const transactionType = key.transactionType
    const category = key.category

    if (category === 'DEBIT') {
      totalAmount = totalAmount + amount
    } else if (category === 'CREDIT') {
      totalAmount = totalAmount - amount
    }

    if (transactionType === 'SALE') {
      totalSurchargeAdd += surcharge
      // totalAmount = totalAmount + amount + surcharge
    }
    if (transactionType === 'CANCELLATION') {
      totalSurchargeSub += surcharge
      // totalAmount = totalAmount + amount - surcharge
    }
  })
  return totalAmount + totalSurchargeAdd - totalSurchargeSub
}

const calculateCommission = (data: AgencyWeeklySalesModel[]) => {
  let totalCommission = 0

  data.forEach((item) => {
    const { key, commission } = item
    const transactionType = key.transactionType

    if (transactionType === 'SALE') {
      totalCommission = totalCommission + commission
    }
    if (transactionType === 'CANCELLATION') {
      totalCommission = totalCommission - commission
    }
  })
  return totalCommission
}

export default AgencyWeeklySales
