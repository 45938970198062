import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { ProgramOwner } from '../../models'

const GET_OWNER = gql`
  query GetProgramOwner($id: String!) {
    programOwner(id: $id) {
      id
      name
      trainerId
      active
      programTrainer {
        id
        name
      }
    }
  }
`

interface OwnerData {
  programOwner: ProgramOwner
}

export const useProgramOwner = () => {
  const [get, { loading, error, data }] = useLazyQuery<OwnerData>(GET_OWNER, {
    fetchPolicy: 'network-only',
  })

  const getProgramOwner = (id: string) => {
    get({
      variables: {
        id: id,
      },
    })
  }

  const owner = data?.programOwner || undefined
  return { getProgramOwner, loading, error, owner }
}
