import { useCallback, useEffect, useState } from 'react'
import { generateFilterGraphql } from 'src/admin/core'
import { DefaultProgramRaceWagers, Event, Wager } from '../../models'
import { useProgramDefaultWagers } from '../event/useProgramDefaultWagers'

export const useRaceWagers = () => {
  const { results, getProgramDefaultWagers } = useProgramDefaultWagers()
  const programWagers: DefaultProgramRaceWagers[] = results.results

  const [selectedWagers, setSelectedWagers] = useState<Array<string>>([])
  const [possibleWagersName, setPossibleWagersName] = useState<Array<string>>([])
  const [loadingWagers, setLoadingWagers] = useState(true)
  const [possibleWagers, setPossibleWagers] = useState<Array<Wager>>([])

  useEffect(() => {
    if (programWagers.length > 0) {
      let selectedWagersName: string[] = []

      const wagers = programWagers[0].wagers
      wagers.forEach((w) => {
        selectedWagersName.push(w.name)
      })
      setSelectedWagers(selectedWagersName)

      let wagersName: string[] = []
      const possibleWagers = programWagers[0].possibleWagers
      possibleWagers.forEach((w) => {
        wagersName.push(w.name)
      })
      setPossibleWagersName(wagersName)
      setPossibleWagers(programWagers[0].possibleWagers)
    }

    setLoadingWagers(false)
  }, [programWagers])

  const fetchProgramDefaultWagers = (event: Event, raceNumber: number | string) => {
    const customFilters = {
      and: [
        {
          totalRaces: { ...generateFilterGraphql(event.numberOfRaces, 'eq') },
          raceNumber: { ...generateFilterGraphql(raceNumber, 'eq') },
        },
      ],
    }

    getProgramDefaultWagers({ where: customFilters, skip: 0, take: 1 })
  }

  // Return format string|string
  // const formatWagersId = (wagers: string[]): string[] => {
  //   if (wagers && wagers.length > 0) {
  //     const wagersStringId: string[] = []
  //     const wagersId = wagers[0].split('|')
  //     // const typeBetOptions = wagersData.results
  //     possibleWagersName.forEach((wagerName) => {
  //       if (wagersId.includes(wagerName)) {
  //         wagersStringId.push(item.name)
  //       }
  //     })
  //     return wagersStringId
  //   } else {
  //   return []
  //   }
  // }

  // Return format string|string
  const formatWagersName = useCallback(
    (values: string[]): string[] => {
      if (values.length > 0 && programWagers.length > 0) {
        const possibleWagers = programWagers[0].possibleWagers

        const names: string[] = []
        if (possibleWagers.length > 0 && values.length > 0) {
          values.forEach((val: string) => {
            const bet = possibleWagers.find((item: Wager) => item.id?.trim() === val.trim())
            if (bet !== undefined) {
              names.push(bet.name)
            }
          })
        }
        return names
      }

      return []
    },
    [programWagers],
  )

  const getWagersIds = useCallback(
    (value: string): string[] => {
      if (value.length > 0 && programWagers.length > 0) {
        const possibleWagers = programWagers[0].possibleWagers

        // const typeBetOptions = wagersData.results
        const values: string[] = value.split('/')
        const ids: string[] = []
        if (possibleWagers.length > 0 && values.length > 0) {
          values.forEach((val: string) => {
            const bet = possibleWagers.find((item: Wager) => item.name.trim() === val.trim())
            if (bet !== undefined) {
              ids.push(bet.id)
            }
          })
        }
        return ids
      }

      return []
    },
    [programWagers],
  )

  return {
    fetchProgramDefaultWagers,
    getWagersIds,
    selectedWagers,
    possibleWagersName,
    possibleWagers,
    formatWagersName,
    loadingWagers,
    raceWagers: programWagers,
  }
}
