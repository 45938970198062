import React, { useEffect, useState } from 'react'
import { Box, Card, Grid, TextField } from '@mui/material'
import DatePicker from '@mui/lab/DatePicker'
import { AgencySelect } from 'src/admin/scenes/entities/agencies/components'
import { WorkstationSelect } from 'src/admin/scenes/entities/stations/components'
import { Filter as FilterModel } from '../../models'
import { Location } from 'src/admin/scenes/entities/agencies/models'
import { Workstation } from 'src/admin/scenes/entities/stations/models'
import { ProductSelect, WorkstationProductSelect } from 'src/admin/core'
import { useAppTranslation } from 'src/admin/core/hooks'
import { WorkstationProductsEnum } from 'src/core/models'

export const initialFilters = {
  productId: '',
  locationId: '',
  stationId: '',
  salesTime: new Date(),
  wagerAmountTotal: undefined,
  ticketNumber: '',
  providerTicket: '',
}

const Filters: React.FC<{ onChange: (filters: FilterModel) => void }> = ({ onChange }) => {
  const [filters, setFilters] = useState<FilterModel>(initialFilters)
  const { label } = useAppTranslation()

  useEffect(() => {
    if (
      filters.salesTime &&
      filters.productId !== '' &&
      filters.locationId !== undefined &&
      filters.stationId !== undefined
    ) {
      onChange(filters)
    }
    if (filters.productId === '' && filters.locationId === undefined && filters.stationId === undefined) {
      onChange(filters)
    }
  }, [filters])

  const onTextfieldChange = (value: any, field: string) => {
    if (field === 'ticketNumber') {
      setFilters({
        ...filters,
        [field]: value,
        wagerAmountTotal: undefined,
        providerTicket: '',
      })
    }
    if (field === 'providerTicket') {
      setFilters({
        ...filters,
        [field]: value,
        wagerAmountTotal: undefined,
        ticketNumber: '',
      })
    }
    if (field === 'wagerAmountTotal') {
      setFilters({
        ...filters,
        [field]: Number.parseFloat(value),
        providerTicket: '',
        ticketNumber: '',
      })
    }
  }

  return (
    <Card
      sx={{
        p: 1,
      }}
    >
      <Box
        sx={{
          p: 1,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={3}>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              value={filters.salesTime}
              onChange={(newValue) =>
                setFilters({
                  ...filters,
                  salesTime: newValue,
                })
              }
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <AgencySelect
              onChangeValue={(value: Location) =>
                setFilters({
                  ...filters,
                  locationId: value?.id,
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <WorkstationSelect
              locationId={filters.locationId ? filters.locationId : ''}
              onChangeValue={(value: Workstation) =>
                setFilters({
                  ...filters,
                  stationId: value?.id,
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <WorkstationProductSelect
              value={filters.productId as WorkstationProductsEnum}
              onChange={(val) => {
                setFilters({
                  ...filters,
                  productId: val,
                })
              }}
              getDefaultValue={(val) => {
                setFilters({
                  ...filters,
                  productId: val,
                })
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          p: 1,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              value={filters.ticketNumber}
              label={label('TICKET')}
              placeholder={label('TICKET')}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => onTextfieldChange(event.target.value, 'ticketNumber')}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              value={filters.providerTicket}
              label={label('PROVEEDOR')}
              placeholder={label('PROVEEDOR')}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => onTextfieldChange(event.target.value, 'providerTicket')}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              value={filters.wagerAmountTotal}
              label={label('MONTO')}
              placeholder={label('MONTO')}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => onTextfieldChange(event.target.value, 'wagerAmountTotal')}
            />
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

export default Filters
