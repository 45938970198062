import React from 'react'
import { CustomAutocomplete } from 'src/admin/core/components/Select/CustomAutoComplete'
import { useAppTranslation, useLocalizedContent } from 'src/admin/core/hooks'
import { Currency } from '../models/Currency'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'

export type CurrencyProps = {
  onChangeValue?: (value: Currency) => void
  initialValue?: string
}

export interface Currency2 {
  entityId: string
  grupal: string
  abbreviation?: string
}

const resultCurrency = [
  {
    entityId: 1,
    grupal: 'DOP',
    abbreviation: 'DOP',
  },
  {
    entityId: 2,
    grupal: 'USD',
    abbreviation: 'USD',
  },
] as Currency[]

export const CurrencySelect: React.FC<CurrencyProps> = ({ onChangeValue, initialValue }) => {
  // const { results: resultCurrency, loading } = useLocalizedContent({ entityType: 'System', entityName: 'Currency' })
  const { label } = useAppTranslation()
  const [selectedCurrency, setSelectedCurrency] = React.useState(initialValue || 1)

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedCurrency(event.target.value as string)
  }

  const handleClickMenu = (currency: Currency) => {
    if (onChangeValue) {
      onChangeValue(currency)
    }
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{label('MONEDAS')}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label={label('SELECCIONAR_MONEDA')}
        value={selectedCurrency}
        onChange={handleChange}
      >
        {resultCurrency.map((currency: Currency, index: number) => (
          <MenuItem key={index} value={currency.entityId} onClick={() => handleClickMenu(currency)}>
            {label(currency?.abbreviation)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
