import { gql } from '@apollo/client'

export const WORKSTATION_FRAGMENT = gql`
  fragment WorkstationFragment on Workstation {
    id
    name
    type
    active
    enterpriseId
    enterprise {
      name
      subdomain
      domain
      active
    }
  }
`
