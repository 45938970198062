import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../../../core/models'
import { ProgramRaceDistances } from '../../models'
import { useEffect, useState } from 'react'

const GET_PROGRAM_DISTANCES = gql`
  query ProgramRaceDistances {
    programRaceDistances {
      items {
        id
        name
        active
      }
    }
  }
`

interface DistancesData {
  programRaceDistances: {
    items: ProgramRaceDistances[]
    totalCount: number
  }
}

export const useProgramRaceDistances = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<DistancesData>(GET_PROGRAM_DISTANCES, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<ProgramRaceDistances, Metadata>>({
    results: [],
    metadata: {},
  })

  useEffect(() => {
    if (data?.programRaceDistances) {
      setResults({
        results: data?.programRaceDistances.items || [],
        metadata: {
          Count: data?.programRaceDistances.totalCount,
        },
      })
    }
  }, [loading])

  const getProgramRaceDistances = ({ where, take, skip }: SearchGraphql) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 100,
      },
    })
  }

  return { getProgramRaceDistances, loading, error, results }
}
