import React from 'react'
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 'auto',
      border: 'solid thin #FFF',
      position: 'relative',
      marginTop: 3,
    },

    bar: {
      display: 'inline-block',
      width: 10,
      height: 15,
      backgroundColor: 'limegreen',
    },
    empty: {
      display: 'inline-block',
      width: 10,
      height: 15,
      backgroundColor: '#ddddde',
    },
    barTitle: {
      marginRight: 5,
    },
  }),
)

type ProgressBatteryBarProps = {
  value: number
}

export const PercentBatteryBar = ({ value }: ProgressBatteryBarProps) => {
  const classes = useStyles({ value })

  return (
    <div className={classes.root}>
      <div className={classes.bar} />
      <div className={classes.bar} />
      <div className={classes.empty} />
      <div className={classes.empty} />
      <div className={classes.empty} />
    </div>
  )
}
