import { gql, useMutation } from '@apollo/client'

const UPSERT_AGENCY = gql`
  mutation UpsertAgency($input: UpsertAgencyInput!) {
    upsertAgency(input: $input) {
      id
      name
      type
      products {
        code
        providerCode
        name
      }
      addresses {
        address
        cityOrSectorId
        countryId
        countyOrMunicipalityId
        stateOrProvinceId
      }
      active
    }
  }
`

export const useUpsertAgency = () => {
  const [upsertAgency, { loading, error }] = useMutation(UPSERT_AGENCY)
  return { upsertAgency, loading, error }
}
