import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { generateFilterGraphql } from 'src/admin/core/services'
import { useAgencies } from 'src/admin/scenes/entities/agencies/hooks'
import { Agency } from 'src/core/models'
import { useAppTranslation } from '../../hooks'
import { getAppBarState } from 'src/store'

export type AgencyProps = {
  onChangeValue?: (value: Agency, action: string) => void
  getDefaultAgency?: (value: Agency) => void
  getAllAgencies?: (values: Agency[]) => void
  disableClearable?: boolean
  setDefault?: boolean
}

export const AgencySelect: React.FC<AgencyProps> = ({
  onChangeValue,
  getDefaultAgency,
  getAllAgencies,
  disableClearable = true,
  setDefault = false,
}) => {
  const { results, loading, getAgencies } = useAgencies()
  const [selectedAgency, setSelectedAgency] = useState<any>(null)
  const { label } = useAppTranslation()
  const { userEnterprise } = getAppBarState().appState

  useEffect(() => {
    fetchLocation()
  }, [])

  useEffect(() => {
    if (results.results.length > 0) {
      if (setDefault || getDefaultAgency) {
        const agency = {
          id: results.results[0].id,
          name: results.results[0].name,
          active: results.results[0].active,
        }
        setSelectedAgency(agency)
        getDefaultAgency(agency)
      }

      if (getAllAgencies instanceof Function) {
        getAllAgencies(results.results)
      }
    }
  }, [results.results])

  const fetchLocation = (agencyName = '') => {
    const customFilters = {
      and: [
        { name: { ...generateFilterGraphql(agencyName, 'contains') } },
        { name: { ...generateFilterGraphql('ONLINE_AGENCY', 'neq') } },
        { enterpriseId: { ...generateFilterGraphql(userEnterprise.id, 'eq') } },
      ],
    }

    getAgencies({ where: customFilters, skip: 0, take: 500 })
  }

  const handleChange = (event: any, values: Agency, reason: string) => {
    setSelectedAgency(values)
    if (onChangeValue) {
      onChangeValue(values, reason)
    }
  }

  return (
    <Autocomplete
      sx={{
        m: 0,
        minWidth: 'inherit',
      }}
      onChange={handleChange}
      value={selectedAgency}
      getOptionLabel={(option) => option['name']}
      options={results.results}
      loading={loading}
      disableClearable={disableClearable}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label('AGENCIAS')}
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={label('SELECCIONAR_AGENCIA')}
        />
      )}
      disabled={false}
    />
  )
}
