import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import SuspenseLoader from 'src/components/SuspenseLoader'

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  )

const UserRegisterWizard = Loader(lazy(() => import('src/admin/scenes/sandbox/userRegisterWizard')))

const sandboxRoutes = [
  {
    path: '/',
    element: <Navigate to="/" replace />,
  },
  {
    path: 'user-register-wizard',
    element: <UserRegisterWizard />,
  },
]

export default sandboxRoutes
