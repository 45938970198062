import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  Stack,
  Typography,
} from '@mui/material'
import { useAppTranslation, useNotification } from 'src/admin/core/hooks'
import _lodash from 'lodash'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { useUsers } from 'src/admin/scenes/security/users/hooks/useUsers'

import { WorkstationRegistrationCode } from '../models'
import { useAddGenerateWorkstationCode } from '../hooks'
// import { CustomAutoCompleteSelect } from '../../../core/components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import _ from 'lodash'
import { CustomAutocomplete, generateFilterGraphql } from 'src/admin/core'
import { User } from 'src/admin/scenes/security/users/models'

const marks = [
  {
    value: 1440,
    label: '1D',
  },
  {
    value: 7200,
    label: '5D',
  },
  {
    value: 14400,
    label: '10D',
  },
  {
    value: 21600,
    label: '15D',
  },
]
type StationCodeGeneratorProps = {
  isOpen: boolean
  workstationId: string
  onClose: () => void
}

const generatorInitialValues = {
  owner: '',
  workstationId: '',
  lifetimeInSeconds: 60,
}

interface GeneratedCodeResponse {
  generateWorstationRegistrationCode: {
    registrationCode: string
  }
}

export const StationCodeGenerator = ({ isOpen, onClose, workstationId }: StationCodeGeneratorProps) => {
  const [isUnselectedUser, setIsUnselectedUser] = useState(false)
  const [generatorValues, setGenerator] = useState<WorkstationRegistrationCode>(generatorInitialValues)
  const { getUsers, results, loading: loadingUsers } = useUsers()
  const { label } = useAppTranslation()
  const { addGenerateWorkstationCode } = useAddGenerateWorkstationCode()
  const { errorNotification } = useNotification()
  const [generatedCode, setGeneratedCode] = useState('')
  const [open, setOpen] = useState(false)

  useEffect(() => {
    let generatorFormClone = _lodash.cloneDeep(generatorValues)
    generatorFormClone.workstationId = workstationId
    setGenerator(generatorFormClone)
  }, [workstationId])

  useEffect(() => {
    filterUsers('')
  }, [])

  const filterUsers = (user: string, skip = 0, take = 100) => {
    const customFilters = {
      and: [{ userName: { ...generateFilterGraphql(user, 'contains') } }],
    }

    getUsers({ where: customFilters, skip, take })
  }

  const userData = results.results.map((item: User) => item)

  const onSelectUser = (user: User, action: string) => {
    console.log(user, action)
    if (action == 'clear') {
      filterUsers('')
    }
    if (action === 'selectOption') {
      let generatorFormClone = _lodash.cloneDeep(generatorValues)
      generatorFormClone.owner = user.id ? user.id : ''
      setGenerator(generatorFormClone)
      setIsUnselectedUser(true)
      setGeneratedCode('')
    }
  }

  const onInputChangeUser = (value: string) => {
    // filterUsers(value)
  }

  const onSliderChange = (event: Event, value: number | Array<number>, activeThumb: number) => {
    const generatorFormClone = _lodash.cloneDeep(generatorValues)
    generatorFormClone.lifetimeInSeconds = (value as number) * 60

    setGenerator(generatorFormClone)
  }

  const valuetext = (value: number) => {
    return `${value} Min`
  }

  const labelText = (value: number) => {
    return `${value < 1 ? '1' : value}M`
  }

  const onGenerate = async () => {
    try {
      const response = await addGenerateWorkstationCode({
        variables: {
          input: generatorValues,
        },
      })
      const code = (response.data as GeneratedCodeResponse).generateWorstationRegistrationCode.registrationCode
      setGeneratedCode(code)
    } catch (er) {
      errorNotification(label('ERROR_GENERANDO_EL_CODIGO'))
    }
  }

  const closeGenerator = () => {
    setGeneratedCode('')
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth>
      <DialogTitle id="form-dialog-title">{label('GENERAR_CODIGO')}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Box>
            <Typography id="discrete-slider-restrict" gutterBottom>
              {label('USUARIOS')}
            </Typography>
            <CustomAutocomplete
              data={userData}
              keyValue={'userName'}
              keyLabel={'userName'}
              isLoading={loadingUsers}
              onChangeValue={onSelectUser}
              onInputValueChange={onInputChangeUser}
            />
          </Box>
          <Box>
            <Typography id="discrete-slider-restrict" gutterBottom>
              {label('TIEMPO_EXPIRACION')}
            </Typography>
            <Slider
              onChange={onSliderChange}
              defaultValue={1}
              getAriaValueText={valuetext}
              valueLabelFormat={labelText}
              aria-labelledby="discrete-slider-custom"
              step={7200}
              valueLabelDisplay="auto"
              marks={marks}
              min={1440}
              max={21600}
            />
          </Box>
        </Stack>

        {generatedCode.length > 0 && (
          <Stack spacing={2} direction="row">
            <Typography color="textSecondary" gutterBottom>
              {label('CODIGO')}:
            </Typography>
            <Typography color="textSecondary" gutterBottom>
              {generatedCode}
            </Typography>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <CopyToClipboard text={generatedCode ? generatedCode : ''} onCopy={closeGenerator}>
                <Button
                  sx={{
                    padding: 'unset',
                    marginLeft: '-10px',
                  }}
                >
                  <FileCopyIcon color={'primary'} />
                </Button>
              </CopyToClipboard>
            </ClickAwayListener>
          </Stack>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={closeGenerator} color="primary">
          Cancel
        </Button>
        <Button onClick={onGenerate} color="primary" disabled={!isUnselectedUser}>
          {label('GENERAR_CODIGO')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
