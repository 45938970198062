import { useCallback, useEffect } from 'react'
import { FormPage, PageTab, PageHeader, FormContent, BackButton, isValidUrl } from 'src/admin/core'
import { useAppTranslation, useNotification, useUserClaimActions } from 'src/admin/core/hooks'
import { Form } from './Form'
import { useNavigate } from 'react-router-dom'
import { CreateEnterprise } from './CreateEnterprise'
import { UpdateEnterprise } from './UpdateEnterprise'
import { useFormHandler } from '../hooks'
import { Loader } from 'src/admin/components/Loader'
import { Stack } from '@mui/material'
import { useRegisterEnterprise } from '../hooks/useRegisterEnterprise'
import { Enterprise as EnterpriseModel } from 'src/core/models'
import { ClaimActions } from 'src/admin/core/models'
import { SecurityPage } from 'src/admin/core/components/Page/SecurityPage'
import { useProfileEnterprises } from '../hooks/useProfileEnterprises'
import { AuthService } from 'src/admin/core/auth/authService'
import { useDispatch } from 'react-redux'
import { setEnterprises } from 'src/admin/core/stores/appState/actions'

const Enterprise: React.FC = () => {
  const { label } = useAppTranslation()
  const navigate = useNavigate()
  const { successNotification, errorNotification } = useNotification()
  const { formSchema, initialValues, loading } = useFormHandler()
  const { registerEnterprise, error } = useRegisterEnterprise()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.enterprises', [ClaimActions.CanView])
  const { data, getProfileEnterprises } = useProfileEnterprises()
  const authService = new AuthService()
  const dispatch = useDispatch()

  const afterSave = useCallback((response: Promise<any>, action?: string) => {
    response.then((res) => {
      const data = res.data.upsertEnterprise as EnterpriseModel

      successNotification(label(initialValues.id ? 'DATO_ACTUALIZADO' : 'DATO_REGISTRADO'))
      const url = `https://${data.subdomain}.${data.domain}`

      if (action === 'CREATE') {
        if (isValidUrl(url)) {
          const registerResponse = registerEnterprise({
            variables: {
              input: {
                id: data.id,
                enterpriseUrl: url,
              },
            },
          })

          registerResponse
            .then((response) => {
              if (response.data.registerEnterprise.success) {
                successNotification(label('CONSORCIO_REGISTRADO'))
              } else {
                errorNotification(label('CONSORCIO_NO_REGISTRADO'))
              }
            })
            .catch((err) => {
              errorNotification(label('CONSORCIO_NO_REGISTRADO'))
            })
        } else {
          errorNotification(label('URL_INVALIDA'))
        }
        setTimeout(() => {
          window.location.replace('/admin/entities/enterprises')
        }, 3000)
      } else {
        refreshEnterprises()
      }
    })
  }, [])

  const refreshEnterprises = async () => {
    const user = await authService.getUser()
    if (user) {
      try {
        getProfileEnterprises(user.profile.sub)
      } catch (e) {
        console.log(e)
      }
    }
  }
  useEffect(() => {
    const userEnterprises = data ? (data.profile.enterprises as EnterpriseModel[]) : []

    if (userEnterprises.length > 0) {
      dispatch(setEnterprises(userEnterprises))

      setTimeout(() => {
        navigate(`/admin/entities/enterprises`)
      }, 2500)
    }
  }, [data])

  return (
    <Loader loading={loading}>
      <SecurityPage hasAccess={actions.canView}>
        <FormPage
          form={{
            formSchema: formSchema,
            formValues: initialValues,
          }}
        >
          <PageTab text={label('CONSORCIO_TITULO')} />
          <PageHeader
            title={label('CONSORCIO_TITULO')}
            subTitle={label('CONSORCIO_SUB_TITULO')}
            actions={
              <Stack spacing={1} direction={'row'}>
                <BackButton />
                {initialValues && initialValues.id ? (
                  <UpdateEnterprise afterSave={afterSave} />
                ) : (
                  <CreateEnterprise afterSave={afterSave} />
                )}
              </Stack>
            }
          />
          <FormContent>
            <Form />
          </FormContent>
        </FormPage>
      </SecurityPage>
    </Loader>
  )
}

export default Enterprise
