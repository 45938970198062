import { useCallback, useContext } from 'react'
import { ProgramSelectOption, ProgramSelectOptionContext } from 'src/admin/core/stores/programSelectOptions'

export const useGetProgramOptionsByEntity = () => {
  const {
    state: { programSelectOptions },
  } = useContext(ProgramSelectOptionContext)

  const getProgramSelectOptionsByEntity = (entity: string): [] | ProgramSelectOption[] => {
    if (programSelectOptions && programSelectOptions.length > 0) {
      return programSelectOptions.filter((item: ProgramSelectOption) => item.entity === entity)
    } else {
      return []
    }
  }

  const getPerformaceCodeOptions = useCallback((): ProgramSelectOption[] => {
    return getProgramSelectOptionsByEntity('PerformanceCode')
  }, [programSelectOptions])

  const getDistanceOptions = useCallback((): ProgramSelectOption[] => {
    return getProgramSelectOptionsByEntity('RaceDistance')
  }, [programSelectOptions])

  const getTypeOptions = useCallback((): ProgramSelectOption[] => {
    return getProgramSelectOptionsByEntity('TypeOfRace')
  }, [programSelectOptions])

  const getMedicationOptions = useCallback((): ProgramSelectOption[] => {
    return getProgramSelectOptionsByEntity('Medication')
  }, [programSelectOptions])

  return {
    getProgramSelectOptionsByEntity,
    getPerformaceCodeOptions,
    getDistanceOptions,
    getTypeOptions,
    getMedicationOptions,
    programSelectOptions,
  }
}
