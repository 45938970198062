import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Avatar,
  Box,
  Button,
  Divider,
  alpha,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import UnfoldMoreTwoToneIcon from '@mui/icons-material/UnfoldMoreTwoTone'
import { useDispatch } from 'react-redux'
import Scrollbar from 'src/admin/components/Scrollbar'
import { getAppBarState } from 'src/store'
import { setUserEnterprise } from 'src/admin/core/stores/appState/actions'
import { Enterprise } from 'src/core/models'

const UserBoxButton = styled(Button)(
  ({ theme }) => `
    padding: ${theme.spacing(1)};
    background-color: ${alpha(theme.colors.alpha.black[100], 0.08)};

    .MuiButton-label {
      justify-content: flex-start;
    }

    &:hover {
      background-color: ${alpha(theme.colors.alpha.black[100], 0.12)};
    }
`,
)

const MenuUserBox = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(2)};
`,
)

const UserBoxText = styled(Box)(
  ({ theme }) => `
    text-align: left;
    padding-left: ${theme.spacing(1)};
`,
)

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.sidebar.menuItemColor};
    display: block;

    &.popoverTypo {
      color: ${theme.palette.secondary.main};
    }
`,
)

function SidebarTopSection() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { userEnterprise, enterprises } = getAppBarState().appState

  const navigate = useNavigate()

  const ref = useRef<any>(null)
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleChangeEnterprise = (enterprise: Enterprise): void => {
    dispatch(setUserEnterprise(enterprise))
    window.sessionStorage.setItem('selectedEnterprise', JSON.stringify(enterprise))

    handleClose()
    window.location.href = '/admin/statistics/sales-profit-dashboard'
  }

  return (
    <>
      <UserBoxButton fullWidth color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={userEnterprise?.name} src={userEnterprise?.name} />
        <Box display="flex" flex={1} alignItems="center" justifyContent="space-between">
          <UserBoxText>
            <UserBoxLabel variant="body1">{userEnterprise?.name}</UserBoxLabel>
          </UserBoxText>
          <UnfoldMoreTwoToneIcon
            fontSize="small"
            sx={{
              ml: 1,
            }}
          />
        </Box>
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210,
          }}
          display="flex"
        >
          <Avatar variant="rounded" alt={userEnterprise?.name} src={userEnterprise?.name} />
          <UserBoxText>
            <UserBoxLabel className="popoverTypo" variant="body1">
              {userEnterprise?.name}
            </UserBoxLabel>
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0,
          }}
        />
        <Box
          sx={{
            height: 350,
          }}
        >
          <Scrollbar>
            <List
              sx={{
                p: 1,
                '.MuiListItem-root:hover': {
                  cursor: 'pointer',
                },
              }}
              component="nav"
            >
              {enterprises.map((enterprise, index) => (
                <ListItem key={index} onClick={() => handleChangeEnterprise(enterprise)}>
                  <Avatar
                    variant="rounded"
                    alt={enterprise.name}
                    src={enterprise.name}
                    sx={{ width: 20, height: 20 }}
                  />
                  <ListItemText primary={enterprise.name} sx={{ pl: 'inherit' }} />
                </ListItem>
              ))}
            </List>
          </Scrollbar>
        </Box>
        <Divider />
      </Popover>
    </>
  )
}

export default SidebarTopSection
