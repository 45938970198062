import React from 'react'
import { Button, CircularProgress } from '@mui/material'
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'
import { useTranslation } from 'react-i18next'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'

type buttonProps = {
  onClick: () => void
  className?: string
  disabled?: boolean
  isLoading?: boolean
  size?: 'small' | 'medium' | 'large' | undefined
}
export const PrintButton = ({ onClick, size, ...props }: buttonProps) => {
  const { t: transAction } = useTranslation('Action')

  return (
    <Button
      {...props}
      variant="contained"
      size={size}
      color="primary"
      onClick={onClick}
      startIcon={<LocalPrintshopIcon />}
    >
      {transAction('IMPRIMIR')}
    </Button>
  )
}
