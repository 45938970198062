import { gql, useMutation } from '@apollo/client'

const UPSERT_PEOPLE = gql`
  mutation upsertPeople($input: UpsertPeopleInput!) {
    upsertPeople(input: $input) {
      id
      identificationNumber
      firstName
      lastName
      nickName
      birthdate
      cellPhone
      email
      gender
      active
    }
  }
`

export const useUpsertPeople = () => {
  const [upsertPeople, { loading, error }] = useMutation(UPSERT_PEOPLE)
  return { upsertPeople, loading, error }
}
