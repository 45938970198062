import type { ReactNode } from 'react'

import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone'
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone'
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone'
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone'
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone'
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone'
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone'
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone'
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone'
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone'
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone'
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone'

export interface MenuItem {
  link?: string
  icon?: ReactNode
  badge?: string
  badgeTooltip?: string

  items?: MenuItem[]
  name: string

  allowedClaims?: string
  headingClaims?: string[]
  doesNotRequiredEnterprise?: boolean
  isHeading?: boolean
}

export interface MenuItems {
  items: MenuItem[]
  heading: string
  allowedModules?: string
}

const menuItems: MenuItems[] = [
  {
    allowedModules: 'dashboard',
    heading: '',
    items: [
      {
        name: 'TABLERO_VENTAS_GANANCIAS',
        icon: BackupTableTwoToneIcon,
        link: '/admin/statistics/sales-profit-dashboard',
        allowedClaims: 'dashboard',
      },
    ],
  },
  // {
  //   allowedModules: 'transactions',
  //   heading: 'TRANSACCIONES',
  //   items: [
  //     {
  //       name: 'TRANSACCIONES',
  //       icon: BackupTableTwoToneIcon,
  //       link: '',
  //       items: [
  //         {
  //           name: 'CUENTAS_CLIENTES',
  //           link: '/admin/transactions/client-accounts',
  //           allowedClaims: 'entities.client-accounts',
  //         },
  //         {
  //           name: 'TRANSACCIONES_CUENTAS',
  //           link: '/admin/transactions/client-account',
  //           allowedClaims: 'entities.client-accounts',
  //         },
  //         {
  //           name: 'AJUSTE_DE_CUENTAS_DE_CLIENTE',
  //           link: '/admin/transactions/client-account/create',
  //           allowedClaims: 'entities.client-accounts',
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    allowedModules: 'consults',
    heading: 'CONSULTAS',
    items: [
      {
        name: 'CONSULTAS',
        icon: SmartToyTwoToneIcon,
        link: '',
        items: [
          // {
          //   name: 'RESUMEN_VENTAS_HIPICAS',
          //   link: '/admin/consults/horse-sales-report',
          // },
          /* {
            name: 'TICKETS',
            link: '/admin/consults/tickets',
            allowedClaims: 'consults.tickets',
          }, */
          {
            name: 'VERIFICACION_DE_TICKETS',
            link: '/admin/consults/tickets-verification',
            allowedClaims: 'consults.tickets-verification',
          },
          // {
          //   name: 'TERMINAL_LOGS',
          //   link: '/admin/consults/terminal-logs',
          //   allowedClaims: 'consults.tickets',
          // },
          // {
          //   name: 'CONTROL_DE_EVENTOS',
          //   link: '/admin/consults/events-control',
          //   allowedClaims: 'consults.tickets',
          // },
          {
            name: 'BALANCES_SEMANALES',
            link: '/admin/consults/weekly-balances',
            allowedClaims: 'consults.weekly-balances',
          },
          {
            name: 'BALANCES_DIARIOS',
            link: '/admin/consults/daily-balances',
            allowedClaims: 'consults.daily-balances',
          },
          // {
          //   name: 'VENTA_POR_CONSORCIO',
          //   link: '/admin/consults/enterprise-sales',
          //   allowedClaims: 'consults.tickets',
          // },
          // {
          //   name: 'GANANCIAS_Y_PERDIDAS',
          //   link: '/admin/consults/profit-and-loss',
          //   allowedClaims: 'consults.profit-and-loss',
          // },
          // {
          //   name: 'EMPADRONAMIENTO_BASICO',
          //   link: '/admin/consults/basic-registration-plays',
          //   allowedClaims: 'consults.basic-registration-plays',
          // },
          // {
          //   name: 'ESTADO_SITUACION_AGENCIAS_TITULO',
          //   link: '/admin/consults/agency-situation',
          //   allowedClaims: 'consults.agency-situation',
          // },
          // {
          //   name: 'RESUMEN_OPERACIONES_TITULO',
          //   link: '/admin/consults/agencies-operations',
          //   allowedClaims: 'consults.agency-situation',
          // },
          // {
          //   name: 'OPERACIONES_POR_CONSORCIOS',
          //   link: '/admin/consults/partnership-operations',
          //   allowedClaims: 'consults.agencies-operations',
          // },
          // {
          //   name: 'OPERACIONES_POR_LOCALIDADES',
          //   link: '/admin/consults/locations-operations',
          //   allowedClaims: 'consults.agencies-operations',
          // },
          // {
          //   name: 'MAQUINAS_DETALLADAS',
          //   link: '/admin/consults/machine-details',
          //   allowedClaims: 'consults.machine-details.view',
          // },
          // {
          //   name: 'MAQUINAS_RESUMIDA',
          //   link: '/admin/consults/machine-summarized',
          //   allowedClaims: 'consults.machine-summarized',
          // },
          // {
          //   name: 'RESUMEN_DE_CUENTA',
          //   link: '/admin/consults/account-summary',
          //   allowedClaims: 'consults.profit-and-loss',
          // },
          // {
          //   name: 'MAQUINA_GRAFICA',
          //   link: '/admin/consults/machine-chart',
          //   allowedClaims: 'consults.machine-summarized',
          // },
        ],
      },
    ],
  },
  {
    allowedModules: 'security',
    heading: 'SEGURIDAD',
    items: [
      {
        name: 'SEGURIDAD',
        icon: BackupTableTwoToneIcon,
        link: '',
        items: [
          {
            name: 'ROLES_PERMISOS',
            link: '/admin/security/roles',
            allowedClaims: 'security.profiles',
          },
          {
            name: 'USUARIOS_EMPLEADOS',
            link: '/admin/security/users',
            allowedClaims: 'security.users',
          },
        ],
      },
    ],
  },
  {
    allowedModules: 'configuration',
    heading: 'CONFIGURACION',
    items: [
      {
        doesNotRequiredEnterprise: false,
        name: 'CONFIGURACION',
        icon: VpnKeyTwoToneIcon,
        link: '/entities',
        items: [
          {
            name: 'ENTIDADES',
            link: '/entities',
            doesNotRequiredEnterprise: false,
            allowedClaims: 'enterprises.agencies.workstations',
            isHeading: true,
            headingClaims: ['configuration.enterprises', 'configuration.agencies', 'configuration.workstations'],
            items: [
              {
                name: 'CONSORCIOS',
                link: '/admin/entities/enterprises',
                allowedClaims: 'configuration.enterprises',
                doesNotRequiredEnterprise: false,
              },
              {
                name: 'AGENCIAS',
                link: '/admin/entities/agencies',
                allowedClaims: 'configuration.agencies',
              },
              {
                name: 'ESTACIONES',
                link: '/admin/entities/workstations',
                allowedClaims: 'configuration.workstations',
              },
              {
                name: 'PERSONAS',
                link: '/admin/entities/peoples',
                allowedClaims: 'configuration.peoples',
              },
            ],
          },
          {
            name: 'PROGRAMA_HIPICO',
            headingClaims: [
              'configuration.events',
              'configuration.competitors',
              'configuration.jockey',
              'configuration.trainers',
              'configuration.owners',
            ],
            isHeading: true,
            items: [
              {
                name: 'EVENTOS',
                link: '/admin/entities/events',
                allowedClaims: 'configuration.events',
              },
              {
                name: 'COMPETIDORES',
                link: '/admin/entities/events-competitors',
                allowedClaims: 'configuration.competitors',
              },
              {
                name: 'JINETES',
                link: '/admin/entities/events-jockeyOrKennels',
                allowedClaims: 'configuration.jockey',
              },
              {
                name: 'ENTRENADORES',
                link: '/admin/entities/events-trainers',
                allowedClaims: 'configuration.trainers',
              },
              {
                name: 'PROPIETARIOS',
                link: '/admin/entities/events-owners',
                allowedClaims: 'configuration.owners',
              },
            ],
          },
        ],
      },
    ],
  },
]

export default menuItems
