import { gql, useMutation } from '@apollo/client'

const UPDATE_PROGRAM_RUNNER = gql`
  mutation UpdateProgramRunner($input: UpsertProgramRunnerInput!) {
    upsertProgramRunner(input: $input) {
      id
      programRaceId
      number
      odds
      position
      weight
      active
      jockeyOrKennelId
      competitorId
      medication
      ownerId
      createdDate
      trainerId
      trainer {
        name
      }
    }
  }
`

export interface UpdateProgramRunnerInput {
  id: number
  programRaceId: number
  number: string
  odds: string
  position: number
  name: string
  jockeyOrKennel: string
  trainer: string
  owner: string
  weight: number
  active: boolean
}

export const useUpdateProgramRunner = () => {
  const [updateProgramRunner, { loading, error }] = useMutation<UpdateProgramRunnerInput>(UPDATE_PROGRAM_RUNNER)
  return { updateProgramRunner, loading, error }
}
