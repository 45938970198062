import styled from '@emotion/styled'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ClearIcon from '@mui/icons-material/Clear'

type buttonProps = {
  onClick: () => void
  className?: string
  label?: string
  disabled?: boolean
}
export const ClearButton = ({ onClick, label, disabled, ...props }: buttonProps) => {
  const { t: transAction } = useTranslation('Action')

  return (
    <Button
      {...props}
      variant="contained"
      color={'secondary'}
      onClick={onClick}
      startIcon={<ClearIcon />}
      disabled={disabled}
    >
      {transAction(label ? label : 'LIMPIAR')}
    </Button>
  )
}
