import { useEffect } from 'react'
import * as Yup from 'yup'
import { useAppTranslation } from 'src/admin/core/hooks'
import { useParams } from 'react-router-dom'
import { ProgramJockeyOrKennel } from '../../models'
import { useProgramJockeyOrKennel } from './useJockeyOrKennel'

const initialValues: ProgramJockeyOrKennel = {
  id: '',
  name: '',
  active: true,
}

export const useFormHandler = () => {
  const { label } = useAppTranslation()
  const { id } = useParams()
  const { jockeyOrKennel, loading, getProgramJockeyOrKennel } = useProgramJockeyOrKennel()

  useEffect(() => {
    if (id) {
      getProgramJockeyOrKennel(id)
    }
  }, [id])

  const formSchema = Yup.object().shape({
    name: Yup.string().required(label('REQUERIDO')),
  })

  return {
    loading,
    formSchema,
    initialValues: jockeyOrKennel || initialValues,
  }
}
