import { Card, CardContent, CardHeader, Divider, FormControl, FormControlLabel, Grid, Stack } from '@mui/material'
import { Field } from 'formik'
import { TextField, Checkbox } from 'formik-mui'

import { StringFormikSelect } from 'src/admin/core'
import { BooleanRadioButton } from 'src/admin/core/components/RadioButtons'
import { useAppTranslation } from 'src/admin/core/hooks'
import { Workstation } from '../models'

type StationFormProps = {
  station: Workstation | undefined
  locationId: number | undefined
  disableFields: boolean
}

export const StationForm = ({ station, disableFields }: StationFormProps) => {
  const { label } = useAppTranslation()

  return (
    <Card>
      <CardHeader title={label(station.id ? 'EDITANDO_ESTACION' : 'CREANDO_ESTACION')} />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Stack spacing={2}>
              <Field
                fullWidth
                component={StringFormikSelect}
                labelTitle={label('TIPO_DE_CAJA')}
                name="workstationType"
                data={['TERMINAL', 'MOBILE', 'KIOSK']}
                defaultValue={station.workstationType}
              />
              <Field fullWidth component={TextField} type="text" label={label('NOMBRE')} name="name" />
              <Field
                component={BooleanRadioButton}
                title={'ESTADO'}
                name="active"
                label1={'ACTIVO'}
                label2={'INACTIVO'}
                value1={'active'}
                value2={'inactive'}
              />
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
