import { gql, useMutation } from '@apollo/client'

const ADD_PROGRAM_TRAINER = gql`
  mutation CreateProgramTrainer($input: UpsertProgramTrainerInput!) {
    upsertProgramTrainer(input: $input) {
      id
      name
      active
    }
  }
`
export interface AddProgramTrainerInput {
  name: string
  active: boolean
}

export const useAddProgramTrainer = () => {
  const [addProgramTrainer, { loading, error }] = useMutation<AddProgramTrainerInput>(ADD_PROGRAM_TRAINER)
  return { addProgramTrainer, loading, error }
}
