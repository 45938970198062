import { gql, useMutation } from '@apollo/client'

const UPSERT_WORKSTATION = gql`
  mutation workstation($input: UpsertWorkstationInput!) {
    upsertWorkstation(input: $input) {
      id
      name
      active
    }
  }
`

export const useUpsertWorkstation = () => {
  const [upsertWorkstation, { loading, error }] = useMutation(UPSERT_WORKSTATION)
  return { upsertWorkstation, loading, error }
}
