import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useAppTranslation } from '../../hooks'
import { getAppBarState } from 'src/store'
import { WorkstationProductsEnum } from 'src/core/models'
import { useEffect, useState } from 'react'

export const WorkstationProductSelect = ({
  onChange,
  value,
  getDefaultValue,
}: {
  value: WorkstationProductsEnum | undefined
  onChange?: (value: WorkstationProductsEnum) => void
  getDefaultValue: (value: WorkstationProductsEnum) => void
}) => {
  const { label } = useAppTranslation()
  const { userEnterprise } = getAppBarState().appState
  const products = (userEnterprise && userEnterprise.products) || []
  const [defaultValue, setDefaultValue] = useState(null)
  const [localDisabled, setLocalDisabled] = useState(false)

  useEffect(() => {
    if (products.length === 1) {
      setDefaultValue(products[0])
      setLocalDisabled(true)
      getDefaultValue(products[0])
    } else {
      setDefaultValue('HORSE_RACES')
      getDefaultValue('HORSE_RACES' as WorkstationProductsEnum)
    }
  }, [products])

  const handleChange = (event: SelectChangeEvent) => {
    const prod = event.target.value as WorkstationProductsEnum
    if (onChange) {
      onChange(prod)
    }
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="product-simple-select-label">{label('PRODUCTO')}</InputLabel>
      {defaultValue && (
        <Select
          fullWidth
          labelId="product-simple-select-label"
          id="product-simple-select"
          defaultValue={defaultValue}
          value={value}
          label="product"
          onChange={handleChange}
          placeholder={label('PRODUCTO')}
          disabled={localDisabled}
        >
          <MenuItem value={''}>{label('')}</MenuItem>
          {products.map((product, key) => {
            return (
              <MenuItem key={key} value={product}>
                {label(product)}
              </MenuItem>
            )
          })}
        </Select>
      )}
    </FormControl>
  )
}
