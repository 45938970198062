import { FiltersCard } from 'src/admin/components/Cards/FiltersCard'
import { CompanySelect } from 'src/admin/scenes/entities/companies/components/CompanySelect'
import { Enterprise as EnterpriseModel } from '../models'
import { LabelImportantOutlined } from '@mui/icons-material'
import { Company } from '../../companies/models'
import { Grid } from '@mui/material'
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup'
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'
import { styled } from '@mui/material/styles'
import Radio from '@mui/material/Radio'
import { useAppTranslation } from 'src/admin/core/hooks'
import { SearchField } from 'src/admin/core'
import { useCallback, useEffect, useState } from 'react'

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean
}

export interface IFiltersEnterprises {
  active: boolean
  companyId: number | null
  search?: string
}

const Filters: React.FC<{ onChange: (filters: IFiltersEnterprises) => void }> = ({ onChange }) => {
  const { label } = useAppTranslation()
  const [filters, setFilters] = useState<IFiltersEnterprises>({
    active: true,
    companyId: null,
    search: '',
  })

  useEffect(() => {
    onChange(filters)
  }, [filters])

  const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
      '.MuiFormControlLabel-label': checked && {
        color: theme.palette.primary.main,
      },
    }),
  )

  function MyFormControlLabel(props: FormControlLabelProps) {
    const radioGroup = useRadioGroup()

    let checked = false

    if (radioGroup) {
      checked = radioGroup.value === props.value
    }

    return <StyledFormControlLabel checked={checked} {...props} />
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value
    setFilters({ ...filters, [event.target.name]: value === 'active' ? true : false })
  }

  return (
    <FiltersCard>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2} lg={2}>
          <RadioGroup row name="active" defaultValue={'active'} onChange={handleChange}>
            <MyFormControlLabel value={'active'} label={label('ACTIVO') as string} control={<Radio />} />
            <MyFormControlLabel value={'inactive'} label={label('INACTIVO') as string} control={<Radio />} />
          </RadioGroup>
        </Grid>
        {/* <Grid item xs={12} md={4} lg={4}>
          <CompanySelect
            onChangeValue={(value: Company) => setFilters({ ...filters, companyId: value ? value.id : null })}
          />
        </Grid> */}
        <Grid item xs={12} md={6} lg={6}>
          <SearchField
            onChange={(query: string) => setFilters({ ...filters, search: query })}
            placeholder={label('MOSTRAR_LOS_QUE_COINCIDAD_CON')}
            width={'100%'}
          />
        </Grid>
      </Grid>
    </FiltersCard>
  )
}

export default Filters
