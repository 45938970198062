import React from 'react'
import { LoadingAnimation } from 'src/admin/components/Loader'
import { AuthConsumer } from '../AuthProvider'

const Logout = () => (
  <AuthConsumer>
    {({ logout }) => {
      logout()
      return <LoadingAnimation />
    }}
  </AuthConsumer>
)

export default Logout
