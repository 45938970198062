import { debounce } from 'lodash'
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { ChangeEvent, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from 'react-i18next';

type SearchFieldProps = {
  onChange: (value: string) => void
  placeholder?: string
  size?: 'small' | 'medium'
  width?: number | string
}

export const SearchField = ({onChange, placeholder, size, width}: SearchFieldProps) => {
  const { t: transLabel }: { t: any } = useTranslation();

  const debouncedDispatchChangeQuery = useCallback(
    debounce((value: string) => {
      onChange(value)
    }, 500),
    [onChange],
  )

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value
    debouncedDispatchChangeQuery(value)
  }

  return (
    <TextField
      fullWidth={!!width}
      sx={{
        width: width
      }}
      size={size}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchTwoToneIcon />
          </InputAdornment>
        )
      }}
      placeholder={transLabel(placeholder)}
    />
  );
}
