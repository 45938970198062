import { TextareaAutosize, Typography, useTheme } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { IFormField } from '../../models'

export interface FieldProps extends IFormField {
  fullWidth?: boolean
  disabled?: boolean
  placeholder?: string
  defaultValue?: string
}

export const TextareaFormik = ({ disabled, fullWidth, defaultValue, placeholder, ...props }: FieldProps) => {
  const {
    form: { setFieldValue, values, errors },
    field: { name },
  } = props
  const theme = useTheme()

  /**
   * @description change date selected
   * @param date
   */
  const handleChange = useCallback(
    (event: React.ChangeEvent<any>) => {
      const value = event.target.value
      setFieldValue(name, value)
    },
    [setFieldValue, name],
  )

  const errorMessage = errors[name] !== undefined ? errors[name] : ''

  return (
    <>
      <TextareaAutosize
        {...props}
        value={values[name]}
        disabled={disabled}
        onChange={handleChange}
        style={{ width: '100%' }}
        minRows={3}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
      {errorMessage && <Typography color={theme.colors.error.main}>{errorMessage}</Typography>}
    </>
  )
}
