import { Button, useTheme, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useAppTranslation } from 'src/admin/core/hooks'
import {
  getCurrentDate,
  getEndWeekByDate,
  getLastWeek,
  getNextWeek,
  getStartWeekByDate,
} from 'src/admin/core/services/dateFunctions'

export const monday = getStartWeekByDate(getCurrentDate())
export const sunday = getEndWeekByDate(getCurrentDate())

export type WeekDaysType = {
  monday: string
  sunday: string
}

export const DateWeekControl = ({
  onChange,
  showDateLabel = true,
}: {
  showDateLabel?: boolean
  onChange: (week: WeekDaysType) => void
}) => {
  const theme = useTheme()
  const { label } = useAppTranslation()
  const [week, setWeek] = useState({ monday: monday, sunday: sunday })
  const [selectedDate, setSelectedDate] = useState<string>(monday)

  useEffect(() => {
    onChange(week)
  }, [week])

  const handleWeekDays = (date: string) => {
    setSelectedDate(date)
    const monday: string = getStartWeekByDate(date)
    const sunday: string = getEndWeekByDate(date)
    setWeek({
      monday: monday,
      sunday: sunday,
    })
  }

  const handleCurrentWeek = () => {
    handleWeekDays(getCurrentDate())
  }

  const handleLastWeek = (date: string) => {
    const lastWeek = getLastWeek(date)
    handleWeekDays(lastWeek)
  }

  const handleNextWeek = (date: string) => {
    const nextWeek = getNextWeek(date)
    handleWeekDays(nextWeek)
  }

  return (
    <Stack spacing={2} direction="row">
      {showDateLabel && (
        <Button
          fullWidth
          color="secondary"
          sx={{
            px: 2,
            backgroundColor: `${theme.colors.secondary.lighter}`,
            color: `${theme.colors.secondary.dark}`,
          }}
        >
          {`${week.monday} - ${week.sunday}`}
        </Button>
      )}

      <Button variant="contained" color="primary" onClick={() => handleLastWeek(selectedDate)}>
        {label('ANTERIOR')}
      </Button>
      <Button variant="contained" color="primary" onClick={handleCurrentWeek}>
        {label('ACTUAL')}
      </Button>
      <Button variant="contained" color="primary" onClick={() => handleNextWeek(selectedDate)}>
        {label('SIGUIENTE')}
      </Button>
    </Stack>
  )
}
