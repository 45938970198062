import { SaveButton } from 'src/admin/core'
import { useFormikContext } from 'formik'
import { Workstation, WorkstationTypeEnum } from '../models'
import { useAddWorkstation } from '../hooks'

export const CreateStation: React.FC<{
  locationId: number
  afterSave?: (promise: Promise<any>, action: string) => void
}> = ({ locationId, afterSave }) => {
  const { addWorkstation, loading, error } = useAddWorkstation()
  const form = useFormikContext()
  const { values, dirty, isValid, setSubmitting } = form
  const station = { ...(values as Workstation) }

  const handleCreate = () => {
    setSubmitting(true)
    const response = addWorkstation({
      variables: {
        input: {
          name: station.name,
          workstationType: station.workstationType,
          locationId: locationId,
        },
      },
    })
    if (afterSave) {
      afterSave(response, 'create')
    }
    setSubmitting(false)
  }

  return <SaveButton onClick={handleCreate} isLoading={loading} disabled={!(dirty && isValid)} />
}
