import React from 'react'
import { Stack } from '@mui/material'

type FormVerticalProps = {
  children: React.ReactElement | React.ReactElement[]
}

export const FormVertical = ({ children }: FormVerticalProps) => {
  return (
    <Stack component="form" spacing={2} noValidate autoComplete="off">
      {children}
    </Stack>
  )
}
