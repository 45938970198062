import { useCallback, useContext, useEffect, useState } from 'react'
import { Box, Button, Card, CardContent, Divider, Grid, Stack, Typography } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { TimePicker } from 'formik-mui-lab'
import { BooleanRadioButton } from 'src/admin/core/components/RadioButtons/BooleanRadioButton'
import { useAppTranslation, useNotification } from 'src/admin/core/hooks'
import { DistanceSelectFormik, TypeOfRacesFormik } from '../../components/Select'
import { WagerAutoCompleteSelectFormik } from '../../components/Select/WagerAutoCompleteSelectFormik'
import { useEventFormHandler, useRaceWagers } from '../../hooks'
import { EventContext, setRace, setRaceFormMode, setRunnerFormMode } from '../../state'
import { useFormikContext } from 'formik'
import { UpdateButton } from './UpdateButton'
import { CreateButton } from './CreateButton'
import { AddButton, ClearButton } from 'src/admin/core'
import { addMinutesToDateTimeUTC } from 'src/admin/core/services/dateFunctions'
import _ from 'lodash'
import { FORM_MODE } from '../../models'
import { useGetProgramOptionsByEntity } from 'src/admin/core/stores/programSelectOptions'
import { Loader } from 'src/admin/components/Loader'

type Props = {
  afterSave: (promise: Promise<any>) => void
}

export const Form: React.FC<Props> = ({ afterSave }) => {
  const { label, system } = useAppTranslation()
  const { errorNotification } = useNotification()
  const {
    fetchProgramDefaultWagers,
    selectedWagers,
    possibleWagersName,
    loadingWagers,
    possibleWagers,
    formatWagersName,
  } = useRaceWagers()
  const { setFieldValue, resetForm } = useFormikContext()

  const {
    dispatch,
    state: { event, races, race, raceFormMode, runnerFormMode },
  } = useContext(EventContext)

  useEffect(() => {
    if (event && races.length > 0) {
      const raceNumber = race ? race.raceNumber : races.length + 1
      fetchProgramDefaultWagers(event, raceNumber)
    }
  }, [event, race])

  useEffect(() => {
    if (race?.id) {
      const raceClone = _.cloneDeep(race)

      setFieldValue('raceNumber', raceClone.raceNumber)
      setFieldValue('postTime', raceClone.postTime)
      setFieldValue('runners', raceClone.runners)
      setFieldValue('purse', raceClone.purse)
      setFieldValue('ageRestriction', raceClone.ageRestriction)
      setFieldValue('wagerIds', formatWagersName(raceClone.wagerIds))
      setFieldValue('active', raceClone.active)

      dispatch(setRaceFormMode('EDIT'))
    }
  }, [race])

  useEffect(() => {
    if (race?.id) {
      const raceClone = _.cloneDeep(race)
      setFieldValue('wagerIds', formatWagersName(raceClone.wagerIds))
    }
  }, [race, possibleWagers])

  useEffect(() => {
    if (selectedWagers.length > 0 && raceFormMode === 'CREATE') {
      setFieldValue('wagerIds', selectedWagers.join('/'))
    }
  }, [selectedWagers])

  useEffect(() => {
    if (raceFormMode === 'CANCEL') {
      handleCancel()
    }
  }, [raceFormMode])

  const handleNewRace = () => {
    if (event && event.numberOfRaces <= races.length) {
      errorNotification(`${system('ERROR_CARRERA_MAXIMA_PERMITIDA')} ${event.numberOfRaces}`)
    } else {
      const raceNumber = races.length + 1
      setFieldValue('raceNumber', raceNumber)
      dispatch(setRaceFormMode('CREATE'))
      setNewRaceTime()
      fetchProgramDefaultWagers(event, raceNumber)
    }
  }

  const setNewRaceTime = () => {
    if (races.length > 0) {
      const lastRace = races[races.length - 1]
      setFieldValue('postTime', addMinutesToDateTimeUTC(lastRace.postTime, 15))
    }
  }

  const handleCancel = () => {
    resetForm()
    dispatch(setRace(null))
    setFieldValue('distanceId', { code: '', name: '' })
    setFieldValue('raceNumber', '')
    setFieldValue('postTime', addMinutesToDateTimeUTC(new Date(), 15))
    setFieldValue('runners', '')
    setFieldValue('distanceId', 0)
    setFieldValue('purse', '')
    setFieldValue('typeOfRace', '')
    setFieldValue('ageRestriction', '')
    setFieldValue('wagerIds', '')
  }
  const isDisabled = raceFormMode === 'CANCEL' || runnerFormMode !== 'CANCEL'

  return (
    <Loader loading={loadingWagers}>
      <Card>
        <Box p={2.5} display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography gutterBottom variant="h4">
              {label('CARRERAS')}
            </Typography>
          </Box>
          <Stack direction="row" spacing={1}>
            {raceFormMode === 'CANCEL' ? (
              <AddButton onClick={handleNewRace} />
            ) : (
              <>
                {race?.id ? (
                  <Box>
                    <UpdateButton afterSave={afterSave} wagers={possibleWagers} />
                  </Box>
                ) : (
                  <Box>
                    <CreateButton afterSave={afterSave} wagers={possibleWagers} />
                  </Box>
                )}
                <ClearButton onClick={() => dispatch(setRaceFormMode('CANCEL'))} />
              </>
            )}
          </Stack>
        </Box>
        <Divider />
        <CardContent>
          <Grid container spacing={3} pb={3}>
            <Grid item lg={3} md={3} xs={12} sm={12}>
              <Field
                fullWidth
                component={TextField}
                type="number"
                label={label('NUMERO')}
                name="raceNumber"
                keyLabel="name"
                keyValue="code"
                disabled={true}
              />
            </Grid>

            <Grid item lg={2} md={2} xs={12} sm={12}>
              <Field fullWidth component={TimePicker} label={label('HORA')} name="postTime" disabled={isDisabled} />
            </Grid>

            <Grid item lg={2} md={2} xs={12} sm={12}>
              <Field
                fullWidth
                component={TextField}
                type="number"
                label={label('CORREDORES')}
                name="runners"
                InputProps={{ inputProps: { min: 1, max: 99 } }}
                disabled={isDisabled}
              />
            </Grid>

            <Grid item lg={2} md={2} xs={12} sm={12}>
              <Field
                fullWidth
                component={DistanceSelectFormik}
                label={label('DISTANCIA')}
                name="distanceId"
                disabled={isDisabled}
                distanceId={race?.distanceId}
              />
            </Grid>

            <Grid item lg={2} md={2} xs={12} sm={12}>
              <Field
                fullWidth
                component={TextField}
                type="text"
                label={label('CARTERA')}
                name="purse"
                InputProps={{ inputProps: { min: 1, max: 99 } }}
                disabled={isDisabled}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} pb={3}>
            <Grid item lg={3} md={3} xs={12} sm={12}>
              <Field
                fullWidth
                component={TypeOfRacesFormik}
                label={label('TIPO')}
                name="typeOfRace"
                keyLabel="name"
                keyValue="code"
                disabled={isDisabled}
                typeId={race?.typeOfRace}
              />
            </Grid>

            <Grid item lg={2} md={2} xs={12} sm={12}>
              <Field
                fullWidth
                component={TextField}
                type="text"
                label={label('RESTRICCION_DE_EDAD')}
                name="ageRestriction"
                InputProps={{ inputProps: { min: 1, max: 99 } }}
                disabled={isDisabled}
              />
            </Grid>

            <Grid item lg={4} md={4} xs={12} sm={12}>
              <Field
                fullWidth
                component={WagerAutoCompleteSelectFormik}
                label={label('APUESTA')}
                name="wagerIds"
                data={possibleWagersName}
                keyLabel="name"
                keyValue="id"
                multiple={true}
                disabled={isDisabled}
              />
            </Grid>

            <Grid item lg={2} md={2} sm={12} xs={12}>
              <Field
                component={BooleanRadioButton}
                title={'ESTADO'}
                name="active"
                label1={'ACTIVO'}
                label2={'INACTIVO'}
                value1={'active'}
                value2={'inactive'}
                disabled={isDisabled}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Loader>
  )
}
