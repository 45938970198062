import { ProgramSelectOption } from './ProgramSelectOption'
import { createReducer } from 'typesafe-actions'

import { ProgramSelectOptionActions, setProgramSelectOption } from './actions'

export interface ProgramSelectOptionContextState {
  programSelectOption: ProgramSelectOption
  programSelectOptions?: ProgramSelectOption[]
}

export const initialState: ProgramSelectOptionContextState = {
  programSelectOption: {
    id: undefined,
    name: '',
    code: '',
    entity: '',
  } as ProgramSelectOption,
  programSelectOptions: [],
}

export const reducer = createReducer<ProgramSelectOptionContextState, ProgramSelectOptionActions>({
  programSelectOption: initialState.programSelectOption,
  programSelectOptions: initialState.programSelectOptions,
}).handleAction(setProgramSelectOption, (state, action) => {
  return {
    ...state,
    programSelectOption: action.payload,
  }
})
