import { format } from 'date-fns'
import moment from 'moment'

export const getCurrentYear = () => {
  return new Date().getFullYear()
}

export const formatDate = (date: Date | undefined | null) => {
  if (!date) return ''

  var offset = moment().utcOffset()
  return moment.utc(date.toString()).utcOffset(offset).format('dddd, MMMM Do YYYY, h:mm:ss a')
}

export const getCurrentShortDateUs = () => {
  var offset = moment().utcOffset()
  return moment.utc(moment().toString()).utcOffset(offset).format('MM/DD/YYYY')
}

export const formatToShortDate = (date: Date | undefined | null | string, format = 'DD/MM/YYYY') => {
  if (!date) return ''

  var offset = moment().utcOffset()
  return moment.utc(date.toString()).utcOffset(offset).format(format)
}

export const customFormatDate = (date: Date | undefined | null | string, format: string) => {
  if (!date) return ''

  var offset = moment().utcOffset()
  return moment.utc(date.toString()).utcOffset(offset).format(format)
}

export const formatToShortDateUS = (
  date: Date | undefined | null | string,
  format: string | undefined = 'DD/MM/YYYY',
) => {
  if (!date) return ''
  return moment.utc(date.toString()).format(format)
}

export const formatDateTime = (date: Date | undefined | null) => {
  if (!date) return ''

  var offset = moment().utcOffset()
  return moment.utc(date.toString()).utcOffset(offset).format('DD/MM/YYYY h:mm:ss')
}

export const isDateToday = (date: Date | string | undefined) => {
  return moment(date).isSame(new Date(), 'day')
}

export const isValidDate = (date: Date | string | undefined) => {
  return moment(date, moment.ISO_8601, true).isValid()
}

export const formatToShortLocalDate = (
  date: Date | undefined | null | string,
  format: string | undefined = 'DD/MM/YYYY',
) => {
  if (!date) return ''
  return moment.utc(date.toString()).format(format)
}

export const getDayNumber = (date: Date | string | undefined) => {
  const localDate = moment(date, 'YYYY/MM/DD')
  return date === undefined ? '' : localDate.format('D')
}

export const getYearNumber = (date: Date | string | undefined) => {
  const localDate = moment(date, 'YYYY/MM/DD')
  return date === undefined ? '' : localDate.format('Y')
}

export const formatTime = (date: Date | undefined | null) => {
  if (!date) return ''

  return moment.utc(date.toString()).format('h:mm A')
}

export const dateIsBeforeThanToday = (date: Date | undefined | null): boolean => {
  if (!date) return true

  var offset = moment().utcOffset()
  const incomeDateNumber = moment.utc(date.toString()).utcOffset(offset).format('D')
  const todayNumber = moment().date()

  return Number.parseFloat(incomeDateNumber) < todayNumber
}

export const getStartWeekByDate = (date, format = 'YYYY-MM-DD') =>
  moment(date, format).startOf('isoWeek').format(format)

export const getEndWeekByDate = (date, format = 'YYYY-MM-DD') => moment(date, format).endOf('isoWeek').format(format)

export const getMondayLastWeek = (lastWeek: string, format = 'YYYY-MM-DD'): string =>
  moment(lastWeek, format).isoWeekday(-6).format(format)

export const getLastWeek = (date: string, format = 'YYYY-MM-DD'): string =>
  moment(date, format).subtract(7, 'days').format(format)

export const getNextWeek = (date: string, format = 'YYYY-MM-DD'): string =>
  moment(date, format).add(7, 'days').format(format)

export const getCurrentDate = (format = 'YYYY-MM-DD'): string => {
  return moment.utc().format(format)
}

export const formatToShortLocalDateTime = (
  date: Date | undefined | null | string,
  format: string | undefined = 'DD-MM-YYYY h:mm:ss',
) => {
  if (!date) return ''
  return moment.utc(date.toString()).format(format)
}

export const formatToShortAPItDate = (date: Date | undefined | null | string) => {
  if (!date) return ''

  var offset = moment().utcOffset()
  return moment.utc(date.toString()).utcOffset(offset).format('YYYY-MM-DD')
}

export const formatDateTimeToUTC = (date: Date) => {
  if (!date) return ''

  return moment(date).utc().format('YYYY-MM-DD HH:mm')
}

export const formatToLocalTime = (date: Date | undefined | null | string) => {
  if (!date) return ''

  return moment(date.toString()).format('h:mm a')
}

export const dateIsBeforeThanTodayMoment = (date: Date | undefined | null | string): boolean => {
  if (!date) return true
  const shortDate = moment(date).utc()
  return moment(shortDate).isBefore(moment())
}

export const isFutureDate = (date: Date | undefined | null | string): boolean => {
  if (!date) return true
  const shortDate = moment(date).utc()
  const today = moment().format('YYYY-MM-DD')
  return moment(shortDate).isAfter(moment(today).utc())
}

export const isSameDate = (date: Date | undefined | null | string): boolean => {
  if (!date) return true
  const shortDate = moment(date).utc()
  const today = moment().format('YYYY-MM-DD')
  return moment(shortDate).isSame(moment(today).utc())
}

export const addMinutesToDateTimeUTC = (dateTime: Date | string, minsToAdd: number) => {
  return moment.utc(dateTime).add(minsToAdd, 'minutes').format()
}

export const getTomorrow = (format = 'YYYY-MM-DD'): string => {
  return moment().add(1, 'days').utc().format(format)
}

export const convertDateStringToUTC = (date: string): string => {
  return moment(date, 'YYYY-MM-DDTHH:mm').utc().format()
}

export const getLastDate = (dayBefore: number) => {
  return moment()
    .subtract(dayBefore - 1, 'day')
    .format('YYYY-MM-DD')
}

export const getDateBefore = (date: string | Date, format = 'YYYY-MM-DD'): Date =>
  moment(date, format).subtract(1, 'days').toDate()

export const getDateNext = (date: string | Date, format = 'YYYY-MM-DD'): Date =>
  moment(date, format).add(1, 'days').toDate()

export const formatJSDateToLocal = (date: Date) => {
  const dd = String(date.getDate()).padStart(2, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const yyyy = date.getFullYear()

  return yyyy + '-' + mm + '-' + dd
}

export const formatSpanishDate = (dateString: string): string => {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
  const date = new Date(dateString)
  const formattedDate = date.toLocaleDateString('es-ES', options as Intl.DateTimeFormatOptions)
  return formattedDate
}
