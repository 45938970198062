import { useState } from 'react'
import { Button, TextField, Grid } from '@mui/material'
import { useAppTranslation } from 'src/admin/core/hooks'
import { WorkstationProductSelect } from 'src/admin/core'
import AddBoxIcon from '@mui/icons-material/AddBox'
import { Product, Province } from 'src/admin/core/models'
import { WorkstationProductsEnum } from 'src/core/models'

export const ProductForm = ({
  onSave,
  province,
  handleMessageError,
}: {
  province: Province | undefined
  onSave: (product: Product) => void
  handleMessageError: (value: string) => void
}) => {
  const { label } = useAppTranslation()
  const [product, setProduct] = useState({
    name: '',
    code: '',
    providerCode: '',
  })

  const handleSave = () => {
    if (!province) {
      handleMessageError('PROVINCIA_REQUERIDA')
    } else {
      if (product.name === '' || product.code == '') {
        handleMessageError('PRODUCTO_O_CODIGO_REQUERIDO')
      } else {
        handleMessageError('')
        product.code = `D${province.code}${product.code}`
        product.providerCode = `POF${product.code}QzVQR01FZEZZMlpMMW14VQ==`

        onSave(product)
        setProduct({
          name: '',
          code: '',
          providerCode: '',
        })
      }
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <WorkstationProductSelect
            value={product.name as WorkstationProductsEnum}
            getDefaultValue={(val) => {
              setProduct({
                ...product,
                name: val,
              })
            }}
            onChange={(val) => {
              setProduct({
                ...product,
                name: val,
              })
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label={label('CODIGO')}
            variant="outlined"
            fullWidth
            value={product.code}
            onChange={(e) => {
              const regex = /^[0-9\b]+$/
              const value = e.target.value as string
              if ((regex.test(value) || value === '') && value.length < 7) {
                setProduct({
                  ...product,
                  code: e.target.value as string,
                })
              }
            }}
          />
        </Grid>
        {/* <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label={label('PROVEEDOR')}
            variant="outlined"
            fullWidth
            value={product.providerCode}
            onChange={(e) => {
              setProduct({
                ...product,
                providerCode: e.target.value as string,
              })
            }}
          />
        </Grid> */}
        <Grid item xs={1}>
          <Button color="primary" aria-label="upload picture" size="large" onClick={handleSave}>
            <AddBoxIcon />
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default ProductForm
