import { Fragment, useCallback, useContext, useState } from 'react'
import { IconButton } from '@mui/material'
import { GridCellParams, GridRowParams } from '@mui/x-data-grid'

// App Components
import { DataGridColumns } from 'src/admin/core/components/DataGrid/CustomDataGrid'
import { AdminDataGrid } from 'src/admin/core/components/DataGrid/AdminDataGrid'
import { ContentDataGrid } from 'src/admin/core/components/DataGrid'
import { useAppTranslation, useNotification } from 'src/admin/core/hooks'
import { Race } from '../models'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useRemoveRace } from '../hooks'
import { EventContext, setRace, setRaceFormMode, setRunnerFormMode } from '../state'
import { DialogAlerts } from 'src/admin/core/components/alerts'
import { formatAsCurrency } from 'src/admin/core'

type RacesProps = {
  afterRemove: () => void
}

const Races = ({ afterRemove }: RacesProps) => {
  const { system, label } = useAppTranslation()
  const { errorNotification, successNotification } = useNotification()
  const [showAlertRemoveRace, setShowAlertRemoveRace] = useState(false)
  const { removeRace, error } = useRemoveRace()
  const [raceToRemove, setRaceToRemove] = useState<Race | undefined>(undefined)

  const {
    state: { event, races },
    dispatch,
  } = useContext(EventContext)

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      DE_CARRERA_NO: { field: 'raceNumber', contentType: 'number', align: 'left' },
      HORA: { field: 'postTime', contentType: 'time' },
      DESCRIPCION: {
        field: 'description',
        contentType: 'custom',
        align: 'left',
        headerAlign: 'left',
        renderCell: (params: GridCellParams) => {
          const race = params.row as Race
          return <span>{`${race.distance.name} ${label('PREMIO')} ${formatAsCurrency(Number(race.purse))}`}</span>
        },
      },
      ESTADO: { field: 'active', contentType: 'boolean' },
    }
  }, [])

  const selectToEdit = (params: GridRowParams) => {
    dispatch(setRaceFormMode('EDIT'))
    dispatch(setRunnerFormMode('CANCEL'))
    dispatch(setRace(params.row as Race))
  }

  const handleRemoveRace = () => {
    try {
      const response = removeRace({
        variables: {
          id: raceToRemove.id,
        },
      })
      setShowAlertRemoveRace(false)
      afterRemove()
      successNotification(system('DATO_BORRADO'))
    } catch (e) {
      console.log(e)
    }
  }

  const handleRowSelectedToRemove = (params: GridCellParams) => {
    const raceToRemove = params.row as Race
    setRaceToRemove(raceToRemove)
    setShowAlertRemoveRace(true)
  }

  return (
    <>
      <ContentDataGrid height={300}>
        <AdminDataGrid
          data={races}
          columns={getColumnDefinitions()}
          onRowClick={selectToEdit}
          hideFooterPagination={true}
        />
      </ContentDataGrid>
      <DialogAlerts
        state={showAlertRemoveRace}
        titulo={'ELIMINAR_ITEM'}
        contenido={'DESEAR_ELIMINAR_ESTE_ITEM'}
        tipo="PREGUNTA"
        onSecondaryClick={() => setShowAlertRemoveRace(false)}
        handleClose={() => setShowAlertRemoveRace(false)}
        onPrimaryClick={handleRemoveRace}
      />
    </>
  )
}

export default Races
