import { Card, CardContent, Divider, Grid, Stack, fabClasses } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { DatePicker } from 'formik-mui-lab'
import { useAppTranslation } from 'src/admin/core/hooks'
import { CedulaTextFieldFormik, FormAsterisk, PhoneTextFieldFormik, StringFormikSelect } from 'src/admin/core'
import { StringRadioButtonFormik } from 'src/admin/core/components/RadioButtons'
import { People } from '../models'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { usePeopleMasters } from '../hooks'
import { generateFilterGraphql } from 'src/admin/core'

export const Form = ({ people, disabledForm }: { disabledForm: boolean; people: People }) => {
  const { label } = useAppTranslation()
  const form = useFormikContext()
  const { results, loading, getPeopleMasters } = usePeopleMasters()
  const [fetchedPeopleMasters, setFetchedPeopleMasters] = useState(false)
  const [peopleMasters, setPeopleMasters] = useState<People | null>(null)
  const { values, setFieldValue } = form
  const formValue = values as People
  const identificationType = formValue.identificationType

  useEffect(() => {
    setFieldValue('identificationNumber', '')
    setFieldValue('firstName', '')
    setFieldValue('lastName', '')
    setFieldValue('birthdate', null)
    setFieldValue('gender', 'MALE')
    setPeopleMasters(null)
  }, [identificationType])

  // Validate padron
  useEffect(() => {
    const identificationType = values['identificationType']

    if (identificationType === 'CEDULA') {
      const identificationNumber = values['identificationNumber']
      const customFilters = {
        where: {
          and: [{ identificationNumber: { ...generateFilterGraphql(identificationNumber.replace(/\D/g, ''), 'eq') } }],
        },
      }
      setFetchedPeopleMasters(true)
      getPeopleMasters(customFilters)
    }
  }, [values])

  useEffect(() => {
    if (results.results.length > 0) {
      const master = results.results[0]
      setPeopleMasters(master)
      setFieldValue('firstName', master.firstName)
      setFieldValue('lastName', master.lastName)
      setFieldValue('birthdate', master.birthdate)
      setFieldValue('gender', master.gender)
    } else {
      if (peopleMasters) {
        setFieldValue('firstName', '')
        setFieldValue('lastName', '')
        setFieldValue('birthdate', null)
        setFieldValue('gender', 'MALE')
        setPeopleMasters(null)
      }
    }
  }, [results])

  return (
    <Card>
      <Divider />
      <CardContent>
        <Stack spacing={2}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Field
                fullWidth
                component={StringFormikSelect}
                labelTitle={label('TIPO_DE_DOCUMENTO')}
                name="identificationType"
                data={['CEDULA', 'PASSPORT', 'DRIVER_LICENCE']}
                defaultValue={people.identificationType}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {identificationType === 'CEDULA' ? (
                <FormAsterisk validate={true}>
                  <Field
                    fullWidth
                    component={CedulaTextFieldFormik}
                    type="text"
                    label={label('DOCUMENTO')}
                    name="identificationNumber"
                    disabled={disabledForm}
                  />
                </FormAsterisk>
              ) : (
                <FormAsterisk validate={true}>
                  <Field
                    fullWidth
                    component={TextField}
                    type="text"
                    label={label('DOCUMENTO')}
                    name="identificationNumber"
                    disabled={disabledForm}
                  />
                </FormAsterisk>
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormAsterisk validate={true}>
                <Field
                  fullWidth
                  component={TextField}
                  type="text"
                  label={label('NOMBRE')}
                  name="firstName"
                  disabled={disabledForm || peopleMasters !== null}
                />
              </FormAsterisk>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormAsterisk validate={true}>
                <Field
                  fullWidth
                  component={TextField}
                  type="text"
                  label={label('APELLIDO')}
                  name="lastName"
                  disabled={disabledForm || peopleMasters !== null}
                />
              </FormAsterisk>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormAsterisk>
                <Field
                  fullWidth
                  component={TextField}
                  type="text"
                  label={label('APODO')}
                  name="nickName"
                  disabled={disabledForm}
                />
              </FormAsterisk>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Field
                fullWidth
                component={TextField}
                type="email"
                label={label('EMAIL')}
                name="email"
                disabled={disabledForm}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormAsterisk validate={true}>
                <Field
                  fullWidth
                  component={PhoneTextFieldFormik}
                  label={label('TELEFONO')}
                  name="cellPhone"
                  disabled={disabledForm}
                />
              </FormAsterisk>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormAsterisk validate={true}>
                <Field
                  fullWidth
                  component={DatePicker}
                  type="text"
                  label={label('FECHA_NACIMIENTO')}
                  name="birthdate"
                  inputFormat={'dd/MM/yyyy'}
                  disabled={disabledForm || peopleMasters !== null}
                />
              </FormAsterisk>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Field
                component={StringRadioButtonFormik}
                title={'SEXO'}
                name="gender"
                label1={'MALE'}
                label2={'FEMALE'}
                value1={'MALE'}
                value2={'FEMALE'}
                disabled={disabledForm || peopleMasters !== null}
              />
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  )
}
