import { useCallback } from 'react'
import { gql, useLazyQuery } from '@apollo/client'

const GET_LOTSALCOMDOWNLOAD = gql`
  query LotsalcomFileDownloadUri($date: DateTime!) {
    lotsalcomFileDownloadUri(date: $date) {
      sasUri
      expiration
      fileName
    }
  }
`

interface Data {
  lotsalcomFileDownloadUri: {
    sasUri: string
    expiration: Date
    fileName: string
  }
}

export const useLotsalcomFileDownloadUri = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<Data>(GET_LOTSALCOMDOWNLOAD, {
    fetchPolicy: 'network-only',
  })

  const getLotsalcomFileDownloadUri = useCallback(
    (date: string) => {
      getResults({ variables: { date } })
    },
    [getResults],
  )

  const file = data?.lotsalcomFileDownloadUri || undefined

  return { loading, error, file, getLotsalcomFileDownloadUri }
}
