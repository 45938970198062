import { gql, useLazyQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../../../core/models'
import { Race } from '../../models'
import { useEffect, useState } from 'react'

const GET_RACES = gql`
  query GetProgramRaces($where: ProgramRaceFilterInput, $skip: Int, $take: Int, $order: [ProgramRaceSortInput!]) {
    programRaces(where: $where, skip: $skip, take: $take, order: $order) {
      items {
        id
        programEventId
        raceNumber
        typeOfRace
        purse
        distanceId
        runners
        genderRestriction
        ageRestriction
        weight
        wagerIds
        breed
        course
        postTime
        createdDate
        createdBy
        active
        distance {
          name
        }
        programRunners {
          id
          number
          medication
          weight
          odds
          jockeyOrKennelId
          jockeyOrKennel {
            name
          }
          ownerId
          owner {
            name
          }
          competitorId
          competitor {
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface RacesData {
  programRaces: {
    totalCount: number
    items: Race[]
  }
}

export const useRaces = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<RacesData>(GET_RACES, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<Race, Metadata>>({
    results: [],
    metadata: {},
  })

  useEffect(() => {
    if (data?.programRaces) {
      setResults({
        results: data?.programRaces.items || [],
        metadata: {
          Count: data?.programRaces.totalCount,
        },
      })
    }
  }, [loading])

  const getRaces = ({ where, take, skip, order }: SearchGraphql) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 5,
        order: order
          ? order
          : {
              raceNumber: 'ASC',
            },
      },
    })
  }

  return { getRaces, loading, error, results }
}
