import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCountries } from '../../hooks/geography'
import { Country, IFormField } from '../../models'
import { TextField, Autocomplete, AutocompleteChangeReason } from '@mui/material'

export interface CountrySelectProps extends IFormField {
  label?: string
  onChangeCountry?: (country: Country) => void
  disabled?: boolean
  defaultCountry?: string
}

export const CountrySelect = ({ onChangeCountry, defaultCountry, disabled, ...props }: CountrySelectProps) => {
  const {
    form: { setFieldValue, values, errors },
    field: { name },
  } = props

  const { getCountries, results, loading } = useCountries()
  const { t: transLabel } = useTranslation()
  const [selected, setSelected] = useState<any>(null)
  const countries = results.results
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    filterCountries(defaultCountry || '')
  }, [defaultCountry])

  useEffect(() => {
    if (results.results.length > 0) {
      const filter = results.results.filter(
        (item: any) => item.code === defaultCountry || item.id === defaultCountry || values[name],
      )
      if (filter.length > 0) {
        setSelected(filter[0])
        setFieldValue('country', filter[0])
      }
    }
  }, [defaultCountry, countries])

  const filterCountries = (code?: string, skip = 0, take = 100) => {
    // const customFilters = {
    //   and: [{ code: { ...generateFilterGraphql(code || '', 'contains') } }],
    // }

    getCountries({ skip, take })
  }

  const handleChange = useCallback(
    (event: React.ChangeEvent<any>, value: any, reason: AutocompleteChangeReason) => {
      setFieldValue(name, value)
      setSelected(value)
      if (onChangeCountry instanceof Function && value) {
        onChangeCountry(value)
      }
    },
    [setFieldValue, name, onChangeCountry],
  )

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setInputValue(value)
  }

  return (
    <Autocomplete
      sx={{
        m: 0,
        minWidth: 'inherit',
      }}
      onChange={handleChange}
      value={selected}
      getOptionLabel={(option) => (option['name'] ? option['name'] : '')}
      options={countries}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={onInputChange}
          label={transLabel('PAIS')}
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors[name]}
          helperText={errors[name] ?? ''}
        />
      )}
      disabled={disabled}
      {...props}
    />
  )
}
