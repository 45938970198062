import React from 'react'
import { Grid } from '@mui/material'
import { FormBody } from '../Form/FormBody'
import { FormVertical } from '../Form/FormVertical'

type FormContentProps = {
  children: JSX.Element | JSX.Element[]
  orientation?: 'vertical' | 'horizontal'
}

const FormContent: React.FC<FormContentProps> = ({ orientation, children }) => {
  return (
    <>
      <Grid container direction="row" alignItems="stretch" spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {orientation === 'vertical' ? (
            <FormVertical>{children}</FormVertical>
          ) : orientation === 'horizontal' ? (
            <FormBody>{children}</FormBody>
          ) : (
            children
          )}
        </Grid>
      </Grid>
    </>
  )
}

FormContent.displayName = 'FormContent'
export { FormContent }
