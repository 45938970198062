import * as Yup from 'yup'
import { Formik } from 'formik'
import { Button, TextField, CircularProgress, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { usePasswordReset } from './hook/usePasswordReset'
import { useEffect, useState } from 'react'
import { Loader } from 'src/admin/components/Loader'
import { useSearchParams } from 'react-router-dom'

function Form({ handleAfterSave }: { handleAfterSave: (res: boolean) => void }) {
  const { passwordReset, error, loading } = usePasswordReset()
  const { t }: { t: any } = useTranslation()
  const [messageResponse, setMessageResponse] = useState('')
  const [searchParams] = useSearchParams()

  const code = searchParams.get('code')
  const user = searchParams.get('user')

  const initialFormValues = {
    password: '',
    passwordConfirmation: '',
    userName: user || '',
    passwordResetCode: code || '',
    submit: null,
  }

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={Yup.object().shape({
        userName: Yup.string().required(t('REQUERIDO')),
        passwordResetCode: Yup.string().required(t('REQUERIDO')),
        password: Yup.string()
          .required(t('REQUERIDO'))
          .min(6, t('CLAVE_NO_CUMPLE'))
          .matches(/[0-9]+/, t('CLAVE_NO_CUMPLE'))
          .matches(/[A-Z]/, t('CLAVE_NO_CUMPLE'))
          .matches(/[a-z]/, t('CLAVE_NO_CUMPLE'))
          .matches(/[^\w\s]/, t('CLAVE_NO_CUMPLE')),
        passwordConfirmation: Yup.string().oneOf(
          [Yup.ref('password'), null],
          t('THE_PASSWORD_AND_CONFIRMATION_DID_NOT_MATCH'),
        ),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        await passwordReset({
          variables: {
            input: {
              password: values.password,
              passwordConfirmation: values.passwordConfirmation,
              passwordResetCode: values.passwordResetCode,
              userName: values.userName,
            },
          },
        })
          .then((response) => {
            if (response.data?.passwordReset.success) {
              setStatus({ success: true })
              handleAfterSave(true)
            } else {
              setMessageResponse(
                response.data?.passwordReset.errors.length > 0
                  ? response.data?.input.data.passwordReset.errors[0]
                  : t('ERROR_DESCONOCIDO'),
              )
            }
          })
          .catch((err) => {
            setStatus({ success: false })
            setMessageResponse(err.message)
            setSubmitting(false)
          })
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <>
          <form noValidate onSubmit={handleSubmit}>
            {!user && (
              <TextField
                error={Boolean(touched.userName && errors.userName)}
                fullWidth
                margin="normal"
                helperText={touched.userName && errors.userName}
                label={t('USUARIO')}
                name="userName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.userName}
                variant="outlined"
                type="text"
                inputProps={{ autoComplete: 'off' }}
                autoFocus
              />
            )}

            {!code && (
              <TextField
                error={Boolean(touched.passwordResetCode && errors.passwordResetCode)}
                fullWidth
                margin="normal"
                helperText={touched.passwordResetCode && errors.passwordResetCode}
                label={t('CODIGO')}
                name="passwordResetCode"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.passwordResetCode}
                variant="outlined"
                inputProps={{ autoComplete: 'off' }}
              />
            )}

            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              margin="normal"
              helperText={touched.password && errors.password}
              label={t('NUEVA_CLAVE')}
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
              inputProps={{ autoComplete: 'off' }}
              autoFocus={!!user && !!code}
            />

            <TextField
              error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
              fullWidth
              margin="normal"
              helperText={touched.passwordConfirmation && errors.passwordConfirmation}
              label={t('CONFIRMAR_CLAVE')}
              name="passwordConfirmation"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.passwordConfirmation}
              variant="outlined"
              inputProps={{ autoComplete: 'off' }}
            />

            <Button
              sx={{
                mt: 2,
                mb: 2,
              }}
              color="primary"
              startIcon={loading ? <CircularProgress size="1rem" /> : null}
              disabled={loading}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
            >
              {t('CAMBIAR_CLAVE')}
            </Button>
          </form>

          {messageResponse.length > 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography color={'error'} variant="h5">
                  {t(messageResponse)}
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Formik>
  )
}

export default Form
