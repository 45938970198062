import { SaveButton } from 'src/admin/core'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { useNotification } from 'src/admin/core/hooks'
import { useUpsertPeople } from '../hooks'
// import { useAddProgramEvent } from '../hooks'

export const CreateButton: React.FC<{ afterSave?: (promise: Promise<any>, action: string) => void }> = ({
  afterSave,
}) => {
  const { upsertPeople, loading, error } = useUpsertPeople()
  const { errorGraphNotification } = useNotification()

  const form = useFormikContext()
  const { values, dirty, isValid, setSubmitting } = form

  useEffect(() => {
    if (error) {
      errorGraphNotification(error)
    }
  }, [error])

  const handleCreate = () => {
    setSubmitting(true)
    const peopleToSave = values as any

    const response = upsertPeople({
      variables: {
        input: {
          identificationType: peopleToSave.identificationType == 'CEDULA' ? 'ID' : peopleToSave.identificationType,
          identificationNumber: peopleToSave.identificationNumber.replace(/\D/g, ''),
          firstName: peopleToSave.firstName,
          lastName: peopleToSave.lastName,
          email: peopleToSave.email,
          nickName: peopleToSave.nickName,
          gender: peopleToSave.gender,
          birthdate: peopleToSave.birthdate,
          cellPhone: peopleToSave.cellPhone,
          // active: peopleToSave.active,
        },
      },
    })
    response
      .then((result: any) => {
        if (afterSave) {
          afterSave(response, 'create')
        }
        setSubmitting(false)
      })
      .catch(() => {
        setSubmitting(false)
      })
  }

  return <SaveButton onClick={handleCreate} isLoading={loading} disabled={!(dirty && isValid)} />
}
