import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { useAppTranslation, useNotification } from 'src/admin/core/hooks'
import { Alert, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { User } from '../models'
import { useWorkstations } from 'src/admin/scenes/entities/stations/hooks'
import { generateFilterGraphql } from 'src/admin/core/services'
import { Loader } from 'src/admin/components/Loader'
import { Enterprise, Workstation } from 'src/core/models'
import DeleteIcon from '@mui/icons-material/Delete'
import { useUpsertWorkstation } from 'src/admin/scenes/entities/workstations/hooks/useUpsertWorkstation'
import LoadingButton from '@mui/lab/LoadingButton'
import _ from 'lodash'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export const UserEnterprises = ({ show, onClose, user }: { user: User; show: boolean; onClose: () => void }) => {
  const { label } = useAppTranslation()
  const {
    results: { results },
    loading,
    getWorkstations,
  } = useWorkstations()
  const { upsertWorkstation, loading: savingWorkstation, error } = useUpsertWorkstation()
  const { successNotification, errorNotification } = useNotification()

  React.useEffect(() => {
    if (user) {
      fetchWorkstations(user)
    }
  }, [user])

  const fetchWorkstations = (user: User) => {
    const customFilters = {
      accessList: {
        some: { ...generateFilterGraphql(user.id, 'contains') },
      },
    }

    getWorkstations({ where: customFilters, skip: 0, take: 100 })
  }

  const handleRemoveWorkstation = (workstation: Workstation) => {
    const workstationCopy = _.cloneDeep(workstation)
    workstationCopy.accessList = workstationCopy.accessList.filter((id) => id !== user.id)
    handleUpdateWorkstation(workstationCopy)
  }

  const handleUpdateWorkstation = (workstation: Workstation) => {
    try {
      const response = upsertWorkstation({
        variables: {
          input: {
            id: workstation.id,
            name: workstation.name,
            type: workstation.type,
            active: workstation.active,
            enterpriseId: workstation.enterprise.id,
            accessList: workstation.accessList,
          },
        },
      })
      successNotification(label('DATO_ACTUALIZADO'))

      setTimeout(() => {
        fetchWorkstations(user)
      }, 1000)
    } catch (e) {
      errorNotification(label('ERROR_ACTUALIZANDO_EL_DATO'))
    }
  }

  return (
    <div>
      <BootstrapDialog onClose={onClose} aria-labelledby="customized-dialog-title" open={show} maxWidth="md" fullWidth>
        <BootstrapDialogTitle id="user-enterprises" onClose={onClose}>
          {label('ESTACIONES_DEL_USUARIO')}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Alert severity="info">
            {user.givenName} {user.familyName} — <strong>{user.userName}</strong>
          </Alert>
          <Loader loading={loading}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{label('AGENCIA')}</TableCell>
                    <TableCell>{label('ESTACION')}</TableCell>
                    <TableCell>{label('TIPO')}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results.map((row: Workstation) => (
                    <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {row.agency.name}
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{label(row.type)}</TableCell>
                      <TableCell>
                        {savingWorkstation ? (
                          <LoadingButton loading={true} />
                        ) : (
                          <DeleteIcon onClick={() => handleRemoveWorkstation(row)} sx={{ cursor: 'pointer' }} />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Loader>
        </DialogContent>
      </BootstrapDialog>
    </div>
  )
}
