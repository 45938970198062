import { AgencyWeeklySales } from '../models/AgencyWeeklySales'
import { useState, useEffect, useCallback } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql, WhereGraphql } from 'src/admin/core/models'
import _ from 'lodash'

const GET_AGENCY_WEEKLY_SALES = gql`
  query AgencyTicketTransactionsSummary(
    $product: ProductEnum!
    $currency: CurrencyType!
    $from: DateTime
    $to: DateTime
    $where: TicketTransactionsSummaryFilterInput
    $where2: AccountTransactionsSummaryFilterInput
  ) {
    agencyTicketTransactionsSummary(
      product: $product
      currency: $currency
      from: $from
      to: $to
      where: $where
      order: { amount: DESC }
    ) {
      amount
      commission
      surcharge
      key {
        id
        category
        date
        transactionType
        currency
        agency {
          name
          products {
            code
          }
        }
      }
    }
    agencyAccountTransactionsSummary(product: $product, currency: $currency, from: $from, to: $to, where: $where2) {
      amount
      key {
        id
        category
        date
        transactionType
      }
    }
  }
`

interface Data {
  agencyTicketTransactionsSummary: AgencyWeeklySales[]
  agencyAccountTransactionsSummary: AgencyWeeklySales[]
}

export const useAgencyTicketTransactionsSummary = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<Data>(GET_AGENCY_WEEKLY_SALES, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<AgencyWeeklySales, Metadata>>({
    results: [],
    metadata: {},
  })
  const [drawData, setDrawData] = useState<AgencyWeeklySales[]>([])

  const getAgencyTicketTransactionsSummary = useCallback(
    ({
      product,
      currency,
      from,
      to,
      where,
    }: {
      product: string
      currency: string
      from: string
      to: string
      where: WhereGraphql | WhereGraphql[]
    }) => {
      getResults({
        variables: {
          where: {
            or: where,
          },
          where2: {
            or: where,
          },
          product,
          currency,
          to,
          from,
          order: {
            key: {
              date: 'ASC',
            },
          },
        },
      })
    },
    [getResults],
  )

  useEffect(() => {
    if (data?.agencyTicketTransactionsSummary) {
      setResults({
        results: data?.agencyTicketTransactionsSummary ? data?.agencyTicketTransactionsSummary : [],
        metadata: {
          Count: 100,
        },
      })
    }

    if (data?.agencyAccountTransactionsSummary) {
      setDrawData(data?.agencyAccountTransactionsSummary)
    }
  }, [loading])

  return {
    loading,
    error,
    results,
    drawAndPayDraw: drawData,
    getAgencyTicketTransactionsSummary,
  }
}
