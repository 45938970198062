import { createReducer } from 'typesafe-actions'

import {
  EventActions,
  setEvent,
  setEventFormMode,
  setRace,
  setRaces,
  setRaceFormMode,
  setRunner,
  setRunnerFormMode,
  setRunners,
  setTypeOfRaces,
} from './actions'
import { Event, FORM_MODE, ProgramTypeOfRaces, Race, Runner } from '../models'

export interface EventState {
  event: Event
  race?: Race
  races: Race[]
  runner?: Runner
  runners: Runner[]
  typeOfRaces: ProgramTypeOfRaces[]
  eventFormMode: FORM_MODE
  runnerFormMode: FORM_MODE
  raceFormMode: FORM_MODE
}

export const initialState: EventState = {
  event: {
    id: '',
    name: '',
    date: new Date(),
    performanceCode: '',
    numberOfRaces: 1,
    active: true,
  },
  race: null,
  races: [],
  runner: null,
  runners: [],
  typeOfRaces: [],
  eventFormMode: 'VIEW',
  runnerFormMode: 'CANCEL',
  raceFormMode: 'CANCEL',
}

export const reducer = createReducer<EventState, EventActions>({
  event: initialState.event,
  race: initialState.race,
  races: initialState.races,
  runner: initialState.runner,
  runners: initialState.runners,
  eventFormMode: initialState.eventFormMode,
  runnerFormMode: initialState.runnerFormMode,
  raceFormMode: initialState.raceFormMode,
  typeOfRaces: initialState.typeOfRaces,
})
  .handleAction(setEvent, (state, action) => {
    return {
      ...state,
      event: action.payload,
    }
  })
  .handleAction(setRace, (state, action) => {
    return {
      ...state,
      race: action.payload,
    }
  })
  .handleAction(setRaces, (state, action) => {
    return {
      ...state,
      races: action.payload,
    }
  })
  .handleAction(setRunner, (state, action) => {
    return {
      ...state,
      runner: action.payload,
    }
  })
  .handleAction(setRunners, (state, action) => {
    return {
      ...state,
      runners: action.payload,
    }
  })
  .handleAction(setEventFormMode, (state, action) => {
    return {
      ...state,
      eventFormMode: action.payload,
    }
  })
  .handleAction(setRunnerFormMode, (state, action) => {
    return {
      ...state,
      runnerFormMode: action.payload,
    }
  })
  .handleAction(setRaceFormMode, (state, action) => {
    return {
      ...state,
      raceFormMode: action.payload,
    }
  })
  .handleAction(setTypeOfRaces, (state, action) => {
    return {
      ...state,
      typeOfRaces: action.payload,
    }
  })
