import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, useTheme } from '@mui/material'
import { GridRowParams } from '@mui/x-data-grid'
// App Components
import { DataGridColumns } from 'src/admin/core/components/DataGrid/CustomDataGrid'
import { AdminDataGrid } from 'src/admin/core/components/DataGrid/AdminDataGrid'
import { Page } from 'src/admin/core/components'
import { CreateButton, generateFilterGraphql } from 'src/admin/core'
import { ContentDataGrid } from 'src/admin/core/components/DataGrid'

import { useAppTranslation, usePagination, useUserClaimActions } from 'src/admin/core/hooks'
import { ClaimActions } from 'src/admin/core/models'
import { usePeoples } from './hooks'
import { People } from './models'
import Filters, { FiltersProps, initialFilters } from './components/Filters'

const Peoples = () => {
  const { label } = useAppTranslation()
  const navigate = useNavigate()
  const { results, loading, getPeoples } = usePeoples()
  const { page, pageSize, skip, handlePageChange, handlePageSizeChange } = usePagination()
  const [filters, setFilters] = useState<FiltersProps>(initialFilters)
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('security.peoples', [
    ClaimActions.CanCreate,
    ClaimActions.CanSearch,
    ClaimActions.CanView,
  ])
  const [showEnterprises, setShowEnterprises] = useState(false)
  const [people, setPeople] = useState<People>(null)
  useEffect(() => {}, [validateClaimActions])

  useEffect(() => {
    const customFilters = {
      where: {
        or: [
          { firstName: { ...generateFilterGraphql(filters.search, 'contains') } },
          { lastName: { ...generateFilterGraphql(filters.search, 'contains') } },
          { nickName: { ...generateFilterGraphql(filters.search, 'contains') } },
          { email: { ...generateFilterGraphql(filters.search, 'contains') } },
          { identificationNumber: { ...generateFilterGraphql(filters.search, 'contains') } },
        ],
      },
      take: pageSize,
      skip: skip,
    }
    getPeoples(customFilters)
  }, [filters, skip, pageSize])

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      DOCUMENTO: { field: 'identificationNumber', contentType: 'string' },
      NOMBRES: { field: 'firstName', contentType: 'string' },
      APELLIDOS: { field: 'lastName', contentType: 'string' },
      APODO: { field: 'nickName', contentType: 'string' },
    }
  }, [])

  const goToCreate = () => {
    navigate(`/admin/entities/peoples/create`)
  }

  const goToEdit = (row: GridRowParams) => {
    if (actions.canView) {
      navigate(`/admin/entities/peoples/${row.id}`)
    }
  }

  return (
    <>
      <Page
        hasAccess={actions.canView}
        tabTitle={label('PERSONAS_EMPLEADOS')}
        pageTitle={label('PERSONAS_EMPLEADOS')}
        pageSubTitle={label('GESTION_DE_PERSONAS_Y_EMPLEADOS')}
        actions={actions.canCreate && <CreateButton onClick={goToCreate} />}
      >
        {actions.canSearch && (
          <Grid item xs={12}>
            <Filters onChange={(filters) => setFilters({ ...filters })} />
          </Grid>
        )}
        <Grid item xs={12}>
          <ContentDataGrid>
            <AdminDataGrid
              columns={getColumnDefinitions()}
              data={results.results}
              count={results.metadata.Count}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              loading={loading}
              page={page}
              pageSize={pageSize}
              onRowClick={goToEdit}
            />
          </ContentDataGrid>
        </Grid>
      </Page>
    </>
  )
}

export default Peoples
