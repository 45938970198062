import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import SuspenseLoader from 'src/components/SuspenseLoader'
import Users from '../scenes/security/users'
import User from '../scenes/security/users/user'
import { Roles } from '../scenes/security/roles'

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  )

const StatusComingSoon = Loader(lazy(() => import('src/admin/content/pages/Status/ComingSoon')))

const securityRoutes = [
  {
    path: '/',
    element: <Navigate to="roles" replace />,
  },
  {
    path: 'roles',
    element: <Roles />,
  },
  {
    path: 'users',
    element: <Users />,
  },
  {
    path: 'users/create',
    element: <User />,
  },
  {
    path: 'users/:id',
    element: <User />,
  },
]

export default securityRoutes
