import { useRoutes } from 'react-router-dom'
import router from 'src/router'

import { SnackbarProvider } from 'notistack'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import useAuth from 'src/hooks/useAuth'

import { CssBaseline } from '@mui/material'
import ThemeProvider from './theme/ThemeProvider'
import 'src/i18n'

function App() {
  const content = useRoutes(router)
  const auth = useAuth()

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <CssBaseline />
          {content}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}
export default App
