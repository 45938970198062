import { Card, CardContent, CardHeader, Divider, FormControl, Grid, Stack, Typography } from '@mui/material'
import { Field, useFormikContext } from 'formik'
import { TextField } from 'formik-material-ui'
import { useCallback, useEffect, useState } from 'react'
import { useAppTranslation, useNotification } from 'src/admin/core/hooks'
import Products from './AgencyProduct'
import { Municipality, Product, Province } from 'src/admin/core/models'
import ProductForm from './AgencyProduct/ProductForm'
import { PhoneTextFieldFormik, TypeSelect } from 'src/admin/core'
import { BooleanRadioButton } from 'src/admin/core/components/RadioButtons'
import { MunicipalitySelect, ProvinceSelect, SectorSelect } from 'src/admin/core/components/Geography'
import { Enterprise } from 'src/core/models'
import Text from 'src/admin/components/Text'
import { AgencyForm as FormType } from '../hooks'

export const AgencyForm = () => {
  const { label } = useAppTranslation()
  const { errorNotification } = useNotification()
  const { setFieldValue, values } = useFormikContext()
  const agency = values as FormType
  const strEnterprise = window.sessionStorage.getItem('selectedEnterprise')
  const enterprise = JSON.parse(strEnterprise) as Enterprise
  const [messageError, setMessageError] = useState('')

  const countryId = enterprise.addresses?.length > 0 ? enterprise.addresses[0].countryId : ''

  const handleDeleteProduct = useCallback(
    (product: Product) => {
      setFieldValue(
        'products',
        agency.products.filter(
          (prod) =>
            prod?.code !== product.code || prod?.name !== product.name || prod?.providerCode !== product.providerCode,
        ),
      )
    },
    [setFieldValue, agency.products],
  )

  const handleSaveProduct = useCallback(
    (product: Product) => {
      const exists = agency.products.some((prod) => prod.name === product.name)
      if (!exists) {
        setFieldValue('products', [...agency.products, product])
      } else {
        setMessageError(label('PRODUCTO_DUPLICADO'))
      }
    },
    [setFieldValue, agency.products],
  )

  return (
    <Card>
      <CardHeader title={label('DATOS_DE_LA_AGENCIA')} />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Field fullWidth component={TextField} type="text" label={label('NOMBRE')} name="name" />
          </Grid>
          <Grid item xs={4}>
            <TypeSelect disabled={true} />
          </Grid>
          <Grid item xs={4}>
            <Field fullWidth component={PhoneTextFieldFormik} label={label('TELEFONO')} name="phone" />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <Field
                component={ProvinceSelect}
                name={'stateOrProvinceId'}
                countryId={countryId}
                disabled={countryId === ''}
                defaultValue={agency.stateOrProvinceId?.id}
              />
            </FormControl>
            {countryId === '' && <Text color="error">{label('PAIS_CONSORCIO_INVALIDO')}</Text>}
          </Grid>
          <Grid item xs={4}>
            {agency.stateOrProvinceId?.id && (
              <FormControl fullWidth>
                <Field
                  component={MunicipalitySelect}
                  name={'countyOrMunicipalityId'}
                  provinceId={agency.stateOrProvinceId?.id}
                  disabled={countryId === ''}
                  defaultValue={agency.countyOrMunicipalityId?.id}
                />
              </FormControl>
            )}
          </Grid>
          <Grid item xs={4}>
            {agency.countyOrMunicipalityId?.id && (
              <FormControl fullWidth>
                <Field
                  component={SectorSelect}
                  name={'cityOrSectorId'}
                  municipalityId={agency.countyOrMunicipalityId?.id}
                  disabled={countryId === ''}
                  defaultValue={agency.cityOrSectorId?.id}
                />
              </FormControl>
            )}
          </Grid>
          <Grid item xs={8}>
            <Field fullWidth component={TextField} type="text" label={label('DIRECCION')} name="address" />
          </Grid>
          <Grid item xs={4}>
            <Field
              component={BooleanRadioButton}
              title={'ESTADO'}
              name="active"
              label1={'ACTIVO'}
              label2={'INACTIVO'}
              value1={'active'}
              value2={'inactive'}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              fullWidth
              component={TextField}
              type="number"
              label={label('LONGITUD')}
              name="geographic.longitude"
            />
          </Grid>
          <Grid item xs={4}>
            <Field fullWidth component={TextField} type="number" label={label('LATITUD')} name="geographic.latitude" />
          </Grid>
        </Grid>

        <Grid container spacing={2} marginTop={2} marginBottom={1}>
          <Grid item xs={12}>
            <Typography noWrap variant="h6" fontWeight="bold">
              {label('PRODUCTOS_DISPONIBLE_AGENCIA')}
            </Typography>
            <Divider />
          </Grid>
        </Grid>

        <Stack spacing={2}>
          <ProductForm
            onSave={handleSaveProduct}
            province={agency.stateOrProvinceId}
            handleMessageError={setMessageError}
          />
          {agency?.products.length > 0 && <Products onDelete={handleDeleteProduct} products={agency.products} />}
        </Stack>
        {messageError.length > 0 && (
          <Typography noWrap variant="body2" color="error">
            {label(messageError)}
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}
