import { SaveButton } from 'src/admin/core'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { useAppTranslation, useNotification } from 'src/admin/core/hooks'
import { useUpsertPeople } from '../hooks/useUpsertPeople'

export const UpdateButton: React.FC<{ afterSave?: (promise: Promise<any>) => void }> = ({ afterSave }) => {
  const { upsertPeople, loading, error } = useUpsertPeople()
  const { errorGraphNotification, errorNotification } = useNotification()
  const { label } = useAppTranslation()

  const form = useFormikContext()
  const { values, dirty, isValid, setSubmitting } = form

  useEffect(() => {
    if (error) {
      errorNotification(label(error.message))
    }
  }, [error])

  const handleSave = () => {
    const peopleToSave = values as any
    const response = upsertPeople({
      variables: {
        input: {
          id: peopleToSave.id,
          identificationType: peopleToSave.identificationType == 'CEDULA' ? 'ID' : peopleToSave.identificationType,
          identificationNumber: peopleToSave.identificationNumber.replace(/\D/g, ''),
          firstName: peopleToSave.firstName,
          lastName: peopleToSave.lastName,
          email: peopleToSave.email,
          nickName: peopleToSave.nickName,
          gender: peopleToSave.gender,
          birthdate: peopleToSave.birthdate,
          cellPhone: peopleToSave.cellPhone,
          active: peopleToSave.active,
        },
      },
    })
    return response
      .then(() => {
        if (afterSave) {
          afterSave(response)
        }
        setSubmitting(false)
      })
      .catch(() => {
        setSubmitting(false)
      })
  }

  return <SaveButton onClick={handleSave} isLoading={loading} disabled={!(dirty && isValid)} />
}
