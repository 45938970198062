import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useState } from 'react'
import { ProgramJockeyOrKennel } from '../../models'

const GET_JOCKEY_OR_KENNEL = gql`
  query GetProgramJockeyOrKennel($id: String!) {
    programJockeyOrKennel(id: $id) {
      id
      name
      active
    }
  }
`

interface JockeyOrKennelData {
  programJockeyOrKennel: ProgramJockeyOrKennel
}

export const useProgramJockeyOrKennel = () => {
  const [get, { loading, error, data }] = useLazyQuery<JockeyOrKennelData>(GET_JOCKEY_OR_KENNEL, {
    fetchPolicy: 'network-only',
  })

  const getProgramJockeyOrKennel = (id: string) => {
    get({
      variables: {
        id: id,
      },
    })
  }

  const jockeyOrKennel = data?.programJockeyOrKennel || undefined
  return { getProgramJockeyOrKennel, loading, error, jockeyOrKennel }
}
