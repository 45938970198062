import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useAppTranslation } from 'src/admin/core/hooks'
import { formatAsCurrency } from 'src/admin/core'
import Stack from '@mui/material/Stack'
import { Grid, styled } from '@mui/material'
import { formatToShortLocalDateTime } from 'src/admin/core/services/dateFunctions'
import { ConsolidatedData } from '../'

const Label = styled(Grid)(({ theme }) => ({
  fontWeight: 'bold',
}))

export const PrintReport = ({ data, date }: { data: ConsolidatedData[]; date: string }) => {
  const { label } = useAppTranslation()

  const firstRecord = data.length > 0 ? data[0] : null
  const now = new Date()

  return (
    <>
      {firstRecord && (
        <Stack direction="row" spacing={2} style={{ padding: '10px' }}>
          <Grid container spacing={2}>
            <Label item xs={8} md={8} textAlign="left" style={{ display: 'grid' }}>
              <span>{label('BALANCE_DIARIO_TITULO')}</span>
            </Label>
            <Label item xs={4} md={4} textAlign="right">
              {`${formatToShortLocalDateTime(now)}`}
            </Label>
          </Grid>
        </Stack>
      )}

      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>{label('AGENCIAS')}</TableCell>
              <TableCell>{label('VENTAS')}</TableCell>
              <TableCell>{label('CANCELACIONES')}</TableCell>
              <TableCell>{label('PAGOS')}</TableCell>
              <TableCell>{label('SOBRECARGOS')}</TableCell>
              <TableCell>{label('DEPOSITOS')}</TableCell>
              <TableCell>{label('RETIROS')}</TableCell>
              <TableCell>{label('BALANCE')}</TableCell>
              <TableCell>{label('DRAW')}</TableCell>
              <TableCell>{label('PAYDRAW')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left">{row.agencyWithCode}</TableCell>
                <TableCell align="right">{formatAsCurrency(row.sales, 0)}</TableCell>
                <TableCell align="right">{formatAsCurrency(row.cancelled, 0)}</TableCell>
                <TableCell align="right">{formatAsCurrency(row.payments, 0)}</TableCell>
                <TableCell align="right">{formatAsCurrency(row.surcharge, 0)}</TableCell>
                <TableCell align="right">{formatAsCurrency(row.deposits, 0)}</TableCell>
                <TableCell align="right">{formatAsCurrency(row.withdrawals, 0)}</TableCell>
                <TableCell align="right">{formatAsCurrency(row.amount, 0)}</TableCell>
                <TableCell align="right">{formatAsCurrency(row.draw, 0)}</TableCell>
                <TableCell align="right">{formatAsCurrency(row.payDraw, 0)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
