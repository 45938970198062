import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, useTheme } from '@mui/material'
import { GridRowParams } from '@mui/x-data-grid'

// App Components
import { DataGridColumns } from 'src/admin/core/components/DataGrid/CustomDataGrid'
import { AdminDataGrid } from 'src/admin/core/components/DataGrid/AdminDataGrid'
import { Page } from 'src/admin/core/components'
import { useEnterprises } from './hooks'
import { CreateButton, generateFilterGraphql } from 'src/admin/core'
import { ContentDataGrid } from 'src/admin/core/components/DataGrid'

import { useAppTranslation, usePagination, useUserClaimActions } from 'src/admin/core/hooks'
import Filters, { IFiltersEnterprises } from './Filters'
import { ClaimActions } from 'src/admin/core/models'
// import { SearchField } from 'src/admin/core/components/Fields'

const Enterprises = () => {
  const theme = useTheme()
  const { label } = useAppTranslation()
  const navigate = useNavigate()
  const { results, count, loading, getEnterprises } = useEnterprises()
  const { page, pageSize, skip, handlePageChange, handlePageSizeChange } = usePagination()
  const [filters, setFilters] = useState<IFiltersEnterprises>({
    active: true,
    companyId: 0,
    search: '',
  })
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.enterprises', [ClaimActions.CanView])

  useEffect(() => {
    fetchEnterprises(filters)
  }, [filters, skip, pageSize])

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      NOMBRE: { field: 'name', contentType: 'string', align: 'left' },
      ESTADO: { field: 'active', contentType: 'boolean', headerAlign: 'left', align: 'left' },
    }
  }, [])

  const goToCreate = () => {
    navigate(`/admin/entities/enterprises/create`)
  }

  const goToEdit = (row: GridRowParams) => {
    navigate(`/admin/entities/enterprises/${row.id}`)
  }

  const fetchEnterprises = useCallback(
    (filters: IFiltersEnterprises) => {
      let customFilters = {
        or: {
          name: { ...generateFilterGraphql(filters.search, 'contains') },
          active: { ...generateFilterGraphql(filters.active, 'eq') },
        },
      }

      getEnterprises({ where: customFilters, skip: skip, take: pageSize })
    },
    [page, pageSize],
  )

  return (
    <Page
      hasAccess={actions.canView}
      tabTitle={label('CONSORCIO_TITULO')}
      pageTitle={label('CONSORCIO_TITULO')}
      pageSubTitle={label('LISTA_DE_CONSORCIOS')}
      actions={<CreateButton onClick={goToCreate} />}
    >
      <Grid item xs={12}>
        <Filters onChange={(filters) => setFilters(filters)} />
      </Grid>
      <Grid item xs={12}>
        <ContentDataGrid>
          <AdminDataGrid
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            page={page}
            pageSize={pageSize}
            count={count}
            columns={getColumnDefinitions()}
            data={results}
            onRowClick={goToEdit}
          />
        </ContentDataGrid>
      </Grid>
    </Page>
  )
}

export default Enterprises
