import { gql, useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Metadata, ResultsList, SearchGraphql } from 'src/admin/core/models'
import { People } from '../models'

const GET_PEOPLE_MASTERS = gql`
  query PeopleMasters($where: PeopleMasterFilterInput) {
    peopleMasters(where: $where) {
      items {
        id
        identificationNumber
        firstName
        lastName
        birthdate
        gender
      }
    }
  }
`

interface Data {
  peopleMasters: {
    items: People[]
  }
}

export const usePeopleMasters = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<Data>(GET_PEOPLE_MASTERS, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<People, Metadata>>({
    results: [],
    metadata: {},
  })

  useEffect(() => {
    if (data?.peopleMasters) {
      setResults({
        results: data?.peopleMasters.items || [],
        metadata: {
          Count: data?.peopleMasters.items.length,
        },
      })
    }
  }, [loading])

  const getPeopleMasters = ({ where }: SearchGraphql) => {
    getResults({
      variables: {
        where: { ...where },
      },
    })
  }

  return { getPeopleMasters, loading, error, results }
}
