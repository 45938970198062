import * as Yup from 'yup'
import { useAppTranslation } from 'src/admin/core/hooks'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useEnterprise } from './useEnterprise'
import { Enterprise } from 'src/core/models'
import { Country } from 'src/admin/core/models'
import _ from 'lodash'

export interface FormEnterprise extends Enterprise {
  country?: Country
}
const initialValues: FormEnterprise = {
  id: '',
  name: '',
  active: true,
  domain: '',
  subdomain: '',
  products: [],
  country: undefined,
}

export const useFormHandler = () => {
  const { label } = useAppTranslation()
  const { id } = useParams()
  const { getEnterprise, data, loading } = useEnterprise()
  const [enterprise, setEnterprise] = useState<FormEnterprise | null>(initialValues)

  useEffect(() => {
    if (id) {
      getEnterprise(id)
    }
  }, [id])

  const formSchema = Yup.object().shape({
    name: Yup.string().required(label('REQUERIDO')),
    domain: Yup.string()
      .matches(/^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/, label('VALOR_NO_VALIDO'))
      .required(label('REQUERIDO')),
    subdomain: Yup.string().required(label('REQUERIDO')),
    active: Yup.boolean(),
    country: Yup.object().required(label('REQUERIDO')).nullable(),
  })

  return { loading, formSchema, initialValues: data?.enterprise || enterprise }
}
