import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../../../core/models'
import { ProgramCompetitor } from '../../models'
import { useEffect, useState } from 'react'

const GET_COMPETITORS = gql`
  query GetProgramCompetitors($where: ProgramCompetitorFilterInput, $skip: Int, $take: Int) {
    programCompetitors(where: $where, skip: $skip, take: $take, order: { name: ASC }) {
      items {
        id
        name
        ownerId
        owner {
          name
        }
        medication
        active
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface CompetitorsData {
  programCompetitors: {
    totalCount: number
    items: ProgramCompetitor[]
  }
}

export const useProgramCompetitors = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<CompetitorsData>(GET_COMPETITORS, {
    fetchPolicy: 'network-only',
  })
  const [results, setResults] = useState<ResultsList<ProgramCompetitor, Metadata>>({
    results: [],
    metadata: {},
  })

  const getProgramCompetitors = ({ where, take, skip }: SearchGraphql) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 100,
      },
    })
  }

  useEffect(() => {
    if (data?.programCompetitors) {
      setResults({
        results: data?.programCompetitors.items || [],
        metadata: {
          Count: data?.programCompetitors.totalCount,
        },
      })
    }
  }, [loading])

  // const clonedResults = 
  // Add owner name to the collection
  if (results.results.length > 0) {
    results.results = results.results.map((r) => {
      let clonedR = { ...r }
      clonedR.ownerName = r.owner?.name
      return clonedR
    })
  }

  return { getProgramCompetitors, loading, error, results }
}
