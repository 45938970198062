import { SaveButton } from 'src/admin/core'
import { useFormikContext } from 'formik'
import { Agency } from '../models/Agency'
import { AgencyForm, useUpsertAgency } from '../hooks'
import { Enterprise } from '../../enterprises/models'
import { useAppTranslation, useNotification } from 'src/admin/core/hooks'

export const CreateAgency: React.FC<{ afterSave?: (promise: Promise<any>, action: string) => void }> = ({
  afterSave,
}) => {
  const { errorNotification } = useNotification()
  const { label } = useAppTranslation()
  const { upsertAgency, loading, error } = useUpsertAgency()
  const form = useFormikContext()
  const { values, dirty, isValid, setSubmitting } = form
  const agency = { ...(values as AgencyForm) }

  const handleCreate = () => {
    setSubmitting(true)
    const strEnterprise = window.sessionStorage.getItem('selectedEnterprise')
    const enterprise = JSON.parse(strEnterprise) as Enterprise

    const country = enterprise.addresses?.length > 0 ? enterprise.addresses[0].countryId : undefined
    if (!country) {
      errorNotification(label('PAIS_CONSORCIO_INVALIDO'))
    } else {
      const response = upsertAgency({
        variables: {
          input: {
            name: agency.name,
            type: agency.type,
            products: agency.products,
            active: agency.active,
            enterpriseId: enterprise.id,
            phone: agency.phone,
            addresses: [
              {
                address: agency.address,
                cityOrSectorId: agency.cityOrSectorId?.id,
                countryId: country,
                countyOrMunicipalityId: agency.countyOrMunicipalityId?.id,
                stateOrProvinceId: agency.stateOrProvinceId?.id,
                main: true,
                zip: 0,
              },
            ],
            geographic: {
              type: 'POINT',
              coordinates: [agency.geographic?.longitude, agency.geographic?.latitude],
            },
          },
        },
      })
      if (afterSave) {
        afterSave(response, 'create')
      }
    }
    setSubmitting(false)
  }

  return <SaveButton onClick={handleCreate} isLoading={loading} disabled={!(dirty && isValid)} />
}
