import React from 'react'
import { Stack } from '@mui/material';
  
  type FormBodyProps = {
    children: React.ReactElement | React.ReactElement[]
  }
  
  export const FormBody = ({ children }: FormBodyProps) => {
    return (
        <Stack
            component="form"
            spacing={2}
            noValidate
            autoComplete="off"
        >
            {children}
        </Stack>
    )
  }
  