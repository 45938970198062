import { Button, useTheme, Stack, debounce, TextField } from '@mui/material'
import { useState } from 'react'
import { useAppTranslation } from 'src/admin/core/hooks'
import {
  getCurrentDate,
  getDateBefore,
  getDateNext,
  getLastDate,
  getTomorrow,
} from 'src/admin/core/services/dateFunctions'
import DatePicker from '@mui/lab/DatePicker'

export const today = new Date()

export const DateDayControl = ({
  onChange,
  showButtons = true,
  showDatePicker = true,
}: {
  showDatePicker?: boolean
  showButtons?: boolean
  onChange: (date: string | Date) => void
}) => {
  const theme = useTheme()
  const { label } = useAppTranslation()
  const [value, setValue] = useState<string | Date>(today)

  const handleCurrent = () => {
    setValue(new Date())
    onChange(new Date())
  }

  const handleLast = (date: string | Date) => {
    const yesterday = getDateBefore(date)
    setValue(yesterday)
    onChange(yesterday)
  }

  const handleNext = (date: string | Date) => {
    const tomorrow = getDateNext(date)
    setValue(tomorrow)
    onChange(tomorrow)
  }

  const onDateChange = debounce((givenDate) => {
    setValue(givenDate)
    onChange(givenDate)
  }, 500)

  return (
    <Stack spacing={2} direction="row">
      {showDatePicker && (
        <DatePicker
          value={value}
          onChange={(newValue) => onDateChange(newValue)}
          renderInput={(params) => <TextField {...params} fullWidth disabled={true} value={value} />}
          inputFormat="dd/MM/yyyy"
        />
      )}

      {showButtons && (
        <>
          <Button variant="contained" color="primary" onClick={() => handleLast(value)}>
            {label('ANTERIOR')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleCurrent}>
            {label('ACTUAL')}
          </Button>
          <Button variant="contained" color="primary" onClick={() => handleNext(value)}>
            {label('SIGUIENTE')}
          </Button>
        </>
      )}
    </Stack>
  )
}
