import { useEffect } from 'react'
import * as Yup from 'yup'
import { useAppTranslation } from 'src/admin/core/hooks'
import { useParams } from 'react-router-dom'
import { ProgramCompetitor } from '../../models'
import { useProgramCompetitor } from './useProgramCompetitor'

const initialValues: ProgramCompetitor = {
  id: '',
  name: '',
  ownerId: undefined,
  active: true,
}

export const useFormHandler = () => {
  const { label } = useAppTranslation()
  const { id } = useParams()
  const { competitor, loading, getProgramCompetitor } = useProgramCompetitor()

  useEffect(() => {
    if (id) {
      getProgramCompetitor(id)
    }
  }, [id])

  const formSchema = Yup.object().shape({
    name: Yup.string().required(label('REQUERIDO')),
    ownerId: Yup.string().nullable().required(label('REQUERIDO')),
  })

  return {
    loading,
    formSchema,
    initialValues: competitor || initialValues,
  }
}
