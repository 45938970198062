import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useAppTranslation } from 'src/admin/core/hooks'
import { Product } from 'src/admin/core/models'

const Products = ({ products, onDelete }: { products: Product[]; onDelete: (product: Product) => void }) => {
  const { label } = useAppTranslation()

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="product table">
        <TableHead>
          <TableRow>
            <TableCell>{label('NOMBRE')}</TableCell>
            <TableCell>{label('CODIGO')}</TableCell>
            <TableCell>{label('PROVEEDOR')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {label(product.name)}
              </TableCell>
              <TableCell>{product.code}</TableCell>
              <TableCell>{product.providerCode}</TableCell>
              <TableCell>
                <IconButton aria-label="delete" onClick={() => onDelete(product)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default Products
