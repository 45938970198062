import React, { ReactElement, useEffect, useState } from 'react'
// import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { AllowedClaimValues, Claim, Role, RoleScopeClaims } from '../models'
import { PermissionAllowedClaims } from './PermissionAllowedClaims'
import { PermissionRoleScopeClaims } from './PermissionRoleScopeClaims'
import { PermissionAccordion } from './PermissionAccordion'
import { AccordionDetails, Box, styled } from '@mui/material'

const Content = styled(Box)(() => ({
  width: '100%',
  '& .MuiAccordionSummary-content': {
    justifyContent: 'space-around',
  },
  '& .MuiAccordionDetails-root': {
    justifyContent: 'space-around',
  },
}))

type PermissionsProps = {
  role: Role
  allowedClaimValues: Array<AllowedClaimValues> | undefined
  roleScopeClaims: Array<RoleScopeClaims> | undefined
}

const cleanDashboard = (claims: Claim[]) => {
  return claims.filter((c) => c.claimType.includes('dashboard'))
}

export const Permissions = ({ allowedClaimValues, roleScopeClaims, role }: PermissionsProps) => {
  const [Permission, setPermission] = useState<null | ReactElement[]>(null)

  useEffect(() => {
    if (roleScopeClaims) {
      setPermission(getRenderedPermission(roleScopeClaims))
    }
  }, [roleScopeClaims])

  const getRenderedPermission = (roleScopeClaims: Array<RoleScopeClaims>): ReactElement[] => {
    return roleScopeClaims.map((item, index) => {
      if (item.displayName === 'ADMIN_DASHBOARD') {
        item.claims = cleanDashboard(item.claims)
      }

      return (
        <PermissionAccordion
          key={index}
          id={index}
          header={<PermissionAllowedClaims title={item.displayName} allowedClaimValues={allowedClaimValues} />}
          details={
            <>
              {item.claims !== undefined
                ? item.claims.map((item, index) => {
                    return (
                      <AccordionDetails key={index}>
                        <PermissionRoleScopeClaims
                          role={role}
                          title={clearDisplayName(item.displayName)}
                          allowed={item.allowed}
                          values={item.values}
                          claimType={item.claimType}
                        />
                      </AccordionDetails>
                    )
                  })
                : null}
            </>
          }
        />
      )
    })
  }

  return <Content>{Permission}</Content>
}

const clearDisplayName = (name: string) => {
  const index = name.indexOf('.')
  const nameBeforeFirstPoint = name.substring(index + 1, name.length)
  return nameBeforeFirstPoint.split('.')[0]
}
