import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useAppTranslation } from 'src/admin/core/hooks'
import { styled } from '@mui/material'
import { Event } from '../../../models'
import { formatSpanishDate } from 'src/admin/core/services/dateFunctions'

const CustomTable = styled(Table)(({ theme }) => ({
  '& .MuiTableCell-root': {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
}))

const CustomTableHead = styled(TableHead)(({ theme }) => ({
  '& .MuiTableCell-root': {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
}))

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  textTransform: 'none',
}))

export const ReportHeader = ({ event }: { event: Event }) => {
  const { label } = useAppTranslation()

  return (
    <>
      <TableContainer>
        <CustomTable size="small">
          <CustomTableHead>
            <TableRow>
              <CustomTableCell
                sx={{
                  textAlign: 'center',
                }}
              >
                {label('EVENTO_SUB_TITULO')}
              </CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell
                sx={{
                  textAlign: 'center',
                }}
              >
                {`${formatSpanishDate(event.date as string)}`}, {event.name}
              </CustomTableCell>
            </TableRow>
          </CustomTableHead>
        </CustomTable>
      </TableContainer>
    </>
  )
}
