import React, { createContext, Dispatch } from 'react'
import { ProgramSelectOptionContextState, initialState } from './reducer'
import { ProgramSelectOptionActions } from './actions'

export const ProgramSelectOptionContext = createContext<{
  state: ProgramSelectOptionContextState
  dispatch: Dispatch<ProgramSelectOptionActions>
}>({
  state: initialState,
  dispatch: () => {},
})
