import { Field } from 'formik'
import { useAppTranslation } from 'src/admin/core/hooks'
import { StringAutoCompleteSelectFormik } from 'src/admin/core'
import { useRole } from '../hooks/useRoles'
import { useEffect, useState } from 'react'
import { Role } from '../models/Role'
import { excludedRolesForNotUserAdmin, excludedRolesForUserAdmin } from 'src/admin/core/services/userClaims'

export const RoleSelectFormik = ({
  onChangeValue,
  disabled,
}: {
  disabled: boolean
  onChangeValue?: (value: any, action: string, item: any) => void
}) => {
  const { label } = useAppTranslation()
  const [roles, setRoles] = useState<String[]>([])
  const {
    results: { results },
    getRoles,
  } = useRole()

  useEffect(() => {
    getRoles({ where: undefined, skip: 0, take: 100 })
  }, [])

  useEffect(() => {
    let userRoles: string[] = []

    if (results.length > 0) {
      results.map((role: Role) => {
        if (!excludedRolesForUserAdmin.includes(role.name)) {
          userRoles.push(role.name)
        } else if (!excludedRolesForNotUserAdmin.includes(role.name)) {
          userRoles.push(role.name)
        }
        setRoles(userRoles)
      })
    }
  }, [results])

  return (
    <Field
      fullWidth
      data={roles}
      component={StringAutoCompleteSelectFormik}
      label={label('ROLE')}
      name="roles"
      multiple
      onChangeValue={onChangeValue}
      disabled={disabled}
    />
  )
}
