import { gql, useMutation } from '@apollo/client'

const UPDATE_WORKsTATION = gql`
  mutation UpdateWorkstation($id: Int!, $input: UpdateWorkstationInput!) {
    updateWorkstation(id: $id, input: $input) {
      workstation {
        name
        workstationType
        locationId
        active
        enterpriseId
        __typename
      }
      __typename
    }
  }
`
export interface UpdateWorkstationInput {
  name: string
  workstationTypeId?: number
  locationId?: number
  canRegister?: boolean
}

export const useUpdateWorkstation = () => {
  const [updateWorkstation, { loading, error }] = useMutation<UpdateWorkstationInput>(UPDATE_WORKsTATION)
  return { updateWorkstation, loading, error }
}
