import { FC, ReactNode, useContext, useEffect, useState } from 'react'
import { Box, useTheme } from '@mui/material'
import { Outlet } from 'react-router-dom'
import ThemeSettings from 'src/components/ThemeSettings'

import Sidebar from './Sidebar'
import Header from './Header'

import { Loader } from 'src/admin/components/Loader'
import { parseJwt } from 'src/admin/core'
import { extractUserClaims, extractUserModules, isUserAdmin } from 'src/admin/core/services/userClaims'
import { AuthService } from 'src/admin/core/auth/authService'
import {
  setAllowedModules,
  setEnterprises,
  setIsAdmin,
  setUser,
  setUserClaims,
  setUserEnterprise,
} from 'src/admin/core/stores/appState/actions'
import { useDispatch } from 'react-redux'
import { useProfileEnterprises } from 'src/admin/scenes/entities/enterprises/hooks/useProfileEnterprises'
import { Enterprise } from 'src/core/models'

interface AccentHeaderLayoutProps {
  children?: ReactNode
}

const AccentHeaderLayout: FC<AccentHeaderLayoutProps> = () => {
  const authService = new AuthService()
  const theme = useTheme()
  const [userSettingIsReady, setUserSettingIsReady] = useState(false)
  const dispatch = useDispatch()
  const { data, getProfileEnterprises } = useProfileEnterprises()

  useEffect(() => {
    setupUser()
  }, [])

  const setupUser = async () => {
    const user = await authService.getUser()
    if (user) {
      dispatch(setUser(user))

      try {
        const claims = parseJwt(user?.access_token)
        if (claims) {
          const userClaims = extractUserClaims(claims)
          dispatch(setIsAdmin(isUserAdmin(claims)))
          dispatch(setUserClaims(userClaims))
          dispatch(setAllowedModules(extractUserModules(claims)))
        }
        getProfileEnterprises(user.profile.sub)
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    const userEnterprises = data ? (data.profile.enterprises as Enterprise[]) : []

    if (userEnterprises.length > 0) {
      const defaultEnterprise = userEnterprises[0]
      const enterprise = window.sessionStorage.getItem('selectedEnterprise')
      if ((enterprise && enterprise === 'none') || !enterprise) {
        window.sessionStorage.setItem('selectedEnterprise', JSON.stringify(defaultEnterprise))
        dispatch(setUserEnterprise(defaultEnterprise))
      } else {
        dispatch(setUserEnterprise(JSON.parse(enterprise) as Enterprise))
      }
      const sortEnterprises = userEnterprises.slice().sort((a, b) => a.name.localeCompare(b.name));
      dispatch(setEnterprises(sortEnterprises))
    } else {
      const enterprise = window.sessionStorage.getItem('selectedEnterprise')
      if ((enterprise && enterprise === 'none') || !enterprise) {
        dispatch(setUserEnterprise(JSON.parse(enterprise) as Enterprise))
      }
    }

    setTimeout(() => {
      setUserSettingIsReady(true)
    }, 2000)
  }, [data])

  return (
    <Loader loading={!userSettingIsReady}>
      <Header />
      <Sidebar />
      <Box
        sx={{
          position: 'relative',
          zIndex: 5,
          flex: 1,
          display: 'flex',
          pt: `${theme.header.height}`,
          [theme.breakpoints.up('lg')]: {
            pl: `${theme.sidebar.width}`,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box flexGrow={1}>
            <Outlet />
          </Box>
        </Box>
        <ThemeSettings />
      </Box>
    </Loader>
  )
}

export default AccentHeaderLayout
