export type EnvSettingsType =
  | 'REACT_APP_PB_AUTH_URL'
  | 'REACT_APP_PB_AUTH_SCOPE'
  | 'REACT_APP_PB_AUTH_CLIENT_ID'
  | 'REACT_APP_PB_API_TIMEOUT'
  | 'REACT_APP_PB_CDN_BASE_URL'
  | 'REACT_APP_PB_GRAPH_BASE_URL'
  | 'REACT_APP_PB_AUTH_AUTOMATIC_SILENT_RENEW'
  | 'REACT_APP_PB_AUTH_SILENT_REQUEST_TIMEOUT'
  | 'REACT_APP_PB_AUTH_REDIRECT_URI'
  | 'REACT_APP_PB_AUTH_SILENT_REDIRECT_URI'
  | 'REACT_APP_PB_AUTH_POST_LOGOUT_REDIRECT_URI'
  | 'REACT_APP_PB_POS_URL'

export const getEnvSettings = () => {
  const envSettings: Record<EnvSettingsType | any, string | undefined> = {
    REACT_APP_PB_AUTH_URL: 'https://idp.microsuave.com',
    REACT_APP_PB_AUTH_SCOPE: 'openid profile placebet-administration',
    REACT_APP_PB_AUTH_CLIENT_ID: 'placebet-admin',
    REACT_APP_PB_API_TIMEOUT: '30000',
    REACT_APP_PB_GRAPH_BASE_URL: 'https://router.microsuave.com',
    REACT_APP_PB_POS_URL: 'https://dev.microsuave.com',
  }

  var buildSettings = (window as any)['_env_'] as any

  Object.keys(envSettings).forEach((key) => {
    const value = buildSettings[key] || process.env[key]
    if (value) {
      envSettings[key] = value
    }
  })

  return envSettings
}

export const isMobile = (): boolean => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}
