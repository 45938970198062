import { FormPage, PageTab, PageHeader, FormContent, BackButton, PrintButton } from 'src/admin/core'
import { useAppTranslation, useNotification, useUserClaimActions } from 'src/admin/core/hooks'
import { Form } from './Form'
import { useNavigate } from 'react-router-dom'
import { useCallback, useRef, useState } from 'react'
import { useEventFormHandler } from '../../hooks'
import { Loader } from 'src/admin/components/Loader'
import { ProgramSelectOptionProvider } from 'src/admin/core/stores/programSelectOptions'
import Race from '../../races/race'
import { EventProvider } from '../../state'
import _ from 'lodash'
import { SecurityPage } from 'src/admin/core/components/Page/SecurityPage'
import { ClaimActions } from 'src/admin/core/models'
import { Box, Grid, Stack, styled } from '@mui/material'
import { useReactToPrint } from 'react-to-print'
import { ReportContent } from './Report'
import { ReportHeader } from './Report/ReportHeader'
import { FooterReport } from './Report/Footer'

const pageStyle = `
  @page { size: legal landscape; }

`
const PrintWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
}))

const label = (v: string) => v

const Event: React.FC = () => {
  const { label } = useAppTranslation()
  const navigate = useNavigate()
  const { successNotification, errorNotification } = useNotification()
  const { formSchema, initialValues, loading, programSelectOptions } = useEventFormHandler()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('configuration.events', [ClaimActions.CanView])
  const [startPrinting, setStartPrinting] = useState(false)
  const componentRef = useRef<any>(null)

  const afterSave = useCallback((response: Promise<any>, action?: string) => {
    response
      .then((res) => {
        successNotification(label(initialValues.id ? 'DATO_ACTUALIZADO' : 'DATO_REGISTRADO'))
        if (action === 'create') {
          const eventId = _.get(res, 'data.upsertProgramEvent.id', undefined)

          if (eventId) {
            setTimeout(() => {
              navigate(`/admin/entities/events/${eventId}`)
            }, 1000)
          } else {
            errorNotification(label('ERROR_REGISTRANDO_EL_DATO'))
          }
        }
      })
      .catch((e) => {
        errorNotification(label(e.message))
      })
  }, [])

  const handlePrint = useCallback(() => {
    setStartPrinting(true)
    setTimeout(() => {
      printReport()
    }, 1000)
  }, [])

  const printReport = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
    onAfterPrint: () => {
      window.focus()
    },
    onBeforePrint: () => {
      setStartPrinting(false)
    },
  })

  // return (
  //   <Loader loading={loading}>
  //     <SecurityPage hasAccess={actions.canView}>
  //       <EventProvider event={initialValues}>
  //         {!startPrinting && (
  //           <PrintWrapper ref={componentRef}>
  //             <Grid container sx={{padding: '10px'}}>
  //               <Grid item sm={12}>
  //                 <ReportHeader />
  //                 {[1, 2, 3].map((i) => (
  //                   <PrintReport key={i} />
  //                 ))}
  //               </Grid>
  //             </Grid>
  //           </PrintWrapper>
  //         )}
  //       </EventProvider>
  //     </SecurityPage>
  //   </Loader>
  // )

  return (
    <Loader loading={loading}>
      <SecurityPage hasAccess={actions.canView}>
        <EventProvider event={initialValues}>
          <ProgramSelectOptionProvider programSelectOptions={programSelectOptions}>
            <FormPage
              form={{
                formSchema: formSchema,
                formValues: initialValues,
              }}
            >
              <PageTab text={label('PROGRAMA_HIPICO')} />
              <PageHeader
                title={label('PROGRAMA_HIPICO')}
                actions={
                  <Stack direction="row" spacing={1}>
                    <BackButton />
                    <PrintButton onClick={handlePrint} />
                  </Stack>
                }
              />
              <FormContent>
                <Form initialValues={initialValues} afterSave={afterSave} />
              </FormContent>
            </FormPage>
            <FormContent>{initialValues.id ? <Race /> : null}</FormContent>
          </ProgramSelectOptionProvider>
          {startPrinting && initialValues.id && (
            <PrintWrapper ref={componentRef}>
              <Grid container sx={{ padding: '10px' }}>
                <Grid item sm={12}>
                  <ReportHeader event={initialValues} />
                  <ReportContent />
                  <FooterReport />
                </Grid>
              </Grid>
            </PrintWrapper>
          )}
        </EventProvider>
      </SecurityPage>
    </Loader>
  )
}

export default Event
