import { gql, useMutation } from '@apollo/client'

const REGISTER_ENTERPRISE = gql`
  mutation RegisterEnterprise($input: RegisterEnterpriseInput!) {
    registerEnterprise(input: $input) {
      success
    }
  }
`

export const useRegisterEnterprise = () => {
  const [registerEnterprise, { loading, error }] = useMutation(REGISTER_ENTERPRISE)
  return { registerEnterprise, loading, error }
}
