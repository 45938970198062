import { SaveButton } from 'src/admin/core'
import { useFormikContext } from 'formik'
import { formatDateTimeToUTC } from 'src/admin/core/services/dateFunctions'
import { useAppTranslation, useNotification } from 'src/admin/core/hooks'
import { Race, Wager } from '../../models'
import { EventContext, setRaceFormMode } from '../../state'
import { useContext } from 'react'
import { useAddProgramRace, useRaceWagers } from '../../hooks'

export const CreateButton: React.FC<{ afterSave?: (promise: Promise<any>) => void; wagers: Wager[] }> = ({
  afterSave,
  wagers,
}) => {
  const form = useFormikContext()
  const { values, dirty, isValid, setSubmitting } = form
  const { errorNotification } = useNotification()
  const { system, label } = useAppTranslation()
  const {
    dispatch,
    state: { event },
  } = useContext(EventContext)
  const { addProgramRace, loading } = useAddProgramRace()

  const handleCreate = () => {
    setSubmitting(true)
    const raceToSave = values as any

    if (raceToSave.wagerIds.length === 0) {
      form.setFieldError('wagerIds', label('REQUERIDO'))
      return
    }
    if (event.id) {
      raceToSave.programEventId = event.id

      if (Number(raceToSave.raceNumber) > event.numberOfRaces) {
        errorNotification(system('ERROR_NUMERO_CARRERA_MAYOR_A_NUMERO_EVENTO'))
      } else {
        const response = addProgramRace({
          variables: {
            input: {
              programEventId: raceToSave.programEventId,
              raceNumber: raceToSave.raceNumber,
              typeOfRace: raceToSave.typeOfRace?.code,
              purse: raceToSave.purse,
              distanceId: raceToSave.distanceId?.id,
              wagerIds: getWagersIds(raceToSave.wagerIds),
              runners: raceToSave.runners,
              genderRestriction: raceToSave.genderRestriction,
              ageRestriction: raceToSave.ageRestriction,
              // weight: raceToSave.weight,
              breed: raceToSave.breed,
              course: raceToSave.course,
              postTime: formatDateTimeToUTC(raceToSave.postTime),
              active: raceToSave.active,
            },
          },
        })
        dispatch(setRaceFormMode('CANCEL'))
        afterSave(response)
      }
    } else {
      errorNotification(system('ERROR_EVENTO_NO_ENCONTRADO'))
    }
  }

  const getWagersIds = (value: string): string[] => {
    if (value.length > 0 && wagers.length > 0) {
      // const typeBetOptions = wagersData.results
      const values: string[] = value.split('/')
      const ids: string[] = []
      if (wagers.length > 0 && values.length > 0) {
        values.forEach((val: string) => {
          const bet = wagers.find((item: Wager) => item.name.trim() === val.trim())
          if (bet !== undefined) {
            ids.push(bet.id)
          }
        })
      }
      return ids
    }

    return []
  }

  return <SaveButton onClick={handleCreate} isLoading={loading} disabled={!(dirty && isValid)} />
}
