import React, { useCallback } from 'react'
import { IFormField } from '../../models'
import { RadioGroup, FormControlLabel, FormLabel, Radio } from '@mui/material'
import { useAppTranslation } from '../../hooks'

export interface StringRadioButtonProps extends IFormField {
  title: string
  label1: string
  label2: string
  value1: string
  value2: string
  disabled?: boolean
  onChange?: (value: string) => void
}

export const StringRadioButtonFormik = ({
  label1,
  label2,
  value1,
  value2,
  disabled,
  title,
  onChange,
  ...props
}: StringRadioButtonProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props

  const { label } = useAppTranslation()

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setFieldValue(name, event.target.value === value1 ? value1 : value2)
      if (onChange instanceof Function) {
        onChange(event.target.value === value1 ? value1 : value2)
      }
    },
    [setFieldValue, name, onChange, value1, value2],
  )

  return (
    <React.Fragment>
      <FormLabel component="legend">{label(title)}</FormLabel>
      <RadioGroup
        row
        aria-label={name}
        name={name}
        value={values[name] === value1 ? value1 : value2}
        onChange={handleChange}
      >
        <FormControlLabel
          value={value1}
          control={<Radio color="primary" disabled={disabled} />}
          label={label(label1) as string}
        />
        <FormControlLabel
          value={value2}
          control={<Radio color="primary" disabled={disabled} />}
          label={label(label2) as string}
        />
      </RadioGroup>
    </React.Fragment>
  )
}
