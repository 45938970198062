import { SaveButton } from 'src/admin/core'
import { useFormikContext } from 'formik'
import { User } from '../models'
import { useCreateUser } from '../hooks'
import { Enterprise } from 'src/admin/scenes/entities/enterprises/models'
import { useEffect } from 'react'
import { useNotification } from 'src/admin/core/hooks'
// import { useAddProgramEvent } from '../hooks'

export const CreateButton: React.FC<{ afterSave?: (promise: Promise<any>, action: string) => void }> = ({
  afterSave,
}) => {
  const { createUser, loading, error } = useCreateUser()
  const { errorGraphNotification } = useNotification()

  const form = useFormikContext()
  const { values, dirty, isValid, setSubmitting } = form

  useEffect(() => {
    if (error) {
      errorGraphNotification(error)
    }
  }, [error])

  const handleCreate = () => {
    setSubmitting(true)
    const userToSave = values as any
    const strEnterprise = window.sessionStorage.getItem('selectedEnterprise')
    const enterprise = JSON.parse(strEnterprise) as Enterprise
    // Add default role
    userToSave.roles.push('employee')

    const response = createUser({
      variables: {
        request: {
          roles: userToSave.roles && userToSave.roles.length > 0 ? userToSave.roles : 'employee',
          enterprises: [enterprise.id],
          documentId: userToSave.documentId,
          profile: {
            familyName: userToSave.familyName,
            givenName: userToSave.givenName,
            email: userToSave.email,
            middleName: userToSave.middleName,
            nickname: userToSave.nickname,
            address: userToSave.address,
            gender: userToSave.gender,
            birthdate: userToSave.birthdate,
            phoneNumber: userToSave.phoneNumber,
            locale: userToSave.locale,
            zoneinfo: userToSave.zoneinfo,
            // active: userToSave.active,
          },
        },
      },
    })
    response
      .then((result: any) => {
        if (afterSave) {
          afterSave(response, 'create')
        }
        setSubmitting(false)
      })
      .catch(() => {
        setSubmitting(false)
      })
  }

  return <SaveButton onClick={handleCreate} isLoading={loading} disabled={!(dirty && isValid)} />
}
