import { gql, useLazyQuery } from '@apollo/client'
import { SearchGraphql, PageInfo, ResultsList, Metadata } from '../../../../core/models'
import { People } from '../models'
import { useEffect, useState } from 'react'

const GET_PEOPLES = gql`
  query GetPeople($skip: Int, $take: Int, $where: PeopleFilterInput, $order: [PeopleSortInput!]) {
    peoples(where: $where, order: $order, take: $take, skip: $skip) {
      items {
        id
        firstName
        lastName
        nickName
        identificationNumber
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface PeopleData {
  peoples: {
    totalCount: number
    items: People[]
    pageInfo: PageInfo
  }
}

export const usePeoples = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<PeopleData>(GET_PEOPLES, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<People, Metadata>>({
    results: [],
    metadata: {},
  })

  useEffect(() => {
    if (data?.peoples) {
      setResults({
        results: data?.peoples.items || [],
        metadata: {
          Count: data?.peoples.totalCount,
        },
      })
    }
  }, [loading])

  const getPeoples = ({ where, take, skip }: SearchGraphql, order?: any) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 100,
      },
    })
  }

  const People = results.results.map((people) => {
    people.fullName = `${people.firstName} ${people.lastName}`
    return people
  })
  return { loading, error, People, results, getPeoples }
}
