import * as Yup from 'yup'
import { useAppTranslation } from 'src/admin/core/hooks'
import { Race } from '../../models'
import { getRegretToNotAllowSpecialsCharacters } from 'src/admin/core'

const initialValues: Race = {
  id: 0,
  programEventId: 0,
  raceNumber: '',
  typeOfRace: '',
  purse: '',
  distanceId: 0,
  runners: '',
  genderRestriction: 'H',
  ageRestriction: '',
  weight: '',
  wagers: [],
  wagerIds: [],
  breed: 'TB',
  course: 'D',
  postTime: new Date(),
  active: true,
}

export const useRaceFormHandler = () => {
  const { label } = useAppTranslation()

  const formSchema = Yup.object().shape({
    raceNumber: Yup.number()
      .min(1, label('SOLO_VALORES_ENTRE') + ' (1-99)')
      .max(99, label('SOLO_VALORES_ENTRE') + ' (1-99)')
      .required(label('REQUERIDO')),
    purse: Yup.number()
      .min(1, label('SOLO_VALORES_ENTRE') + ' (1-9999999999)')
      .max(9999999999, label('SOLO_VALORES_ENTRE') + ' (1-9999999999)')
      .required(label('REQUERIDO')),
    distanceId: Yup.object()
      .nullable()
      .required(label('REQUERIDO'))
      .test('test_distanceId', '', function (distance) {
        return distance ? true : this.createError({ message: label('REQUERIDO') })
      }),
    runners: Yup.number()
      .min(2, label('SOLO_VALORES_ENTRE') + ' (2-99)')
      .max(99, label('SOLO_VALORES_ENTRE') + ' (2-99)')
      .required(label('REQUERIDO')),
    // genderRestriction: Yup.string().nullable().required(label('REQUERIDO')),
    ageRestriction: Yup.string()
      .max(2, label('SOLO_VALORES_ENTRE') + ' (1-2)')
      .required(label('REQUERIDO'))
      .matches(getRegretToNotAllowSpecialsCharacters(), label('CARACTERES_ESPECIALES_NO_PERMITIDOS')),
    postTime: Yup.date().nullable().required(label('REQUERIDO')),
  })

  return {
    formSchema,
    initialValues: initialValues,
  }
}
