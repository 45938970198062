import { SaveButton } from 'src/admin/core'
import { useFormikContext } from 'formik'
import { User } from '../models'
import { useUpdateUser } from '../hooks'
import { useEffect } from 'react'
import { useNotification } from 'src/admin/core/hooks'

export const UpdateButton: React.FC<{ afterSave?: (promise: Promise<any>) => void }> = ({ afterSave }) => {
  const { updateUser, loading, error } = useUpdateUser()
  const { errorGraphNotification, errorNotification } = useNotification()

  const form = useFormikContext()
  const { values, dirty, isValid, setSubmitting } = form

  useEffect(() => {
    if (error) {
      errorNotification(error.message)
    }
  }, [error])

  const handleSave = () => {
    const userToSave = values as any
    const response = updateUser({
      variables: {
        input: {
          id: userToSave.id,
          // roles: userToSave.roles && userToSave.roles.length > 0 ? userToSave.roles : '',
          claims: {
            familyName: userToSave.familyName,
            givenName: userToSave.givenName,
            email: userToSave.email,
            middleName: userToSave.middleName,
            nickname: userToSave.nickname,
            address: userToSave.address,
            gender: userToSave.gender,
            birthdate: userToSave.birthdate,
            phoneNumber: userToSave.phoneNumber,
            locale: userToSave.locale,
            zoneinfo: userToSave.zoneinfo,
            // active: userToSave.active,
          },
        },
      },
    })
    return response
      .then(() => {
        if (afterSave) {
          afterSave(response)
        }
        setSubmitting(false)
      })
      .catch(() => {
        setSubmitting(false)
      })
  }

  return <SaveButton onClick={handleSave} isLoading={loading} disabled={!(dirty && isValid)} />
}
