import React, { useEffect, useState } from 'react'
import { TextField } from 'formik-material-ui'

import { Autocomplete, AutocompleteChangeReason, CircularProgress } from '@mui/material'
import { IFormField } from 'src/admin/core/models'
import { ProgramSelectOption } from '../../models'
import { useProgramMedications } from '../../hooks'
import { generateFilterGraphql } from 'src/admin/core'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export interface MedicationSelectFormikProps extends IFormField {
  keyValue: string
  keyLabel?: string
  isLoading?: boolean
  label?: string
  defaultValue?: any
  onChangeValue?: (value: any, action: string) => void
  onInputValueChange?: (value: string, fieldName: string, action: string) => void
  onSelectOpen?: () => void
  disabled?: boolean
  multiple?: boolean
  data: ProgramSelectOption[]
  props?: any
}

export const MedicationSelectFormik = ({
  keyValue,
  keyLabel,
  isLoading,
  label,
  onChangeValue,
  onInputValueChange,
  onSelectOpen,
  defaultValue,
  disabled,
  multiple,
  data,
  ...props
}: MedicationSelectFormikProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props

  const [medications, setMedications] = useState([])

  useEffect(() => {
    if (defaultValue) {
      const object = data.filter((item) => item.code == defaultValue)
      const names = object.map((item) => item.name)
      const values = getArrayOfValues(names)
      setFieldValue(name, values)
    } else {
      setFieldValue(name, '')
    }
  }, [defaultValue, data])

  useEffect(() => {
    // let medicationDefaultValue: any[] = []
    let medicationString: string[] = []
    if (data.length > 0) {
      data.map((item: ProgramSelectOption) => {
        medicationString.push(item.name)
      })
    }
    setMedications(medicationString)
  }, [data])

  const onSelect = (event: React.ChangeEvent<any>, value: string[], reason: AutocompleteChangeReason) => {
    try {
      if (reason === 'selectOption' || reason === 'removeOption') {
        setFieldValue(name, value.join(','))

        if (onChangeValue instanceof Function) {
          onChangeValue(value, reason)
        }
      }
      if (reason === 'clear') {
        setFieldValue(name, '')
        if (onChangeValue instanceof Function) {
          onChangeValue(value, reason)
        }
      }
    } catch (e) {}
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
  }

  const getArrayOfValues = (value: string | string[]): string[] | [] => {
    if (Array.isArray(value)) {
      return value
    } else if (value && value.length > 0) {
      const cleanData = value.split(',')

      // Remove duplicated
      const newData = cleanData.filter((item: string, index: number) => {
        return cleanData.indexOf(item) == index && item !== ''
      })

      return newData
    } else {
      return []
    }
  }

  return (
    <Autocomplete
      disabled={disabled}
      multiple
      id="tags-outlined"
      value={values['medication'] !== '' ? getArrayOfValues(values['medication']) : []}
      options={medications}
      onChange={onSelect}
      getOptionLabel={(option) => option}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          onChange={onInputChange}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}
