import { gql, useLazyQuery } from '@apollo/client'

const GET_PROGRAM_FILE = gql`
  query GetProgramFileDownloadLink($eventId: String!) {
    programFileDownloadUri(eventId: $eventId) {
      sasUri
      expiration
      fileName
    }
  }
`

interface Data {
  programFileDownloadUri: {
    fileName: string
    expiration: Date
    sasUri: string
  }
}

export const useGetProgramFile = () => {
  const [get, { loading, error, data }] = useLazyQuery<Data>(GET_PROGRAM_FILE, {
    fetchPolicy: 'network-only',
  })

  const getProgramFile = (id: string) => {
    if (id) {
      get({
        variables: {
          eventId: id,
        },
      })
    }
  }

  const programFileDownloadUri = data?.programFileDownloadUri || undefined
  return { getProgramFile, loading, error, programFileDownloadUri }
}
