import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useAppTranslation } from 'src/admin/core/hooks'
import { formatAsCurrency } from 'src/admin/core'
import Stack from '@mui/material/Stack'
import { Grid, styled } from '@mui/material'
import { formatToShortLocalDate, formatToShortLocalDateTime } from 'src/admin/core/services/dateFunctions'
import { ConsolidatedData, WeekRange } from '..'

const Label = styled(Grid)(({ theme }) => ({
  fontWeight: 'bold',
}))

export const PrintReport = ({ data, dateRange }: { data: ConsolidatedData[]; dateRange: WeekRange }) => {
  const { label } = useAppTranslation()

  const firstRecord = data.length > 0 ? data[0] : null
  const now = new Date()

  return (
    <>
      {firstRecord && (
        <Stack direction="row" spacing={2} style={{ padding: '10px' }}>
          <Grid container spacing={2}>
            <Label item xs={4} md={4}>
              {`Semana del ${formatToShortLocalDate(dateRange.monday, 'YYYY-MM-DD')} ${label(
                'A',
              )} ${formatToShortLocalDate(dateRange.sunday, 'YYYY-MM-DD')}`}
            </Label>
            <Label item xs={4} md={4} textAlign="center" style={{ display: 'grid' }}>
              <span>{label('BALANCE_SEMANAL_TITULO')}</span>
              {/* <span>{`${firstRecord.enterprise} ${firstRecord.currency}`}</span> */}
            </Label>
            <Label item xs={4} md={4} textAlign="right">
              {`${formatToShortLocalDateTime(now)}`}
            </Label>
          </Grid>
        </Stack>
      )}

      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>{label('AGENCIAS')}</TableCell>
              <TableCell>{label('LUN')}</TableCell>
              <TableCell>{label('MAR')}</TableCell>
              <TableCell>{label('MIE')}</TableCell>
              <TableCell>{label('JUE')}</TableCell>
              <TableCell>{label('VIE')}</TableCell>
              <TableCell>{label('SAB')}</TableCell>
              <TableCell>{label('DOM')}</TableCell>
              <TableCell>{label('TOTAL')}</TableCell>
              <TableCell>{label('COMMISSION')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.agencyName}
                </TableCell>
                <TableCell align="right">{formatAsCurrency(row.monday, 0)}</TableCell>
                <TableCell align="right">{formatAsCurrency(row.tuesday, 0)}</TableCell>
                <TableCell align="right">{formatAsCurrency(row.wednesday, 0)}</TableCell>
                <TableCell align="right">{formatAsCurrency(row.thursday, 0)}</TableCell>
                <TableCell align="right">{formatAsCurrency(row.friday, 0)}</TableCell>
                <TableCell align="right">{formatAsCurrency(row.saturday, 0)}</TableCell>
                <TableCell align="right">{formatAsCurrency(row.sunday, 0)}</TableCell>
                <TableCell align="right">{formatAsCurrency(row.amount, 0)}</TableCell>
                <TableCell align="right">{formatAsCurrency(row.commission, 0)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
