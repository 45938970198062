import React, { useCallback } from 'react'
import { TextField, Autocomplete, AutocompleteChangeReason, CircularProgress } from '@mui/material'
import { IFormField } from '../../models'

export interface AutoCompleteSelectFormikProps extends IFormField {
  keyValue: string
  data: string[]
  isLoading?: boolean
  label?: string
  defaultValue?: any
  onChangeValue?: (value: any, action: string, item: any) => void
  onInputValueChange?: (value: string) => void
  disabled?: boolean
  multiple?: boolean
  props?: any
}

export const StringAutoCompleteSelectFormik = ({
  keyValue,
  data,
  isLoading,
  label,
  onChangeValue,
  onInputValueChange,
  defaultValue,
  disabled,
  multiple,
  ...props
}: AutoCompleteSelectFormikProps) => {
  const {
    form: { setFieldValue, values },
    field: { name },
  } = props
  const [open, setOpen] = React.useState(false)

  const onSelect = useCallback(
    (event: React.ChangeEvent<any>, value: string[], reason: AutocompleteChangeReason) => {
      setFieldValue(name, value)
      if (onChangeValue instanceof Function) {
        onChangeValue(value, reason, value[value.length - 1])
      }
    },
    [setFieldValue, name, onChangeValue],
  )

  return (
    <Autocomplete
      {...props}
      disabled={disabled}
      defaultValue={multiple ? [] : null}
      value={defaultValue ? defaultValue : values[name] ?? []}
      onChange={onSelect}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={data}
      loading={isLoading}
      multiple={multiple}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget
          if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
            console.log('More data')
          }
        },
      }}
    />
  )
}
