import { useState, useEffect, useCallback } from 'react'
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Enterprise } from '../models'
import { Metadata, ResultsList } from 'src/admin/core/models'

const GET_USER_ENTERPRISES = gql`
  query getUserEnterprisesQuery {
    enterprises(skip: 0, take: 100, order: { name: ASC }) {
      items {
        id
        name
        domain
        subdomain
      }
    }
  }
`

interface UserEnterprisesData {
  userEnterprises: {
    items: Enterprise[]
  }
}

export const useUserEnterprises = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<UserEnterprisesData>(GET_USER_ENTERPRISES, {
    fetchPolicy: 'network-only',
  })

  const getUserEnterprises = () => {
    getResults({
      variables: {
        where: { active: { eq: true } },
      },
    })
  }

  return { getUserEnterprises, loading, error, results: data?.userEnterprises.items || [] }
}
