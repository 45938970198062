import React from 'react'
import { createStyles, makeStyles } from '@mui/styles'
import CheckIcon from '@mui/icons-material/Check'
import GppBadIcon from '@mui/icons-material/GppBad'
import { Chip, Theme } from '@mui/material'
import { useTranslation } from 'react-i18next'

type StatusChipsProps = {
  status: boolean
  label?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    active: {
      backgroundColor: '#E5F9ED',
      height: '70%',
      borderRadius: '5px',
    },
    inActive: {
      backgroundColor: '#F5F5F7',
      height: '70%',
      borderRadius: '5px',
    },
  }),
)

export const StatusChips = ({ status, label }: StatusChipsProps) => {
  const classes = useStyles()
  const { t: translation } = useTranslation()

  return status === true ? (
    <Chip
      label={translation(label ? label : 'ACTIVO') as string}
      className={classes.active}
      icon={<CheckIcon />}
      size="small"
      variant="outlined"
      color="success"
    />
  ) : (
    <Chip
      label={translation(label ? label : 'INACTIVO') as string}
      className={classes.inActive}
      icon={<GppBadIcon />}
      size="small"
      variant="outlined"
      color="error"
    />
  )
}
