import { Button, makeStyles, styled } from '@mui/material'
import { useAppTranslation } from '../../hooks'
import DownloadIcon from '@mui/icons-material/Download'
import { orange } from '@mui/material/colors'

type buttonProps = {
  onClick: () => void
  className?: string
  disabled?: boolean
  title?: string
}

const Download = styled(Button)(({ theme }) => ({
  color: '#ffffff',
  backgroundColor: orange[500],
  '&:hover': {
    backgroundColor: orange[700],
  },
}))

export const DownloadButton = ({ onClick, title, ...props }: buttonProps) => {
  const { label } = useAppTranslation()

  return (
    <Download {...props} variant="contained" onClick={onClick} startIcon={<DownloadIcon />}>
      {title || label('DESCARGAR')}
    </Download>
  )
}
