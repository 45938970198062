import { gql, useLazyQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../../../core/models'
import { ProgramTypeOfRaces } from '../../models'
import { useEffect, useState } from 'react'

const GET_PROGRAM_TYPE_OF_RACES = gql`
  query ProgramTypeOfRaces {
    programTypeOfRaces {
      items {
        code
        id
        name
        active
      }
    }
  }
`

interface TypeOfRacesData {
  programTypeOfRaces: {
    items: ProgramTypeOfRaces[]
    totalCount: number
  }
}

export const useProgramTypeOfRaces = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<TypeOfRacesData>(GET_PROGRAM_TYPE_OF_RACES, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<ProgramTypeOfRaces, Metadata>>({
    results: [],
    metadata: {},
  })

  useEffect(() => {
    if (data?.programTypeOfRaces) {
      setResults({
        results: data?.programTypeOfRaces.items || [],
        metadata: {
          Count: data?.programTypeOfRaces.totalCount,
        },
      })
    }
  }, [loading])

  const getProgramTypeOfRaces = ({ where, take, skip }: SearchGraphql) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 100,
      },
    })
  }

  return { getProgramTypeOfRaces, loading, error, results }
}
