import { Button, CircularProgress } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import { useTranslation } from 'react-i18next'

type buttonProps = {
  onClick?: () => void
  title: string
  color?: 'error' | 'inherit' | 'success' | 'primary' | 'secondary' | 'info' | 'warning'
  className?: string
  disabled?: boolean
  isLoading?: boolean
  size?: 'small' | 'medium' | 'large' | undefined
}
export const CustomButton = ({ onClick, isLoading, size, title, color, ...props }: buttonProps) => {
  const { t: transAction } = useTranslation()

  return (
    <Button
      {...props}
      variant="contained"
      color={color}
      onClick={onClick}
      size={size}
      startIcon={isLoading ? <CircularProgress size={20} color={'inherit'} /> : <SaveIcon />}
    >
      {transAction(title)}
    </Button>
  )
}
