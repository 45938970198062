import { Card, CardContent, Divider, Grid } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { BooleanRadioButton } from 'src/admin/core/components/RadioButtons/BooleanRadioButton'
import { useAppTranslation } from 'src/admin/core/hooks'

export const Form = () => {
  const { label } = useAppTranslation()

  return (
    <Card>
      <Divider />
      <CardContent>
        <Grid container spacing={3} pb={3}>
          <Grid item lg={6} md={6} xs={12} sm={12}>
            <Field fullWidth component={TextField} type="text" label={label('NOMBRE')} name="name" disabled={false} />
          </Grid>
          <Grid item lg={2} md={2} sm={12} xs={12}>
            <Field
              component={BooleanRadioButton}
              title={'ESTADO'}
              name="active"
              label1={'ACTIVO'}
              label2={'INACTIVO'}
              value1={'active'}
              value2={'inactive'}
              disabled={false}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
