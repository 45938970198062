import { gql, useMutation } from '@apollo/client'

const UPDATE_PROGRAM_EVENT = gql`
  mutation UpdateProgramEvent($input: UpsertProgramEventInput!) {
    upsertProgramEvent(input: $input) {
      id
      name
      date
      performanceCode
      numberOfRaces
      active
    }
  }
`

export interface UpdateProgramEventInput {
  id: number
  name: string
  date: Date
  performanceCode: string
  numberOfRaces: number
  active: boolean
}

export const useUpdateProgramEvent = () => {
  const [updateProgramEvent, { loading, error }] = useMutation<UpdateProgramEventInput>(UPDATE_PROGRAM_EVENT)
  return { updateProgramEvent, loading, error }
}
