import { Address } from 'src/admin/core/models'

export interface BaseEntity {
  id: string
  name: string
  active?: boolean
}

export interface Device extends BaseEntity {}
export interface Agency extends BaseEntity {
  products?: Products[]
}

export enum WorkstationProductsEnum {
  HORSE_RACES = 'HORSE_RACES',
  COCKFIGHTS = 'COCKFIGHTS',
  SPORTS = 'SPORTS',
}

export interface Enterprise extends BaseEntity {
  domain: string
  subdomain: string
  products?: WorkstationProductsEnum[]
  addresses?: Address[]
}

export type WorkstationType = 'NONE' | 'TERMINAL' | 'MOBILE' | 'KIOSK'

export type Workstation = {
  id: string
  name: string
  type: WorkstationType
  active: boolean
  enterprise: Enterprise
  agency: Agency
  device: Device
  accessList?: string[]
}

export type Payoff = {
  net: number
}

export type WagerPayoff = {
  claimed: boolean
  payoff: Payoff
}

export type HorseRacesWager = {
  eventId: string
  wagerPayoff: WagerPayoff
}

export type CockfightsWager = {
  eventId: string
  wagerPayoff: WagerPayoff
}

export type Products = {
  code: string
}

export enum GeographicTypeEnum {
  Unknown = -1,
  Point = 'POINT',
  MultiPoint = 'MultiPoint',
  LineString = 'LineString',
  MultiLineString = 'MultiLineString',
  Polygon = 'Polygon',
  MultiPolygon = 'MultiPolygon',
  GeometryCollection = 'GeometryCollection',
}

export type Geographic = {
  type: GeographicTypeEnum
  coordinates: number[]
  latitude?: number
  longitude?: number
}
