import { useCallback, useState } from 'react'
import { getAccessToken } from 'pb-shared'
import { RequestState } from '../models'
import { getEnvSettings } from '../services'

interface Response {
  result: RequestState
  errors: string[]
}

export const useRestClient = () => {
  const { REACT_APP_PB_GRAPH_BASE_URL } = getEnvSettings()
  const [response, setResponse] = useState<Response>({ result: 'None', errors: [] })

  const downloadFile = useCallback(
    async (url: string, fileName: string, type = 'blob'): Promise<any> => {
      const path = `${REACT_APP_PB_GRAPH_BASE_URL}${url}`

      const headers = new Headers()
      headers.append('Content-Type', 'application/octet-stream')
      headers.append('Authorization', 'Bearer ' + (getAccessToken() || 'NO_TOKEN'))

      const config = {
        method: 'GET',
        body: null,
        headers: headers,
        referrer: btoa('develop'),
        type: type,
      }

      const request = new Request(path, config)

      fetch(request)
        .then((res) => {
          return res.blob()
        })
        .then((data) => {
          let link = document.createElement('a')
          link.href = path
          link.setAttribute('download', fileName) //or any other extension
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          setResponse({ result: 'Success', errors: [] })
        })
        .catch((e) => {
          setResponse({ result: 'Unknown', errors: ['DESCARGA_FALLIDA'] })
        })
    },
    [REACT_APP_PB_GRAPH_BASE_URL],
  )

  return { downloadFile, response }
}
