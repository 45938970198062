import { gql, useMutation } from '@apollo/client'

const UPSERT_ENTERPRISE = gql`
  mutation UpsertEnterprise($input: UpsertEnterpriseInput!) {
    upsertEnterprise(input: $input) {
      id
      name
      subdomain
      domain
      active
      products
      addresses {
        countryId
      }
    }
  }
`

export const useUpsertEnterprise = () => {
  const [upsertEnterprise, { loading, error }] = useMutation(UPSERT_ENTERPRISE)
  return { upsertEnterprise, loading, error }
}
