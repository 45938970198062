import { useState, useEffect, useCallback } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { WeeklyCommission } from '../models/WeeklyCommission'
import { Metadata, ResultsList, SearchGraphql } from 'src/admin/core/models'

const GET_WEEKLY_COMMISSIONS = gql`
  query GetWeeklyComissions($where: WeeklyCommissionFilterInput!, $skip: Int, $take: Int) {
    weeklyComissions(where: $where, skip: $skip, take: $take, order: { location: ASC }) {
      totalCount
      items {
        commission
        productId
        product
        enterpriseId
        enterprise
        locationId
        location
        currencyId
        currency
        clientCode
        startOfWeek
        endOfWeek
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        balance
      }
    }
  }
`

interface Data {
  weeklyComissions: {
    totalCount: number
    items: WeeklyCommission[]
  }
}

export const useWeeklyCommissions = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<Data>(GET_WEEKLY_COMMISSIONS, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<WeeklyCommission, Metadata>>({
    results: [],
    metadata: {},
  })

  const getWeeklyCommissions = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({ variables: { where, take, skip } })
    },
    [getResults],
  )

  useEffect(() => {
    if (data?.weeklyComissions) {
      setResults({
        results: data?.weeklyComissions.items || [],
        metadata: {
          Count: data?.weeklyComissions.totalCount,
        },
      })
    }
  }, [loading])

  results.results = results.results.map((d, i) => {
    return { id: i + 1, ...d }
  })

  return { loading, error, results, getWeeklyCommissions }
}
