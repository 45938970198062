import { Enterprise } from 'src/core/models'
import { ActionType, createAction } from 'typesafe-actions'
import { Dictionary } from '../../models'
import { User } from 'oidc-client'

export const clearAction = createAction('CLEAR')()
export const globalSearchAction = createAction('GLOBAL_SEARCH')<string>()
export const tokenExpiredAction = createAction('TOKEN_EXPIRED')()
export const setUserEnterprise = createAction('SET_USER_ENTERPRISE')<Enterprise>()
export const setEnterprises = createAction('SET_ENTERPRISES')<Array<Enterprise>>()
export const setUserClaims = createAction('SET_USER_CLAIMS')<Dictionary>()
export const setIsAdmin = createAction('SET_IS_ADMIN')<boolean>()
export const setUser = createAction('SET_USER')<User>()
export const setAllowedModules = createAction('SET_ALLOWED_MODULES')<string[]>()

export type Actions =
  | ActionType<typeof clearAction>
  | ActionType<typeof globalSearchAction>
  | ActionType<typeof setUserEnterprise>
  | ActionType<typeof setEnterprises>
  | ActionType<typeof setUserClaims>
  | ActionType<typeof setIsAdmin>
  | ActionType<typeof setUser>
  | ActionType<typeof setAllowedModules>
