import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../../../core/models'
import { Wager } from '../../models'
import { useEffect, useState } from 'react'

const GET_WAGERS = gql`
  query GetWagers($where: WagerFilterInput, $skip: Int, $take: Int) {
    wagers(where: $where, skip: $skip, take: $take) {
      items {
        productId
        code
        id
        name
        active
      }
    }
  }
`

interface WagersData {
  wagers: {
    totalCount: number
    items: Wager[]
  }
}

export const useWagers = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<WagersData>(GET_WAGERS, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<Wager, Metadata>>({
    results: [],
    metadata: {},
  })

  useEffect(() => {
    if (data?.wagers) {
      setResults({
        results: data?.wagers.items || [],
        metadata: {
          Count: data?.wagers.totalCount,
        },
      })
    }
  }, [loading])

  const getWagers = ({ where, take, skip }: SearchGraphql) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 100,
      },
    })
  }

  return { getWagers, loading, error, results }
}
