import { useCallback, useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { Role } from '../models/Role'
import { Metadata, ResultsList, SearchGraphql } from 'src/admin/core/models'

const GET_ROLES = gql`
  query GetRoles($where: RoleFilterInput, $skip: Int, $take: Int) {
    roles(where: $where, skip: $skip, take: $take) {
      items {
        id
        name
        normalized
      }
    }
  }
`

export interface RoleData {
  roles: {
    items: Role[]
  }
}

export const useRole = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<RoleData>(GET_ROLES, { fetchPolicy: 'network-only' })

  const [results, setResults] = useState<ResultsList<Role, Metadata>>({
    results: [],
    metadata: {},
  })

  const getRoles = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({ variables: { where, take, skip } })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.roles.items || [],
      metadata: {},
    })
  }, [data?.roles.items, loading])

  return { loading, error, results, getRoles }
}
