import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { GridCellParams, GridRowParams } from '@mui/x-data-grid'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService'
// App Components
import { DataGridColumns } from 'src/admin/core/components/DataGrid/CustomDataGrid'
import { AdminDataGrid } from 'src/admin/core/components/DataGrid/AdminDataGrid'
import { Page } from 'src/admin/core/components'
import { CreateButton, generateFilterGraphql } from 'src/admin/core'
import { ContentDataGrid } from 'src/admin/core/components/DataGrid'

// Hooks
import { useUsers } from './hooks'

import { useAppTranslation, usePagination, useUserClaimActions } from 'src/admin/core/hooks'
import Filters, { FiltersProps, initialFilters } from './components/Filters'
import { customFormatDate } from 'src/admin/core/services/dateFunctions'
import { getAppBarState } from 'src/store'
import { ClaimActions } from 'src/admin/core/models'
import { action } from 'typesafe-actions'
import { User } from './models'
import { UserEnterprises } from './components/UserEnterprises'
import { LabelOff } from '@mui/icons-material'
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone'

const Users = () => {
  const theme = useTheme()
  const { label } = useAppTranslation()
  const navigate = useNavigate()
  const { results, loading, getUsers } = useUsers()
  const { page, pageSize, skip, handlePageChange, handlePageSizeChange } = usePagination()
  const [filters, setFilters] = useState<FiltersProps>(initialFilters)
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('security.users', [
    ClaimActions.CanCreate,
    ClaimActions.CanSearch,
    ClaimActions.CanView,
  ])
  const [showEnterprises, setShowEnterprises] = useState(false)
  const [user, setUser] = useState<User>(null)
  useEffect(() => {}, [validateClaimActions])

  useEffect(() => {
    const customFilters = {
      where: {
        or: [
          { userName: { ...generateFilterGraphql(filters.search, 'contains') } },
          { givenName: { ...generateFilterGraphql(filters.search, 'contains') } },
          { familyName: { ...generateFilterGraphql(filters.search, 'contains') } },
          { email: { ...generateFilterGraphql(filters.search, 'contains') } },
        ],
      },
      take: pageSize,
      skip: skip,
    }
    getUsers(customFilters)
  }, [filters, skip, pageSize])

  const getColumnDefinitions = useCallback((): DataGridColumns => {
    return {
      USUARIO: { field: 'userName', contentType: 'string' },
      NOMBRES: { field: 'givenName', contentType: 'string' },
      APELLIDOS: { field: 'familyName', contentType: 'string' },
      ACCION: {
        field: '',
        contentType: 'custom',
        renderCell: (params: any) => {
          const { row } = params
          return (
            <>
              <Tooltip title={label('VER')} arrow>
                <IconButton color="primary" onClick={() => goToEdit(row)}>
                  <LaunchTwoToneIcon fontSize="small" />
                </IconButton>
              </Tooltip>{' '}
              <Tooltip title={label('ESTACIONES')} arrow>
                <IconButton color="primary" onClick={() => onShowEnterprises(row)}>
                  <HomeRepairServiceIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          )
        },
      },
    }
  }, [])

  const goToCreate = () => {
    navigate(`/admin/security/users/create`)
  }

  const goToEdit = (row: GridRowParams) => {
    if (actions.canView) {
      navigate(`/admin/security/users/${row.id}`)
    }
  }

  const onShowEnterprises = (row: User) => {
    setShowEnterprises(true)
    setUser(row)
  }

  return (
    <>
      {user && <UserEnterprises show={showEnterprises} onClose={() => setShowEnterprises(false)} user={user} />}
      <Page
        hasAccess={actions.canView}
        tabTitle={label('USUARIOS_EMPLEADOS')}
        pageTitle={label('USUARIOS_EMPLEADOS')}
        pageSubTitle={label('GESTION_DE_USUARIOS_Y_EMPLEADOS')}
        actions={actions.canCreate && <CreateButton onClick={goToCreate} />}
      >
        {actions.canSearch && (
          <Grid item xs={12}>
            <Filters onChange={(filters) => setFilters({ ...filters })} />
          </Grid>
        )}
        <Grid item xs={12}>
          <ContentDataGrid>
            <AdminDataGrid
              columns={getColumnDefinitions()}
              data={results.results}
              count={results.metadata.Count}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              loading={loading}
              page={page}
              pageSize={pageSize}
            />
          </ContentDataGrid>
        </Grid>
      </Page>
    </>
  )
}

export default Users
