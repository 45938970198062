import { SaveButton, generateFilterGraphql } from 'src/admin/core'
import { useFormikContext } from 'formik'
import { useAppTranslation, useNotification } from 'src/admin/core/hooks'
import { ProgramMedication, ProgramSelectOption, Runner } from '../../models'
import { EventContext, setRunnerFormMode } from '../../state'
import { useContext, useEffect } from 'react'
import { useAddProgramRunner, useRunners, useUpdateProgramRunner } from '../../hooks'
import { useGetProgramOptionsByEntity } from 'src/admin/core/stores/programSelectOptions'

export const CreateButton: React.FC<{
  disabled: boolean
  runners: Runner[]
  medications: ProgramMedication[]
  afterSave?: (promise: Promise<any>) => void
}> = ({ runners, afterSave, disabled, medications }) => {
  const form = useFormikContext()
  const { values, dirty, isValid, setSubmitting } = form
  const { errorNotification } = useNotification()
  const { system } = useAppTranslation()
  const {
    dispatch,
    state: { event, race },
  } = useContext(EventContext)
  const { addProgramRunner, loading: saving } = useAddProgramRunner()
  const { getMedicationOptions } = useGetProgramOptionsByEntity()
  const { updateProgramRunner, loading } = useUpdateProgramRunner()
  const { getRunners, results, fetched } = useRunners()

  useEffect(() => {
    if (fetched) {
      if (results.results.length > 0) {
        errorNotification(system('ERROR_COMPETIDOR_EN_USO'))
      } else {
        handleCreate()
      }
    }
  }, [fetched, results])

  const triggerValidator = () => {
    const runnerToSave = values as Runner
    const customFilters = {
      and: [
        {
          programEventId: { ...generateFilterGraphql(event.id, 'eq') },
          competitorId: { ...generateFilterGraphql(runnerToSave.competitorId, 'eq') },
        },
      ],
    }
    getRunners({ where: customFilters, skip: 0, take: 100 })
  }
  const handleCreate = () => {
    setSubmitting(true)
    const runnerToSave = values as any

    if (race && race.id && isRunnerValid(runnerToSave)) {
      runnerToSave.programRaceId = race.id
      if (runnerToSave.number > race.runners) {
        errorNotification(system('ERROR_NUMERO_CORREDOR_MAYOR_A_NUMERO_CARRERAS'))
      } else {
        const position = Number.parseInt(runnerToSave.number)
        const number = String(runnerToSave.number)

        const medicationCode = getMedicationCode(runnerToSave.medication)

        const response = addProgramRunner({
          variables: {
            input: {
              programRaceId: runnerToSave.programRaceId,
              number: number,
              odds: runnerToSave.odds,
              position: position,
              competitorId: runnerToSave.competitorId,
              jockeyOrKennelId: runnerToSave.jockeyOrKennelId,
              trainerId: runnerToSave.trainerId,
              ownerId: runnerToSave.ownerId,
              weight: runnerToSave.weight,
              medication: medicationCode,
              active: runnerToSave.active,
              programEventId: event.id,
            },
          },
        })
        dispatch(setRunnerFormMode('CANCEL'))
        afterSave(response)
      }
    }
  }

  const getMedicationCode = (value: string): string => {
    const values: string[] = Array.isArray(value) ? value : value.split(',')
    const ids: string[] = []
    if (medications.length > 0 && values.length > 0) {
      values.forEach((val: string) => {
        const medication = medications.find((item: ProgramMedication) => {
          return item.name == val
        })
        if (medication !== undefined) {
          ids.push(medication.code)
        }
      })
    }
    if (ids.length > 0) {
      return ids.join('')
    }
    return ''
  }

  const isRunnerValid = (runnerToValidate: Runner): boolean => {
    let isValid = true
    runners.map((item: Runner) => {
      if (runnerToValidate.id !== item.id) {
        if (runnerToValidate.competitorId === item.competitorId) {
          errorNotification(system('ERROR_COMPETIDOR_EN_USO'))
          isValid = false
        }
        if (runnerToValidate.position === item.position) {
          errorNotification(system('ERROR_POSICION_EN_USO_POR_OTRA_CARRERA'))
          isValid = false
        }
        if (runnerToValidate.number === item.number) {
          errorNotification(system('ERROR_NUMERO_EN_USO'))
          isValid = false
        }
        if (runnerToValidate.jockeyOrKennelId === item.jockeyOrKennelId) {
          errorNotification(system('ERROR_JINETE_EN_USO'))
          isValid = false
        }
      }
    })
    return isValid
  }

  return <SaveButton onClick={triggerValidator} isLoading={saving} disabled={!(dirty && isValid) || disabled} />
}
