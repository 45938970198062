import { useState, useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { ProductEnterpriseModel } from '../models'

const GET_PRODUCT_ENTERPRISE = gql`
  query GetProductEnterprise {
    productEnterprise {
      items {
        enterpriseId
        productId
        productName
      }
    }
  }
`

interface Data {
  productEnterprise: {
    items: ProductEnterpriseModel[]
  }
}

export const useProductEnterprise = () => {
  const { loading, error, data } = useQuery<Data>(GET_PRODUCT_ENTERPRISE)

  const [results, setResults] = useState<ProductEnterpriseModel[]>([])

  useEffect(() => {
    setResults(data?.productEnterprise.items || [])
  }, [data?.productEnterprise.items, loading])

  return { loading, error, results }
}
