import { useTranslation } from 'react-i18next'
import { AllowedClaimValues } from '../models'
import { useUserClaimActions } from 'src/admin/core/hooks'
import { ClaimActions } from 'src/admin/core/models'
import { Checkbox, FormControlLabel, Grid, Typography, styled } from '@mui/material'
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material'

const HeaderTitle = styled(Typography)(
  ({ theme }) => `
  font-size: ${theme.typography.pxToRem(15)};
  flex-shrink: 0;
`,
)

const SecondaryHeader = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(15)};
    color: ${theme.palette.text.secondary};
    padding-right: 5%;
    margin-top: -10px;
`,
)

const ContentGrid = styled(Grid)(
  ({ theme }) => `
  min-width: 200px;
  text-align: 'right';
`,
)

const ControlLabel = styled(FormControlLabel)(
  ({ theme }) => `
  margin-left: 0;
`,
)

type PermissionAllowedClaimsProps = {
  title: string
  allowedClaimValues: Array<AllowedClaimValues> | undefined
}

export const PermissionAllowedClaims = ({ title, allowedClaimValues }: PermissionAllowedClaimsProps) => {
  const { t: transLabel } = useTranslation()
  const { t: transMenu } = useTranslation()
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('security.profiles', [ClaimActions.CanEdit])

  return (
    <Grid container>
      <Grid item sm={12} xs={12} md={2} lg={2}>
        <HeaderTitle>{transMenu(title)}</HeaderTitle>
      </Grid>

      {allowedClaimValues !== undefined && (
        <>
          <ContentGrid item sm={12} xs={12} md={1} lg={1}>
            <SecondaryHeader>
              {transLabel(allowedClaimValues[0].value)}
              <ControlLabel
                control={
                  <Checkbox
                    disabled={!actions.canEdit}
                    icon={<RadioButtonUnchecked fontSize="small" />}
                    checkedIcon={<RadioButtonChecked fontSize="small" />}
                    name="checkedH"
                  />
                }
                label=""
              />
            </SecondaryHeader>
          </ContentGrid>
          <ContentGrid item sm={12} xs={12} md={1} lg={1}>
            <SecondaryHeader>
              {transLabel(allowedClaimValues[1].value)}
              <ControlLabel
                control={
                  <Checkbox
                    disabled={!actions.canEdit}
                    icon={<RadioButtonUnchecked fontSize="small" />}
                    checkedIcon={<RadioButtonChecked fontSize="small" />}
                    name="checkedH"
                  />
                }
                label=""
              />
            </SecondaryHeader>
          </ContentGrid>
          <ContentGrid item sm={12} xs={12} md={1} lg={1}>
            <SecondaryHeader>
              {transLabel(allowedClaimValues[2].value)}
              <ControlLabel
                control={
                  <Checkbox
                    disabled={!actions.canEdit}
                    icon={<RadioButtonUnchecked fontSize="small" />}
                    checkedIcon={<RadioButtonChecked fontSize="small" />}
                    name="checkedH"
                  />
                }
                label=""
              />
            </SecondaryHeader>
          </ContentGrid>
          <ContentGrid item sm={12} xs={12} md={1} lg={1}>
            <SecondaryHeader>
              {transLabel(allowedClaimValues[3].value)}
              <ControlLabel
                control={
                  <Checkbox
                    disabled={!actions.canEdit}
                    icon={<RadioButtonUnchecked fontSize="small" />}
                    checkedIcon={<RadioButtonChecked fontSize="small" />}
                    name="checkedH"
                  />
                }
                label=""
              />
            </SecondaryHeader>
          </ContentGrid>
          <ContentGrid item sm={12} xs={12} md={1} lg={1}>
            <SecondaryHeader>
              {transLabel(allowedClaimValues[4].value)}
              <ControlLabel
                control={
                  <Checkbox
                    disabled={!actions.canEdit}
                    icon={<RadioButtonUnchecked fontSize="small" />}
                    checkedIcon={<RadioButtonChecked fontSize="small" />}
                    name="checkedH"
                  />
                }
                label=""
              />
            </SecondaryHeader>
          </ContentGrid>
          <ContentGrid item sm={12} xs={12} md={1} lg={1}>
            <SecondaryHeader>
              {transLabel(allowedClaimValues[5].value)}
              <ControlLabel
                control={
                  <Checkbox
                    disabled={!actions.canEdit}
                    icon={<RadioButtonUnchecked fontSize="small" />}
                    checkedIcon={<RadioButtonChecked fontSize="small" />}
                    name="checkedH"
                  />
                }
                label=""
              />
            </SecondaryHeader>
          </ContentGrid>
          <ContentGrid item sm={12} xs={12} md={1} lg={1}>
            <SecondaryHeader>
              {transLabel(allowedClaimValues[6].value)}
              <ControlLabel
                control={
                  <Checkbox
                    disabled={!actions.canEdit}
                    icon={<RadioButtonUnchecked fontSize="small" />}
                    checkedIcon={<RadioButtonChecked fontSize="small" />}
                    name="checkedH"
                  />
                }
                label=""
              />
            </SecondaryHeader>
          </ContentGrid>
        </>
      )}
    </Grid>
  )
}
