import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useAppTranslation } from 'src/admin/core/hooks'
import { useParams } from 'react-router-dom'
import { useUser } from './useUser'
import { User } from '../models'
import { phoneRegExp } from 'src/admin/core'
import _ from 'lodash'
import { excludedRolesForNotUserAdmin } from 'src/admin/core/services/userClaims'

const initialValues: User = {
  id: '',
  givenName: '',
  familyName: '',
  nickname: '',
  email: '',
  phoneNumber: '',
  birthdate: new Date(),
  address: '',
  zoneinfo: '',
  locale: '',
  gender: '',
  roles: [],
  active: false,
  documentId: '',
}

export const useUserFormHandler = () => {
  const { label } = useAppTranslation()
  const { id } = useParams()
  const { getUser, data, loading } = useUser()
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    if (id && !loading) {
      fetchUser(id)
    }
  }, [id])

  const fetchUser = (id: string) => {
    getUser(id)
  }

  useEffect(() => {
    if (data?.user) {
      const clonedUser = _.cloneDeep(data?.user)
      const roles = clonedUser.roles.filter((role: string) => !excludedRolesForNotUserAdmin.includes(role))
      clonedUser.roles = roles
      clonedUser.documentId = ''
      setUser(clonedUser)
    }
  }, [data])

  const formSchemaCreating = Yup.object().shape({
    givenName: Yup.string().required(label('REQUERIDO')),
    familyName: Yup.string().required(label('REQUERIDO')),
    phoneNumber: Yup.string().required(label('REQUERIDO')).matches(phoneRegExp, label('VALOR_NO_VALIDO')).nullable(),
    roles: Yup.array().min(1, label('REQUERIDO')),
    documentId: Yup.string().min(11, label('MINIMO_11')).required(label('REQUERIDO')),
    email: Yup.string().email(label('CORREO_INVALIDO')).max(255).required(label('REQUERIDO')),
  })

  const formSchemaUpdating = Yup.object().shape({
    givenName: Yup.string().required(label('REQUERIDO')),
    familyName: Yup.string().required(label('REQUERIDO')),
    phoneNumber: Yup.string().required(label('REQUERIDO')).matches(phoneRegExp, label('VALOR_NO_VALIDO')).nullable(),
    roles: Yup.array().min(1, label('REQUERIDO')),
    email: Yup.string().email(label('CORREO_INVALIDO')).max(255).required(label('REQUERIDO')),
  })

  return {
    loading,
    formSchema: id ? formSchemaUpdating : formSchemaCreating,
    initialValues: user || initialValues,
    fetchUser: fetchUser,
  }
}
