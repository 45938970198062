import { useState, useEffect, useCallback } from 'react'
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Metadata, ResultsList } from 'src/admin/core/models'
import { Enterprise } from 'src/core/models'

const GET_ENTERPRISES = gql`
  query getAllEnterprisesQuery($where: EnterpriseFilterInput, $skip: Int, $take: Int) {
    enterprises(where: $where, skip: $skip, take: $take) {
      items {
        id
        name
        active
        domain
        subdomain
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface EnterprisesData {
  enterprises: {
    items: Enterprise[]
    totalCount: number
  }
}

export const useEnterprises = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<EnterprisesData>(GET_ENTERPRISES, {
    fetchPolicy: 'network-only',
  })
  const [fetched, setFetched] = useState(false)
  const [results, setResults] = useState<ResultsList<Enterprise, Metadata>>({
    results: [],
    metadata: {},
  })

  const getEnterprises = ({ where, take, skip }: any) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 20,
      },
    })
  }

  useEffect(() => {
    if (data?.enterprises) {
      setResults({
        results: data?.enterprises.items || [],
        metadata: {
          Count: data?.enterprises.totalCount,
        },
      })
      setFetched(true)
    }
  }, [loading])

  return {
    fetched,
    loading,
    error,
    count: data?.enterprises.totalCount || 0,
    results: data?.enterprises.items || [],
    getEnterprises,
  }
}
