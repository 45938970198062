import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Button, Grid, Typography } from '@mui/material'

// Theme Components
import Footer from 'src/admin/components/Footer'
import PageTitleWrapper from 'src/admin/components/PageTitleWrapper'
import { Loader } from 'src/admin/components/Loader'
import { SecurityPage } from './SecurityPage'

type PageProps = {
  tabTitle?: string
  pageTitle?: string | React.ReactElement
  pageSubTitle?: string | React.ReactElement
  loading?: boolean
  children: React.ReactElement | React.ReactElement[]
  actions?: React.ReactElement | React.ReactElement[]
  hasAccess?: boolean
}

export const Page = ({ tabTitle, pageTitle, pageSubTitle, actions, loading, hasAccess, children }: PageProps) => {
  return (
    <SecurityPage hasAccess={hasAccess}>
      {tabTitle && (
        <Helmet>
          <title>{tabTitle}</title>
        </Helmet>
      )}
      <PageTitleWrapper>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            {pageTitle && (
              <Typography variant="h3" component="h3" gutterBottom>
                {pageTitle}
              </Typography>
            )}

            {pageSubTitle && <Typography variant="subtitle2">{pageSubTitle}</Typography>}
          </Grid>
          {actions && <Grid item>{actions}</Grid>}
        </Grid>
      </PageTitleWrapper>
      <Grid
        sx={{
          px: 4,
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={2}
      >
        <Loader loading={loading}>{children}</Loader>
      </Grid>
      <Footer />
    </SecurityPage>
  )
}
