import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Metadata, ResultsList, SearchGraphql } from '../../../../../core/models'
import { ProgramOwner } from '../../models'
import { useEffect, useState } from 'react'

const GET_OWNERS = gql`
  query GetProgramOwners($where: ProgramOwnerFilterInput!, $skip: Int, $take: Int) {
    programOwners(where: $where, skip: $skip, take: $take, order: { name: ASC }) {
      items {
        id
        name
        trainerId
        active
        programTrainer {
          id
          name
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface OwnersData {
  programOwners: {
    totalCount: number
    items: ProgramOwner[]
  }
}

export const useProgramOwners = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<OwnersData>(GET_OWNERS, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<ProgramOwner, Metadata>>({
    results: [],
    metadata: {},
  })

  useEffect(() => {
    if (data?.programOwners) {
      setResults({
        results: data?.programOwners.items || [],
        metadata: {
          Count: data?.programOwners.totalCount,
        },
      })
    }
  }, [loading])

  const getProgramOwners = ({ where, take, skip }: SearchGraphql) => {
    getResults({
      variables: {
        where: { ...where },
        skip: skip ? skip : 0,
        take: take ? take : 100,
      },
    })
  }

  return { getProgramOwners, loading, error, results }
}
