import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useCallback, useState } from 'react'
import { Enterprise } from '../models'

const GET_ENTERPRISE = gql`
  query GetEnterprise($enterpriseId: String!) {
    enterprise(id: $enterpriseId) {
      id
      name
      domain
      subdomain
      active
      products
      addresses {
        countryId
      }
    }
  }
`

export interface EnterpriseQuery {
  enterprise: Enterprise
}

export const useEnterprise = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<EnterpriseQuery>(GET_ENTERPRISE, {
    fetchPolicy: 'network-only',
  })

  const getEnterprise = useCallback(
    (id: string) => {
      getResults({
        variables: { enterpriseId: id },
      })
    },
    [getResults],
  )

  return { getEnterprise, loading, error, data }
}
