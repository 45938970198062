import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from 'react-i18next'

type buttonProps = {
  onClick: () => void
  className?: string
  disabled?: boolean
}
export const AddButton = ({ onClick, ...props }: buttonProps) => {
  const { t: transAction } = useTranslation('Action')

  return (
    <Button {...props} variant="contained" color="primary" onClick={onClick} startIcon={<AddIcon />}>
      {transAction('CREAR')}
    </Button>
  )
}
