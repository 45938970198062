import { gql, useLazyQuery } from '@apollo/client'

const GET_PEOPLE = gql`
  query GetPeople($peopleId: String!) {
    people(id: $peopleId) {
      id
      identificationType
      identificationNumber
      firstName
      lastName
      nickName
      birthdate
      cellPhone
      email
      gender
      active
    }
  }
`

export const usePeople = () => {
  const [getResults, { loading, error, data }] = useLazyQuery(GET_PEOPLE, {
    fetchPolicy: 'network-only',
  })

  const getPeople = (peopleId: string) => {
    getResults({
      variables: {
        peopleId,
      },
    })
  }

  return { getPeople, loading, error, data }
}
