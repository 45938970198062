import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useAppTranslation } from '../../hooks'

export const ProductSelect = ({ onChange, value }: { value: string; onChange?: (value: string) => void }) => {
  const { label } = useAppTranslation()

  const handleChange = (event: SelectChangeEvent) => {
    const prod = event.target.value as string
    if (onChange) {
      onChange(prod)
    }
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="product-simple-select-label">{label('PRODUCTO')}</InputLabel>
      <Select
        fullWidth
        labelId="product-simple-select-label"
        id="product-simple-select"
        value={value}
        label="product"
        onChange={handleChange}
      >
        <MenuItem value={''}>{label('')}</MenuItem>
        <MenuItem value={'HORSE_RACES'}>{label('HORSE_RACES')}</MenuItem>
        <MenuItem value={'COCKFIGHTS'}>{label('COCKFIGHTS')}</MenuItem>
        <MenuItem value={'SPORTS'}>{label('SPORTS')}</MenuItem>
      </Select>
    </FormControl>
  )
}
