import { useState, useEffect, useCallback } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { WeeklyCommission } from '../models/WeeklyCommission'
import { Metadata, ResultsList, SearchGraphql } from 'src/admin/core/models'

const GET_WEEKLY_COMMISSIONS = gql`
  query GetWeeklyComissionsTotal($where: WeeklyCommissionFilterInput!, $skip: Int, $take: Int) {
    weeklyComissionsTotal(where: $where, skip: $skip, take: $take) {
      totalCount
      items {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        balance
        commission
      }
    }
  }
`

interface Data {
  weeklyComissionsTotal: {
    totalCount: number
    items: WeeklyCommission[]
  }
}

export const useWeeklyCommissionsTotals = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<Data>(GET_WEEKLY_COMMISSIONS, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<WeeklyCommission, Metadata>>({
    results: [],
    metadata: {},
  })

  const getWeeklyCommissionsTotals = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({ variables: { where, take, skip } })
    },
    [getResults],
  )

  useEffect(() => {
    if (data?.weeklyComissionsTotal) {
      setResults({
        results: data?.weeklyComissionsTotal.items || [],
        metadata: {
          Count: data?.weeklyComissionsTotal.totalCount,
        },
      })
    }
  }, [loading])

  return { loading, error, results, getWeeklyCommissionsTotals }
}
