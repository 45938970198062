import React, { useState } from 'react'
import {
  Typography,
  ClickAwayListener,
  Tooltip,
  Button,
  styled,
  createStyles,
  Theme,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { CustomAlert } from 'src/admin/core/components/alerts'
import { Link } from 'react-router-dom'

type UserCredentialsBannerProps = {
  userName: string
  password?: string
  code?: string
}

const useStyles = styled(() =>
  createStyles({
    copyButton: {
      marginTop: -5,
    },
  }),
)

export const UserCredentialsBanner = ({ userName, password, code }: UserCredentialsBannerProps) => {
  const { t: transLabel } = useTranslation()
  const [copyUsername, setCopyUsername] = useState(false)
  const [copyURL, setCopyURL] = useState(false)
  const [copyCode, setCopyCode] = useState(false)
  const origin = window.location.origin
  const resetPasswordPath = '/passwordreset'
  const URL = `${origin}${resetPasswordPath}`

  return (
    <Box width={'50%'}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} align="left">
                {transLabel('CREDENCIALES_DEL_USUARIO_UNICA_VEZ_MOSTRADO')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                <strong>{transLabel('USUARIO')}</strong>
              </TableCell>
              <TableCell align="left">
                <strong>{transLabel('CODIGO')}</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <ClickAwayListener onClickAway={() => setCopyUsername(false)}>
                  <div>
                    {userName}&nbsp;&nbsp;&nbsp;
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => setCopyUsername(false)}
                      open={copyUsername}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={transLabel('COPIADO') as string}
                    >
                      <Button color="primary" variant="outlined" size="small">
                        <CopyToClipboard text={userName} onCopy={() => setCopyUsername(true)}>
                          {copyUsername ? <DoneAllIcon color="success" /> : <FileCopyIcon color={'primary'} />}
                        </CopyToClipboard>
                      </Button>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              </TableCell>
              <TableCell align="left">
                <ClickAwayListener onClickAway={() => setCopyCode(false)}>
                  <div>
                    {code}&nbsp;&nbsp;&nbsp;
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => setCopyCode(false)}
                      open={copyCode}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={transLabel('COPIADO') as string}
                    >
                      <Button color="primary" variant="outlined" size="small">
                        <CopyToClipboard text={code} onCopy={() => setCopyCode(true)}>
                          {copyCode ? <DoneAllIcon color="success" /> : <FileCopyIcon color={'primary'} />}
                        </CopyToClipboard>
                      </Button>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
