import { SaveButton } from 'src/admin/core'
import { useFormikContext } from 'formik'
import { Event } from '../../models'
import { useUpdateProgramEvent } from '../../hooks'
import { dateIsBeforeThanTodayMoment, formatToShortLocalDate } from 'src/admin/core/services/dateFunctions'
import { useAppTranslation, useNotification } from 'src/admin/core/hooks'

export const UpdateButton: React.FC<{ afterSave?: (promise: Promise<any>) => void }> = ({ afterSave }) => {
  const { updateProgramEvent, loading, error } = useUpdateProgramEvent()
  const form = useFormikContext()
  const { values, dirty, isValid, setSubmitting } = form
  const { system } = useAppTranslation()
  const { errorNotification } = useNotification()

  const handleSave = () => {
    const formValues = values as Event
    if (!dateIsBeforeThanTodayMoment(formValues.date)) {
      setSubmitting(true)

      const data: Event = {
        name: formValues.name,
        date: formatToShortLocalDate(formValues.date, 'MM/DD/YYYY'),
        performanceCode: formValues.performanceCode,
        numberOfRaces: formValues.numberOfRaces,
        active: formValues.active,
      }

      const response = updateProgramEvent({
        variables: {
          input: {
            id: formValues.id,
            name: data.name,
            date: data.date,
            performanceCode:
              typeof data.performanceCode === 'object' ? data.performanceCode?.code : data.performanceCode,
            numberOfRaces: data.numberOfRaces,
            active: data.active,
          },
        },
      })
      if (afterSave) {
        afterSave(response)
      }
      setSubmitting(false)
    } else {
      errorNotification(system('LA_FECHA_DEL_EVENTO_HA_PASADO'))
    }
  }

  return <SaveButton onClick={handleSave} isLoading={loading} disabled={!dirty} />
}
