import { gql, useLazyQuery } from '@apollo/client'
import { SearchGraphql, PageInfo, ResultsList, Metadata } from '../../../../core/models'
import { User } from '../models'
import { useEffect, useState } from 'react'
import { Enterprise } from 'src/admin/scenes/entities/enterprises/models'
import { getAppBarState } from 'src/store'

const GET_USERS = gql`
  query GetUsers(
    $enterpriseId: String!
    $skip: Int
    $take: Int
    $where: UserWithClaimsFilterInput
    $order: [UserWithClaimsSortInput!]
  ) {
    enterpriseUsers(enterpriseId: $enterpriseId, where: $where, order: $order, take: $take, skip: $skip) {
      items {
        id
        userName
        email
        givenName
        familyName
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

interface UsersData {
  enterpriseUsers: {
    totalCount: number
    items: User[]
    pageInfo: PageInfo
  }
}

export const useUsers = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<UsersData>(GET_USERS, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<User, Metadata>>({
    results: [],
    metadata: {},
  })
  const { userEnterprise } = getAppBarState().appState

  useEffect(() => {
    if (data?.enterpriseUsers) {
      setResults({
        results: data?.enterpriseUsers.items || [],
        metadata: {
          Count: data?.enterpriseUsers.totalCount,
        },
      })
    }
  }, [loading])

  const getUsers = ({ where, take, skip }: SearchGraphql, order?: any) => {
    const strEnterprise = window.sessionStorage.getItem('selectedEnterprise')
    const enterprise = JSON.parse(strEnterprise) as Enterprise

    getResults({
      variables: {
        where: { ...where },
        enterpriseId: userEnterprise?.id,
        skip: skip ? skip : 0,
        take: take ? take : 100,
        order: order ? order : { userName: 'DESC' },
      },
    })
  }

  const users = results.results.map((user) => {
    const clonedUser = { ...user }
    clonedUser.fullName = `${clonedUser.familyName} ${clonedUser.givenName}`
    return clonedUser
  })
  return { loading, error, users, results, getUsers }
}
