import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Agency } from '../models'

const GET_AGENCY = gql`
  query GetAgency($agencyId: String!) {
    agency(id: $agencyId) {
      id
      name
      type
      active
      phone
      addresses {
        address
        cityOrSectorId
        countryId
        countyOrMunicipalityId
        stateOrProvinceId
      }
      products {
        code
        providerCode
        name
      }
      geographic {
        type
        coordinates
      }
    }
  }
`

interface Data {
  agency: Agency
}

export const useAgency = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<Data>(GET_AGENCY, {
    fetchPolicy: 'network-only',
  })
  const [agency, setAgency] = useState<Agency>(null)

  const getAgency = (agencyId: string) => {
    getResults({
      variables: { agencyId },
    })
  }

  useEffect(() => {
    if (data?.agency) {
      setAgency(data.agency)
    }
  }, [loading])

  return { getAgency, loading, error, agency }
}
