import { FormPage, PageTab, PageHeader, FormContent, BackButton } from 'src/admin/core'
import { useAppTranslation, useNotification, useUserClaimActions } from 'src/admin/core/hooks'
import { Form } from './Form'
import { useNavigate } from 'react-router-dom'
import { useCallback, useState } from 'react'
import _ from 'lodash'
import { CreateButton } from './CreateButton'
import { UpdateButton } from './UpdateButton'
import { Button, Stack, styled } from '@mui/material'
import { green } from '@mui/material/colors'
import { ClaimActions } from 'src/admin/core/models'
import { SecurityPage } from 'src/admin/core/components/Page/SecurityPage'
import { usePeopleFormHandler } from '../hooks/usePeopleFormHandler'
import { People as PeopleModel } from '../models'
import { Loader } from 'src/admin/components/Loader'

const ColorButton = styled(Button)(() => ({
  root: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))

const People: React.FC = () => {
  const { label, system } = useAppTranslation()
  const navigate = useNavigate()
  const { successNotification, errorNotification } = useNotification()
  const { formSchema, initialValues, loading } = usePeopleFormHandler()
  const [isReloadingUser, setReloadingUser] = useState(false)
  const { validateClaimActions } = useUserClaimActions()
  const actions = validateClaimActions('security.users', [
    ClaimActions.CanCreate,
    ClaimActions.CanView,
    ClaimActions.CanEdit,
  ])
  const disabledForm = !actions.canEdit
  const actionPassword = validateClaimActions('security.change-password', [
    ClaimActions.CanCreate,
    ClaimActions.CanEdit,
  ])

  const afterSave = useCallback((response: Promise<any>, action?: string) => {
    response
      .then((res) => {
        successNotification(label(initialValues?.id ? 'DATO_ACTUALIZADO' : 'DATO_REGISTRADO'))
        navigate(`/admin/entities/peoples`)
      })
      .catch((e) => {
        console.error(e)
        errorNotification(label(e.message))
      })
  }, [])
  return (
    <SecurityPage hasAccess={actions.canView}>
      <FormPage
        form={{
          formSchema: formSchema,
          formValues: initialValues,
        }}
      >
        <PageTab text={label('PERSONA_TITULO')} />
        <PageHeader
          title={label('PERSONA_SUB_TITULO')}
          actions={
            <Stack spacing={1} direction={'row'}>
              <BackButton />
              {initialValues && initialValues.id ? (
                <>{actions.canEdit && <UpdateButton afterSave={afterSave} />}</>
              ) : (
                actions.canCreate && <CreateButton afterSave={afterSave} />
              )}
            </Stack>
          }
        />
        <FormContent>
          <Form people={initialValues} disabledForm={disabledForm} />
        </FormContent>
      </FormPage>
    </SecurityPage>
  )
}

export default People
