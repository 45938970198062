import { UserManager, WebStorageStateStore, Log, User, Profile, UserManagerSettings } from 'oidc-client'
import jwt_decode from 'jwt-decode'
import { getEnvSettings } from '../services'

export interface AuthContextInterface {
  getUserManager: () => UserManager
  signinRedirectCallback: () => void
  logout: () => void
  signoutRedirectCallback: () => void
  isAuthenticated: () => boolean
  signinRedirect: () => void
  signinSilentCallback: () => void
  createSigninRequest: () => void
  getUserProfile: () => Promise<Profile>
}

export interface AuthConfiguration {
  authority: string
  clientId: string
  scope: string
  redirectUri?: string
  silentRedirectUri?: string
  postLogoutRedirectUri?: string
}

export const getHostUrl = () => {
  return `${window.location.protocol}//${window.location.host}`
}

export const getOidcMetadata = () => {
  const { authority } = getAuthConfiguration()
  return {
    issuer: authority,
    jwks_uri: authority + '/.well-known/openid-configuration/jwks',
    authorization_endpoint: authority + '/connect/authorize',
    token_endpoint: authority + '/connect/token',
    userinfo_endpoint: authority + '/connect/userinfo',
    end_session_endpoint: authority + '/connect/endsession',
    check_session_iframe: authority + '/connect/checksession',
    revocation_endpoint: authority + '/connect/revocation',
    introspection_endpoint: authority + '/connect/introspect',
  }
}

export const getAuthConfiguration = (): UserManagerSettings => {
  const envSettings = getEnvSettings()

  const settings = {
    authority: envSettings.REACT_APP_PB_AUTH_URL, // (string): The URL of the OIDC provider.
    client_id: envSettings.REACT_APP_PB_AUTH_CLIENT_ID, // (string): Your client application's identifier as registered with the OIDC provider.
    response_type: 'code', // (string, default: 'id_token'): The type of response desired from the OIDC provider.
    scope: envSettings.REACT_APP_PB_AUTH_SCOPE || 'openid profile', // (string, default: 'openid'): The scope being requested from the OIDC provider.
    automaticSilentRenew: /true/i.test(envSettings.REACT_APP_PB_AUTH_AUTOMATIC_SILENT_RENEW) || true,
    silentRequestTimeout: envSettings.REACT_APP_PB_AUTH_SILENT_REQUEST_TIMEOUT || 10000,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    loadUserInfo: true, // (boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
    redirect_uri: `${envSettings.REACT_APP_PB_AUTH_REDIRECT_URI || getHostUrl()}/signin-oidc`, // The URI of your client application to receive a response from the OIDC provider.
    silent_redirect_uri: `${envSettings.REACT_APP_PB_AUTH_SILENT_REDIRECT_URI || getHostUrl()}/silentrenew`, // (string): The URL for the page containing the code handling the silent renew.
    post_logout_redirect_uri: `${
      envSettings.REACT_APP_PB_AUTH_POST_LOGOUT_REDIRECT_URI || getHostUrl()
    }/logout/callback`, // (string): The OIDC post-logout redirect URI.
  }

  return settings
}

export const getAccessToken = (): string | undefined => {
  const { authority, client_id } = getAuthConfiguration()
  const key = `oidc.user:${authority}:${client_id}`
  const data = sessionStorage.getItem(key) ?? ''
  if (data && data.length > 0) {
    const oidcStorage = JSON.parse(data)
    return oidcStorage.access_token
  } else {
    return undefined
  }
}

export class AuthService implements AuthContextInterface {
  UserManager?: UserManager

  Settings?: UserManagerSettings

  constructor(settings?: UserManagerSettings, useDebug?: boolean) {
    // Logger
    if (!!useDebug) {
      Log.logger = console
      //  Log.level = Log.DEBUG
    }
    this.Settings = settings
  }

  getUserManager = (): UserManager => {
    if (!this.UserManager) {
      this.UserManager = new UserManager({
        ...getAuthConfiguration(),
        ...this.Settings,
        userStore: new WebStorageStateStore({ store: window.sessionStorage }),
        metadata: {
          ...getOidcMetadata(),
        },
      })

      this.UserManager.events.addUserLoaded(() => {
        if (window.location.href.indexOf('signin-oidc') !== -1) {
          this.navigateToScreen()
        }
      })

      this.UserManager.events.addSilentRenewError((e) => {
        // tslint:disable-next-line:no-console
        console.log('silent renew error', e.message)
      })

      this.UserManager.events.addAccessTokenExpired(() => {
        // tslint:disable-next-line:no-console
        console.log('token expired')
        this.signinSilent()
      })
    }

    return this.UserManager
  }

  signinRedirectCallback = async () => {
    // tslint:disable-next-line:no-console
    console.log('called signinRedirectCallback')
    try {
      const user = await this.getUserManager().signinRedirectCallback()
    } catch (err) {
      this.getUserManager().clearStaleState()
      this.getUser()
    }
  }

  getUser = async () => {
    const user = await this.getUserManager().getUser()
    if (user === null || user === undefined) {
      return this.getUserManager().signinRedirectCallback()
    }
    return user
  }

  getUserProfile = async () => {
    const user = await this.getUser()
    return user?.profile
  }

  parseJwt = (token: string) => {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  }

  signinRedirect = () => {
    localStorage.setItem('redirectUri', window.location.pathname)
    this.getUserManager().signinRedirect()
  }

  navigateToScreen = () => {
    const url = localStorage.getItem('redirectUri') || '/'
    window.location.replace(url)
  }

  isAuthenticated = () => {
    const token = getAccessToken()
    if (!token) {
      return false
    }
    const expired = Date.now() >= JSON.parse(atob(token.split('.')[1])).exp * 1000
    return !expired
  }

  signinSilent = () => {
    this.getUserManager()
      .signinSilent()
      .then((user) => {
        // tslint:disable-next-line:no-console
        console.log('signed in silent', user)
      })
      .catch((err) => {
        // tslint:disable-next-line:no-console
        console.log('Failed to complete silent token renewal. sign again.')
        // tslint:disable-next-line:no-console
        console.log(err)
        this.getUserManager().clearStaleState()
        this.signinRedirect()
      })
  }

  signinSilentCallback = () => {
    // tslint:disable-next-line:no-console
    console.log('called signinSilentCallback')
    this.getUserManager().signinSilentCallback()
  }

  createSigninRequest = () => {
    // tslint:disable-next-line:no-console
    console.log('called createSigninRequest')
    return this.getUserManager().createSigninRequest()
  }

  logout = () => {
    this.getUserManager().signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
    })
    this.getUserManager().clearStaleState()
  }

  signoutRedirectCallback = () => {
    this.getUserManager()
      .signoutRedirectCallback()
      .then(() => {
        localStorage.clear()
        window.location.replace(getHostUrl() ?? '/')
      })
    this.getUserManager().clearStaleState()
  }

  getUserEnterpriseIds = async () => {
    const user = await this.getUser()
    const claims = jwt_decode(user?.access_token)
    const enterpriseIds = claims['placebet-enterprise']

    return Array.isArray(enterpriseIds) ? enterpriseIds : [enterpriseIds]
  }
}
