import { getAppBarState } from 'src/store'

import { MenuItems } from 'src/admin/layouts/AccentHeaderLayout/Sidebar/SidebarMenu/items'

export const useUserSettings = () => {
  const { isAdmin, allowedModules } = getAppBarState().appState

  const getAllowedMenu = (menuItems: MenuItems[]): MenuItems[] => {
    if (!isAdmin) {
      const modules = menuItems.filter((menu: MenuItems) => allowedModules.includes(menu.allowedModules))
      return modules
    }
    return menuItems
  }

  const getUserModules = (): string[] => {
    const { userClaims } = getAppBarState().appState
    const userModules: string[] = []
    Object.keys(userClaims).forEach((key) => {
      userModules.push(key)
    })
    // Remove similar modules
    return userModules.filter((item, idx, userModules) => userModules.indexOf(item) === idx)
  }

  return { getAllowedMenu, getUserModules }
}
