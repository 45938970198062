import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Event } from '../../models'

const GET_EVENT = gql`
  query GetProgramEvent($id: String!) {
    programEvent(id: $id) {
      id
      name
      date
      performanceCode
      numberOfRaces
      active
    }
  }
`

interface EventData {
  programEvent: Event
}

export const useEvent = () => {
  const [get, { loading, error, data }] = useLazyQuery<EventData>(GET_EVENT, {
    fetchPolicy: 'network-only',
  })

  const getEvent = (id: string) => {
    if (id) {
      get({
        variables: {
          id: id,
        },
      })
    }
  }

  const event = data?.programEvent || undefined
  return { getEvent, loading, error, event }
}
