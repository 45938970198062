import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useAppTranslation } from 'src/admin/core/hooks'
import { formatAsCurrency } from 'src/admin/core'
import { styled } from '@mui/material'
import { EventContext } from '../../../state'
import React, { useCallback, useContext } from 'react'
import { RaceWagers } from './RaceWagers'

const CustomTable = styled(Table)(({ theme }) => ({
  '& .MuiTableCell-root': {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
}))

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  textTransform: 'none',
}))

export const ReportContent = () => {
  const { label } = useAppTranslation()

  const {
    state: { races, typeOfRaces },
  } = useContext(EventContext)

  const getTypeOfRace = useCallback(
    (code): string => {
      try {
        return typeOfRaces.filter((type) => type.code == code)[0].name
      } catch (error) {
        return ''
      }
    },
    [typeOfRaces],
  )

  return (
    <>
      {races.map((race, index) => {
        return (
          <React.Fragment key={index}>
            <TableContainer>
              <CustomTable size="small">
                <TableHead>
                  {/* Race */}
                  <TableRow>
                    <CustomTableCell align="left" sx={{ height: '10px' }} colSpan={10} />
                  </TableRow>
                  <TableRow>
                    <CustomTableCell colSpan={10} align="left" sx={{ textDecoration: 'none' }}>{`${label('CARRERA')} #${
                      race.raceNumber
                    }, ${race.distance?.name} ${label('METROS')}, ${label('PREMIO')} RD${formatAsCurrency(
                      Number(race.purse),
                    )}`}</CustomTableCell>
                  </TableRow>
                  <TableRow>
                    <CustomTableCell colSpan={10} align="left">
                      {`${race.ageRestriction} ${getTypeOfRace(race.typeOfRace)}, `}
                      <RaceWagers race={race} />
                    </CustomTableCell>
                  </TableRow>

                  {/* Runners */}
                  <TableRow>
                    <CustomTableCell align="left" colSpan={10} />
                  </TableRow>
                  <TableRow>
                    <CustomTableCell sx={{ width: '25px' }}>{label('No')}</CustomTableCell>
                    <CustomTableCell>{label('Odds')}</CustomTableCell>

                    <CustomTableCell>{label('COMPETIDOR')}</CustomTableCell>

                    <CustomTableCell>{label('MEDICAMENTO')}</CustomTableCell>

                    <CustomTableCell>{label('PESO')}</CustomTableCell>

                    <CustomTableCell>{label('JINETE')}</CustomTableCell>
                    <CustomTableCell>{label('PROPIETARIO')}</CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {race.programRunners.map((runner, index) => {
                    return (
                      <TableRow key={index}>
                        <CustomTableCell sx={{ width: '25px' }}>{runner.number}</CustomTableCell>
                        <CustomTableCell>{runner.odds}</CustomTableCell>

                        <CustomTableCell>{runner.competitor?.name}</CustomTableCell>

                        <CustomTableCell>{runner.medication}</CustomTableCell>

                        <CustomTableCell>{runner.weight}</CustomTableCell>

                        <CustomTableCell>{runner.jockeyOrKennel?.name}</CustomTableCell>
                        <CustomTableCell>{runner.owner?.name}</CustomTableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </CustomTable>
            </TableContainer>
          </React.Fragment>
        )
      })}
    </>
  )
}
