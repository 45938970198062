import React from 'react'
import { Box, CircularProgress, styled } from '@mui/material'

const LoaderContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'flex-center',
  width: '100%',
  height: '100%',
  my: 5,
  color: theme.palette.secondary.dark
}))

export const LoadingAnimation = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        my: 5
      }}
    >
      <CircularProgress />
    </Box>
  )
}

interface ComponentProps {
  children: React.ReactNode
  component?: React.ReactElement
  loading: boolean
}

export const Loader = ({ children, component, loading }: ComponentProps) => {
  return loading ? (
    <React.Fragment>
      <LoaderContent>
        {!!component ? (
          <React.Fragment>{component}</React.Fragment>
        ) : (
          <LoadingAnimation />
        )}
      </LoaderContent>
    </React.Fragment>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  )
}
