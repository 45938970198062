import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

const Text = styled('span')(
  ({ theme }) => `       
    padding: ${theme.spacing(0.5)};
    padding-top: ${theme.spacing(2)};
    color: ${theme.palette.error.main};
        
  `,
)

export const FormAsterisk = ({ children, validate }: { validate?: boolean; children: React.ReactElement }) => {
  return (
    <Stack direction={'row'}>
      {children}
      <Text>{validate ? '*' : ''}</Text>
    </Stack>
  )
}
