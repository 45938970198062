import { useState, useEffect, useCallback } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { DailyCommission } from '../models/DailyCommission'
import { Metadata, ResultsList, SearchGraphql } from 'src/admin/core/models'

const GET_DAiLY_COMMISSIONS = gql`
  query GetDailyCommissions($where: DailyCommissionFilterInput, $skip: Int, $take: Int) {
    dailyComissions(where: $where, skip: $skip, take: $take, order: { location: ASC }) {
      totalCount
      items {
        id
        balance
        cancelledAmount
        cashedAmount
        clientCode
        commission
        currency
        currencyId
        enterprise
        enterpriseId
        location
        locationId
        product
        productId
        salesDate
        surcharge
        wagerAmount
        weekday
      }
    }
  }
`

interface DailyCommissionsData {
  dailyComissions: {
    totalCount: number
    items: DailyCommission[]
  }
}

export const useDailyCommissions = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<DailyCommissionsData>(GET_DAiLY_COMMISSIONS, {
    fetchPolicy: 'network-only',
  })

  const [results, setResults] = useState<ResultsList<DailyCommission, Metadata>>({
    results: [],
    metadata: {},
  })

  const getDailyCommissions = useCallback(
    ({ where, take, skip }: SearchGraphql) => {
      getResults({ variables: { where, take, skip } })
    },
    [getResults],
  )

  useEffect(() => {
    setResults({
      results: data?.dailyComissions.items || [],
      metadata: {
        Count: data?.dailyComissions.totalCount,
      },
    })
  }, [data?.dailyComissions.items, loading])

  return { loading, error, results, getDailyCommissions }
}
