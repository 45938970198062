import React from 'react'
import { Grid, Typography } from '@mui/material'
import Footer from 'src/admin/components/Footer'
import { WrapperFormikProvider } from '../Form/WrapperFormikProvider'
import { IForm } from '../../models'

type FormPageProps = {
  form: IForm
  children: JSX.Element | JSX.Element[]
}

export const FormPage: React.FC<FormPageProps> = ({ form, children }) => (
  <WrapperFormikProvider form={{ ...form }}>
    {Array.isArray(children) ? (
      children.map((child, index) =>
        child.type?.displayName === 'FormContent' ? (
          <React.Fragment key={index}>
            <Grid
              sx={{
                px: 4,
              }}
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={2}
            >
              {child}
            </Grid>
          </React.Fragment>
        ) : (
          child
        ),
      )
    ) : children.type?.displayName === 'FormContent' ? (
      <React.Fragment>
        <Grid
          sx={{
            px: 4,
          }}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
        >
          {children}
        </Grid>
      </React.Fragment>
    ) : (
      children
    )}
  </WrapperFormikProvider>
)
