import { Link as RouterLink } from 'react-router-dom'
import { Box, Card, Link, Tooltip, Typography, Container, Alert, styled, Grid, Button } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import useAuth from 'src/hooks/useAuth'
import Form from './Form'
import { useTranslation } from 'react-i18next'
import PlaceBetIcon from 'src/admin/core/svgIcons/PlaceBet/PlaceBetIcon'
import ChangedPasswordSuccessAlert from './components/ChangedPasswordSuccessAlert'
import { useState } from 'react'

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`,
)

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  padding: 20px;
`,
)

function PasswordReset() {
  const { t }: { t: any } = useTranslation()
  const [changeSuccess, setChangeSuccess] = useState(false)

  return (
    <>
      <Helmet>
        <title>{'Placebet reinicio de clave'}</title>
      </Helmet>
      <MainContent>
        <TopWrapper>
          <Container maxWidth="sm">
            <Box
              style={{
                marginLeft: '25%',
              }}
            >
              <PlaceBetIcon width="60%" />
            </Box>
            <Card
              sx={{
                mt: 3,
                px: 4,
                pt: 5,
                pb: 3,
              }}
            >
              <Box>
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1,
                  }}
                >
                  {t('CAMBIAR_CLAVE')}
                </Typography>
              </Box>
              {!changeSuccess ? (
                <Form handleAfterSave={(res) => setChangeSuccess(res)} />
              ) : (
                <ChangedPasswordSuccessAlert />
              )}
              <Box mt={2}>
                {!changeSuccess ? (
                  <Link component={RouterLink} to="/admin/statistics/sales-profit-dashboard">
                    <b>{t('INICIAR_SESION')}</b>
                  </Link>
                ) : (
                  <Button variant="text" onClick={() => window.close()} type="button">
                    {t('CONTINUAR')}
                  </Button>
                )}
              </Box>
            </Card>
          </Container>
        </TopWrapper>
      </MainContent>
    </>
  )
}

export default PasswordReset
