import { ApolloError } from '@apollo/client'
import { SnackbarMessage, useSnackbar } from 'notistack'
import { GraphErrorList } from '../components/display/GraphErrorList'
// import List from '@mui/material/List'
// import ListItem from '@mui/material/ListItem'
// import ListItemIcon from '@mui/material/ListItemIcon'
// import ListItemText from '@mui/material/ListItemText'
// import ListSubheader from '@mui/material/ListSubheader'
// import WifiIcon from '@mui/icons-material/Wifi'

interface EnqueueOptions {
  /** Type of the snackbar */
  variant: 'default' | 'error' | 'success' | 'warning' | 'info'
  /** Event fired when user clicks on action button (if any) */
  onClickAction(): void
  /**
   * You can pass material-ui Snackbar props here, and they will be applied to this individual snackbar.
   * for example, this particular snackbar will be dismissed after 1sec.
   */
  autoHideDuration: number
}

interface EnqueueSnackbarOptions {
  options?: Partial<EnqueueOptions>
}

/**
 * https://iamhosseindhv.com/notistack
 * @description This show notification in app
 * @example Wrapping notistick lib to manage all notifications
 * @returns [
 *              successNotification: show a green bar for success Notifications,
 *              errorNotification: show a red bar error Notifications,
 *           ]
 */
export const useNotification = () => {
  // Manage notifications
  const { enqueueSnackbar } = useSnackbar()

  const successNotification = async (message: SnackbarMessage, options?: EnqueueSnackbarOptions) => {
    enqueueSnackbar(message, { variant: 'success', ...options })
  }

  const errorNotification = async (message: SnackbarMessage, options?: EnqueueSnackbarOptions) => {
    enqueueSnackbar(message, { variant: 'error', ...options })
  }

  const errorAPINotification = async (message: SnackbarMessage, options?: EnqueueSnackbarOptions) => {
    enqueueSnackbar(message, { variant: 'error', ...options })
  }

  const warningNotification = async (message: SnackbarMessage, options?: EnqueueSnackbarOptions) => {
    enqueueSnackbar(message, { variant: 'warning', ...options })
  }

  const infoNotification = async (message: SnackbarMessage, options?: EnqueueSnackbarOptions) => {
    enqueueSnackbar(message, { variant: 'info', ...options })
  }

  const errorGraphNotification = async (error: ApolloError, options?: EnqueueSnackbarOptions) => {
    enqueueSnackbar(<GraphErrorList errors={error?.graphQLErrors} />, { variant: 'error', ...options })
  }

  return {
    successNotification,
    errorNotification,
    errorAPINotification,
    warningNotification,
    infoNotification,
    errorGraphNotification,
  }
}
