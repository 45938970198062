import { Grid } from '@mui/material'
import PageTitleWrapper from 'src/admin/components/PageTitleWrapper'
import { PageActions } from './PageActions'
import { PageSubTitle } from './PageSubTitle'
import { PageTitle } from './PageTitle'

type PageHeaderProps = {
  title?: string
  subTitle?: string
  actions?: React.ReactElement | React.ReactElement[]
}

export const PageHeader: React.FC<PageHeaderProps> = ({ title, subTitle, actions }: PageHeaderProps) => {
  return (
    <PageTitleWrapper>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          {title && <PageTitle>{title}</PageTitle>}

          {subTitle && <PageSubTitle>{subTitle}</PageSubTitle>}
        </Grid>
        {actions && <PageActions>{actions}</PageActions>}
      </Grid>
    </PageTitleWrapper>
  )
}
